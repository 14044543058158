import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {NumberComponent} from './number.component';
import {FieldModule} from './../field/field.module';
import {FormsModule} from '@angular/forms';
import {FormatterModule} from '../formatter/formatter.module';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     FormsModule,
     FieldModule,
     FormatterModule
  ],
  declarations:[
    NumberComponent
  ],
  exports: [
    NumberComponent
  ],
  bootstrap: [NumberComponent]
})
export class NumberModule { }
