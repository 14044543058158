<div class="admin-layout">
  <div class="admin-nav">
    <ul class="spot-list-group spot-list-group--interactive">
      <li class="spot-list-group__item spot-list-group__item--header">
        <span class="spot-list-group__header"> Admin </span>
      </li>
      <li class="spot-list-group__item">
        <a href="#/admin/user" class="spot-list-group__link">
          <svg class="spot-icon spot-list-group__item-icon" aria-labelledby="title">
            <title>users</title>
            <use xlink:href="/assets/spot/dist/icons/spot_icons.svg#users"></use>
          </svg>
          <span class="spot-list-group__item-label"> Users </span>
        </a>
      </li>
      <li class="spot-list-group__item">
        <a href="#/admin/cat-assign" class="spot-list-group__link">
          <svg class="spot-icon spot-list-group__item-icon" aria-labelledby="title">
            <title>list</title>
            <use xlink:href="/assets/spot/dist/icons/spot_icons.svg#list"></use>
          </svg>
          <span class="spot-list-group__item-label"> Assigned Categories </span>
        </a>
      </li>
      <hr>
      <li class="spot-list-group__item">
        <a href="#/admin/system-settings" class="spot-list-group__link">
          <svg class="spot-icon spot-list-group__item-icon" aria-labelledby="title">
            <title>test-lab</title>
            <use xlink:href="/assets/spot/dist/icons/spot_icons.svg#test-lab"></use>
          </svg>
          <span class="spot-list-group__item-label"> System Settings </span>
        </a>
      </li>
    </ul>
  </div>
  <div class="admin-body">
    <router-outlet></router-outlet>
  </div>
</div>
