import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { ISessionPanel } from '@shared/model/session-panel';
import { IPriceData } from '@irl_aws/shared/lib/price';
import { Service } from '../service/service';
import type { PriceAPI } from '../../../../../services/lib/price/price.api';
import { UtilService } from '../../services/util/util.service';

@Injectable()
export class PriceService extends Service<typeof PriceAPI> {
    async priceComponents({ session, sapId, panel }: { session: number; sapId: string; panel: number }): Promise<IPriceData[]> {
        try {
            const resp = await firstValueFrom(
                super.post('/price/components', {
                    body: {
                        session,
                        sapId,
                        panel
                    }
                })
            );
            if (!resp.success) {
                console.error('price.service.priceComponents failed: ', resp.error);
                throw new Error('price.service.priceComponents failed: ' + UtilService.getErrorMessage(resp.error));
            }
            return resp.data;
        } catch (err) {
            console.error('Error calling price.service.priceComponents: ', err);
            throw err;
        }
    }

    async price({ session, sapId, panel }: { session: number; sapId: string; panel: number }): Promise<ISessionPanel> {
        try {
            const resp = await firstValueFrom(
                super.post('/price', {
                    body: {
                        session,
                        sapId,
                        panel
                    }
                })
            );
            if (!resp.success) {
                console.error('price.service.price failed: ', resp.error);
                throw new Error('price.service.price failed: ' + UtilService.getErrorMessage(resp.error));
            }
            return resp.data;
        } catch (err) {
            console.error('Error calling price.service.price: ', err);
            throw err;
        }
    }
}
