import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentsModule} from './../../components/components.module';
import {BrowserModule} from '@angular/platform-browser';
import {FeedbackComponent} from './feedback.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     FormsModule,
     ComponentsModule
  ],
  providers: [
  ],
  declarations: [
      FeedbackComponent
  ],
  exports: [
    FeedbackComponent
  ],
  bootstrap: [FeedbackComponent]
})
export class FeedbackModule { }
