import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ComponentsModule } from '../../components/components.module';
import { SAPComponent } from './sap.component';
import { UserService } from '../../services/user/user.service';
import { AccountService } from '../../services/account/account.service';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ComponentsModule,
        TranslocoModule,
    ],
    providers: [
        UserService,
        AccountService
    ],
    declarations: [
        SAPComponent
    ],
    exports: [
        SAPComponent
    ],
    bootstrap: [SAPComponent]
})
export class SAPModule { }
