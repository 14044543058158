import {Directive, ElementRef, SkipSelf} from '@angular/core';
import {AbstractActions} from './actions';


@Directive({
  selector: '[madActions]',
  providers: [{provide: AbstractActions, useExisting: AbstractActions}]
}) export class ActionsDirective {
  //injects the parent action, we use this to inject the madActions element
  //this is useful for template injection
  constructor (@SkipSelf() public action: AbstractActions, public element: ElementRef) {
    action.element = this.element;
    action._updateBindings();
  }
}
