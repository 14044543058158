import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {ResizeModule} from './../resize/resize.module';
import {SpringComponent} from './spring.component';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     ResizeModule
  ],
  declarations: [
    SpringComponent
  ],
  exports: [
    SpringComponent
  ],
  bootstrap: [SpringComponent]
})
export class SpringModule { }
