import { Injectable } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { filter, map } from 'rxjs/operators'

export class EventData {
    topic: string
    payload: any

    constructor(name, payload) {
        this.topic = name
        this.payload = payload
    }
}

@Injectable({ providedIn: 'root' })
export class EventService {
    private _subject = new Subject()

    constructor() {}

    emit(event: EventData) { this._subject.next(event) }

    on(topic: string, action: any): Subscription {
        return this._subject.pipe(
            filter( (e: EventData) => e.topic == topic ),
            map( (e: EventData) => e["payload"] )
        ).subscribe(action)
    }
}
