<mad-field>
    <a #link [class]="linkClass" [title]="title" [href]="href" [attr.target]="target" [attr.tabindex]="getTabIndex()">
        <span #leftIconWrapper class="mad-link-icon-wrapper">
            <ng-content select="[left],mad-icon:not([right]),mad-avatar:not([right])"></ng-content>
        </span>
        <span #contentWrapper class="mad-link-content-wrapper">
            <ng-content></ng-content>
        </span>
        <span #rightIconWrapper class="mad-link-icon-wrapper">
            <ng-content select="[right]"></ng-content>
        </span>
    </a>
</mad-field>
