import { Injectable, ViewChild, Component, ElementRef, Input, OnChanges, OnInit, Output, EventEmitter, HostBinding } from "@angular/core";
import { Actions, Subscription } from "./../actions/actions";


@Injectable()
export class PillActions extends Actions({
    name: 'mad-pill',
    types: {
        "pill-remove": { name: "Remove pill" },
        "pill-action": { name: "Icon action for pill" }
    },
    targets: {
        "pill-icon": {
            matcher: ".cl-pill-icon"
        },
        "removable": {
            matcher: ".removable"
        }
    },
    interactions: {
        keydown: {
            Backspace: {
                "removable": "pill-remove",
            }
        },
        click: {
            'pill-icon': 'pill-action'
        }
    }
}) { };


@Component({
    selector: "mad-pill",
    templateUrl: "./pill.component.html",
    styleUrls: ["./pill.component.scss"],
    providers: PillActions.Providers()
})
export class PillComponent implements OnInit, OnChanges {
    @ViewChild("icon") _icon: ElementRef;
    @ViewChild("selectable") _selectable: ElementRef<HTMLAnchorElement>;

    @Input() outline: boolean = true;
    @Input() type: "primary" | "secondary" | "positive" | "negative" | "warning" | "leopard" | "leopard-box" | "mad-secondary" | "mad" | string = "mad";
    //@Input() icon: "delete" | "add" = null;
    @Input() selectable: boolean = false;
    @Input() removable: boolean = false;

    @Output() action: EventEmitter<PillActions['_value']> = new EventEmitter();

    @HostBinding('class') class;

    subscription: Subscription;

    constructor(public element: ElementRef, public _action: PillActions) {
    }

    ngOnInit() {
        this.ngOnChanges();
        this.subscription = this._action.subscribe((action) => {
            switch (action.type) {
                case "pill-remove":
                case "pill-action":
                    this.doAction(action); break;
            }
        });
    }

    ngOnChanges() {
        this.class = this.getPillClass();
    }

    doAction(action: PillActions['_value']) {
        action.$event.preventDefault();
        //console.error('doing action');
        this.action.emit(action);
    }

    getPillClass() {
        return `cl-pill spot-pill spot-pill--${this.type} ${this.outline ? "spot-pill--outline" : ""} ${this.removable ? 'removable' : ''} ${this.selectable ? 'selectable' : ''}`;
    }

    focus() {
        if (this._selectable) {
            this._selectable.nativeElement.focus();
        }
    }

}
