import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {ButtonComponent} from './button.component';
import {FieldModule} from './../field/field.module';
import {IconModule} from './../icon/icon.module';
import {RippleModule} from '../ripple/ripple.module';

@NgModule({
  imports: [
     BrowserModule,
     RippleModule,
     CommonModule,
     FieldModule,
     IconModule
  ],
  declarations: [
    ButtonComponent
  ],
  exports: [
    ButtonComponent
  ],
  bootstrap: [ButtonComponent]
})
export class ButtonModule { }
