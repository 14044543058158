import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: "[madRipple]"
})
export class RippleDirective {
  constructor (public el: ElementRef) {
    this.onMouseDown = this.onMouseDown.bind(this);
  }

  ngOnInit () {
    this.el.nativeElement.addEventListener('mousedown', this.onMouseDown, true);
  }

  ngOnDestroy () {
    this.el.nativeElement.removeEventListener('mousedown', this.onMouseDown, true);
  }

  onMouseDown (event: MouseEvent) {
    var target = this.el.nativeElement as HTMLElement,
      rippleWrapper = document.createElement('div'),
      ripple = document.createElement('div'),
      max = Math.max(target.offsetWidth, target.offsetHeight);
     
    rippleWrapper.style.position = 'absolute';
    rippleWrapper.style.left = '0';
    rippleWrapper.style.top = '0';
    rippleWrapper.style.overflow = 'hidden';
    rippleWrapper.style.borderRadius = 'inherit';
    target.appendChild(rippleWrapper);

    var rippleBox = rippleWrapper.getBoundingClientRect(),
      targetBox = target.getBoundingClientRect();

    rippleWrapper.style.left = `${targetBox.left - rippleBox.left}px`;
    rippleWrapper.style.top = `${targetBox.top - rippleBox.top}px`;
    rippleWrapper.style.width = `${targetBox.width}px`
    rippleWrapper.style.height = `${targetBox.height}px`
    rippleWrapper.style.pointerEvents = 'none';

    ripple.style.position = 'absolute';
    ripple.style.borderRadius = '50%';
    ripple.style.width = ripple.style.height = `${max}px`;
    ripple.style.backgroundColor = 'rgba(0,0,0,0.1)';
    ripple.style.transform = `scale(${Math.min( 25 / max, 0.5)})`
    ripple.style.transition = 'transform 1000ms ease-out, opacity 1000ms ease-out';
    ripple.style.opacity = '1';
    ripple.style.left = `${event.pageX - targetBox.left - max / 2}px`
    ripple.style.top = `${event.pageY - targetBox.top - max / 2}px`
    rippleWrapper.appendChild(ripple);
    ripple.addEventListener('transitionend', () => rippleWrapper.parentNode && rippleWrapper.parentNode.removeChild(rippleWrapper));
    requestAnimationFrame(() => {
      ripple.style.transform = `scale(2)`;
      ripple.style.opacity = '0';
    });
  }

}
