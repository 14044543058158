import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FormatterDirective} from './formatter.directive';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule
  ],
  declarations:[
      FormatterDirective
  ],
  exports: [
      FormatterDirective
  ],
  bootstrap: []
})
export class FormatterModule { }
