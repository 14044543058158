import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Service } from './../service/service';
import { ISession } from '@shared/model/session';
import type { SessionAPI } from '../../../../../services/lib/session/session.api';
import { UtilService } from '../../services/util/util.service';

@Injectable()
export class SessionService extends Service<typeof SessionAPI> {
    subscribe({ sap, session }: { sap: string; session: number }) {
        return super.ws('/session/subscribe', {
            params: {
                sap,
                session
            }
        });
    }

    submit({ session, sapId }: { session: number, sapId: string }) {
        return super.sse('/session/submit', {
            params: { session, sapId }
        });
    }

    async update({ sap, session, field, value }: { sap: string; session: number; field: string; value: any }): Promise<ISession> {
        try {
            const resp = await firstValueFrom(
                super.post('/session/update', {
                    body: {
                        sap,
                        session,
                        field,
                        value
                    }
                })
            );
            if (!resp.success) {
                console.error("session.update failed: ", resp.error);
                throw new Error("session.update failed: " + UtilService.getErrorMessage(resp.error));
            }
            return resp.data;
        } catch (err) {
            console.error("Error calling session.update: ", err);
            throw err;
        }
    }

    refresh({ sap, session, session_panel_ids, invoice_ids }: { sap: string; session: number; session_panel_ids: number[]; invoice_ids: number[]; }) {
        return super.post('/session/refresh', {
            body: {
                sap,
                session,
                session_panel_ids,
                invoice_ids
            }
        });
    }

    approve({ session, sapId }: { session: number; sapId: string }) {
        return super.sse('/session/approve', {
            params: { session, sapId }
        });
    }

    reject({ session, sapId }: { session: number; sapId: string }) {
        return super.sse('/session/reject', {
            params: { session, sapId }
        });
    }
}
