import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {EmailFaxComponent} from './email-fax.component';
import {FieldModule} from './../field/field.module';
import {SpringModule} from './../spring/spring.module';
import {InputModule} from './../input/input.module';
import {FormsModule} from '@angular/forms';
import {PillModule} from './../pill/pill.module';
import {IconModule} from './../icon/icon.module';
import { ButtonModule } from '../button/button.module';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     FormsModule,
     SpringModule,
     FieldModule,
     InputModule,
     PillModule,
     IconModule,
     ButtonModule

  ],
  declarations:[
    EmailFaxComponent
  ],
  exports: [
    EmailFaxComponent
  ],
  bootstrap: [EmailFaxComponent]
})
export class EmailFaxModule { }
