<mad-modal #modal [visible]="visible" [mask]="mask">
  <ng-content select="mad-header" ngProjectAs="mad-header"></ng-content>
  <mad-header *ngIf="title">{{title}}</mad-header>
  <!--<mad-body>-->
    <!--<ng-content select="mad-body"></ng-content>-->
    {{body}}
    <ng-content></ng-content>
  <!--</mad-body>-->
  <ng-content select="mad-footer"></ng-content>
  <mad-footer>
    <mad-button (click)="button.click()" *ngFor="let button of buttons">
      {{button.text}}
    </mad-button>
  </mad-footer>
</mad-modal>
