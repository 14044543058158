import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { Service } from './../service/service';
import { SearchResponse } from '@services/lib/lims/lims.service';
import type { LIMSAPI, SearchGetRequest } from '../../../../../services/lib/lims/lims.api';
import { UtilService } from '../../services/util/util.service';
import type { Panel } from '@irl_aws/shared/lib/panel';

@Injectable()
export class LIMSService extends Service<typeof LIMSAPI> {

    async search({ search, texts, components, top, limit, sap, sort, sort_dir }: SearchGetRequest['params']): Promise<SearchResponse> {
        try {
            const params: { search?: string; sap?: string; texts?: string[], sort?: 'qty'; sort_dir?: 'asc' | 'desc'; components?: string[]; top?: number; limit?: number } = {};
            if (sap !== undefined) {
                params.sap = sap;
            }
            if (search) {
                params.search = search;
            }
            if (texts) {
                params.texts = texts;
            }
            if (components) {
                params.components = components;
            }
            if (top) {
                params.top = top;
            }
            if (limit) {
                params.limit = limit;
            }
            if (sort) {
                params.sort = sort;
            }
            if (sort_dir) {
                params.sort_dir = sort_dir;
            }

            const resp = await firstValueFrom(
                super.get('/lims/search', {
                    params
                })
            );
            return resp;
        } catch (err) {
            console.error("Error calling lims.search: ", err);
            throw err;
        }
    }

    async match(params: { matchCode: string }): Promise<Panel[]> {
        try {
            const resp = await firstValueFrom(
                super.get('/lims/match', {
                    params
                })
            );
            if (!resp.success) {
                console.error("lims.match failed: ", resp.error);
                throw new Error("lims.match failed: " + UtilService.getErrorMessage(resp.error));
            }
            return resp.data;
        } catch (err) {
            console.error("Error calling lims.match: ", err);
            throw err;
        }
    }

    uploadCompetitiveTestList(file: File): Observable<any> {

        const formData = new FormData();
        formData.append('file', file);

        // return this.post('/lims/uploadCompetitiveTestList', formData);
        return of(true);
    }
}
