import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {AvatarComponent} from './../avatar/avatar.component';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule
  ],
  declarations: [
    AvatarComponent
  ],
  exports: [
    AvatarComponent
  ],
  bootstrap: [AvatarComponent]
})
export class AvatarModule { }
