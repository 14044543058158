import { MadError, MadErrorUtils as Utils } from '../../../../shared/model/error';

export class MadErrorUtils extends Utils { }

export class MadClientError extends MadError {
    public static readonly CLIENT_ERROR_NAME = 'MadClientError';

    public constructor(code: string, message?: string, err?: Error | null, context?: any | null, isSystemError?: boolean) {
        super(code, message, err, context, isSystemError);

        // Required because we are extending a built-in class
        Object.setPrototypeOf(this, MadClientError.prototype);

        this.name = MadClientError.CLIENT_ERROR_NAME;
    }
}
