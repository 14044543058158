import { Component, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PRICE_TYPES } from "@shared/model/session-panel";
import { IUserClaims } from '@shared/model/user';
import { IInvoice } from '@shared/model/invoice';
import { SpringComponent } from '../../components/spring/spring.component';
import { SelectedPanelsComponent } from '../selected-panels/selected-panels.component';
import { ReferenceService, IReferenceDataMapped } from '../../services/reference/reference.service';
import { UserState } from '../../states/user/user.state';
import { AccountState } from '../../states/account/account.state';
import { FileUtil } from '../../utils/file';
import { ISelectedPanel } from '../../states/session/session-panels';
import { Reason } from '@shared/model/session';

const FILE_SIZE_MB_LIMIT = 20;

@Component({
    selector: 'mad-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss', '../selected-panels/style/panel.scss']
})
export class SummaryComponent extends SelectedPanelsComponent implements OnDestroy {
    @Output() back = new EventEmitter<any>();

    @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

    @ViewChild('form') form: SpringComponent;

    @ViewChild('formLink') formLink: ElementRef;

    isVisible = true;

    files: File[] = [];

    isReviewing = false;

    isFormVisible: boolean = false;

    referenceSub: Subscription;

    formShortcut: boolean;

    userSub: Subscription;

    public reasons: { label: string; value: string }[];

    public isPreApprovalModalVisible = false;

    public isPricingApprovalModalVisible = false;

    constructor(
        public referenceService: ReferenceService,
        public accountState: AccountState,
        public element: ElementRef,
        public userState: UserState,
        public changeDetector: ChangeDetectorRef
    ) {
        super(userState, accountState, element, referenceService);
        this.referenceSub = referenceService.data.subscribe((v: IReferenceDataMapped): void => {
            if (v && v.reasons) {
                this.reasons = v.reasons.map(({ reason: value, description: label }): { label: string; value: string } => ({ label, value }));
            }
        });
        this.userSub = this.userState.changes.subscribe((v: IUserClaims): void => {
            let changed = false;
            if (v) {
                if (!v.can_approve && !this.isReviewing) {
                    this.pager.setTotal(0);
                    changed = true;
                } else if (v.can_approve && this.session && this.session.state.reviewing) {
                    changed = true;
                }
            }
            if (changed && this.session) {
                this.onSessionChanges();
            }
        });
    }

    ngOnDestroy(): void {
        this.referenceSub.unsubscribe();
        if (this.userSub) {
            this.userSub.unsubscribe();
        }
    }

    onSessionChanges(): void {
        if (this.isReviewing || (this.userState.state && this.userState.state.can_approve && this.session && this.session.state.reviewing)) {
            super.onSessionChanges();
        }
    }

    getTotalInvoice(): number {
        const panels = this.session.panels.data;
        let total = 0;
        panels.forEach((panel: ISelectedPanel): void => {
            total += panel.projectedLabInvoiceImpact || 0;
        });
        return total;
    }

    getNonMatchWidth(limsId: HTMLElement): number {
        return super.getNonMatchWidth(limsId) - 230;
    }

    onReviewClick(): void {
        this.isReviewing = true;
        this.isTransitioning = true;
        this.isFormVisible = true;

        setTimeout(() => {
            this.isTransitioning = false;
        }, 501);

        this.onSessionChanges();
    }

    onBackClick(): void {
        this.back.emit(true);
    }

    onFileChange(): void {
        const fileInput = this.fileInput;
        const files = fileInput.nativeElement.files;
        const limit = FILE_SIZE_MB_LIMIT;

        for (let i = 0; i < files.length; i += 1) {
            const file = files[i];
            const size = file.size / 1000000;

            if (size <= limit) {
                this.files.push(file);
                this.session.invoices.add(file);
            }
        }

        this.fileInput.nativeElement.value = null;
        this.fileInput.nativeElement.files = null;
    }

    onRemoveInvoice(invoice: IInvoice): void {
        this.session.invoices.remove(invoice.id);
    }

    onScroll(): void {
        if (this.form.element.nativeElement.offsetTop + this.form.element.nativeElement.offsetHeight < this.infiniteList.element.nativeElement.scrollTop) {
            this.formShortcut = true;
        } else {
            this.formShortcut = false;
        }
    }

    public shouldShowFormButton(): boolean {
        return this.isReviewing && !this.session.ssa_reviewing && this.needsPricingApproval();
    }

    public toggleShowForm(): boolean {
        this.isFormVisible = !this.isFormVisible;
        return this.isFormVisible;
    }

    containsCompetitivePanel(): boolean {
        const panels = this.session && this.session.panels && this.session.panels.data;
        let containsCompetitive = false;
        if (panels) {
            containsCompetitive = panels.some((panel: ISelectedPanel): boolean => {
                return panel.type === PRICE_TYPES.COMPETITIVE;
            });
        }
        return containsCompetitive;
    }

    needsPricingApproval(): boolean {
        return !this.session.preapproved;
    }

    public shouldShowScanForm(): boolean {
        return this.isReasonCompetitivePressure() || this.containsCompetitivePanel();
    }

    private isReasonCompetitivePressure(): boolean {
        return this.session.state.reason === Reason.CompetitivePressure;
    }

    async onInvoiceDownload(data: IInvoice): Promise<void> {
        FileUtil.download(
            await this.session.invoiceService.download({
                session: this.session.state.id,
                invoice: data.id
            })
        );
    }

    public onConfirmReviewClick(type: 'APPROVE' | 'REJECT'): void {
        if (type === 'APPROVE') {
            this.session.approve();
        } else {
            this.session.reject();
        }
    }

    public showPreApprovalModal(): void {
        this.isPreApprovalModalVisible = true;
    }

    public showPricingApprovalModal(): void {
        this.isPricingApprovalModalVisible = true;
    }

    public onSubmitClicked(): void {
        if (this.session.preapproved === true) {
            this.showPreApprovalModal();
        } else {
            this.showPricingApprovalModal();
        }
    }

    public submitPreApproval(): void {
        this.dismissModals();
        this.session.submit();
    }

    public submitPricingApproval(): void {
        this.dismissModals();
        this.session.submit();
    }

    public dismissModals(): void {
        this.isPreApprovalModalVisible = false;
        this.isPricingApprovalModalVisible = false;
    }
}
