import { Component, Input, HostBinding, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core'

@Component({
    selector: 'mad-spring',
    templateUrl: './spring.component.html',
    styleUrls: ['./spring.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
}) export class SpringComponent {
    @Input() skipInitialTransition: boolean;
    @Input() direction: 'vertical' | 'horizontal' | 'both' = 'both';
    @Input() height: number;
    @Input() visible: boolean = false;
    @Input() sizeTo: ElementRef = this.element;
    @HostBinding('class') class: string;
    @HostBinding('style') style: string | { [key: string]: any };

    _width: number = 0;
    _height: number = 0;
    isTransitioning: boolean;
    _was_init = false
    _update_timer: any;

    constructor(public element: ElementRef, public changeDetector: ChangeDetectorRef) {
    }


    ngOnChanges() {
        var was_init = !this._was_init;
        if (was_init) {
            this._was_init = true;
            if (!this.skipInitialTransition) {
                this.element.nativeElement.style.transition = null;
                if (this.direction === 'vertical' || this.direction === 'both') {
                    this.element.nativeElement.style.height = '0px'
                }
                if (this.direction === 'horizontal' || this.direction === 'both') {
                    this.element.nativeElement.style.width = '0px'
                }
            }
        }

        this.class = this.getClass();
        if (!was_init || !this.skipInitialTransition) {
            this.isTransitioning = true;
        }

        if (was_init && this.skipInitialTransition) {
            this.update(true)
        } else if (!this._update_timer) {
            this._update_timer = requestAnimationFrame(() => {
                this._update_timer = null;
                this.update();
            });
        }
    }

    _transitionListener: any;
    update(skipInitialTransition = false) {
        var targetHeight = this.height,
            hasTargetHeight = typeof targetHeight === 'number',
            sizeTo = this.sizeTo && this.sizeTo.nativeElement || this.sizeTo,
            style = { width: null, height: null, transition: null, opacity: null } as { height: string; width: string; transition: string, opacity: string },
            currentWidth = sizeTo.offsetWidth,
            currentHeight = sizeTo.offsetHeight,
            is_vertical = this.direction === 'vertical',
            is_horizontal = this.direction === 'horizontal',
            is_both = this.direction === 'both',
            listener: Function;


        this.element.nativeElement.style.transition = null;
        if (is_vertical || is_both) {
            this.element.nativeElement.style.height = null;
        }
        if (is_horizontal || is_both) {
            this.element.nativeElement.style.width = null;
        }

        if (this._transitionListener) {
            this.element.nativeElement.removeEventListener('transitionend', this._transitionListener);
            this._transitionListener = null;
        }

        if (this.visible) {
            style.opacity = '1';
            if (is_vertical) {
                if (skipInitialTransition) {
                    style.height = null;
                } else {
                    style.height = `${sizeTo.offsetHeight}px`;
                }
                this.element.nativeElement.style.height = `${currentHeight}px`;
            } else if (is_horizontal) {
                if (skipInitialTransition) {
                    style.width = null;
                } else {
                    style.width = `${sizeTo.offsetWidth}px`;
                }
                this.element.nativeElement.style.width = `${currentWidth}px`;
            } else if (is_both) {
                if (skipInitialTransition) {
                    style.width = style.height = null;
                } else {
                    style.width = `${sizeTo.offsetWidth}px`;
                    style.height = `${sizeTo.offsetHeight}px`;
                }
                this.element.nativeElement.style.height = `${currentHeight}px`;
                this.element.nativeElement.style.width = `${currentWidth}px`;
            }
            if (hasTargetHeight) {
                style.height = targetHeight + 'px';
            }
        } else if (is_horizontal) {
            style.opacity = '0';
            this.element.nativeElement.style.width = `${currentWidth}px`;
            style.width = '0px';
        } else if (is_vertical) {
            style.opacity = '0';
            this.element.nativeElement.style.height = `${currentHeight}px`;
            style.height = '0px';
        } else {
            style.opacity = '0';
            this.element.nativeElement.style.width = `${currentWidth}px`;
            this.element.nativeElement.style.height = `${currentHeight}px`;
            style.height = '0px';
            style.width = '0px';
        }
        this.element.nativeElement.style.transition = 'width 400ms, height 400ms, opacity 400ms';
        this.element.nativeElement.addEventListener('transitionend', listener = this._transitionListener = (e) => {
            if (e.target !== this.element.nativeElement) {
                return;
            }
            //setTimeout(() => {
            this.element.nativeElement.removeEventListener('transitionend', listener);
            if (this._transitionListener === listener) {
                this.isTransitioning = false;
                if (this.visible) {
                    if (this.direction === 'vertical' || this.direction === 'both') {
                        var targetHeight = this.height,
                            hasTargetHeight = typeof targetHeight === 'number';
                        if (!hasTargetHeight) {
                            this.element.nativeElement.style.height = null;
                        } else {
                            this.element.nativeElement.style.height = targetHeight + 'px';
                        }
                    }
                    if (this.direction === 'horizontal' || this.direction === 'both') {
                        this.element.nativeElement.style.width = null;
                    }
                }
            }

            this.changeDetector.detectChanges();
            //}, 50)
        });
        if (!skipInitialTransition) {
            requestAnimationFrame(() => {
                this.element.nativeElement.style.opacity = style.opacity;
                if ('width' in style) {
                    this.element.nativeElement.style.width = style.width;
                }
                if ('height' in style) {
                    this.element.nativeElement.style.height = style.height;
                }
                this.isTransitioning = false;
            });
        } else {
            if ('width' in style) {
                this.element.nativeElement.style.width = style.width;
            }
            if ('height' in style) {
                this.element.nativeElement.style.height = style.height;
            }
            this.isTransitioning = false;
        }
        this.changeDetector.detectChanges();
    }

    getClass() {
        return `
    ${this.visible && 'visible' || 'hidden'}
    mad-spring
    ${this.direction || 'both'}
    `;
    }
}
