<div class="page-body">

  <div class="page-section-scroll" style="display:block;padding: 10px;">

	<form #sysSettingsForm="ngForm" *ngIf="systemSettings">
	  <div *ngIf="!allowTraining">
		Training mode is only available in sub-Prod environments.
	  </div>

	  <div class="spot-form__field-group" *ngIf="allowTraining">
		<label for="addSapField" class="spot-form__label">Training SAP Ids</label>
		<input name="addSapField" type="text" class="search spot-form__input"  [(ngModel)]="_search"><button (click)="addSAP()">Add</button>
		<div style="margin-top:10px;">
          <div class="bottom-pills">
			<mad-pill class="pill" [selectable]="true" [removable]="true" (action)="removeSAP(sapId)" *ngFor="let sapId of trainingSapIds">
              {{sapId}}
              <mad-icon class="pill-remove-icon"  right icon="fa-times" (click)="removeSAP(sapId)"></mad-icon>
			</mad-pill>
          </div>
		</div>
	  </div>

	  <br/>

	  <mad-message [message]="message"></mad-message>

	  <div class="button-panel">
		<button type="cancel" class="spot-button spot-button--link" (click)="cancel()">Cancel</button>
		<button type="submit" class="spot-button spot-button--primary" (click)="save()">Save</button>
	  </div>

	</form>

  </div>

</div>
