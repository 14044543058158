import {
    Component,
    ElementRef,
    Input,
    ViewChildren,
    ContentChildren,
    QueryList,
    OnInit,
    TemplateRef
} from '@angular/core';
import { DomUtil } from 'src/app/utils/dom';
import { Field } from '../field/field.component';
import { InputActions } from '../input/input.actions';
import { CheckboxComponent } from '../checkbox/checkbox.component';

var ids = 0;

@Component({
    selector: 'mad-checkbox-group',
    templateUrl: './checkbox-group.component.html',
    styleUrls: ['./checkbox-group.component.scss'],
    providers: Field.Providers(CheckboxGroupComponent).concat(InputActions.Providers())
})
export class CheckboxGroupComponent extends Field implements OnInit {
    @ContentChildren(CheckboxComponent) contentChildren: QueryList<CheckboxComponent>;

    @ViewChildren(CheckboxComponent) viewChildren: QueryList<CheckboxComponent>;

    @Input() options: { text: string; value: any }[];

    @Input() innerLabel: string | TemplateRef<any>;

    id = `checkbox-group-${ids++}`;

    _value: this['options'][0]['value'] = [];

    get value() {
        return this._value;
    }
    @Input() set value(value: this['_value']) {
        value = value || [];
        this._value = value;
        const components = (this.contentChildren && this.contentChildren.toArray() || []).concat(this.viewChildren && this.viewChildren.toArray() || []);
        for (let i = 0; i < components.length; i += 1) {
            const cmp = components[i];
            if (value.indexOf(cmp.value) !== -1) {
                cmp._checked = true;
            } else {
                cmp._checked = false;
            }
        }
        this._onChange(this._value);
    }

    constructor(element: ElementRef) {
        super(element);
    }

    ngOnInit(): void {
        this.element.nativeElement.addEventListener('change', this.onChange.bind(this), true);
    }

    onChange($event: Event, noSet?: boolean): any[] {
        const components = (this.contentChildren && this.contentChildren.toArray() || []).concat(this.viewChildren && this.viewChildren.toArray() || []);
        let cmp: CheckboxComponent;
        const value = [];

        for (let i = 0; i < components.length; i += 1) {
            cmp = components[i];
            if (cmp.input.nativeElement.checked) {
                if (cmp.value === undefined || cmp.value === null) {
                    throw new Error('Component does not have a value assigned');
                } else {
                    value.push(components[i].value);
                }
            }
        }
        if (!noSet) {
            this.value = value;
        }
        return value;
    }

    getInnerLabel() {
        if (typeof this.innerLabel === 'string') {
            return this.innerLabel;
        }
        return null;
    }

    isTemplate(): boolean {
        if (this.innerLabel && this.innerLabel instanceof TemplateRef) {
            return true;
        }
        return false;
    }

    focusInput(): void {
        const input = this.element.nativeElement.querySelector('input');
        if (input) {
            input.focus();
        }
    }

    blurInput(): void {
        if ((DomUtil.queryUp(document.activeElement, this.element.nativeElement) && (document.activeElement as HTMLElement).blur)) {
            (document.activeElement as HTMLElement).blur();
        }
    }

    writeValue(value: this['value']): void {
        this.value = value;
    }

    reset(): void {
        this.value = [];
    }
}
