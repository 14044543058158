import { FormGroup, FormControl } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { ISessionPanel, IPanelComponent, PRICE_TYPES } from "@shared/model/session-panel";
import { INotificationGroup } from "@shared/model/reference";
import { Queue } from "@shared/model/utils/queue";
import { MadErrorUtils } from "@shared/model/error/error-utils";
import { Panel } from "../../../../../../aws/shared/lib/panel";
import type { SessionMessage } from "../../../../../services/lib/session/session.service";
import { SessionService } from "../../services/session/session.service";
import { PanelService, UpdateFieldNameType } from "../../services/panel/panel.service";
import { LIMSService } from "../../services/lims/lims.service";
import { PriceService } from "../../services/price/price.service";
import { ReferenceService, IReferenceDataMapped } from "../../services/reference/reference.service";
import { ErrorHandler } from "../../providers/error-handler/error-handler";

export interface INewPanel {
    lims: string;
    lims_code: string;
    lims_name: string;
    material: string;
    match_code: string;
    match_price?: number;
    parent_id?: number;
    is_extra?: boolean;
    related_panel_id?: number;
    children: Panel[];
}

export interface ISelectedPanel extends ISessionPanel {
    currentVolumeTRG?: number;
    currentVolumeTRGEstimate?: number;
    projectedLabInvoiceImpact?: number;
    loading?: boolean;
    discount?: number | string;
    preapproved?: boolean;
    lims_name?: string;
    parent_id?: number;
    self_added?: boolean;
    added?: boolean;
    adding?: boolean;
    removing?: boolean;
    form?: FormGroup;
    expanded?: boolean;
    isDetailsExpanded?: boolean;
    isExpanded?: boolean;
    contains_info?: boolean;
    info_includes?: string;
    info_description?: string;
    notification_group?: string;
    timers?: {
        price?: any;
    };
}

export interface IFieldUpdate {
    sap?: string;
    id: number;
    field: UpdateFieldNameType;
    value: boolean | string | number;
}

const TEST_CODES = {
    IDEXX_ANYWHERE: "SEDUA",
    SEDUPC: "SEDUPC",
    URINALYSIS: "910",
    LAB_UPC: "950",
    DCYTO: "DCYTO",
    IAUA_UPC_RATIO: "994S",
    LAB_UPC_RATIO: "994"
};

export class SessionPanels {
    changes = new BehaviorSubject(null);

    data: ISelectedPanel[] = [];

    notificationGroupsMap: { [key: string]: INotificationGroup } = {};

    updateQueue: Queue<IFieldUpdate> = new Queue<IFieldUpdate>();

    updating: boolean;

    constructor(
        public session: {
            state: { id: number; sap: string };
            resetMissingVersions(): void;
            checkMissingVersions(a: number | number[]): any;
            sessionService: SessionService;
            panelService: PanelService;
            limsService: LIMSService;
            priceService: PriceService;
            referenceService: ReferenceService;
            errorHandler: ErrorHandler;
        }
    ) {
        this.session.referenceService.reference().then(
            (data: IReferenceDataMapped): void => {
                this.notificationGroupsMap = data.notificationGroupsMap;
            },
            (err): void => {
                console.error("Error pulling reference data: ", err);
            }
        );

        this.processQueue();
    }

    public handleMessage(message: SessionMessage<"session_selected_panels"> & { notouch?: string }, doSort = true): void {
        const panels = this.data;
        const { data: messageData, type: messageType } = message;
        let changes = false;

        this.session.checkMissingVersions(messageData.relationships_version);

        let existingPanel: ISelectedPanel;
        let selfCalc: boolean;

        if (messageData && messageData.error) {
            messageData.error = <Error>MadErrorUtils.parseError(messageData.error);
        }

        switch (messageType) {
            case "INSERT":
            case "UPDATE":
                existingPanel = this.findCurrentPanel(panels, messageData);

                if (messageData.error && (!existingPanel || messageData.error !== existingPanel.error)) {
                    if (existingPanel) {
                        existingPanel.error = messageData.error;
                    }
                    this.session.errorHandler.toast({
                        type: "default",
                        // code: UtilService.getErrorCode(messageData.error),
                        priority: "standard",
                        context: { lims_code: messageData.lims_code },
                        title: "Something went wrong.",
                        body: "Wait a moment and refresh or try again later.",
                        duration: 8000
                    });
                    if (messageData.error) {
                        console.error(`Error in op.data: ${messageData.error}`);
                    }
                }

                // initial return from the calculation
                // occurs if this user was one to add this panel
                // and the calculation has returned for the first time
                if (messageData.type && existingPanel && existingPanel.self_added && !existingPanel.type) {
                    selfCalc = true;
                }

                if (!existingPanel && !messageData.inactive) {
                    changes = true;
                    if (!existingPanel) {
                        existingPanel = messageData as ISelectedPanel;
                    }
                    panels.push(existingPanel);
                    existingPanel.timers = {};

                    // leverage form controls and groups so that we can control the status of the session directly and
                    // not at the component level
                    const group = {
                        price: new FormControl(),
                        discount: new FormControl(),
                        qty_estimate: new FormControl()
                    };
                    const form = new FormGroup(group);
                    const controls = form.controls;
                    const update = (field: keyof typeof group, send = false): void => {
                        const value = controls[field].value;
                        if (value === null || !controls[field].dirty) {
                            return;
                        }
                        existingPanel[field] = value;

                        if (send) {
                            if (existingPanel.timers[field]) {
                                clearTimeout(existingPanel.timers[field]);
                            }
                            existingPanel.timers[field] = setTimeout(async (): Promise<void> => {
                                existingPanel.timers[field] = null;
                                if (existingPanel[field] === "" || existingPanel[field] === null || existingPanel[field] === undefined) {
                                    if (field === "price") {
                                        return;
                                    }
                                    existingPanel[field] = null;
                                }
                                this.updateField({
                                    id: existingPanel.id,
                                    sap: this.session.state.sap,
                                    field,
                                    value: existingPanel[field]
                                }, true);
                            }, 500);
                        }
                    };

                    existingPanel.form = form;

                    let writingDiscount = false;

                    form.controls.price.valueChanges.subscribe((): void => {
                        update("price", true);
                        existingPanel.preapproved = this.isPreapproved(existingPanel);
                        if (!writingDiscount) {
                            this.updateDiscount(existingPanel);
                        }
                    });

                    form.controls.qty_estimate.valueChanges.subscribe((): void => {
                        update("qty_estimate", true);
                        this.updateCurrentVolumeTRG(existingPanel);
                    });

                    form.controls.discount.valueChanges.subscribe((): void => {
                        writingDiscount = true;
                        update("discount");
                        this.updatePrice(existingPanel);
                        writingDiscount = false;
                    });

                    for (const field in group) {
                        if (controls[field]) {
                            controls[field].reset(existingPanel[field], {
                                emitEvent: false
                            });
                            if (!existingPanel.type || existingPanel.error) {
                                controls[field].disable({ emitEvent: false });
                            } else {
                                controls[field].enable({ emitEvent: false });
                            }
                        }
                    }

                    this.updateDiscount(existingPanel);
                    this.updateCurrentVolumeTRG(existingPanel);
                } else {
                    for (const version in messageData.versions) {
                        if (existingPanel.versions[version] === undefined
                            || messageData.versions[version] > existingPanel.versions[version]) {
                            changes = true;
                            existingPanel.versions[version] = messageData.versions[version];
                            if (message.notouch !== version) {
                                existingPanel[version] = messageData[version];
                                if (existingPanel.form.controls[version] && !existingPanel.timers[version]) {
                                    existingPanel.form.controls[version].markAsUntouched();
                                    existingPanel.form.controls[version].reset(
                                        existingPanel[version],
                                        { emitEvents: false });
                                    if (!existingPanel.type || existingPanel.error) {
                                        existingPanel.form.controls[version].disable({ emitEvent: false });
                                    } else {
                                        existingPanel.form.controls[version].enable({ emitEvent: false });
                                    }
                                }
                            }
                        }
                    }
                }

                // set preapproved status on any update
                existingPanel.preapproved = this.isPreapproved(existingPanel);

                if (messageData.turnaround_time) {
                    existingPanel.turnaround_time = messageData.turnaround_time;
                }
                
                if (messageData.new_tat) {
                    existingPanel.new_tat = messageData.new_tat;
                    existingPanel.new_tat_start_date = messageData.new_tat_start_date;
                }

                if (messageData.children) {
                    existingPanel.children = messageData.children;
                }

                if (doSort) {
                    this.sortById();
                }

                if (selfCalc) {
                    this.onSelfCalcPanel(existingPanel);
                }

                break;
            case "DELETE":
                // unexpected behavior fall through and alert user
                for (const panel of panels) {
                    if (panel.id === messageData.id) {
                        panel.inactive = true;
                        this.removeInactivePanel({ id: messageData.id });
                        break;
                    }
                }
                this.session.errorHandler.toast("UNEXPECTED_ERROR_SS2");
                break;
            default:
                this.session.errorHandler.toast("UNEXPECTED_ERROR_SS2");
                break;
        }

        if (existingPanel && ((existingPanel.inactive && !existingPanel.removing))) {
            setTimeout((): void => {
                if (existingPanel.inactive && !existingPanel.removing) {
                    this.removeInactivePanel({ id: existingPanel.id });
                }
            });
        }

        if (changes) {
            this.changes.next(true);
        }
    }

    private isPreapproved(panel: ISessionPanel): boolean {
        return +panel.price >= +panel.floor
            && panel.type !== PRICE_TYPES.COMPETITIVE;
    }

    private findCurrentPanel(panels: ISessionPanel[], messageData: any): ISelectedPanel | null {
        const currentPanel = panels.find((panel: ISessionPanel): boolean => {
            // try to find an equivalent loading panel first with an id=-1
            // these panels are self_added panels, but the concept has to stay seperate
            // because a panel will be self_added after the id shift
            if (panel.id === -1 && panel.lims_code === messageData.lims_code) {
                const current: ISelectedPanel = panel;
                current.id = messageData.id;
                current.loading = false;
                return true;
            }
            if (panel.id === messageData.id) {
                if (panel.id === -1) {
                    return false;
                }
                return true;
            }
            return false;
        });
        return currentPanel;
    }

    public async add(newPanel: INewPanel): Promise<ISessionPanel> {
        const newData: ISelectedPanel = {
            id: -1,
            self_added: true,
            loading: true,
            match_code: newPanel.match_code,
            match_price: newPanel.match_price,
            price: null,
            inactive: false,
            lims_code: newPanel.lims_code,
            lims_name: newPanel.lims_name,
            lims: newPanel.lims,
            material: newPanel.material,
            children: newPanel.children || [
                {
                    lims_code: newPanel.lims_code,
                    lims_name: newPanel.lims_name,
                    material: newPanel.material
                }],
            is_extra: newPanel.is_extra,
            session: this.session.state.id,
            current: null,
            recommended: null,
            floor: null,
            list: null,
            type: null,
            versions: {} as any,
            relationships_version: null,
            notification_group: this.getAssignedNotificationGroup(newPanel)
        };

        // When user tries to add an S-code panel, check to see if the non-S-code equivalent is
        // already present.  If so, show an error to user and return.
        if (this.containsUrinalysisMatch(this.data, newPanel)) {
            console.warn("Found matching non-S-code panel already added.");
            this.session.errorHandler.toast({
                type: "danger",
                title: "ERROR",
                code: "URINALYSIS_ALREADY_PRESENT",
                context: {
                    lims_code: newPanel.lims_code
                }
            });
            return null;
        }

        let relatedPanelId = newPanel.related_panel_id;
        if (this.panelContainsIdexxAnywhere(newData)
            || this.panelContainsIdexxAnywhereUPC(newData)
            || this.panelContainsIdexxAnywhereUPCRatio(newData)) {
            const extraPanel = await this.handleIDEXXAnywhere(newData);
            if (extraPanel) {
                newData.related_panel_id = extraPanel.id;
                relatedPanelId = extraPanel.id;
            }
        }

        newData.preapproved = this.isPreapproved(newData);

        // Check new code in case we have a custom message to show user.
        this.checkDoubleUrinalysis(newData);
        this.displayNotificationIfDcyto(newData);
        if (newData.notification_group) {
            this.displayNotificationGroup(newData);
        }

        this.handleMessage({
            type: "INSERT",
            target: "session_selected_panels",
            data: newData
        });

        try {
            const result = await this.session.panelService.add({
                lims_code: newPanel.lims_code,
                match_code: newPanel.match_code,
                match_price: newPanel.match_price,
                parent_id: newPanel.parent_id,
                session: this.session.state.id,
                is_extra: newPanel.is_extra,
                related_panel_id: relatedPanelId
            });
            if (result) {
                this.handleMessage({
                    type: "UPDATE",
                    target: "session_selected_panels",
                    data: result
                });
            }
            return result;
        } catch (e) {
            this.onAddPanelError(newData, e);
            return null;
        }
    }

    private onAddPanelError(newData: ISelectedPanel, error: { canceled?: boolean }): void {
        if (error.canceled) {
            newData.inactive = true;
            const index = this.data.indexOf(newData);
            if (index !== -1) {
                this.data.splice(index, 1);
                this.changes.next(true);
            }
        }
    }

    public containsCompetitivePanel(): boolean {
        const panels = this.data;
        const containsCompetitive = panels
            && panels.some((panel: ISelectedPanel): boolean => panel.type === PRICE_TYPES.COMPETITIVE);
        return containsCompetitive;
    }

    onSelfCalcPanel(panel: ISessionPanel): void {
        const list = +panel.list;
        const current = +panel.current;
        const price = +panel.price;
        const matchCode = panel.match_code;
        const matchPrice = +panel.match_price;
        const qty = +panel.qty || 0;
        if (panel.type === PRICE_TYPES.COMPETITIVE) {
            // user-entered comp. price is lower than the customer's current IDEXX price
            if (price < current && (current !== list || qty)) {
                this.session.errorHandler.toast({
                    type: "warning",
                    title: "WARNING",
                    code: "MATCH_REVIEW",
                    context: {
                        lims_code: panel.lims_code
                    }
                });
            }
        } else if (matchCode) {
            // code was added through IDEX match, but the price entered was worse than the idexx price
            if (matchPrice === 0 || Number.isNaN(matchPrice) || matchPrice > current) {
                this.session.errorHandler.toast({
                    type: "warning",
                    title: "WARNING",
                    code: "MATCH_REPLACED",
                    context: {
                        lims_code: panel.lims_code,
                        current: `$${panel.current}`
                    }
                });
            }
        }
    }

    async remove(removePanel: ISelectedPanel): Promise<ISessionPanel[]> {
        try {
            // mark as removing
            const result = await this.session.panelService.remove({ session_panel_id: removePanel.id });
            if (result && result.length) {
                this.handleMessage({
                    type: "UPDATE",
                    target: "session_selected_panels",
                    data: result[0]
                });
            }

            if (this.panelContainsIdexxAnywhere(removePanel)
                || this.panelContainsIdexxAnywhereUPC(removePanel)
                || this.panelContainsIdexxAnywhereUPCRatio(removePanel)) {
                this.removeIDEXXAnywhere(removePanel);
            }

            return result;
        } catch (err) {
            console.error("Error removing panel: ", removePanel, err);
            return null;
        }
    }

    private async update({ id, field, value }: { id: number; field: UpdateFieldNameType; value: boolean | string | number }): Promise<void> {
        console.log("Calling panelService.update...", id, field, value);
        const result = await this.session.panelService.update({ sessionPanelId: id, field, value });
        console.log("Done.");

        // Keep pricing between S-code panel and (hidden) non-S-code the same.
        this.updateUrinalysisIfPresent({ id, field, value });

        if (result && result.length) {
            this.handleMessage({
                type: "UPDATE",
                target: "session_selected_panels",
                data: result[0],
                notouch: field
            });
        }
    }

    async price({ id }: { id: number }): Promise<ISessionPanel> {
        const panels = this.data;
        const handleError = (e: string): void => {
            panels.forEach((panel: ISessionPanel): void => {
                panel.error = e;
                if (panel.versions) {
                    panel.versions.error = 0;
                }
            });
            this.changes.next(true);
        };
        for (const panel of panels) {
            if (panel.id === id) {
                panel.type = null;
                panel.error = null;
                if (panel.versions) {
                    panel.versions.type = 0;
                    panel.versions.error = 0;
                }
            }
        }

        try {
            const result = await this.session.priceService.price({
                session: this.session.state.id,
                sapId: this.session.state.sap,
                panel: id
            });

            if (result) {
                this.handleMessage({
                    type: "UPDATE",
                    target: "session_selected_panels",
                    data: result
                });
            } else if (!result) {
                handleError("PRICE_TIMEOUT");
            }
            return result;
        } catch (e) {
            handleError(e);
            return null;
        }
    }

    /**
     * Check if the list of selected panels includes the specified lims code.
     * Only check selected or hidden panels, don't look at children
     */
    public sessionContainsPanel(limsCode: string): boolean {
        return this.data.some((panel: ISelectedPanel): boolean => this.panelMatches(panel, limsCode));
    }

    /**
     * Returns true if specific panel contains the limsCode.
     * Don't worry about is_extra
     */
    private panelMatches(panel: ISelectedPanel, limsCode: string): boolean {
        return panel.lims_code === limsCode;
    }

    public isValid(): boolean {
        if (!this.data.length) {
            return false;
        }
        let hasActivePanels = false;
        let hasErrorPanels = false;
        const panels = this.data;

        for (const panel of panels) {
            if (!panel.inactive) {
                hasActivePanels = true;
            }
            if (panel.error || !panel.type) {
                hasErrorPanels = true;
            }
        }

        return hasActivePanels && !hasErrorPanels;
    }

    public removeInactivePanel({ id }: { id: number }): boolean {
        const panels = this.data;
        for (let i = 0; i < panels.length; i += 1) {
            if (panels[i].id === id && panels[i].inactive) {
                panels.splice(i, 1);
                this.sortById();
                this.changes.next(true);
                return true;
            }
        }
        return false;
    }

    public sortById(): void {
        this.data.sort((a: ISelectedPanel, b: ISelectedPanel): number => {
            if (a.id === -1 && b.id > -1) {
                return 1;
            }
            if (b.id === -1 && a.id > -1) {
                return -1;
            }
            return a.id - b.id;
        });
    }

    // master_Component
    // TODO min date
    // TODO email validation
    private updateDiscount(panel: ISelectedPanel): void {
        const { form, list } = panel;
        const price = +form.controls.price.value;
        const listPrice = +list;
        const discount = 100 * (1 - price / listPrice);
        const discountValue = discount.toString();

        form.controls.discount.patchValue(discountValue, { emitEvent: false });
        this.updateCurrentVolumeTRG(panel);
    }

    // Keep pricing between S-code panel and (hidden) non-S-code the same.
    private async updateUrinalysisIfPresent(updateFieldReq: IFieldUpdate): Promise<boolean> {
        const srcPanel = this.data.find((panel: ISelectedPanel): boolean => panel.id === updateFieldReq.id);
        if (srcPanel && srcPanel.related_panel_id) {
            const extraPanel = this.data.find((panel: ISelectedPanel): boolean => panel.id === srcPanel.related_panel_id);
            if (extraPanel) {
                console.log("Keeping extra panel in sync...", extraPanel);
                await this.session.panelService.update({ sessionPanelId: extraPanel.id, field: updateFieldReq.field, value: updateFieldReq.value });
                return true;
            }
        }
        return false;
    }

    private updatePrice(panel: ISelectedPanel): void {
        const discount = +panel.form.controls.discount.value;
        const price = (panel.list * (1 - (discount / 100))).toFixed(2);

        panel.form.controls.price.markAsDirty();
        panel.form.controls.price.markAsTouched();

        panel.form.controls.price.patchValue(price);

        this.updateCurrentVolumeTRG(panel);
    }

    private updateCurrentVolumeTRG(panel: ISelectedPanel): void {
        const currentPrice = +panel.current;
        const price = parseFloat(panel.form.controls.price.value);
        const qty = +panel.qty;
        let qtyEstimateDefault = panel.form.controls.qty_estimate.value;
        const qtyEstimate = +qtyEstimateDefault;

        panel.currentVolumeTRG = (price - currentPrice) * qty;
        panel.currentVolumeTRGEstimate = (price * qtyEstimate) - (currentPrice * qty);
        if (Number.isNaN(parseFloat(qtyEstimateDefault))) {
            qtyEstimateDefault = qty;
        }
        panel.projectedLabInvoiceImpact = (+qtyEstimateDefault - qty) * price + panel.currentVolumeTRG;
        this.changes.next(true);
    }

    /**
     * For Idexx Anywhere/Urinalysis handling.
     * Returns true if panel is an S-code and the equivalent non-S-code is already present.
     */
    private containsUrinalysisMatch(panels: ISelectedPanel[], { lims_code: limsCode }: INewPanel): boolean {
        if (!panels || !limsCode.endsWith("S")) {
            return false;
        }
        const nonSCode = limsCode.replace(/S$/, "");
        return panels.some((selectedPanel: ISelectedPanel): boolean => selectedPanel.lims_code === nonSCode);
    }

    /**
     * User has added a panel that contains IDEXX Anywhere, "SEDUPC", or "994S".
     * First go find the non-S code version of the selected panel.
     * If not found, all done here.
     * If found, make sure it's not already present (user hasn't already manually
     *   added that code to selected panels
     * If not already there, add the non-S code version to selected panels, setting
     *   the is_extra flag to ensure we know this is a "hidden" panel under most circumstances.
     */
    private async handleIDEXXAnywhere(newData: ISelectedPanel): Promise<ISessionPanel | null> {
        const foundCode = await this.getIAUACode(newData);
        if (foundCode) {
            if (this.sessionContainsPanel(foundCode.lims_code)) {
                console.warn("Non-S code already present for IDEXX Anywhere: ", foundCode);
                return null;
            }
            return await this.add({
                lims: foundCode.lims,
                lims_code: foundCode.lims_code,
                lims_name: foundCode.lims_name,
                material: foundCode.material,
                match_code: null,
                match_price: null,
                children: foundCode.children,
                parent_id: foundCode.parent_id,
                is_extra: true
            });
        }
        return null;
    }

    private async removeIDEXXAnywhere(newData: ISelectedPanel): Promise<void> {
        const foundCode = this.findExtraPanel(newData);
        if (foundCode) {
            this.remove(foundCode);
        } else {
            console.warn("Didn't find extra non-S code for: ", newData.lims_code);
        }
    }

    /**
     * Find the related non-S code that corresponds with the IDEXX Anywhere/SEDUA panel being removed.
     * Use related_panel_id when available.
     *  Otherwise, use is_extra flag and look for non-S code that corresponds to the removed panel's test code.
     */
    private findExtraPanel(srcPanel: ISelectedPanel): ISelectedPanel {
        const { related_panel_id: relatedPanelId, lims_code: limsCode } = srcPanel;
        const nonSCode = limsCode.replace(/S$/, "");

        return this.data.find((panel: ISelectedPanel): boolean => {
            if (relatedPanelId && panel.id === relatedPanelId) {
                return true;
            }
            if (panel.is_extra && panel.lims_code === nonSCode) {
                return true;
            }
            return false;
        });
    }

    /**
     * When adding a panel containing either IDEXX Anywhere or Urinalysis, check if the other is already present.
     * If so, then present a warning to the user letting them know, but don't throw an error.
     * Ignore any extra/hidden panels added for IDEXX Anywhere.
     */
    private checkDoubleUrinalysis(newData: ISelectedPanel): void {

        // Looking for "910" and "SEDUA" both selected

        // Check if new selected panel contains 910 and if "SEDUA" is already selected.
        if (this.panelContainsUrinalysis(newData) && this.sessionContainsIdexxAnywhere()) {
            this.notifyUrinalysisSEDUA();
        }

        // Check if new selected panel contains "SEDUA" (but not "SEDUPC") and if "910" is already selected.
        if (this.panelContainsIdexxAnywhere(newData, true) && this.sessionContainsUrinalysis(true)) {
            this.notifyUrinalysisSEDUA();
        }

        // Looking for "910"/"950" and "SEDUA"/"SEDUPC" both selected

        // Check if new selected panel contains "910" and if "SEDUA" is already selected.
        if (this.panelContainsUrinalysisUPC(newData) && this.sessionContainsIdexxAnywhereUPC()) {
            this.notifyUrinalysisSEDUA();
        }

        // Check if new selected panel contains "SEDUA" (but not "SEDUPC") and if "910" is already selected.
        if (this.panelContainsIdexxAnywhereUPC(newData) && this.sessionContainsUrinalysisUPC()) {
            this.notifyUrinalysisSEDUA();
        }

        // Looking for "994" and "994S" both selected

        // Check if new selected panel contains "994" and if "994S" is already selected.
        if (this.panelContainsLabUPCRatio(newData) && this.sessionContainsIdexxAnywhereUPCRatio()) {
            this.notifyUrinalysisSEDUA();
        }

        // Check if new selected panel contains "994S" and if "994" is already selected.
        if (this.panelContainsIdexxAnywhereUPCRatio(newData) && this.sessionContainsLabUPCRatio()) {
            this.notifyUrinalysisSEDUA();
        }

    }

    /**
     * When user adds a DCYTO code, present a message to the user.
     * 'You have selected Digital Cytology 1 site option. To set up pricing for 2-4 sites, please reach out to Sales Support.'
     * US58311
     */
    private displayNotificationIfDcyto(newData: ISelectedPanel): void {
        // Check if new selected panel contains "910" and if "SEDUA" is already selected.
        if (newData.lims_code === TEST_CODES.DCYTO) {
            this.session.errorHandler.toast({
                type: "success",
                title: "Notification",
                code: "DCYTO_ADDED",
                duration: "infinite",
                progressBar: false
            });
        }
    }

    /**
     * When user adds a cytology, pathology, or biopsy code, present a message to the user.
     * Message will be one of three pre-defined texts based on the notification group assigned to the test code
     * US65068
     */
    private displayNotificationGroup(newData: ISelectedPanel): void {
        console.log("displayNotificationGroup: ", newData);
        // Display notification based on notification group assigned to selected panel.
        const group = newData.notification_group;
        if (!group) {
            console.warn("Notification group not assigned, no message will be displayed...");
            return;
        }
        if (this.sessionContainsNotificationGroup(group)) {
            console.warn(`Message for group ${group} already shown.  Not showing again for same session.`);
            return;
        }
        const key = `CODE_NOTIFICATION_${group}`;
        console.log("key=", key);
        this.session.errorHandler.toast({
            type: "success",
            title: "Notification",
            code: key,
            duration: 10000,
            progressBar: true
        });
    }

    /**
     * Return true if currently selected panels include one or more with the specified notification group.
     * Note that panel.notification_group will only be populated when a code is first added to the session this session.
     * It won't be present for save-and-retrieve scenarios.  So must use reference data to look up in a reliable manner.
     */
    private sessionContainsNotificationGroup(group: string): boolean {
        const result: ISelectedPanel = this.data.find((panel: ISelectedPanel): boolean => {
            const pGroup = this.getAssignedNotificationGroup(panel);
            return pGroup === group;
        });
        return result !== null && result !== undefined;
    }

    private getAssignedNotificationGroup(panel: { lims_code: string }): string {
        const result = this.notificationGroupsMap[panel.lims_code];
        return result ? result.notification_group : null;
    }

    private notifyUrinalysisSEDUA(): void {
        this.session.errorHandler.toast({
            type: "warning",
            title: "Notification",
            code: "SEDUA_URINALYSIS",
            duration: "infinite",
            progressBar: false
        });
    }

    private async getIAUACode(panel: ISelectedPanel): Promise<Panel | null> {
        const limsCode = panel.lims_code;
        const nonSCode = limsCode.replace(/S$/, "");
        try {
            const result = await this.session.limsService.search({
                search: nonSCode,
                limit: 1
            });
            if (result?.data?.results?.length > 0) {
                const foundCode = result.data.results[0];

                if (foundCode.lims_code !== limsCode) {
                    return foundCode;
                }
                // Search service is returning same "S" code for the nonSCode search
                console.error(`Same "S" code returned in search for ${nonSCode}.  Treating it as not found...`);
            } else {
                console.warn(`Warning: Non-S code not found for: ${limsCode}, ${nonSCode}`);
            }
        } catch (err) {
            console.error(`Error while searching for ${nonSCode}: ${err}`);
        }
        return null;
    }

    /**
     * Check if any requested field updates are present.
     * This is used to make updates run sequentially and limit the number of calls that go to the
     * backend if a user makes a large number of changes at once, say like typing slowly into a text
     * field.  Without this in place, every time the user paused 500ms, an update request was sent to
     * the backend.  These ended up piling up behind locked transactions in the database and failed
     * requests.  With this in place, only one update is called at a time, and any earlier unprocessed
     * requests to the same field will be ignored so only the latest is run.
     */
    private processQueue(): void {
        const queue = this.updateQueue;

        if (!queue.size()) {
            // If the queue is empty, wait for 500ms and try again (based on field update debouncing)
            this.updating = false;
            setTimeout((): void => {
                this.processQueue();
            }, 500);
        } else {
            // Pull the oldest update request, run it, and then re-run the queue process

            const request = queue.dequeue();
            this.updating = queue.size() > 0;

            this.update(request)
                .then(
                    (): void => {
                        console.log("Update request finished: ", request);
                        // Here we can handle success for the saved field
                    })
                .catch((err: Error): void => {
                    console.log("Update request failed: ", request, err);
                })
                .finally((): void => {
                    this.processQueue();
                });
        }
    }

    private updateField(fieldReq: IFieldUpdate, killEarlier = false): number {
        console.log("updateField: ", fieldReq);
        const queue = this.updateQueue;

        const fieldName = fieldReq.field;
        if (killEarlier && queue.contains(fieldName)) {
            queue.remove(fieldName);
        }

        // push the current field value to the queue
        queue.enqueue(fieldReq);

        this.updating = true;

        return queue.size();
    }

    /**
     * Returns true if specific panel contains the limsCode.
     * If ignoreExtra is true (see handleIDEXXAnywhere) ignore extra "hidden" panels.
     */
    private panelContains(panel: ISelectedPanel, limsCode: string, ignoreExtra = false): boolean {
        if (ignoreExtra && panel.is_extra) { 
            return false; 
        }
        if (panel.lims_code === limsCode && (!ignoreExtra || !panel.is_extra)) {
            return true;
        }
        if (panel.children) {
            return panel.children.some((childPanel: IPanelComponent): boolean => childPanel.lims_code === limsCode);
        }
        return false;
    }

    /**
     * Check if selected panel contains "SEDUA" (but not "SEDUPC")
     * If ignoreExtra is specifed, ignore extra "hidden" panels.
     */
    private panelContainsIdexxAnywhere(newData: ISelectedPanel, ignoreExtra = false): boolean {
        return this.panelContains(newData, TEST_CODES.IDEXX_ANYWHERE, ignoreExtra)
            && !this.panelContains(newData, TEST_CODES.SEDUPC, ignoreExtra);
    }

    /**
     * Check if selected panel contains both "SEDUA" and "SEDUPC"
     * Ignores extras
     */
    private panelContainsIdexxAnywhereUPC(newData: ISelectedPanel): boolean {
        return this.panelContains(newData, TEST_CODES.IDEXX_ANYWHERE, true)
            && this.panelContains(newData, TEST_CODES.SEDUPC, true);
    }

    /**
     * Returns true if specified panel contains "910" (but not "950")
     * Ignores extras
     */
    private panelContainsUrinalysis(newData: ISelectedPanel): boolean {
        return this.panelContains(newData, TEST_CODES.URINALYSIS, true)
            && !this.panelContains(newData, TEST_CODES.LAB_UPC, true);
    }

    /**
     * Returns true if specified panel contains both "910" and "950"
     * Ignores extras
     */
    private panelContainsUrinalysisUPC(newData: ISelectedPanel): boolean {
        return this.panelContains(newData, TEST_CODES.URINALYSIS, true)
            && this.panelContains(newData, TEST_CODES.LAB_UPC, true);
    }

    /**
     * Returns true if specified panel contains "994S"
     * Ignores extras
     */
    private panelContainsIdexxAnywhereUPCRatio(newData: ISelectedPanel): boolean {
        return this.panelContains(newData, TEST_CODES.IAUA_UPC_RATIO, true);
    }

    /**
     * Returns true if specified panel contains "994"
     * Ignores extras
     */
    private panelContainsLabUPCRatio(newData: ISelectedPanel): boolean {
        return this.panelContains(newData, TEST_CODES.LAB_UPC_RATIO, true);
    }

    /**
     * Returns true if any panel in this session contains "910" (but not "950")
     * Ignores extras
     */
    private sessionContainsUrinalysis(ignoreExtra = false): boolean {
        return this.data.some((panel: ISelectedPanel): boolean => {
            if (ignoreExtra && panel.is_extra) {
                return false;
            }
            return this.panelContainsUrinalysis(panel);
        });
    }

    /**
     * Returns true if any panel in this session contains "SEDUA" (but not "SEDUPC")
     * Ignores extras
     */
    private sessionContainsIdexxAnywhere(): boolean {
        return this.data.some((panel: ISelectedPanel): boolean => this.panelContainsIdexxAnywhere(panel, true));
    }

    /**
     * Returns true if any panel in this session contains both "SEDUA" and "SEDUPC"
     * Ignores extras
     */
    private sessionContainsIdexxAnywhereUPC(): boolean {
        return this.data.some((panel: ISelectedPanel): boolean => this.panelContainsIdexxAnywhereUPC(panel));
    }

    /**
     * Returns true if any panel in this session contains both "910" and "950"
     * Ignores extras
     */
    private sessionContainsUrinalysisUPC(): boolean {
        return this.data.some((panel: ISelectedPanel): boolean => this.panelContainsUrinalysisUPC(panel));
    }

    /**
     * Returns true if any panel in this session contains "994"
     * Ignores extras
     */
    private sessionContainsLabUPCRatio(): boolean {
        return this.data.some((panel: ISelectedPanel): boolean => this.panelContainsLabUPCRatio(panel));
    }

    /**
     * Returns true if any panel in this session contains "994S"
     * Ignores extras
     */
    private sessionContainsIdexxAnywhereUPCRatio(): boolean {
        return this.data.some((panel: ISelectedPanel): boolean => this.panelContainsIdexxAnywhereUPCRatio(panel));
    }
}
