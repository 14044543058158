import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Message } from '@services/lib/api/websocket/message';
import { WebSocketBase, IWebSocketClient } from '@services/lib/api/websocket/websocket.base';
import { WebSocketConfig } from './websocket.config';
import { WebSocketSubscription } from './websocket.sub';

@Injectable()
export class WebSocket extends WebSocketBase {
    connectTimerHandle: any; // Used to timeout initial connection

    reconnectTimerHandle: any; // Used to reconnect websocket after closed

    connectStart: number;

    private authWait: {
        promise: Promise<boolean>;
        resolve: Function;
        reject: Function;
    };

    subscriptions: {
        [key: string]: WebSocketSubscription;
    } = {};

    //    reconnect = true;

    constructor(public msal: MsalService, public config: WebSocketConfig) {
        super(
            new Promise<IWebSocketClient>((resolve): void => {
                setTimeout((): void => {
                    this.config = new WebSocketConfig(config);
                    resolve(null);
                });
            })
        );
    }

    public async connect(client: IWebSocketClient = new window.WebSocket(this.config.url)): Promise<void> {
        return new Promise<void>((resolve: (value: void | PromiseLike<void>) => void, reject: (reason: any) => void): void => {
            try {
                this.connectStart = Date.now();
                if (this.connectTimerHandle) {
                    clearTimeout(this.connectTimerHandle);
                }

                this.connectTimerHandle = setTimeout((): void => {
                    this.connectTimerHandle = null;
                    if (client) {
                        try {
                            console.error('connectTimeout!  Calling client.close...');
                            client.close();
                        } catch (e) {
                            console.error('Error in websocket.client.close: ', e);
                            reject(e);
                        }
                    }
                }, this.config.connectTimeout);

                this.setWaitForAuth();

                super.connect(client);

                resolve();
            } catch (err) {
                console.error('Websocket: Error connecting', err);
                reject(err);
            }
        });
    }

    onClose(): void {
        if (this.reconnectTimerHandle) {
            clearTimeout(this.reconnectTimerHandle);
            this.reconnectTimerHandle = null;
        }
        if (this.connectTimerHandle) {
            clearTimeout(this.connectTimerHandle);
            this.connectTimerHandle = null;
        }
        this.reconnectTimerHandle = setTimeout((): void => {
            try {
                this.reconnectTimerHandle = null;
                this.connect();
            } catch (err) {
                console.error('Error thrown while reconnecting...', err);
            }
        }, Math.max(this.config.reconnectDelay - (Date.now() - this.connectStart), 0));
    }

    async onOpen(): Promise<void> {
        if (this.connectTimerHandle) {
            clearTimeout(this.connectTimerHandle);
            this.connectTimerHandle = null;
        }
        if (this.authWait) {
            try {
                await this.authWait.promise;

                // auto resubscribe
                if (this.subscriptions) {
                    const subs = this.subscriptions;
                    for (const [path, sub] of Object.entries(subs)) {
                        // if we fail the resub process, fail immediately so that we can retry
                        const result = await this.subscribe(sub.path, sub.params, sub);

                        if (result.fullPath !== path) {
                            delete subs[path];
                        }
                    }
                }
            } catch (e) {
                console.error('Error in websocket.onOpen', e);
                this.disconnect();
                // Can't throw error or get unmanaged rejection in websocket.base connect listener.
                // throw e;
            }
        }
    }

    disconnect(): void {
        super.disconnect();
    }

    setWaitForAuth(): void {
        if (!this.authWait) {
            this.authWait = {} as any;
            this.authWait.promise = new Promise<boolean>((resolve, reject): void => {
                this.authWait.resolve = (): void => {
                    this.authWait = null;
                    resolve(true);
                };
                this.authWait.reject = (err): void => {
                    console.log('authWait reject: ', err);
                    this.authWait = null;
                    reject(err);
                };
            });
        }
    }

    async onAuth(message: Message): Promise<void> {
        if (message.data === 'AUTH_REQUIRED') {
            this.setWaitForAuth();

            const result = await this.msal.instance.acquireTokenSilent({
                scopes: ['User.Read', 'profile'],
                account: this.msal.instance.getAllAccounts()[0]
            });

            try {
                const response = await (await message.respond({ success: true, error: null, data: result.idToken })).response();

                if (response.success) {
                    this.authWait.resolve(true);
                } else {
                    console.error('AUTH_REQUIRED: failed!', response);
                    this.authWait.reject(response.error);
                }
            } catch (err) {
                console.error('Error thrown waiting for response from onAuth: ', err);
                this.authWait.reject(err);
            }
        }
    }

    private getPath(inputPath: string, params: { [key: string]: any }): string {
        let path = inputPath;
        if (params) {
            let p: string;
            const paramsList = [];
            for (p in params) {
                if (params[p] !== null && params[p] !== undefined) {
                    paramsList.push(`${p}=${params[p]}`);
                }
            }
            if (paramsList.length) {
                path = <any>`${path}?${paramsList.join('&')}`;
            }
        }
        return path;
    }

    async subscribe<MESSAGE_DATA = any, PATH = any, PARAMS extends { [key: string]: any } = any>(
        path: PATH,
        params: PARAMS,
        sub?: WebSocketSubscription
    ): Promise<WebSocketSubscription> {
        try {
            if (this.authWait) {
                await this.authWait.promise;
            }

            // this creates a new sendPath in case params changed (eg storing the session id -- that way we can ensure that we reconnect to the same session)
            const sendPath: string = this.getPath(path as any, params);

            try {
                // fullPath is subscribed to sendPath
                const response = await (await this.send({ type: 'subscribe', path: sendPath })).response();

                if (!response.success) {
                    throw response.error;
                }
            } catch (err) {
                console.error('Error thrown waiting for response from subscribe call: ', err);
                throw err;
            }

            let result: WebSocketSubscription;
            if (!this.subscriptions[<any>sendPath]) {
                result = sub || new WebSocketSubscription<MESSAGE_DATA, PATH, PARAMS>(path, sendPath, params, this);
                this.subscriptions[<any>sendPath] = result;
                result.fullPath = sendPath;
            } else {
                result = this.subscriptions[<any>sendPath];
            }

            return result;
        } catch (err) {
            console.error('Error in subscribe call: ', path, params, err);
            throw err;
        }
    }

    async unsubscribe(sub: WebSocketSubscription): Promise<Message> {
        const foundSub = this.subscriptions[sub.fullPath];

        if (!foundSub) {
            throw new Error('NOT_FOUND');
        }

        console.warn('websocket: deleting subscriptions: ', foundSub.fullPath);
        delete this.subscriptions[foundSub.fullPath];

        return this.send({
            type: 'unsubscribe',
            path: foundSub.fullPath, // unsub from fullPath
            data: null,
            success: true,
            error: null
        });
    }

    onError(error?: Error | string): void {
        console.error('websocket.onError: ', error);
        super.onError(error);

        this.disconnect();
    }

    onMessage(message: Message): void {
        super.onMessage(message);

        switch (message.type) {
            case 'auth':
                this.onAuth(message);
                break;
            default:
                if (this.subscriptions[message.path]) {
                    this.subscriptions[message.path].next(message);
                }
                break;
        }
    }
}
