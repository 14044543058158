import {Component, Input} from '@angular/core';

@Component({
    selector: 'mad-fieldset',
    templateUrl: './fieldset.component.html',
    styleUrls: ['./fieldset.component.scss']
})
export class FieldsetComponent {
    @Input() label: string;
}
