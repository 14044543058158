<div class="page-body">
  <div class="spot-tabs">
    <div class="spot-tabs__list-wrap">
      <ul class="spot-tabs__list">
        <li class="spot-tabs__tab">
          <a href="#assigned-cats" class="spot-tabs__link spot-tabs__link--active"> Assigned Categories </a>
        </li>
        <li class="spot-tabs__tab">
          <a href="#missing-cats" class="spot-tabs__link"> Missing </a>
        </li>
        <li class="spot-tabs__tab" *ngIf="invalidCodes && invalidCodes.length > 0">
          <a href="#invalid_cats" class="spot-tabs__link"> Invalid </a>
        </li>
      </ul>

    </div>
    <div class="spot-tabs__content-wrap">
      <div id="assigned-cats" class="spot-tabs__content">

		<h1>Assigned categories</h1>

		<table id="code-table" class="spot-data-table spot-data-table--clickable spot-data-table--sortable" >
		  <thead>
			<tr>
			  <th style="text-align: right;" sort-col="testCode">Test Code</th>
			  <th style="text-align: center;" sort-col="name">Name</th>
			  <th style="text-align: center;" sort-col="name">Category</th>
			  <th style="text-align: center;" sort-col="key">Key</th>
			</tr>
		  </thead>
		  <tbody>
			<tr *ngFor="let tCode of pages[pageNum]"  (click)="selectTest(tCode)" >

			  <td style="text-align:right;">{{tCode.testCode}} </td>
			  <td>{{tCode.testName}} </td>
			  <td style="text-align:center;" *ngIf="selectedTest === tCode" >
				<select name="category" class="form-control" [(ngModel)]='tCode.financialCategory' (change)="categorySelected(tCode, $event)" [compareWith]="compareCategory">
				  <option name='' value=''></option>
				  <option *ngFor="let ctg of categories" [ngValue]="ctg.name">{{ctg.name}}</option>
				</select>
				<ng-container *ngIf="isMismatch(tCode.financialCategory)">
				  <svg class="spot-icon spot-list-group__item-icon" aria-labelledby="title" style="fill: red;">
					<title>alert-notification</title>
					<use xlink:href="/assets/spot/dist/icons/spot_icons.svg#alert-notification"></use>
				  </svg>
				</ng-container>
			  </td>
			  <td style="text-align:center;" *ngIf="selectedTest !== tCode" >{{tCode.financialCategory}}
				<ng-container *ngIf="isMismatch(tCode.financialCategory)">
				  <svg class="spot-icon spot-list-group__item-icon" aria-labelledby="title" style="fill: red;">
					<title>alert-notification</title>
					<use xlink:href="/assets/spot/dist/icons/spot_icons.svg#alert-notification"></use>
				  </svg>
				</ng-container>

			  </td>
			  <td style="text-align:center;">{{tCode.financialCategoryKey}}</td>
			</tr>
		  </tbody>
		</table>
        <div class="spot-paging">
          <div class="spot-paging__group">
            <div id="btnPrev" class="spot-paging__button spot-paging__button--previous spot-paging__button--disabled"  title="Previous" (click)="prevPage()">
              <svg>
                <use xlink:href="/assets/spot/dist/symbols/spot_symbols.svg#spot-previous" /></svg>
            </div>
			<div *ngFor="let page of pages; let ix=index" [id]="'btn' + ix" [ngClass]="'spot-paging__button ' + (ix === pageNum ? ' current-page' : '' )"  (click)="goPage(ix)"> {{ix}} </div>
            <div id="btnNext" class="spot-paging__button spot-paging__button--next"  title="Next" (click)="nextPage()">
              <svg>
                <use xlink:href="/assets/spot/dist/symbols/spot_symbols.svg#spot-next" /></svg>
            </div>
          </div>
        </div>
	  </div>
      <div id="missing-cats" class="spot-tabs__content">
		<h1>Missing codes</h1>
		<h3>Codes that exist in madsalesforce.lims_test__c with lims='LYNXX US' and marked as active</h3>
		<table id="code-table" class="spot-data-table spot-data-table--clickable spot-data-table--sortable" >
		  <thead>
			<tr>
			  <th style="text-align: right;" sort-col="testCode">Test Code</th>
			  <th style="text-align: center;" sort-col="name">Name</th>
			  <th style="text-align: center;" sort-col="name">Category</th>
			  <th style="text-align: center;" sort-col="key">Key</th>
			  <th style="width: 150px"></th>
			</tr>
		  </thead>
		  <tbody>
			<tr *ngFor="let tCode of missingCodes"  (click)="selectMissing(tCode)" >
			  <td style="text-align:right;">{{tCode.testCode}} </td>
			  <td>{{tCode.testName}} </td>
			  <td style="text-align:center;" *ngIf="selectedMissing === tCode" >
				<select name="category" class="form-control" [(ngModel)]='tCode.financialCategory' (change)="categorySelected(tCode, $event)" [compareWith]="compareCategory">
				  <option name='' value=''></option>
				  <option *ngFor="let ctg of categories" [ngValue]="ctg.name">{{ctg.name}}</option>
				</select>
				<ng-container *ngIf="isMismatch(tCode.financialCategory)">
				  <svg class="spot-icon spot-list-group__item-icon" aria-labelledby="title" style="fill: red;">
					<title>alert-notification</title>
					<use xlink:href="/assets/spot/dist/icons/spot_icons.svg#alert-notification"></use>
				  </svg>
				</ng-container>
			  </td>
			  <td style="text-align:center;" *ngIf="selectedTest !== tCode" >{{tCode.financialCategory}}
				<ng-container *ngIf="isMismatch(tCode.financialCategory)">
				  <svg class="spot-icon spot-list-group__item-icon" aria-labelledby="title" style="fill: red;">
					<title>alert-notification</title>
					<use xlink:href="/assets/spot/dist/icons/spot_icons.svg#alert-notification"></use>
				  </svg>
				</ng-container>

			  </td>
			  <td style="text-align:center;">{{tCode.financialCategoryKey}}</td>
			  <td style="width: 150px">
				<button *ngIf="selectedMissing === tCode" (click)="addTest(tCode)">Add test</button>
			  </td>
			</tr>
		  </tbody>
		</table>
      </div>
      <div id="invalid_cats" class="spot-tabs__content" *ngIf="invalidCodes && invalidCodes.length > 0">
		<h1>Invalid Codes</h1>
		<h3>Codes that exist in admin_test_code, but not in madsalesforce.lims_test__c</h3>
		<table id="code-table" class="spot-data-table spot-data-table--clickable spot-data-table--sortable" >
		  <thead>
			<tr>
			  <th style="text-align: right;width:100px;" sort-col="testCode">Test Code</th>
			  <th style="text-align: center;" sort-col="name">Name</th>
			  <th style="text-align: center;width: 200px" sort-col="name">Category</th>
			  <th style="text-align: center;" sort-col="key">Key</th>
			</tr>
		  </thead>
		  <tbody>
			<tr *ngFor="let tCode of invalidCodes"  (click)="selectInvalid(tCode)" >
			  <td style="text-align:right;">{{tCode.testCode}} </td>
			  <td>{{tCode.testName}} </td>
			  <td style="text-align:center;" *ngIf="selectedTest === tCode" >
				<select name="category" class="form-control" [(ngModel)]='tCode.financialCategory' (change)="categorySelected(tCode, $event)" [compareWith]="compareCategory">
				  <option name='' value=''></option>
				  <option *ngFor="let ctg of categories" [ngValue]="ctg.name">{{ctg.name}}</option>
				</select>
				<ng-container *ngIf="isMismatch(tCode.financialCategory)">
				  <svg class="spot-icon spot-list-group__item-icon" aria-labelledby="title" style="fill: red;">
					<title>alert-notification</title>
					<use xlink:href="/assets/spot/dist/icons/spot_icons.svg#alert-notification"></use>
				  </svg>
				</ng-container>
			  </td>
			  <td style="text-align:center;" *ngIf="selectedTest !== tCode" >{{tCode.financialCategory}}
				<ng-container *ngIf="isMismatch(tCode.financialCategory)">
				  <svg class="spot-icon spot-list-group__item-icon" aria-labelledby="title" style="fill: red;">
					<title>alert-notification</title>
					<use xlink:href="/assets/spot/dist/icons/spot_icons.svg#alert-notification"></use>
				  </svg>
				</ng-container>

			  </td>
			  <td style="text-align:center;">{{tCode.financialCategoryKey}}</td>
			</tr>
		  </tbody>
		</table>
	  </div>
	</div>
  </div>
</div>
