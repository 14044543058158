import { Component, Input, TemplateRef, ViewChild, ElementRef, NgZone, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { OutletItem, OutletComponent } from '../outlet/outlet.component';

type ANIMATIONS =
    | 'bounceIn'
    | 'bounceOut'
    | 'bounceInDown'
    | 'bounceOutDown'
    | 'bounceInLeft'
    | 'bounceOutLeft'
    | 'bounceInRight'
    | 'bounceOutRight'
    | 'bounceInUp'
    | 'bounceOutUp'
    | 'fadeIn'
    | 'fadeOut'
    | 'fadeInDown'
    | 'fadeOutDown'
    | 'fadeInDownBig'
    | 'fadeOutDownBig'
    | 'fadeInLeft'
    | 'fadeOutLeft'
    | 'fadeInLeftBig'
    | 'fadeOutLeftBig'
    | 'fadeInRight'
    | 'fadeOutRight'
    | 'fadeInRightBig'
    | 'fadeOutRightBig'
    | 'fadeInUp'
    | 'fadeOutUp'
    | 'fadeInUpBig'
    | 'fadeOutUpBig'
    | 'slideInDown'
    | 'slideOutDown'
    | 'slideInLeft'
    | 'slideOutLeft'
    | 'slideInRight'
    | 'slideOutRight'
    | 'slideInUp'
    | 'scaleIn'
    | 'scaleOut';

export type TOAST_TYPE = 'danger' | 'warning' | 'success' | 'default';

export type TOAST_PRIORITY = 'standard' | 'medium' | 'high';

export type TOAST_POSITION = 'right' | 'center' | 'left';

const MAX_ACTIVE_TOASTS = 30;

@Component({
    selector: 'mad-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent extends OutletItem {
    @ViewChild('toast') template: TemplateRef<any>

    @ViewChild('progress') progress: ElementRef<any>

    @ViewChild('wrapper') wrapper: ElementRef<any>

    @Input() visible: boolean = false;

    @Input() outlet: string;

    _body_template: TemplateRef<any>;

    @Input() duration: number | 'infinite' = 8000;

    @Input() title: string | TemplateRef<any>;

    @Input() body: string | TemplateRef<any> | SafeHtml;

    @Input() dismissible: boolean = true;

    @Input() priority: TOAST_PRIORITY = 'high';

    @Input() progressBar: boolean = true;

    @Input() icon: string;

    @Input() type: TOAST_TYPE = 'default';

    @Input() position: TOAST_POSITION;

    @Input() animation: ANIMATIONS = 'bounceInRight';

    @Input() animationDuration = '1s';

    @Input() maxWidth = '500px';

    component: any;

    mask = false;

    hiding = false;

    _close_timer: any;

    _hide_timer: any;

    _is_toast = true;

    _top: number;

    _height: number;

    _off_screen: boolean;

    constructor(public element: ElementRef, public ngZone: NgZone, public changeDetector: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        setTimeout(() => {
            this.ngOnChanges(null);
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        const duration = this.duration;

        if (this.body) {
            if (this.body instanceof TemplateRef) {
                this._body_template = this.body;
            } else {
                this._body_template = null;
            }
        }

        if (this.duration && !this._hide_timer) {
            if (typeof duration === 'number') {
                if (this.progressBar) {
                    this.progress.nativeElement.style.transition = `width ${this.duration}ms linear`
                    setTimeout(() => {
                        if (this.progress) {
                            this.progress.nativeElement.style.width = '0';
                        }
                    }, 0)
                }
                this._hide_timer = setTimeout(() => {
                    this._hide_timer = null;
                    this.close()
                }, duration);
            } else if (duration === 'infinite') {
                if (this.progressBar && this.progress) {
                    this.progress.nativeElement.style.animation = 'widthBounce 2000ms linear infinite'
                }
            }
        }
    }

    public getClass(): string {
        return `mad-toast
    ${this.type}
    ${this.priority}
    ${this.position || 'right'}
    ${(!this.hiding) ? 'showing' : 'hiding'}
    `;
    }

    public update(): boolean {
        if (!this._off_screen) {
            this.changeDetector.detectChanges();
            if (this.wrapper) {
                const nextHeight = this.wrapper.nativeElement.getBoundingClientRect().height;
                if (this._height !== nextHeight) {
                    this._height = nextHeight;
                }
            }
        }
        const outlet = OutletComponent.outletMap[this.outlet || ''] && OutletComponent.outletMap[this.outlet || ''];
        const templates = outlet.templates;
        const parent = outlet.outlet;
        const height = parent._height;
        let y = 60;

        if (templates) {
            for (let i = templates.length - 1; i >= 0; i -= 1) {
                const template = templates[i] as ToastComponent;
                if (template === this) {
                    break;
                }
                if (template._is_toast && template.position === this.position && template.wrapper && !template.hiding && template.visible) {
                    y += template._height + 10;
                    if (y > height) {
                        if (!template._off_screen) {
                            template._off_screen = true;
                            if (template.hiding) {
                                template.onAnimationEnd();
                                y -= (template._height + 10);
                            }
                            template.changeDetector.detectChanges();
                            break;
                        }
                    } else {
                        if (template._off_screen) {
                            template._off_screen = false;
                            template.changeDetector.detectChanges();
                            break;
                        }
                    }
                }
            }
        }

        if (this._top !== y) {
            this._top = y;
        }

        if (y > height) {
            if (!this._off_screen) {
                this._off_screen = true;
                this.changeDetector.detectChanges();
                if (this.hiding) {
                    this.onAnimationEnd();
                }
            }
        } else {
            if (this._off_screen) {
                this._off_screen = false;
                this.changeDetector.detectChanges();
            }
        }

        return !this._off_screen && this.visible;
    }

    getStyle() {
        if (this._height === undefined && this.wrapper && !this._off_screen) {
            this._height = this.wrapper.nativeElement.getBoundingClientRect().height;
        }
        return {
            top: `${this._top}px`,
            display: this._off_screen ? 'none' : null
        };
    }

    onAnimationEnd() {
        if (this.hiding) {
            this.visible = false;
            if (this.wrapper) {
                this.wrapper.nativeElement.style.display = 'none';
            }

            OutletComponent.remove(this, this.outlet)
            if (this.component) {
                this.component.destroy();
            }
        }
    }

    close() {
        var index = this.animation.indexOf('In');
        if (index !== -1) {
            this.animation = this.animation.replace('In', 'Out') as any;
        } else {
            this.animation = 'bounceOutRight';
        }
        this.hiding = true;
        if (this._off_screen) {
            this.onAnimationEnd();
        }
    }

    public static show(config: {
        title?: ToastComponent['title'];
        body?: ToastComponent['body'];
        type?: ToastComponent['type'];
        icon?: string;
        priority?: ToastComponent['priority'];
        progressBar?: ToastComponent['progressBar'];
        dismissible?: ToastComponent['dismissible'];
        duration?: ToastComponent['duration'];
        animation?: ToastComponent['animation'];
        animationDuration?: ToastComponent['animationDuration'];
        position?: ToastComponent['position'];
        outlet?: string;
        maxWidth?: string;
    }) {
        const name = config.outlet || '';
        const templates = OutletComponent.outletMap[name] && OutletComponent.outletMap[name].templates;
        const outlet = OutletComponent.outletMap[name] && OutletComponent.outletMap[name].outlet;

        if (templates.length >= MAX_ACTIVE_TOASTS - 1) {
            return;
        }

        if (!outlet) {
            throw 'outlet must be initialized first';
        }
        var factory = outlet.componentFactoryResolver.resolveComponentFactory(this),
            component = outlet.viewContainer.createComponent(factory);

        component.instance.visible = true;
        component.instance.component = component;
        for (var p in config) {
            if (config[p] !== undefined && config[p] !== null) {
                component.instance[p] = config[p];
            }
        }
        component.changeDetectorRef.detectChanges();
        OutletComponent.add(component.instance, component.instance.outlet);
        component.changeDetectorRef.detectChanges();
        return component.instance;
    }
}
