<mad-header style="padding:0;background:white;background:#E5F6F7;height:40px;min-height:40px;">
  <mad-body style="padding:inherit">
	<div style="display:flex; align-items:center;">
	  <mad-link type="secondary" style="color:#00A7B5;position:relative;font-size:21px;width:auto;" (click)="onBackClick()">
		<mad-icon left icon="fa-arrow-left" ></mad-icon>
		Return to Search and Selected Panels
	  </mad-link>
	</div>
  </mad-body>
</mad-header>

<div [formGroup]="session?.form" style="padding-left:1em;overflow:hidden;flex:1;display:flex;flex-direction:column;padding-top:1em;">
  <mad-infinite-list #infiniteList *ngIf="!session.vdc_reviewing"  [class.transitioning]="isTransitioning" [assumedHeight]="77" [buffer]="77" class="selected-panels-result-list scrollable" [pager]="pager"  [template]="codeTemplate" style="display:block;flex:1;" (scroll)="onScroll()">

	<div style="margin-top:2em;"></div>
	<div *ngIf="!session.ssa_reviewing" style="padding-top:0em;margin-bottom:1em;display:flex;flex-wrap:wrap">
	  <div>
		<div style="border: solid 1px #9f9f9f;margin-right:1em;display:inline-block;width:auto;position:relative;padding-right:2em;padding-left:1em;padding-top:2em;padding-bottom:1em;">
		  <div madTypography="header-3" style="color:#2d2e2f">
			IDEXX employee use only.
		  </div>
		  <div style="font-weight:300;margin-top:1em;margin-bottom:1em;">
			Session is not complete, please review the session details and complete information as required.
		  </div>
		  <mad-button class="review-button" [disabled]="isReviewing || !session?.isValid()" (click)="onReviewClick()">Review session</mad-button>
		  <div style="display:inline-flex;vertical-align:middle;font-weight:300;font-size:15px;align-items:center;color:red;" *ngIf="!session.preapproved">
			<svg viewBox="0 0 600 400" width="20" height="20" style="margin-left:1em;margin-right:0.5em;position:relative;top:-1px;left:-2px;">
			  <polygon points="350,60 100,400 600,400"  stroke="red" stroke-width="75" fill="transparent" class="triangle" />
			</svg>
			Additional review will be required
		  </div>
		</div>
	  </div>
	</div>

	<div #formLink>
	  <mad-link  *ngIf="shouldShowFormButton()" madTypography="header-3" (click)="toggleShowForm()" style="margin-bottom:0.5em;">
		View form
		<mad-icon icon="fa-chevron-down" altIcon="fa-chevron-up" [showAlt]="isFormVisible"></mad-icon>
	  </mad-link>
	</div>

	<div style="display:inline-flex;flex-wrap:wrap;position:relative;height:auto;width:100%;">
	  <div #ssaForm *ngIf="session.ssa_reviewing" style="flex:350px;background:#fafafa;margin-right:2em;display:flex;min-width:350px;max-width:1200px;">
		<div style="background:#f5f5f6;padding:1em;padding-right:2em;flex:1;margin-right:1em;margin-bottom:1em;">
		  <div class="required-header" madTypography="header-3" style="font-size:20px;color:#7a7a7a;">
			Submission Acceptance Form
			<div style="margin-top:0.5em;font-size:15px;line-height:normal;">
			  Upon reviewing the request below select 'Approve' or 'Reject' to finalize the submission.
			</div>
			<div style="display:flex;font-size:14px;margin-top:0.5em;">
			  <div>
				Submitted by:
			  </div>
			  <div style="font-weight:bold;margin-left:1em;">
				{{session.state.proposed_by}} -
				{{session.state.proposed_on | date}}
			  </div>
			</div>
			<div *ngIf="session.preapproved === false" style="display:flex;font-size:14px;align-items:center;margin-top:-0.5em;color:red;font-weight:500;margin-bottom:1em;">
			  <svg *ngIf="!session.preapproved" viewBox="0 0 600 400" width="20" height="20" style="margin-left:0px;position:relative;top:-1px;left:-2px;margin-right:0.5em;">
				<polygon points="350,60 100,400 600,400"  stroke="red" stroke-width="75" fill="transparent" class="triangle" />
			  </svg>
			  <ng-container *ngIf="containsCompetitivePanel()">
				Submission contains competitive code
			  </ng-container>
			  <ng-container *ngIf="!containsCompetitivePanel()">
				Price submitted is below floor price
			  </ng-container>
			</div>
		  </div>
		  <div>
			<mad-button type="secondary-teal" style="margin-right:2em;min-width:100px" [madPopover]="{popover: approvalPopover, showOnFocus: true, hideOnBlur: true, type: 'pointer', context: {type:'APPROVE'} }">Approve</mad-button>
			<mad-button type="secondary-teal" style="min-width:100px" [madPopover]="{popover: approvalPopover, showOnFocus: true, hideOnBlur: true, type: 'pointer', context: {type: 'REJECT'} }">Reject</mad-button>
		  </div>
		  <div style="margin-top:3em;width:100%;">
			<mad-textarea formControlName="reviewed_comments" style="width:100%;max-width:600px;height:150px;font-size:14px;">
			  <mad-label style="font-size:14px">Comments (optional)</mad-label>
			</mad-textarea>
		  </div>
		</div>
	  </div>
	  <mad-spring #form [visible]="isFormVisible || session.ssa_reviewing" direction="vertical" style="width:auto !important;margin-right:2em;flex:900px;min-width:900px;max-width:1200px;" [style.background]="session.ssa_reviewing ? '#fafafa' : null">
		<div style="background:#f5f5f6;padding:1em;margin: 0em 0em 1em 1em;"  [style.background]="session.ssa_reviewing ? 'null' : '#f2f2f3'">
		  <div *ngIf="!session.ssa_reviewing" class="required-header" madTypography="header-3" style="font-size:20px;color:#aaa;">
			Form must be completed for review.
		  </div>
		  <div style="display:flex;flex-direction:row;">
			<div style="display:flex;flex-direction:column;min-width:400px;margin-right:5em;">
				<mad-label style="font-size:15px; margin: 1em 0;">* required field</mad-label>
			  <mad-select formControlName="reason" [required]="true" [options]="reasons" style="margin-top:1.5em;font-size:14px;">
				<mad-label style="font-size:14px">Reason</mad-label>
			  </mad-select>
			  <mad-date formControlName="need_by" [required]="!session.ssa_reviewing" placeholder="MM-yyyy-dd" style="background:white;margin-top:2em;font-size:14px;" >
				<mad-label style="font-size:14px">Need by date</mad-label>
			  </mad-date>
			  <mad-textarea formControlName="vdc_comments" style="margin-top:2em;font-size:14px;">
				<mad-label style="font-size:14px">Comments</mad-label>
			  </mad-textarea>
			  <div style="display:flex;margin-top:1.5em;">
				<mad-checkbox #cc_lkam formControlName="cc_lkam" style="margin:0;font-size:14px">Copy LKAM</mad-checkbox>
				<mad-checkbox #cc_rsm formControlName="cc_rsm" style="margin:0;margin-left:1em;font-size:14px;">Copy RSM</mad-checkbox>
			  </div>
			  <mad-email-fax formControlName="cc_emails" placeholder="Add email" [maxValues]="999" [allowFax]="false" style="max-width:400px;"></mad-email-fax>
			</div>
			<div style="display:flex;flex-direction:column;margin-left:2em;" *ngIf="shouldShowScanForm()">
			  <div madTypography="header-3" style="font-size:20px;color:#aaa;margin-bottom:1em;">Scan pricing information</div>
			  <div style="color:#aaa;font-size:15px;margin-bottom:0.5em;">Pricing documentation is required for submission. Ensure front and back of document is scanned.</div>
			  <div style="color:#aaa;font-size:15px;margin-bottom:2em;">Scan all documents in portrait mode.</div>

			  <div>
				<mad-label style="font-size:14px"><ng-container *ngIf="!session.ssa_reviewing">*</ng-container>Choose one:</mad-label>
				<div style="display:flex;margin-top:0.5em;align-items:center;">
				  <mad-button type="primary" style="height:40px;margin-right:1.5em;">
					Add&nbsp;Invoice
					<input #fileInput type="file" class="invisible-input" accept="image/*,application/pdf" multiple (change)="onFileChange()" />
				  </mad-button>
				</div>
				<div style="display:flex;flex-direction:column;margin-top:1em;">
				  <mad-checkbox formControlName="invoice_submitted" style="font-size:14px">Invoice already submitted</mad-checkbox>
				  <mad-checkbox formControlName="no_invoice" style="font-size:14px">No invoice available (please add comments)</mad-checkbox>
				</div>
			  </div>

			  <div class="files" *ngIf="session.invoices.data.length" style="display:flex;margin-top:1em;overflow:visible;margin-left:-1.75em;">
				<div style="flex:1;display:flex;overflow:visible;flex-wrap:wrap;">
				  <div *ngFor="let file of session.invoices.data" style="overflow:visible;text-align:center;position:relative;height:100px;">
					<mad-spring  class="file" style="" direction="horizontal" [visible]="!file.removing" style="overflow:visible;margin-bottom:15px;text-align:center;position:relative;">
					  <div style="position:relative;margin-right:10px;margin-bottom:15px">
						<mad-link style="font-size:50px;padding:5px;border-radius:5px;fill:#a3cbd2;" [madPopover]="{popover: invoicePopover, showOnFocus: true, hideOnBlur: true, type: 'pointer', content: invoiceContent, context: {data: file} }">
						  <mad-icon *ngIf="file?.type.startsWith('image')" class="icon" icon="file-image"></mad-icon>
						  <mad-icon *ngIf="file?.type === 'application/pdf'" class="icon" icon="file-pdf"></mad-icon>
						  <mad-icon *ngIf="!file?.type.startsWith('image') && file?.type !== 'application/pdf'" class="icon" icon="file-zip"></mad-icon>
						</mad-link>
						<div style="position:absolute;left:0;top:0;height:2px;width:60px;left:20px;background:blue;transition: transform 500ms linear, opacity 500ms 750ms;opacity: 0.5;transform-origin: 0 50%;" [style.transform]="'scaleX('+(file.progress === undefined ? 0 : file.progress)+')'" [style.opacity]="(file.progress === 1 || file.progress === undefined) ? 0 : 0.5"></div>
						<mad-link class="remove-link"  style="position:absolute;background:#58a5b2;top:-0.5em;left:60px;color:white;border-radius:50%;padding:0.2em;" [madPopover]="{popover: invoicePopover, showOnFocus: true, hideOnBlur: true, type: 'pointer', content: removeInvoiceContent, context: {data: file}}">
						  <mad-icon icon="fa-times" loadingIcon="fa-spin fa-spinner" [loading]="file.id === -1"></mad-icon>
						</mad-link>
						<div class="name" style="font-size:12px;color:#8a8a8a;width:100px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap">
						  {{file.name}}
						</div>
					  </div>
					</mad-spring>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		</div>
	  </mad-spring>
	</div>

	<div *ngIf="isReviewing || session.ssa_reviewing" style="text-align:end;top:0px;padding-right:50px;background:white;z-index:2;">
	  <mad-button *ngIf="!session?.state?.reviewing" style="position:relative;z-index:1" [disabled]="!session?.isValidToSubmit()" (click)="onSubmitClicked()">Submit</mad-button>
	  <div class="header-row selected-panels-panel" style="overflow:visible;margin-top:1em;">
		<div class="inner" style="overflow:visible">
		  <div style="visibility:hidden;">
			<mad-link [disabled]="true" >
			  <mad-icon icon="fa-chevron-right" altIcon="fa-chevron-down" ></mad-icon>
			</mad-link>
		  </div>
		  <div class="lims-id" #headerLimsId></div>
		  <div class="fade-section" style="opacity:1;font-weight:300;overflow:visible;">
			<div class="matches" [style]="_inner_spring_style" ></div>

			<div *ngIf="userState.state.can_approve" href="javascript:void(0)" style="visibility:hidden;margin-left:5px;display:flex;align-items:center;position:relative;" >
			  <mad-icon style="color:#1e1e1f"  icon="fa-calculator"></mad-icon>
			</div>
			<div class="price-input-wrapper" style="justify-content:center;font-size:14px;margin-left:-0px">
			  Price
			</div>
			<div class="price-input-wrapper" style="justify-content:center;font-size:14px">
			  % off list price
			</div>
			<div class="price-input-wrapper" style="justify-content:center;font-size:14px">
			  Current price
			</div>
			<div class="price-input-wrapper" style="justify-content:center;font-size:14px;">
			  List price
			</div>
			<div class="price-input-wrapper" style="display:block;font-size:14px;margin-top:-18px;">
			  <div style="font-weight:300">
				Monthly Runs
			  </div>
			  <div style="display:flex">
				<div style="justify-content:center;font-size:12px;min-width:50px;width:50px;">
				  Average
				</div>
				<div class="price-input-wrapper" style="justify-content:center;font-size:12px;margin-left:9px;min-width:50px;width:50px;">
				  Estimated
				</div>
			  </div>
			</div>
			<div class="price-input-wrapper" style="display:block;bottom:-28px;position:absolute;right:0;top:0;text-align:center;font-size:14px;width:160px;padding-left:10px;padding-right:10px;margin-top:-18px;margin-left:10px">
			  Projected invoice impact (monthly)
			</div>
		  </div>
		</div>
	  </div>
	</div>
	<div bottom *ngIf="isReviewing || session.ssa_reviewing" style="text-align:end;bottom:0;margin-bottom:2em;padding-right:50px;background:white;padding-bottom:1em;z-index:2;">
	  <div class="projected-lab-invoice" style="height:5em;color:#999;font-weight:bold;display:flex;align-items:center;justify-content:flex-end;background:#f9f9f9;">
		<div>
		  Total Invoice:
		</div>
		<div class="price-input-wrapper" style="display:block;font-size:18px;border-top:solid 3px #7b7b7b;padding-top:25px;margin-top:-30px;background:#f9f9f9;bottom:-28px;text-align:center;width:160px;padding-left:10px;padding-right:10px;margin-left:10px">
		  {{getTotalInvoice() | currency}}
		</div>
	  </div>
	  <mad-button *ngIf="!session?.state?.reviewing" style="margin-top:2em;" [disabled]="!session?.isValidToSubmit()" (click)="onSubmitClicked()">Submit</mad-button>
	</div>

  </mad-infinite-list>
</div>

<ng-template #turnAroundTimeInfo let-data="data" let-selected="selected">
  <div class="tatTooltip">Beginning on {{ data.new_tat_start_date }}, the turnaround time on this test will be updated to: <b>{{ data.new_tat }}</b>
  </div>
</ng-template>

<mad-popover #tatBubble class="opaque white" constrainTo=".account-content"  [auto]="true" position="top-left" >
</mad-popover>

<ng-template #codeTemplate let-index="index" let-height="height">
  <ng-container *ngIf="session?.panels.data[index]; let data">
	<div *ngIf="!data.is_extra" #el_target class="selected-panels-panel" [formGroup]="data.form"  [class.configure]="data === configure"  [class.adding]="!data.inactive && data.adding" [class.added]="!data?.inactive && !data?.added" [style]="getRowStyle(data, el_target)" >
	  <div class="outer">
      <div class="inner">
        <div>
          <mad-link (click)="toggleDetails(data)">
          <mad-icon icon="fa-chevron-right" altIcon="fa-chevron-down" [showAlt]="data.expanded"></mad-icon>
          </mad-link>
        </div>
        <div class="lims-id" [class.visible]="isVisible && data.type" #limsId style="">
          <div class="lims-code">{{data.lims_code}}</div>
          <div class="lims-type"  [style.height]="isVisible && data.type ? '18px': '0px'">{{(data.type === 'COMPETITIVE' ? data.match_code : data.type) || ''}}</div>
        </div>
        <div class="fade-section" [class.visible]="visible" style="overflow:visible;">
          <div [style]="_inner_spring_style" class="matches">
          <div *ngFor="let match of data.children" class="component" >{{match.lims_name || match.lims_code}}</div>
          </div>
          <div style="position:relative;left:-1.5em;padding-top:0.2em;"></div>
          <a *ngIf="userState.state.can_approve" href="javascript:void(0)" style="display:flex;margin-left:5px;align-items:center;position:relative;" [madPopover]="{popover: popover, showOnClick: true,  hideOnBlur: true, type: 'pointer', content: calc}">
          <mad-icon style="color:#1e1e1f"  icon="fa-calculator"></mad-icon>
          <ng-template #calc>
            <div style="color:#1e1e1f;font-size:12px;padding:1em;background:white;">
            <a href="javascript:void(0);" style="text-decoration:none;color:#00A7B5;" (click)="copyToClipboard(data.calc, true)">
              <div style="text-align:center;"><b style="color:#1e1e1f">Click to Copy</b></div>
              <pre><br>{{data?.calc | json}}</pre>
            </a>
            </div>
          </ng-template>
          </a>
          <div class="price" (click)="!data.error ? (toggleDetails(data)): null" style="z-index:1">
          <a *ngIf="data.error" href="javascript:void(0);" (click)="retry(data)" style="position:absolute;color:#1e1e1f;box-shadow:none" >
            <mad-icon #bug [madPopover]="{popover: popover, showOnMouseEnter: true, hideOnMouseOut: true, type: 'bubble', content: error}" style="background:#dfdfdf" class="panel-first-icon"   type="avatar" icon="fa-bug" ></mad-icon>
            <ng-template #error>
            <div style="color:#fff;font-size:12px;padding:1em;background:red;">
              {{ data?.error | transloco:{lims_code: data?.lims_code} }}&nbsp;&nbsp;<b>Click to retry.</b>
            </div>
            </ng-template>
          </a>
          </div>
          <div class="price-input-wrapper">
          <mad-input class="summary-price-input" [class.needs-approval]="!data.preapproved"  #priceInput  [formatter]="{type: 'currency'}" style="padding:1px;" [grow]="true" [emptyBlurToFocusStart]="true" formControlName="price" [value]="data?.form?.controls?.price?.value">
            <div right style="display:flex;align-items:center;width:15px;position:absolute;right:0;top:0;bottom:0" title="Needs Approval">
            <svg *ngIf="!data.preapproved" viewBox="0 0 600 400" width="20" height="20" style="margin-left:0px;position:relative;top:-1px;left:-2px;">
              <polygon points="350,60 100,400 600,400"  stroke="red" stroke-width="75" fill="transparent" class="triangle" />
            </svg>
            </div>
          </mad-input>
          </div>
          <div class="price-input-wrapper">
          <mad-input  #discountInput  [formatter]="{type: 'percent', symbol: ''}" style="padding:1px;" [grow]="true" [emptyBlurToFocusStart]="true" formControlName="discount" [value]="data?.form?.controls?.discount?.value"></mad-input>
          </div>
          <div class="price-input-wrapper" style="color:#9a9a9a;font-weight:500;">{{data.current | currency}}</div>
          <div class="price-input-wrapper" style="color:#9a9a9a;font-weight:500;">{{data.list | currency}}</div>
          <div class="price-input-wrapper">
          <div class="" style="min-width:50px;width:50px;text-align:center;color:#9a9a9a;font-weight:500;">
            {{data.qty | number}}
          </div>
          <div class="" style="display:inline-flex;min-width:50px;width:50px;">
            <mad-input #qtyEstimate [formatter]="{type: 'number', symbol: ''}" style="padding:1px;min-width:50px;max-width:60px;" [grow]="true" formControlName="qty_estimate" [value]="data?.form?.controls?.qty_estimate?.value"></mad-input>
          </div>
          </div>
          <div class="price-input-wrapper" style="display:flex;align-items:center;color:#999;font-weight:bold;position:absolute;top:-23px;right:0;bottom:-21px;background:#f9f9f9;justify-content:center;font-size:15px;width:160px;padding-left:10px;padding-right:10px;margin-left:10px">
          {{data.projectedLabInvoiceImpact | currency}}
          </div>
        </div>
        <mad-link class="remove" [madPopover]="{popover: popover, showOnClick: true, hideOnBlur: true, type: 'pointer', content: removeConfirm}" >
          <mad-icon  class="remove-icon" icon="fa-times" ></mad-icon>
        </mad-link>
        <ng-template #removeConfirm>
          <div style="padding:1em;display:flex;flex-direction:row;align-items:center;">
          <div style="margin-right:2em;font-size:16px;font-weight:bold;">Are you sure?</div>
          <mad-button type="secondary" style="font-size:15px" (click)="onRemoveClick(data);this.popover.hide();">Remove</mad-button>
          <mad-button type="cancel" style="font-size:15px" (click)="popover.hide()">cancel</mad-button>
          </div>
        </ng-template>
      </div>
      <div class="tat">
        <div class="tat-section" *ngIf="data.turnaround_time">
          <div *ngIf="data.new_tat; else currentTat" class="turn-around-time">
            <mad-overlay 
              messageAlign="horizontal" 
              overlayStyle="normal">
            </mad-overlay>
            <mad-link 
              linkClass="navigation-ignored" 
              [madPopover]="{popover: tatBubble, target: infoLink, type: 'pointer', showOnFocus: true, hideOnBlur: true, content: turnAroundTimeInfo, context: {data: data}}" 
              [round]="true" 
            >
              <span class="clock-container"><mad-icon icon="time-clock"></mad-icon></span>
              <span class="turn-around-time-text">{{data.turnaround_time}} *</span>
            </mad-link>
          </div>
          <ng-template #currentTat>
            <div class="turn-around-time">
              <span class="current-tat clock-container"><mad-icon icon="time-clock"></mad-icon></span>
              <span class="turn-around-time-text current-tat">{{data.turnaround_time}}</span>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
	  <mad-spring class="advanced-pricing" direction="vertical" [visible]="data.type && !data.error && data.expanded" [skipInitialTransition]="true" style="margin-right:1em;border-left:solid 1px #eee;box-shadow: -3px -1px inset;background:white;">
		<mad-selected-panels-details *ngIf="visible && data.type && !data.error && data.isExpanded || false" [data]="data"[sapId]="session?.sapId"></mad-selected-panels-details>
	  </mad-spring>
	</div>
  </ng-container>
  <div class="placeholder" *ngIf="!session.panels.data[index]" [style.height]="height+'px'"></div>
</ng-template>

<ng-template #invoiceContent let-data="data">
  <div style="max-width:300px;font-size:12px;padding:0.5em;">
	<div *ngIf="data.infected">
	  This file was identified as potentially malicious.  Are you sure you want to download?
	</div>
	<div *ngIf="data.pending">
	  This file is either still scanning or unable to be scanned.  Are you sure you want to download?
	</div>
	<div *ngIf="data.infected || data.pending" style="display:block;width:100%;margin-top:1em;text-align:end">
	  <mad-button type="secondary" (click)="onInvoiceDownload(data)">Yes</mad-button>
	  <mad-button type="primary" (click)="invoicePopover.hide()">No</mad-button>
	</div>
	<div *ngIf="!data.infected && !data.pending" style="display:block;width:100%;margin-bottom:1em;text-align:end">
	  <mad-link (click)="onInvoiceDownload(data)">Download</mad-link>
	</div>
  </div>
</ng-template>

<ng-template #removeInvoiceContent let-data="data">
  <div style="max-width:300px;font-size:12px;padding:0.5em;">
	Are you sure you want to delete this attachment?
	<div  style="display:block;width:100%;margin-top:1em;text-align:end">
	  <mad-button type="secondary" (click)="onRemoveInvoice(data);invoicePopover.hide()">Yes</mad-button>
	  <mad-button type="primary" (click)="invoicePopover.hide()">No</mad-button>
	</div>
  </div>
</ng-template>

<mad-popover class="white opaque" #invoicePopover position="top-center" >
</mad-popover>

<mad-popover class="white opaque" #popover position="left-center" >
</mad-popover>

<mad-popover class="white opaque" #approvalPopover position="right-center" [mask]="true" >
  <mad-header style="background:#f5f6f8;padding-left:15px;" madTypography="header-3">
	Review decision
  </mad-header>
  <mad-body style="margin-top:1em;max-width:800px;">
	<div style="color:#9b9b9b;font-size:18px;">
	  <ng-container *ngIf="approvalPopover?.context?.type === 'APPROVE'">
		Are you sure you want to approve this session?  All price changes will be automatically submitted to Client Registration at the end of the day."
	  </ng-container>
	  <ng-container *ngIf="approvalPopover?.context?.type === 'REJECT'">
		Are you sure you want to reject this session? Rejected sessions will become locked and cannot be edited or resubmitted.
	  </ng-container>
	</div>
	<div style="display:flex;text-align:center;margin-top:1.5em;width:100%;justify-content:center;align-items:center;">
	  <mad-button type="secondary-teal" (click)="onConfirmReviewClick(approvalPopover?.context?.type);approvalPopover.hide()">
		<ng-container *ngIf="approvalPopover?.context?.type === 'APPROVE'">
		  <mad-icon icon="fa-check" style="color:green;margin-right:0.5em;"></mad-icon>
		  Approve
		</ng-container>
		<ng-container *ngIf="approvalPopover?.context?.type === 'REJECT'">
		  <mad-icon icon="fa-exclamation-triangle" style="color:red;margin-right:0.5em;"></mad-icon>
		  Reject
		</ng-container>
	  </mad-button>
	  <mad-link type="cancel" (click)="approvalPopover.hide()">Cancel and return to review</mad-link>
	</div>
  </mad-body>
</mad-popover>

<mad-modal #modal [visible]="isPreApprovalModalVisible" [mask]="true">
  <mad-header>
    <mad-icon icon="fa-exclamation-triangle" style="color:red;margin-left:2em;;margin-right:0.5em; width: 0; opacity: 0;"></mad-icon><!-- shim -->
    Are you sure you want to submit this request?
  </mad-header>
  <mad-body>
    Pre-approval pricing will be set-up without further review and a copy of the customer-facing output document will be delivered to your email address.
  </mad-body>
  <mad-footer>
      <mad-button (click)="submitPreApproval()">Yes</mad-button>
      <mad-button type="link" (click)="dismissModals()">No, return to session</mad-button>
  </mad-footer>
</mad-modal>

<mad-modal #modal [visible]="isPricingApprovalModalVisible" [mask]="true">
  <mad-header>
    <mad-icon icon="fa-exclamation-triangle" style="color:red;margin-left:2em;;margin-right:0.5em;"></mad-icon>
    Are you sure you want to submit this request?
  </mad-header>
  <mad-body>
    One or more codes in this submission need pricing approval and will be sent to CAG Sales Support. You will receive an email from Sales Support once the review is complete.
  </mad-body>
  <mad-footer>
      <mad-button (click)="submitPricingApproval()">Yes</mad-button>
      <mad-button type="link" (click)="dismissModals()">No, return to session</mad-button>
  </mad-footer>
</mad-modal>
