import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {CardOverlayComponent} from './card-overlay.component';
import {CardImageComponent} from './card-image.component';
import {CardComponent} from './card.component';
import {BodyModule} from './../body/body.module';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     BodyModule
  ],
  declarations: [
      CardOverlayComponent,
      CardImageComponent,
      CardComponent
  ],
  exports: [
      CardOverlayComponent,
      CardImageComponent,
      CardComponent
  ],
  bootstrap: [CardComponent]
})
export class CardModule { }
