export interface IPanelComponent {
    lims_code: string;
    lims_name: string;
    cust_name?: string;
    cust_desc?: string;
    included_with_idexx?: string;
    included_with_idexx_info_b?: string;
    info_description?: string;
    material: string;    
    turnaround_time?: string;
    new_tat?: string;
    new_tat_start_date?: string;
}

export const PRICE_TYPES = {
    SIMULATE_ONLY: "SIMULATE_ONLY",
    DIGITAL: "DIGITAL",
    EXISTING: "EXISTING",
    NEW: "NEW",
    COMPETITIVE: "COMPETITIVE"
} as const;

export type PriceType = keyof typeof PRICE_TYPES;

interface EditablePanel {
    id: number;
    session: number;
    lims: string;
    lims_code: string;
    lims_name?: string;
    material: string;
    desc?: string;
    inactive: boolean;
    match_code?: string;
    match_price?: number;
    is_extra?: boolean;
    type?: PriceType;
    price: number;
    recommended: number;
    floor: number;
    current: number;
    list: number;
    qty?: number;
    qty_estimate?: number;
    error?: string | Error;
    calc?: any;
    calc_date?: Date;
    children?: IPanelComponent[];
    relationships_version: number | number[];
    related_panel_id?: number;
    parent_id?: number;
    turnaround_time?: string;
    new_tat?: string;
    new_tat_start_date?: string;
}

export interface ISessionPanel extends EditablePanel {
    versions?: { [key in (keyof EditablePanel)] }
}
