import {Formatter} from './formatter';

export class DateFormatter extends Formatter {
    format (target: HTMLInputElement, value: string) {
        if (value === null || value === undefined || value === '') {
            value = '';
            return {raw: value, value: '', selectionStart: -1, selectionEnd: -1};
        }
        //this handles set from self (date self format is not a DateTime string -- isntead its yyyy-mm-dd, we detect and adjust accordingly)
        if (/^[0-9]{1,4}\-[0-9]{1,2}\-[0-9]{1,2}$/.test(value)) {
            var xfer = new Date(),
                split = value.split('-');

            xfer.setFullYear(+split[0]);
            xfer.setMonth(+split[1]-1)
            xfer.setDate(+split[2])
        } else {
            var xfer = new Date(value);
        }
        return {
            raw: xfer.toISOString(),
            value: `${(xfer.getFullYear() + '').padStart(4,'0')}-${((xfer.getMonth() + 1) + '').padStart(2,'0')}-${(xfer.getDate() + '').padStart(2, '0')}`,
            selectionStart: -1,
            selectionEnd: -1
        };
    }
}
