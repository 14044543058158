import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {ResizeDirective} from './../resize/resize.directive';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule
  ],
  declarations: [
    ResizeDirective
  ],
  exports: [
    ResizeDirective
  ],
  bootstrap: []
})
export class ResizeModule { }
