const MATCH_WORD = ["matches", "webkitMatchesSelector"].find((word) => (word in HTMLElement.prototype)),
    createRange = (() => {
        try {
            const temp = new Range();
            return temp && (() => new Range());
        } catch (e) {
            return () => document.createRange();
        }
    })();


export class DomUtil {
    static MATCH_WORD: string = MATCH_WORD;

    static queryUp(el: Node, selector: string | Node | ((el: Node) => boolean) | ((string | Node | ((el: Node) => boolean))[])): Node | HTMLElement {
        let node: Node, i: number, ln: number, select: string | Node | ((el:Node)=>boolean), selection: (typeof select)[];
        const is_array = Array.isArray(selector) || selector instanceof NodeList;

        if (!is_array) {
            selection = [selector] as (typeof select)[];
        } else {
            selection = selector as (typeof select)[];
        }

        for (i = 0, ln = selection.length; i < ln; i++) {
            select = selection[i];
            node = el;
            while (node) {
                if (typeof select === 'string') {
                    if (node[MATCH_WORD] && node[MATCH_WORD](select)) {
                        return node;
                    }
                } else if (typeof select === 'function') {
                    if (select(node)) {
                        return node;
                    }
                } else {
                    if (node === select) {
                        return node;
                    }
                }
                node = node.parentNode;
            }
        }
        return null;

    }

    static persistCursor(element: HTMLElement, fn: () => Promise<any>) {
        let start: number,
            end: number,
            selection: Selection,
            range: Range;

        if (element instanceof HTMLInputElement) {
            start = element.selectionStart;
            end = element.selectionEnd;
            fn().then((): void => {
                element.setSelectionRange(start, end);
            });
        } else {
            selection = window.getSelection();

            const ranges = [];

            for (let i = 0, ln = selection.rangeCount; i < ln; i++) {
                range = selection.getRangeAt(i);
                if (range.startContainer === range.endContainer && this.queryUp(range.startContainer, element)) {
                    ranges.push({
                        start: range.startOffset,
                        end: range.endOffset,
                        range
                    });
                }
            }
            fn().then((): void => {
                let _range;
                if (element.firstChild) {
                    for (let i = 0, ln = ranges.length; i < ln; i++) {
                        _range = ranges[i];
                        _range.range.setStart(element.firstChild,
                            Math.min(_range.start, element.firstChild.nodeValue.length));
                        _range.range.setEnd(element.firstChild,
                            Math.min(_range.end, element.firstChild.nodeValue.length));
                    }
                }
            });
        }
        return this;
    }

    static selectContents(element: HTMLElement | HTMLInputElement | HTMLTextAreaElement) {
        if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement) {
            if (element instanceof HTMLInputElement && element.type === 'date') {
                return;
            }
            element.selectionStart = 0;
            element.selectionEnd = element.value.length;
        } else {
            const selection = window.getSelection();
            selection.removeAllRanges();
            const range = document.createRange();
            range.selectNodeContents(element);

            window.getSelection().addRange(range);
        }
        return this;
    }

    static getSelectionCount(): number {
        return window.getSelection().rangeCount;
    }

    static deselectAll() {
        window.getSelection().removeAllRanges();
        return this;
    }

    static isCursorAtStart(element: HTMLElement): {startContainer: Node; endContainer: Node; startOffset: number; endOffset: number; } {
        const range = this.getCursor(element);
        if (element instanceof HTMLInputElement) {
            if (document.activeElement === element && element.selectionStart === 0 && element.selectionEnd === 0) {
                const result = new Range();
                result.setStart(element, 0);
                result.setEnd(element, 0);
                return result;
            }
            return null;
        }
        if (range && ((range.startContainer === element || range.startContainer === element.firstChild) && 0 === range.endOffset
                || (element.firstChild.nodeValue.trim().length === 0 && range.endOffset === 1))) {
            return range;
        } else {
            return null;
        }
    }

    static isCursorAtEnd(element: HTMLElement): {startContainer: Node; endContainer: Node; startOffset: number; endOffset: number; } {
        const range = this.getCursor(element);
        if (element instanceof HTMLInputElement) {
            const ln = element.value.length - 1;
            if (document.activeElement === element && element.selectionStart === ln && element.selectionEnd === ln) {
                return {startContainer: element, endContainer: element, startOffset: ln+1, endOffset: ln+1} as any;
            }
            return null;
        }
        if (range && ((range.endContainer === element || range.endContainer === element.lastChild) &&
                (element.lastChild || element).textContent.trim().length <= range.endOffset)) {
            return range;
        } else {
            return null;
        }
    }

    static getCursor(element: HTMLElement): {startContainer: Node; endContainer: Node; startOffset: number; endOffset: number} {
        const selection = window.getSelection();
        var range: Range;
        if (element instanceof HTMLInputElement) {
            return {
                startContainer: element,
                endContainer: element,
                startOffset: element.selectionStart,
                endOffset: element.selectionEnd
            };
        }
        for (let i = 0, ln = selection.rangeCount; i < ln; i++) {
            range = selection.getRangeAt(i);
            if (range.startContainer === range.endContainer && this.queryUp(range.endContainer, element)) {
                return range;
            }
        }
        return null;
    }

    static setCursor(element: HTMLElement, startOffset: number, endOffset: number = startOffset) {
        const selection = window.getSelection(),
            range = createRange();
        selection.removeAllRanges();
        range.setStart(element.firstChild, startOffset);
        range.setEnd(element.firstChild, endOffset);
        selection.addRange(range);
    }

    // modifies and increments the current text of an element by the passed value
    static addNumber(element: HTMLElement, value, {
        max,
        clear = true,
        select = true,
        cycle = true,
        min
    }: {
        max?: number | null | undefined,
        select?: boolean,
        clear?: boolean,
        cycle?: boolean,
        min?: number | null | undefined
    }) {
        let getProp = "innerText", setProp = "innerHTML";
        if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement) {
            getProp = "value";
            setProp = "value";
        }
        let current: number | string = parseFloat(element[getProp]),
            content;
        const was_nan = isNaN(parseInt("" + current, 10)),
            was_cleared = element.innerText.trim() === "";

        if (was_nan) {
            if (!was_cleared) {
                current = 0;
            }
        } else {
            current += value;
        }

        if (typeof max === "number") {
            if ((isNaN(current) && value > 0) || current > max) {
                if (was_cleared && cycle) {
                    current = min;
                } else if (clear) {
                    current = "";
                } else {
                    current = max;
                }
            }
        }
        if (typeof min === "number") {
            if ((was_nan && value < 0) || current < min) {
                if (was_cleared && cycle) {
                    current = max;
                } else if (clear) {
                    current = "";
                } else {
                    current = min;
                }
            }
        }
        element[setProp] = "" + current;
        if (select) {
            content = element.firstChild || element;
            if (content) {
                DomUtil.selectContents(content);
            }
        }
        return current;
    }

    static hasFocus(element: Element): boolean {
        return this.queryUp(document.activeElement as HTMLElement, element) && true || false;
    }
}
