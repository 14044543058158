<mad-field>
    <ng-content select="mad-label" ngProjectAs="mad-label"></ng-content>
    <ng-content select="mad-micro" ngProjectAs="mad-micro"></ng-content>
    <ng-content select="mad-error" ngProjectAs="mad-error"></ng-content>

    <span class="input-wrapper-inner" [class.grow]="grow">
    <span class="input-wrapper-inner-inner" [class.grow]="grow">
    <div *ngIf="grow" #sizer class="input-sizer" >
        {{_sizer_value}}
    </div>
    <textarea
            #textarea
            class="spot-form__textarea"
            [class.grow]="grow"
            spellcheck="false"
            (keydown)="onKeyDown($event)"
            (input)="onInput($event)"
            (focus)="onFocus($event)"
            (ngModelChange)="onNgModelChange($event)"
            [(ngModel)]="value"
            [placeholder]="placeholder"
            [disabled]="disabled">
        </textarea>
    </span>
    </span>
    <span *ngIf="icon || loading" class="spot-form__select-open-indicator">
        <svg *ngIf="loading" class="spot-icon spot-loading-spinner"
             aria-labelledby="title">
            <title></title>
            <use xlink:href="/assets/spot/dist/icons/spot_icons.svg#spinner"></use>
        </svg>
        <svg *ngIf="!loading && icon === 'search'" class="spot-icon spot-form__select-open-icon"
             aria-labelledby="title">
            <title></title>
            <use xlink:href="/assets/spot/dist/icons/spot_icons.svg#search"></use>
        </svg>
        <svg *ngIf="!loading && icon === 'add'" class="spot-icon spot-form__select-open-icon"
             aria-labelledby="title">
            <title></title>
            <use xlink:href="/assets/spot/dist/icons/spot_icons.svg#add"></use>
        </svg>
    </span>

</mad-field>
