import {
    Component,
    HostBinding,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    Renderer2,
    ViewChild,
    OnInit,
    SimpleChanges,
    ChangeDetectorRef,
    AfterContentChecked
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { InputActions } from './input.actions';
import { Field } from '../field/field.component';
import { DomUtil } from '../../utils/dom';
import { Subscription } from '../actions/actions';
import { FormatterDirective } from '../formatter/formatter.directive';

@Component({
    selector: "mad-input",
    templateUrl: "./input.component.html",
    styleUrls: ["./input.component.scss"],
    providers: Field.Providers(InputComponent).concat(...InputActions.Providers())
})
export class InputComponent extends Field implements ControlValueAccessor, OnChanges, OnInit, OnDestroy, AfterContentChecked {
    @HostBinding('class') class;

    @ViewChild('input') input: ElementRef;

    @ViewChild('leftIconWrapper') leftIconWrapper;

    @ViewChild('rightIconWrapper') rightIconWrapper;

    _formatterDirective: FormatterDirective;

    @ViewChild(FormatterDirective) set formatterDirective(formatterDirective: FormatterDirective) {
        if (this._formatterDirective !== formatterDirective) {
            this._formatterDirective = formatterDirective;
            this._display = this._formatterDirective.format(this.input.nativeElement, this.value).value;
            this.changeDetector.detectChanges();
        }
    }

    get formatterDirective(): FormatterDirective {
        return this._formatterDirective;
    }

    @Input()
    type: 'input' | 'search';

    @Input()
    grow = false;

    @Input()
    inputType = "text";

    @Input()
    placeholder = "";

    @Input()
    loading: boolean = false;

    @Input()
    formatter: { type: 'currency' | 'date' | 'none' } = { type: 'none' };

    @Input()
    emptyBlurToFocusStart: boolean;

    @Output()
    defaultAction = new EventEmitter();

    subscription: Subscription;

    _writing: boolean;

    _leftIcon: boolean;

    _rightIcon: boolean;

    _start_focus_value: string;

    _value: any;

    @Input()
    set value(value) {
        if (value !== this._value) {
            this._value = value;

            if (this.formatterDirective) {
                this._display = this.formatterDirective.format(this.input.nativeElement, this.value).value;
            } else {
                this._display = value;
            }
            if (!this._writing) {
                if (this._onChange) {
                    this._onChange(this.value);
                }
                if (this._onTouched) {
                    this._onTouched();
                }
            }
        }
    }

    get value(): any {
        return this._value;
    }

    _display: any = '';

    lastAttributes: any = undefined;

    constructor(public renderer: Renderer2, public element: ElementRef, public action: InputActions, public changeDetector: ChangeDetectorRef) {
        super(element);
    }

    ngOnInit(): void {
        this.subscription = this.action.subscribe((v) => {
            switch (v.type) {
                case "input-default":
                    this.doDefaultAction();
                    break;
                case "undo-changes":
                    this.undo(v.$event);
                    break;
            }
        });
    }

    ngAfterContentChecked() {
        if (this.leftIconWrapper && this.leftIconWrapper.nativeElement.children.length) {
            this._leftIcon = true;
        } else {
            this._leftIcon = false;
        }
        if (this.rightIconWrapper && this.rightIconWrapper.nativeElement.children.length) {
            this._rightIcon = true;
        } else {
            this._rightIcon = false;
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    onFocus($event) {
        if (this.input) {
            DomUtil.selectContents(this.input.nativeElement);
            this._start_focus_value = this.value;
        }
    }

    onBlur($event: FocusEvent) {
        if (this.emptyBlurToFocusStart && !this.value) {
            this.formatterDirective.format($event.target as HTMLInputElement, this._start_focus_value)
            this.value = this._start_focus_value;
        }
    }

    doDefaultAction() {
        this.defaultAction.emit(this._value);
    }

    writeValue(value: any): void {
        // writeValue is not a user interaction and should not be represented as one
        this._writing = true;

        if (this.formatterDirective) {
            this.formatterDirective.format(this.input.nativeElement, this.value);
        }
        this.value = value;

        this._writing = false;
    }

    blurInput(): void {
        this.input.nativeElement.blur();
    }

    focusInput(): void {
        this.input.nativeElement.focus();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.class = this.type;

        if (!this.input) {
            return;
        }
        let p: string;
        const renderer = this.renderer;
        const input = this.input.nativeElement;
        const attributes = this.getAttributes() || {};
        const last = this.lastAttributes;

        for (p in attributes) {
            if (attributes.hasOwnProperty(p)) {
                renderer.setAttribute(input, p, attributes[p]);
                if (last && last[p] !== undefined) {
                    delete last[p];
                }
            }
        }
        for (p in last) {
            if (last.hasOwnProperty(p)) {
                renderer.removeAttribute(input, p);
            }
        }
        this.lastAttributes = Object.assign({}, attributes);
    }

    getAttributes() {
        return {};
    }

    setLoading(state: boolean) {
        this.loading = state;
    }

    onKeyDown($event) {
    }

    onNgModelChange($event) {
    }

    onInput($event: InputEvent) {
        const result = this.formatterDirective.format(this.input.nativeElement, this._display);
        // update the internal value from user input
        this.value = result.raw;
    }

    undo($event) {
        this.formatterDirective.format($event.target, this._start_focus_value)
        this.value = this._start_focus_value;
    }

    reset() {
        this._value = null;
    }

    _updateValue(display: string) {
        this.value = display;
    }
}
