import {APP_INITIALIZER} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  TranslocoService,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule
} from '@ngneat/transloco';
import {Injectable, NgModule} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {
  }

  getTranslation(lang: string) {
    return new Observable((sub) => {
        import(`../../assets/i18n/${lang}.json`).then(res => {
          sub.next(res.default);
          sub.complete();
        });
    });
  }
}

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en-US'],
        defaultLang: 'en-US',
        fallbackLang: 'en-US',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production
      })
    }, { 
      provide: TRANSLOCO_LOADER,
      useClass: TranslocoHttpLoader 
    }, { 
      provide: APP_INITIALIZER,
      multi: true,
      deps: [TranslocoService],
      useFactory: (transloco: TranslocoService) => {
        return async function  () {
          transloco.setActiveLang('en-US');
          var result = await transloco.load('en-US').toPromise();
          return result;
        };
      }
    }
  ]
})
export class TranslocoRootModule {}
