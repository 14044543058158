import {Injectable, Component, SkipSelf, ContentChildren, ElementRef, OnInit, QueryList, Optional} from "@angular/core";
import {Actions, AbstractActions, Subscription} from "../actions/actions";
import {Field} from "../field/field.component";
import {DomUtil} from "../../utils/dom";

@Injectable()
export class NavigationActions extends Actions({
    name: "mad-navigation",
    types: {
        "nav-focus-next-any": {
            name: 'nav-focus-next-any',
            description: 'Focuses the next selectable element'
        },
        "nav-focus-prev-any": {
            name: "Previous selectable",
            description: "Focuses the previous selectable element."
        },
        "nav-focus-next-field": {name: "Next field", description: "Focuses the next field."},
        "nav-focus-prev-field": {name: "Previous field", description: "Focuses the previous field."},
        "nav-focus-error": {name: "Focuses the first field in a an error state.  "},
        "nav-track-focus": {name: 'Track Focus'},
        "nav-ensure-focus": {name: 'Ensure Focus'},
        "nav-rewind-focus": {name: "Rewind focus", description: "Moves focus backwards through replay history"},
        "nav-fastforward-focus": {
            name: "Fast forward focus",
            description: "Moves focus forward through replay history"
        }
    },
    targets: {
        "field": {matcher: "mad-field"}
    },
    interactions: {
        keydown: {
            "alt+h": "nav-focus-error",
            "alt+j": "nav-focus-next-field",
            "alt+k": "nav-focus-prev-field",
            "alt+o": "nav-rewind-focus",
            "alt+l": "nav-fastforward-focus",
            "ArrowUp": "nav-focus-prev-any",
            "ArrowDown": "nav-focus-next-any",
            "tab":  "nav-focus-next-any",
            "shift+tab":  "nav-focus-prev-any"
        },
        focus: "nav-track-focus",
        blur: "nav-ensure-focus"
    }
}) {
    //needed for testing -- DI bug
    constructor (@Optional() @SkipSelf() public parent: AbstractActions,public element: ElementRef) {
        super(parent, element);
    }
}


@Component({
    selector: "mad-navigation",
    templateUrl: "./navigation.component.html",
    styleUrls: ["./navigation.component.scss"],
    providers: NavigationActions.Providers()
})
export class NavigationComponent implements OnInit {
    @ContentChildren(Field, {descendants: true}) fields: QueryList<Field>;

    _lastSelection = [];
    _nextSelection = [];
    _focus_timer;
    _focusStack = [];
    _currentParents: {element: Element, index: number}[] = [];
    _focus_index = 0;
    _preventStack = false;
    _actionSub: Subscription;

    constructor( @Optional() public element: ElementRef, public action: NavigationActions) {
    }

    ngOnInit() {
        this._actionSub = this.action.subscribe((v, d?) => {
            switch (v.type) {
                case "nav-focus-next-any":
                    this.focusNextAny(v);
                    break;
                case "nav-focus-prev-any":
                    this.focusPrevAny(v);
                    break;
                case "nav-focus-next-field":
                    this.focusNextField(v, d);
                    break;
                case "nav-focus-prev-field":
                    this.focusPrevField(v);
                    break;
                case "nav-focus-error":
                    this.focusError(v);
                    break;
                case "nav-track-focus":
                    this.trackFocus(v);
                    break;
                case "nav-ensure-focus":
                    this.ensureFocus(v);
                    break;
                case "nav-rewind-focus":
                    this.rewindFocus(v);
                    break;
                case "nav-fastforward-focus":
                    this.fastForwardFocus(v);
                    break;
                default:
                    break;
            }
        });

        // handle an element already having focus
        if (document.activeElement !== document.body && DomUtil.queryUp(document.activeElement, this.element.nativeElement)) {
            this.trackFocus(null);
        }
    }

    ngOnDestroy () {
        this._actionSub.unsubscribe();
    }

    getAllFields(element = this.element.nativeElement): any[] {
        return Array.prototype.slice.call(element.querySelectorAll
            (
                 //mad-button:not([type="cancel-icon"]) button:not([disabled]):not([tabindex="-1"]),
                `input:not([disabled]):not([readonly]):not([tabindex = "-1"]):not(.navigation-ignored),
                 select:not([disabled]):not([readonly]):not([tabindex = "-1"]):not(.navigation-ignored),
                 a:not([disabled]):not([tabindex = "-1"]):not(.navigation-ignored),
                 button:not([disabled]):not([tabindex="-1"]):not(.navigation-ignored),
                 [contenteditable]:not([contenteditable="false"]):not([tabindex = "-1"]):not(.navigation-ignored),
                 textarea:not([disabled]):not([readonly]):not([tabindex = "-1"]):not(.navigation-ignored)`
            )
            //button:not(.cl-modal-cancel):not([disabled]):not([tabindex = "-1"]),
        ).filter(v => !DomUtil.queryUp(v, "[hidden]"));
    }

    // moves the cursor backwards through focus history
    rewindFocus(action) {
        if (action && action.$event) {
            action.$event.preventDefault();
        }
        if (this._focus_index > 0) {
            this._focus_index--;
            const field = this._focusStack[this._focus_index];
            if (field) {
                this._preventStack = true;
                field.focus();
            }
        }
    }

    // moves the cursor forwards through focus history
    fastForwardFocus(action) {
        if (action && action.$event) {
            action.$event.preventDefault();
        }
        if (this._focus_index <= this._focusStack.length - 2) {
            this._focus_index++;
            const field = this._focusStack[this._focus_index];
            if (field) {
                this._preventStack = true;
                field.focus();
            }
        }
    }

    // blur occurs after the focus event
    // this code ensures that there is an element selected - in the case of list, samples, and test code removals
    ensureFocus(v) {
        if (this._focus_timer) {
            cancelAnimationFrame(this._focus_timer);
        }

        this._focus_timer = requestAnimationFrame(() => {
            this._focus_timer = 0;

            if (window.getSelection().rangeCount && window.getSelection().getRangeAt(0).startContainer !== document.activeElement) {
                if ((window.getSelection().getRangeAt(0).startContainer as any).focus) {
                    (window.getSelection().getRangeAt(0).startContainer as HTMLElement).focus();
                }
            }

            if (document.activeElement === document.body && this._nextSelection) {
                const parents = this._currentParents || [];
                let parent;
                let i, ln;
                let last_index;
                let field;
                let fields;
                if (v.$event.target.ownerDocument.contains(v.$event.target)) {
                    v.$event.target.focus();
                    return;
                }
                for (i = 0, ln = parents.length; i < ln; i++) {
                    parent = parents[i];
                    last_index = parent.index;
                    if (parent.element.ownerDocument.contains(parent.element)) {
                        fields = this.getAllFields(parent.element);
                        if (fields.length) {
                            if (last_index >= fields.length) {
                                last_index = fields.length - 1;
                            }
                            field = fields[last_index];
                            if (field) {
                                break;
                            }
                        }
                    }
                }

                this._focus_timer = 0;

                if (document.activeElement === document.body && field) {
                    field.focus();
                }
            }
        });
    }

    // records focus cursor position
    trackFocus(v) {
        if (!this._preventStack) {
            if (this._focus_index !== this._focusStack.length - 1) {
                this._focusStack.splice(this._focus_index + 1, this._focusStack.length);
            }
            if (this._focusStack[this._focusStack.length - 1] !== document.activeElement) {
                this._focusStack.push(document.activeElement);
                this._focus_index = this._focusStack.length - 1;
                const parents = this._currentParents = [];
                let element = document.activeElement;
                while (element) {
                    parents.push({index: this.getAllFields(element).indexOf(document.activeElement), element});
                    if (element === this.element.nativeElement) {
                        break;
                    }
                    element = element.parentNode as Element;
                }
            }
        } else {
            this._preventStack = false;
        }

        if (this._focus_timer) {
            cancelAnimationFrame(this._focus_timer);
            this._focus_timer = 0;
        }

        if (this._nextSelection) {
            this._lastSelection = this._nextSelection;
        }
        this._nextSelection = this.getAllFields();
    }


    // focuses the next previous cl-field
    focusField(direction: number, action: NavigationActions['_value']): this {
        const fieldsRaw = this.getAllFields(),
            fields = fieldsRaw.map(v => DomUtil.queryUp(v, "mad-field") || v),
            activeElement = document.activeElement,
            activeField = DomUtil.queryUp(activeElement, "mad-field") || activeElement;

        let foundIndex = fields.indexOf(activeField);
        if (foundIndex === -1) {
            foundIndex = fieldsRaw.indexOf(activeField);
        }
        foundIndex += direction;

        let i, ln = fields.length;

        if (direction >= 1) {
            for (i = foundIndex, ln; i < ln; i++) {
                if (test(fields[i])) {
                    return this;
                }
            }
            for (i = 0, ln = foundIndex - 1; i < ln; i++) {
                if (test(fields[i])) {
                    return this;
                }
            }
        } else {
            for (i = foundIndex, ln; i >= 0; i--) {
                if (test(fields[i])) {
                    return this;
                }
            }
            for (i = fields.length - 1, ln = foundIndex + 1; i > ln; i--) {
                if (test(fields[i])) {
                    return this;
                }
            }
        }

        function test(field) {
            if (field && field !== activeField && field.madField && !field.madField.disabled) {
                if (action && action.$event) {
                    action.$event.preventDefault();
                    action.$event.stopPropagation();
                }
                field.madField.focusInput(action);
                return true;
            }
            return false;
        }

        return this;
    }

    // focuses the next / previous selectable element
    focusAny(direction: -1 | 1, action: NavigationActions['_value']) {
        const focusElements = this.getAllFields(),
            activeElement = document.activeElement,
            activeIndex = focusElements.indexOf(activeElement);

        if (focusElements[activeIndex + direction]) {
            focusElements[activeIndex + direction].focus();
            if (action && action.$event) {
                action.$event.preventDefault();
            }
        } else {
            if (direction === 1 && focusElements[0]) {
                focusElements[0].focus();
                if (action && action.$event) {
                    action.$event.preventDefault();
                }
            } else if (focusElements[focusElements.length - 1]) {
                focusElements[focusElements.length - 1].focus();
                if (action && action.$event) {
                    action.$event.preventDefault();
                }
            }
        }
    }

    // focuses the last mad-field with error
    focusError(action: NavigationActions['_value']): this {
        const fields = this.getAllFields().map(v => DomUtil.queryUp(v, "mad-field") || v),
            activeElement = document.activeElement,
            activeField = DomUtil.queryUp(activeElement, "mad-field") || activeElement;

        if (action && action.$event) {
            action.$event.preventDefault();
        }

        let i, field;
        const foundIndex = fields.indexOf(activeField) - 1;

        for (i = foundIndex; i >= 0; i--) {
            field = fields[i];
            if (field.madField && field.madField.error && field.madField.error.length) {
                field.madField.focusInput(action);
                return this;
            }
        }
        for (i = fields.length - 1; i >= 0; i--) {
            field = fields[i];
            if (field.madField && field.madField.error && field.madField.error.length) {
                field.madField.focusInput(action);
                return this;
            }
        }
        return this;
    }

    focusNextAny(action: NavigationActions['_value']) {
        this.focusAny(1, action);
    }

    focusPrevAny(action: NavigationActions['_value']) {
        this.focusAny(-1, action);
    }

    focusNextField(action: NavigationActions['_value'], direction?: number): this {
        return this.focusField(direction || 1, action);
    }

    focusPrevField(action: NavigationActions['_value']): this {
        return this.focusField(-1, action);
    }
}
