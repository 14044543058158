<mad-header class="selected-panels-header" madTypography="header-3">
  <mad-body class="inner">
    <a href="javascript:void(0)" class="text-wrapper selected-panels-header-link" (click)="onOpenClick()" title="Selected Panels">
      <mad-icon class="selected-panels-icon" icon="clipboard"></mad-icon>
      <mad-spring [visible]="!isVisible" direction="horizontal" class="hide-on-tablet">
        Go&nbsp;to&nbsp;
      </mad-spring>
      <span class="title-text hide-on-tablet">
        Selected&nbsp;Panels
      </span>
    </a>
  </mad-body>
</mad-header>

<div class="selected-panels-continue-wrapper" [class.hidden]="!isVisible || !session?.panels.data.length" >
  <mad-button style="position:relative;" [disabled]="!session?.isValid()" (click)="onContinueClick()">
    Continue
    <mad-icon right icon="fa-arrow-right"></mad-icon>
  </mad-button>
</div>
<div class="selected-panels-content" >
  <mad-spring class="selected-panels-content-spring" [visible]="!session?.panels.data.length" direction="vertical">
    <div class="selected-panels-no-panels-text">
      No panels selected
    </div>
  </mad-spring>
  <mad-infinite-list   #infiniteList *ngIf="!session?.vdc_reviewing" [assumedHeight]="77" [buffer]="77" class="selected-panels-result-list scrollable" [pager]="pager"  [template]="codeTemplate">
  </mad-infinite-list>
</div>

<ng-template #turnAroundTimeInfo let-data="data" let-selected="selected">
  <div class="tatTooltip">Beginning on {{ data.new_tat_start_date }}, the turnaround time on this test will be updated to: <b>{{ data.new_tat }}</b>
  </div>
</ng-template>

<ng-template #codeTemplate let-index="index" let-height="height">
  <ng-container *ngIf="session?.panels.data[index]; let data">
    <div *ngIf="!data.is_extra" #el_target class="selected-panels-panel" [formGroup]="data.form"  [class.configure]="data === configure"  [class.adding]="!data.inactive && data.adding" [class.added]="!data?.inactive && !data?.added" [style]="getRowStyle(data, el_target)" >
	  <div  class="inner-panel" >
      <div class="data-row">
        <mad-overlay [busy]="deletingPanels.indexOf(data.id) != -1" [class.visible]="busy ? 'visible' :''" busyMessage="Removing..." align="right" messageAlign="horizontal" overlayStyle="normal"></mad-overlay>
            <mad-link #infoLink *ngIf="isVisible && data.contains_info" class="info-link" linkClass="navigation-ignored" [madPopover]="{popover: infoBubble, target: isInfoFullScreen(infoLink) ? infoBottomMark : infoLink, type: isInfoFullScreen(infoLink) ? 'bubble' : 'pointer', showOnFocus: true, hideOnBlur: true, content: infoBubbleContent, context: {data: data, selected: [0]}}" [round]="true" tabindex="-1" style="">
              <mad-icon icon="fa-info"></mad-icon>
            </mad-link>
        <div *ngIf="isVisible && !data.contains_info" class="info-link-spacer" linkClass="navigation-ignored"></div>
          <div class="lims-id" [class.visible]="isVisible && data.type" #limsId style="">
            <div class="lims-code">
              {{data.lims_code}}
            </div>
            <div class="lims-type" >
              {{(data.type === 'COMPETITIVE' ? data.match_code : data.type) || ''}}
            </div>
          </div>

          <div class="fade-section hide-on-tablet" [class.visible]="isVisible" >
            <div [style]="_inner_spring_style" class="matches" >
                  <div *ngFor="let match of data.children" class="component" >
                    {{match.lims_name || match.cust_name || match.lims_code}}
                  </div>
            </div>
            <div class="spacer" style="position:relative;left:-1.5em;padding-top:0.2em;">
            </div>
            <a *ngIf="userState?.state?.can_approve" class="calculator" href="javascript:void(0)" style="left:-0.5em;display:flex;align-items:center;position:relative;" [madPopover]="{popover: popover, showOnClick: true,  hideOnBlur: true, type: 'pointer', content: calc}">
                  <mad-icon style="color:#1e1e1f"  icon="fa-calculator"></mad-icon>
                  <ng-template #calc>
              <div style="color:#1e1e1f;font-size:12px;padding:1em;background:white;">
                      <a href="javascript:void(0);" style="text-decoration:none;color:#00A7B5;" (click)="copyToClipboard(data.calc, true)">
                <div style="text-align:center;">
                          <b style="color:#1e1e1f">Click to Copy</b>
                </div>
                <pre>
                          <br>{{data?.calc | json}}
                </pre>
                      </a>
              </div>
                  </ng-template>
            </a>
            <div class="price" (click)="!data.error ? (toggleDetails(data)): null">
                  <mad-icon class="panel-first-icon price-icon" icon="fa-dollar-sign" [showAlt]="!data.type && !data.error" altIcon="fa-spin fa-spinner" type="avatar" ></mad-icon>
                  <a *ngIf="data.error" href="javascript:void(0);" (click)="retry(data)" style="position:absolute;color:#1e1e1f;box-shadow:none" >
              <mad-icon #bug [madPopover]="{popover: popover, showOnMouseEnter: true, hideOnMouseOut: true, type: 'bubble', content: error}" style="background:#dfdfdf" class="panel-first-icon"   type="avatar" icon="fa-bug" ></mad-icon>
              <ng-template #error>
                      <div style="color:#fff;font-size:12px;padding:1em;background:red;">
                {{ data?.error?.code || data?.error | transloco:{lims_code: data?.lims_code} }}
                &nbsp;&nbsp;<b>Click to retry.</b>
                      </div>
              </ng-template>
                  </a>
            </div>
            <div class="price-input-wrapper">
                  <mad-input  #priceInput *ngIf="data.type && !data.error" [formatter]="{type: 'currency', symbol: ''}" class="price-input" [class.needs-approval]="!data.preapproved" [emptyBlurToFocusStart]="true" formControlName="price" [value]="data?.form?.controls?.price?.value">
              <div right style="display:flex;align-items:center;width:18px;position:absolute;right:0;top:0;bottom:0" title="Needs Approval">
                      <svg *ngIf="!data.preapproved" viewBox="0 0 700 400" width="20" height="20" style="margin-left:0px;position:relative;top:-1px;left:-2px">
                <polygon points="350,60 100,400 600,400"  stroke="red" stroke-width="100" fill="transparent" class="triangle" />
                      </svg>
              </div>
                  </mad-input>
            </div>
            <div class="configure" >
              <mad-link (click)="onConfigureClick(data)" title="Configure">
                <mad-icon class="configure-icon" icon="fa-wrench" >
                </mad-icon>
              </mad-link>

              <mad-link class="remove hide-on-tablet" [class.visible]="visible" [madPopover]="{popover: popover, showOnClick: true, hideOnBlur: true, type: 'pointer', content: removeConfirm}" >
                <mad-icon  class="remove-icon" icon="fa-times" ></mad-icon>
              </mad-link>
            </div>
          </div>
          <mad-link class="remove hide-on-tablet large-remove" [class.visible]="visible" [madPopover]="{popover: popover, showOnClick: true, hideOnBlur: true, type: 'pointer', content: removeConfirm}" >
            <mad-icon  class="remove-icon" icon="fa-times" ></mad-icon>
          </mad-link>
            <ng-template #removeConfirm>
          <div style="padding:1em;display:flex;flex-direction:row;align-items:center;">
                <div style="margin-right:2em;font-size:16px;font-weight:bold;">
            Are you sure?
                </div>
                <mad-button type="secondary" style="font-size:15px" (click)="onRemoveClick(data);this.popover.hide();">Remove</mad-button>
                <mad-button type="cancel" style="font-size:15px" (click)="popover.hide()">cancel</mad-button>
          </div>
            </ng-template>
      </div>
      <div class="tat-section" [class.visible]="isVisible" *ngIf="data.turnaround_time">
        <div *ngIf="data.new_tat; else currentTat" class="turn-around-time">
          <mad-overlay 
            messageAlign="horizontal" 
            overlayStyle="normal">
          </mad-overlay>
          <mad-link 
            linkClass="navigation-ignored" 
            [madPopover]="{popover: tatBubble, target: infoLink, type: 'pointer', showOnFocus: true, hideOnBlur: true, content: turnAroundTimeInfo, context: {data: data}}" 
            [round]="true" 
          >
            <span class="clock-container"><mad-icon icon="time-clock"></mad-icon></span>
            <span class="turn-around-time-text">{{data.turnaround_time}} *</span>
          </mad-link>
        </div>
        <ng-template #currentTat>
          <div class="turn-around-time">
            <span class="current-tat clock-container"><mad-icon icon="time-clock"></mad-icon></span>
            <span class="turn-around-time-text current-tat">{{data.turnaround_time}}</span>
          </div>
        </ng-template>
      </div>
	  </div>

	  <mad-spring class="advanced-pricing" direction="vertical" [visible]="visible && data.type && !data.error && data.expanded" [skipInitialTransition]="true" style="margin-right:1em;border-left:solid 1px #eee;background:white;box-shadow: -3px -1px inset">
        <mad-selected-panels-details *ngIf="visible && data.type && !data.error && data.isExpanded || false" [data]="data" [sapId]="session?.sapId"></mad-selected-panels-details>
	  </mad-spring>
    </div>
  </ng-container>
  <div class="placeholder" *ngIf="!session.panels.data[index]" [style.height]="height+'px'">
  </div>
</ng-template>

<mad-popover class="white opaque" #popover position="left-center" >
</mad-popover>

<mad-popover #infoBubble class="opaque white" constrainTo=".account-content"  [auto]="true" position="right-center" >
</mad-popover>

<mad-popover #tatBubble class="opaque white" constrainTo=".account-content"  [auto]="true" position="top-left" >
</mad-popover>

<ng-template #infoBubbleContent let-data="data" let-selected="selected">
  <mad-header style="height:0;min-height:0;padding:0;">
  <mad-link right (click)="infoBubble.hide()" style="z-index:2;position:absolute;right:1.5em;top:2em;">
    <mad-icon class="remove-icon" style="color:#00a7b5;" icon="fa-times" ></mad-icon>
  </mad-link>
  </mad-header>
  <div style="display:flex;flex-direction:column;padding-left:1em;padding-right:1em;padding-top:0;padding-bottom:0" [style.max-height]="getMaxInfoHeight()" [style.width]="getInfoWidth(infoBubble.target)">
    <div class="info-content" style="display:flex;flex-direction:row;flex-wrap:wrap;">
      <div class="info-left-title" madTypography="header-3" style="background:white;width:100%;padding-left:0.5em;z-index:1;position:relative;top:0em;padding-top:0.75em;padding-bottom:0.5em;">
        Component&nbsp;descriptions
        <div madTypography="tertiary" style="color:#a7a7a7">Select a test component</div>
      </div>
      <div class="info-left" style="width:400px;display:flex;flex-direction:column;min-width:300px;flex:300px;max-width:400px;padding-right:3px;overflow-y:auto;overflow-x:hidden;">
        <mad-button-group class="info-tabs scrollable" style="white-space:nowrap;box-shadow:inset -1px 0px 0px 0px #eee;max-width:100%;min-width:100%;z-index:0;overflow:hidden;margin-top:1em;margin-left:0.1em;overflow-y:auto;" type="tab" direction="vertical" [showTabMarker]="true" [ngModel]="selected" (ngModelChange)="infoIndex=$event">
          <mad-button *ngFor="let child of data.children; let i = index" [disabled]="!child.info_description" [attr.data-index]="i" [ngClass]="{'selected' : infoIndex === i}" class="info-tab" type="tab" style="background-color:white;font-size:15px;font-weight:300;">
            {{child.lims_name || child.lims_code}}
          </mad-button>
        </mad-button-group>
      </div>
      <div class="info-right" style="padding-left:1em;min-width:400px;padding-bottom:2em;flex:1;flex-direction:column;justify-content:center;padding:1em;border-left:1px solid #cccfd1;">
        <div madTypography="header-3" style="margin-bottom:1em;font-size:13px;color:#1E1F1F;font-weight:bold;text-align:start;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;">
          {{data.children[infoIndex]?.lims_name || data.children[infoIndex]?.lims_code}}
        </div>
        <div *ngIf="data.children[infoIndex]?.info_description" style="color:#1E1F1F;font-weight:300;font-size:13px;">
          {{data.children[infoIndex]?.info_description}}
        </div>
        <div *ngIf="!data.children[infoIndex]?.info_description" style="font-size:13px;color:#cccfd1">
          No description available
        </div>
      </div>
      <div style="z-index:1;background:white;position:relative;bottom:0em;padding-bottom:1em;padding-top:0.5em;width:100%;padding-left:0.5em;" [style.visibility]="data.info_includes ? 'visible': 'hidden'">
        <div style="font-size:13px;"><strong>Included IDEXX testing (at no additional charge):</strong> {{data.info_includes || ''}}</div>
      </div>
    </div>
  </div>
</ng-template>
