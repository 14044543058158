import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {CheckboxGroupComponent} from './checkbox-group.component';
import {FieldModule} from '../field/field.module';
import {FormsModule} from '@angular/forms';
import {CheckboxModule} from '../checkbox/checkbox.module';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     CheckboxModule,
     FieldModule,
     FormsModule
  ],
  declarations: [
      CheckboxGroupComponent
  ],
  exports: [
      CheckboxGroupComponent
  ],
  bootstrap: [CheckboxGroupComponent]
})
export class CheckboxGroupModule { }
