<mad-field>
    <ng-content select="mad-micro" ngProjectAs="mad-micro"></ng-content>
    <ng-content select="mad-error" ngProjectAs="mad-error"></ng-content>

    <fieldset class="spot-form__field-group spot-form__radio-group">
    <div class="spot-form__radio-group-inner" [class.horizontal]="direction === 'horizontal'">
        <legend class="spot-form__radio-group-label">
            <ng-content select="mad-label" ngProjectAs="mad-label"></ng-content>
            <ng-container *ngIf="isTemplate()">
                <ng-container *ngTemplateOutlet="innerLabel"></ng-container>
            </ng-container>
            <ng-container *ngIf="!isTemplate()">
                {{getInnerLabel()}}
            </ng-container>
        </legend>
        <ng-content></ng-content>
        <mad-radio *ngFor="let option of options" [value]="option.value" [group]="id">
            {{option.text}}
        </mad-radio>
    </div>
    </fieldset>
</mad-field>
