import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {ModalModule} from './../modal/modal.module';
import {HeaderModule} from './../header/header.module';
import {FooterModule} from './../footer/footer.module';
import {AlertComponent} from './alert.component'
import {ButtonModule} from './../button/button.module';
import {BodyModule} from '../body/body.module';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    ModalModule,
    ButtonModule,
    BodyModule,
    HeaderModule,
    FooterModule
  ],
  declarations: [
    AlertComponent
  ],
  exports: [
    AlertComponent
  ],
  bootstrap: [AlertComponent]
})
export class AlertModule { }
