import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { IAccount, IAccountRecent } from '@shared/model/account';
import type { AccountAPI } from '@services/lib/account/account.api';
import { Service } from '../service/service';
import { UtilService } from '../../services/util/util.service';
import { MadClientError, MadErrorUtils } from '../../utils/client-error';

@Injectable()
export class AccountService extends Service<typeof AccountAPI> {
    async open(sap: string): Promise<IAccount> {
        try {
            const resp = await firstValueFrom(super.get('/account', { params: { sap, add_recent: true } }));
            if (!resp.success) {
                console.error('account.open failed: ', resp.error);
                throw MadErrorUtils.parseError(resp.error);
            }
            return resp.data;
        } catch (err) {
            if (err.code) {
                throw err;
            }
            console.error('Error calling account.open: ', err);
            throw new MadClientError('SYS_ERROR', 'Unhandled error thrown in account.open', err);
        }
    }

    async recent(): Promise<IAccountRecent[]> {
        try {
            const resp = await firstValueFrom(super.get('/account/recent'));
            if (!resp.success) {
                console.error('account.recent failed: ', resp.error);
                throw new Error('account.recent failed: ' + UtilService.getErrorMessage(resp.error));
            }
            return resp.data;
        } catch (err) {
            console.error('Error calling account.recent: ', err);
            throw err;
        }
    }
}
