import { Component, OnInit, OnChanges, DoCheck, Input, KeyValueChanges, KeyValueDiffer, KeyValueDiffers } from '@angular/core';

import { Message, LEVEL } from '@shared/model/message';

interface IMessageInfo {
    icon?: string;
    priority?: string;
}

const MESSAGE_INFO: Record<string, IMessageInfo> = {
    ERROR: {
        icon: 'alert-notification',
        priority: 'danger'
    },
    INFO: {
        icon: 'info-2',
        priority: 'default'
    },
    SUCCESS: {
        icon: 'checkmark',
        priority: 'success'
    }
}

const msgClassTpl = 'spot-message priority-medium--PRIORITY dismissible';

@Component({
    selector: 'mad-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnChanges, DoCheck {
    @Input() message: Message;

    iconHref?: string;

    msgClass?: string = 'spot-message priority-medium--default dismissible';

    private msgDiffer: KeyValueDiffer<string, any>;

    constructor(
        private differs: KeyValueDiffers
    ) { }

    ngOnInit(): void {
        this.parseMessage();

        // Need to listen for properties of message object changing as well as message object itself.
        this.msgDiffer = this.differs.find(this.message).create();
    }

    ngOnChanges(): void {
        this.parseMessage();

        // Need to listen for properties of message object changing as well as message object itself.
        this.msgDiffer = this.differs.find(this.message).create();
    }

    messageChanged(changes: KeyValueChanges<string, any>): void {
        this.parseMessage();
    }

    ngDoCheck(): void {
        const changes = this.msgDiffer.diff(this.message);
        if (changes) {
            this.messageChanged(changes);
        }
    }

    parseMessage(): void {
        if (this.message) {
            const level: LEVEL = this.message.level;
            let info: IMessageInfo;
            if (level === LEVEL.INFO) {
                info = MESSAGE_INFO.INFO;
            } else if (level === LEVEL.SUCCESS) {
                info = MESSAGE_INFO.SUCCESS;
            } else if (level === LEVEL.ERROR) {
                info = MESSAGE_INFO.ERROR;
            } else {
                console.warn("Message level not set!", this.message);
                info = MESSAGE_INFO.INFO;
            }
            this.msgClass = msgClassTpl.replace('PRIORITY', info.priority);
            this.iconHref = '/assets/spot/dist/icons/spot_icons.svg#' + info.icon;
        }
    }

}
