import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FieldComponent} from './field.component';
import {LabelComponent} from './label.component';
import {MicroComponent} from './micro.component';
import {ErrorComponent} from './error.component';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule
  ],
  declarations: [
    FieldComponent,
    LabelComponent,
    MicroComponent,
    ErrorComponent
  ],
  exports: [
    FieldComponent,
    LabelComponent,
    MicroComponent,
    ErrorComponent
  ],
  bootstrap: []
})
export class FieldModule { }
