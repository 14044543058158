import {Component, Input, HostBinding, ElementRef, AfterContentChecked} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'mad-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements AfterContentChecked {
    @HostBinding('class') class;
    @Input() type: 'pet-na' | 'pet-none' | 'pet-image' | 'dashed' | 'natural' | 'none' = 'none';
    @Input() background: 'transparent' | 'red' | 'teal' | 'gray' | 'darken' | 'lighten' = 'darken';
    @Input() camera: boolean;
    @Input() size: 'xxs' | 'xs' | 'small' | 'medium' | 'large' = 'xs';
    @Input() image: string = '';

    constructor (public sanitizer: DomSanitizer, public element: ElementRef) {
        this.ngOnChanges();
    }

    getClass() {
        return `mad-avatar
        mad-avatar-size-${this.size}
        mad-avatar-border-${this.type}
        mad-avatar-background-${this.background || 'transparent'}`;
    }

    ngOnChanges () {
        this.class = this.getClass();
    }

    ngAfterContentChecked () {
        this.element.nativeElement.style.minWidth = null;
        this.element.nativeElement.style.minHeight = null;
        var width = this.element.nativeElement.offsetWidth,
            height = this.element.nativeElement.offsetHeight;
        width = height = Math.max(height, width);
        this.element.nativeElement.style.minWidth = `${width}px`;
        this.element.nativeElement.style.minHeight = `${height}px`;
    }

    getImageUrl () {
        if (this.image) {
            return this.sanitizer.bypassSecurityTrustStyle(`url("${this.image}")`);
        } 
        return '';
    }

}
