import {Component,  Input, HostBinding, OnChanges, OnInit, ElementRef} from '@angular/core';

@Component({
    selector: 'mad-body',
    templateUrl: './body.component.html',
    styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnChanges, OnInit {
    @Input() type: 'navigation' | 'card' | 'modal' | 'toast' | 'auto' = 'auto';
    @HostBinding('class') class: string;

    constructor (public element: ElementRef) {}
    ngOnInit () {
        this.ngOnChanges();
    }

    ngOnChanges () {
        this.class = this.getClass();
    }

    getClass () {
        return `mad-body mad-body-${this.type}`;
    }
}
