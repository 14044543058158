import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { ComponentsModule } from '../../components/components.module';
import { SelectedPanelsComponent } from './selected-panels.component';
import { SelectedPanelsDetailsModule } from '../selected-panels-details/selected-panels-details.module';
import { AuthState } from '../../states/auth/auth.state';
import { AccountState } from '../../states/account/account.state';
import { UserService } from '../../services/user/user.service';
import { AccountService } from '../../services/account/account.service';
import { SessionService } from '../../services/session/session.service';

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        TranslocoModule,
        SelectedPanelsDetailsModule
    ],
    providers: [
        AuthState,
        UserService,
        AccountState,
        AccountService,
        SessionService
    ],
    declarations: [
        SelectedPanelsComponent
    ],
    exports: [
        SelectedPanelsComponent
    ],
    bootstrap: [SelectedPanelsComponent]
})
export class SelectedPanelsModule { }
