import { IMadError, MadError } from './mad-error';

export class MadErrorUtils {
    public static parseError<T>(err: string | Error | IMadError<T>): IMadError<T> {
        if (typeof err === 'string') {
            try {
                const jsonObj = JSON.parse(err);
                if (jsonObj.code && jsonObj.name) {
                    // A MadError that's been converted to string.  Convert it back and return.
                    const madError = new MadError(jsonObj.code, jsonObj.message, null, jsonObj.context, jsonObj.isSystemError);
                    madError.name = jsonObj.name;
                    return madError;
                }
                // Some other valid json that's not a MadError.  Wrap it and return.
                return new MadError<T>('SYS_ERROR', 'SYS_ERROR', jsonObj as Error);
            } catch (parseErr) {
                // Just a string error message.  Wrap it and return.
                return new MadError<T>(err);
            }
        }

        if (err instanceof MadError) {
            // Don't wrap existing MadError (or child types)
            return err;
        }

        const msg = err.message || 'Unknown System Error';
        if (err instanceof Error) {
            return new MadError<T>(err.message, msg, <Error>err);
        }

        const errorCode = err.code || 'SYS_ERROR_UNKNOWN';

        // Treat as IMadError
        return new MadError<T>(errorCode, msg, err.sourceError, err.context);
    }

    public static getErrorCode(err: string | Error | IMadError): string | null {
        if (!err) {
            return null;
        }

        if (typeof err === 'string') {
            return err;
        }

        if (err instanceof MadError) {
            // Don't wrap existing MadError (or child types)
            return err.code || err.message;
        }

        if (err instanceof Error) {
            return err.message;
        }

        // Treat as IMadError
        return err.code || err.message || null;
    }

    /**
     * Return abbreviated string from various error-type messages.
     */
    public static getErrorMessage(err: string | Error | IMadError | null): string | null {
        if (!err) {
            return null;
        }

        if (typeof err === 'string') {
            return err;
        }

        const msgParts: string[] = [];

        // if (err.name) {
        //     msgParts.push(err.name);
        // }

        if ('code' in err) {
            msgParts.push(err.code);
        }

        if (err.message) {
            msgParts.push(err.message);
        }

        return msgParts.join(' : ');
    }

    /**
     * Returns an predictable error object containing pieces of the error message parsed out into a common config.
     */
    public static getError(err: string | Error | IMadError | null): IMadError | null {
        console.log('getError: ', typeof err, err);
        if (!err) {
            return null;
        }

        if (typeof err === 'string') {
            return this.parseError(err);
        }
        return err;
    }
}
