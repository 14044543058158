import { Component, Input } from '@angular/core';
import { DigitalLink } from './digital-link';

@Component({
    selector: 'mad-digital-identity',
    templateUrl: './digital-identity.component.html',
    styleUrls: ['./digital-identity.component.scss']
}) export class DigitalIdentityComponent {
    @Input() profile: DigitalLink = null;
    @Input() location: DigitalLink = null;
    @Input() locations: DigitalLink[] = [];

    isLinkVisible(link: DigitalLink) {
        return link && !(<any>link).hidden;
    }

    isLinkSelected(link: DigitalLink) {
        return link && (<any>link).selected || false;
    }

    isLinkDisabled(link: DigitalLink) {
        return link && (<any>link).disabled;
    }

    getLinkHref(link: DigitalLink) {
        return link && (<any>link).href || 'javascript:void(0)';
    }

    getLinkText(link: DigitalLink) {
        //console.error(link);
        return link && (<any>link).text || link;
    }
}
