<div class="mad-sap">
  <div style="position:relative;display:inline-block;width:1px;height:100%;vertical-align:middle"></div>
  <div style="vertical-align:middle;background-color:rgba(255,255,255,.78) !important;background:transparent !important;margin-bottom:10px;padding-left:1em;padding-bottom:1em;position:sticky;padding-right:1em;padding-top:1em;display:flex;flex-direction:column;max-width:50%;width:75%;min-width:500px;display:inline-flex">
    <div style="display:flex;">
      <mad-input #searchInput [loading]="isLoading" placeholder="Enter Account ID" class="mad-sap-search-input" style="font-size:50px;font-weight:300;min-height:100px;flex:1;border-radius:0;margin-bottom:0;background:white;" [(ngModel)]="searchValue" (ngModelChange)="searchValueChange($event)" >
        <mad-link right type="secondary" class="continue"  [disabled]="!searchValue" (click)="openPractice({sap:searchValue})">
          <mad-icon icon="fa-arrow-right" altIcon="" [showAlt]="!searchValue" style="color:black;"></mad-icon>
        </mad-link>
      </mad-input>
    </div>
    <div id="search-error" *ngIf="errorMsg" style="margin: 1em 0;padding:.5em;line-height:inherit;" [innerHTML]="errorMsg"></div>
    <div #search class="mad-sap-search" >
      <div class="mad-sap-spring-wrapper" style="margin-left:0.5em;">
        <span class="mad-sap-recent-spring-wrapper" >
          <mad-spring *ngFor="let item of recentAccounts" visible="true" style="background:rgba(255,255,255,.7);margin:0">
            <div class="mad-sap-recent-spring" style="background:rgba(255,255,255,.0);">
              <mad-link (click)="openPractice(item)">
                <div class="mad-sap-recent-spring-inner">
                  <h1 madTypography="header-3">{{item.sap}}</h1>
                  <div class="mad-sap-recent-spring-name" madTypography="secondary">{{item.name}}</div>
                </div>
              </mad-link>
            </div>
          </mad-spring>
        </span>
      </div>
    </div>
  </div>
</div>
