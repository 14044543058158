import { Component, Input, OnInit, OnChanges, OnDestroy, HostBinding, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ILIMSReference } from '@shared/model/reference';
import { AccountState } from '../../states/account/account.state';
import { UserState } from '../../states/user/user.state';
import { InfiniteListComponent } from '../../components/infinite-list/infinite-list.component';
import { SessionState } from '../../states/session/session.state';
import { ISelectedPanel } from '../../states/session/session-panels';
import { InfinitePager } from '../../components/infinite-list/infinite-pager';
import { ReferenceService } from '../../services/reference/reference.service';

@Component({
    selector: 'mad-selected-panels',
    templateUrl: 'selected-panels.component.html',
    styleUrls: ['selected-panels.component.scss']
})
export class SelectedPanelsComponent implements OnInit, OnChanges, OnDestroy {
    @HostBinding('class') class;

    @ViewChild('infiniteList')
    infiniteList: InfiniteListComponent;

    @Output() open = new EventEmitter<any>();

    @Output() continue = new EventEmitter<any>();

    @Input() configure: ISelectedPanel;

    @Output() configureChange = new EventEmitter<this['configure']>();

    @Input() session: SessionState;

    pager = new InfinitePager({
        total: 0,
        nocache: true,
        totalBufferInterval: 0,
        scrollBufferInterval: 0,
        getData: async (start, limit) => {
            if (this.session && this.session.panels.data.length) {
                return this.session.panels.data.slice(start, start + limit);
            }
        }
    });

    isTransitioning: boolean;

    isVisible: boolean;

    onResize: (e: Event) => any;

    isResizing: boolean;

    isInitializing: boolean;

    _change_sub: Subscription;

    deletingPanels: number[] = [];

    private referenceLimsMap: { [key: string]: ILIMSReference };

    @Input() set visible(visible: boolean) {
        if (visible !== this.isVisible) {
            this.isVisible = visible;
            this.isTransitioning = true;
        }
    }

    public get visible(): boolean {
        return this.isVisible;
    }

    constructor(
        public userState: UserState,
        public accountState: AccountState,
        public element: ElementRef,
        public referenceService: ReferenceService
    ) {
    }

    public async ngOnInit(): Promise<void> {
        window.addEventListener('resize', this.onResize = () => {
            this.isResizing = true;
            this.ngOnChanges(null);
            if (this.infiniteList) {
                this.infiniteList.reset(true);
            }
            this.isResizing = false;
        });
        if (this.visible) {
            this.isInitializing = true;
        }
        requestAnimationFrame(() => {
            this.isInitializing = false;
            this.ngOnChanges(null);
        });

        const refResp = await this.referenceService.reference();
        if (refResp && refResp.limsMap) {
            this.referenceLimsMap = refResp.limsMap;
        }

        this.ngOnChanges(null);
    }

    private assignReferenceInfo(reference: { [key: string]: ILIMSReference }): void {
        if (!reference) {
            return;
        }
        for (const item of this.session.panels.data) {
            if (!item.children) {
                continue;
            }
            for (const child of item.children) {
                const limsCode = child.lims_code;
                const ref = reference[limsCode];

                if (reference[limsCode]) {
                    child.info_description = ref.description;
                    if (ref.includes) {
                        if (!item.info_includes) {
                            item.info_includes = '';
                        } else {
                            item.info_includes += ', ';
                        }
                        item.info_includes += ref.includes.trim();
                    }
                    item.contains_info = true;
                }
            }

        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            if (changes.session && changes.session.currentValue !== changes.session.previousValue) {
                if (this._change_sub) {
                    this._change_sub.unsubscribe();
                }
                if (this.session) {
                    this._change_sub = this.session.changes.subscribe(this.onSessionChanges.bind(this));
                }
            }

            if (changes.configure) {
                //handle
            }
        }

        this.assignReferenceInfo(this.referenceLimsMap);

        this.class = this.getClass();
        this.updateInnerSpring();
    }

    _inner_spring_style = '';
    updateInnerSpring() {
        const obj = this.getInnerSpringStyle(null);
        this._inner_spring_style = `width:${obj.width};min-width:${obj['min-width']};max-width:${obj['max-width']}`
    }

    onSessionChanges() {
        if (this.pager) {
            this.pager.setTotal(this.session.panels.data.length);
        }
    }

    ngOnDestroy() {
        window.removeEventListener('resize', this.onResize);
    }

    getClass() {
        return `${this.visible && 'visible' || 'hidden'} ${this.isResizing && 'resizing' || ''} ${this.isInitializing && 'initalizing' || ''}`;
    }

    getNonMatchWidth(limsId: HTMLElement): number {
        const width = document.body.offsetWidth;
        const estWidth = width < 900 ? 24 : (Math.max(Math.min(width * 0.21, 400), 285) + 24);
        const rowWidth = width - estWidth;
        const limsWidth = Math.max(rowWidth * 0.1, 75);
        return rowWidth - 283 - limsWidth;
    }

    getInnerSpringStyle(limsId: HTMLElement): any {
        const targetWidth = this.getNonMatchWidth(limsId);
        return { width: `${targetWidth}px`, 'max-width': `${targetWidth}px`, 'min-width': `${targetWidth}px` };
    }

    getSessionRows = (startIndex: number, endIndex: number) => {
        if (!this.session || !this.session.panels) {
            return null;
        }
        return this.session.panels.data.slice(startIndex, endIndex + 1);
    }

    async onRemoveClick(item: ISelectedPanel) {
        this.deletingPanels.push(item.id);
        await this.session.panels.remove(item);
        this.deletingPanels = this.deletingPanels.filter((pid: number) => {
            return pid !== item.id;
        });
    }

    getRowStyle(item: ISelectedPanel, elTarget: HTMLElement): void {
        if (!item) {
            return;
        }
        if (!item.adding && !item.added) {
            item.adding = true;
            setTimeout(() => {
                item.added = true;
                item.adding = false;
            }, 1000);
        }
        if ((item.inactive && !item.removing)) {
            item.removing = true;
            elTarget.style.height = elTarget.offsetHeight + 'px';
            setTimeout(() => {
                elTarget.style.height = '0px';
                setTimeout(() => {
                    item.removing = false;
                    this.session.panels.removeInactivePanel({ id: item.id });
                    elTarget.style.height = null;
                }, 500)
            });
        }
    }

    trackMatch(a: number): number {
        return a;
    }

    onOpenClick(): void {
        this.open.emit();
    }

    onConfigureClick(item: ISelectedPanel): void {
        this.configureChange.emit(item);
    }

    scrollToEnd(): void {
        this.infiniteList.scrollToEnd();
    }

    copyToClipboard(data: string, stringify = false): void {
        navigator.clipboard.writeText(stringify ? JSON.stringify(data, null, 2) : data);
    }

    retry(data: ISelectedPanel): void {
        if (this.session) {
            this.session.panels.price(data);
        }
    }

    toggleDetails(item: ISelectedPanel): void {
        item.expanded = !item.expanded;
        if (item.expanded) {
            item.isExpanded = item.expanded;
        } else {
            setTimeout(() => {
                item.isExpanded = item.expanded;
            }, 0)
        }
        if (!item.expanded) {
            item.isDetailsExpanded = false;
        }
    }

    onContinueClick(): void {
        this.continue.emit(true);
    }

    getMaxInfoHeight(): string {
        return Math.min(this.infiniteList.element.nativeElement.offsetHeight - 60, 500) + 'px';
    }

    isInfoFullScreen(infoTarget: any): boolean {
        if (!infoTarget) {
            return false;
        }
        let space = document.body.offsetWidth;
        infoTarget = infoTarget.element || infoTarget;
        infoTarget = infoTarget.nativeElement || infoTarget;

        if (infoTarget.getBoundingClientRect) {
            space -= infoTarget.getBoundingClientRect().right;
        }
        if (space < 1024) {
            return true;
        }
        return false;
    }

    getInfoWidth(infoTarget: any): string {
        if (infoTarget) {
            if (infoTarget.getBoundingClientRect) {
                const width = document.body.offsetWidth;
                return (width - 60) + 'px';
            }
            return '1024px';
        }
        return '';
    }
}
