import {NumberFormatter} from './number.formatter';

//TODO make this configurable when we need it later...
var PERCENT = {
    suffix: '%',
    thousands_sep: ',',
    decimal_sep: '.',
    min_decimal_places: 0,
    max_decimal_places: 0,
    allow_negative: true
}

export class PercentFormatter extends NumberFormatter {
    constructor (config: ConstructorParameters<typeof NumberFormatter>[0]) {
        super(Object.assign({}, PERCENT, config || {}))
    }
}
