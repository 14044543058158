import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentsModule} from './../../components/components.module';
import {BrowserModule} from '@angular/platform-browser';
import {SessionComponent} from './session.component';
import {SubmitProcessModule} from './../submit-process/submit-process.module';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     FormsModule,
     ComponentsModule,
     SubmitProcessModule
  ],
  providers: [
  ],
  declarations: [
      SessionComponent
  ],
  exports: [
      SessionComponent
  ],
  bootstrap: [
      SessionComponent
  ]
})
export class SessionModule { }
