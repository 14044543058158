import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, Renderer2, ViewChild} from "@angular/core";
import {ControlValueAccessor} from "@angular/forms";
import {Field} from "../field/field.component";
import {DomUtil} from "../../utils/dom";


@Component({
    selector: "mad-textarea",
    templateUrl: "./textarea.component.html",
    styleUrls: ["./textarea.component.scss"],
    providers: Field.Providers(TextAreaComponent)
})
export class TextAreaComponent extends Field implements ControlValueAccessor {

    @ViewChild("textarea") textarea: ElementRef;
    @ViewChild("sizer") sizer: ElementRef;

    _sizer_value: string;
    @Input()
    icon: string = "";
    @Input()
    placeholder = "";
    @Input()
    loading: boolean = false;
    @Input()
    grow: boolean = false;

    @Output()
    defaultAction = new EventEmitter();

    _writing: boolean;
    _value: any;

    set value(value) {
        if (value !== this._value) {
            this._value = value;
            if (!this._writing) {
                if (this._onChange) {
                    this._onChange(this.value);
                }
                if (this._onTouched) {
                    this._onTouched();
                }
            }
        }
    }

    get value() {
        return this._value;
    }

    constructor(public element: ElementRef) {
        super(element);
    }

    onFocus($event: Event) {
        DomUtil.selectContents(this.textarea.nativeElement);
    }

    writeValue(value: any): void {
        this._writing = true;
        this.value = value;
        this._writing = false;
    }

    blurInput(): void {
        this.textarea.nativeElement.blur();
    }

    focusInput(): void {
        this.textarea.nativeElement.focus();
    }

    setLoading(state: boolean) {
        this.loading = state;
    }

    onKeyDown($event: Event) {
    }

    onNgModelChange($event: string) {
        if (this.grow && this.sizer) {
            $event = $event.replace(/\n/g, '\r\n');
            if ($event[$event.length-1] === '\n') {
                $event += ' ';
            }
            this._sizer_value = $event;
        }
    }

    onInput($event: Event) {
    }

    reset() {
        this._value = null;
    }

}
