<mad-field >
    <button #button [disabled]="disabled" [class]="buttonClass" madRipple>
        <span #leftIconWrapper>
            <ng-content select="[left],mad-icon:not([right]),mad-avatar:not([right])"></ng-content>
        </span>
        <span #contentWrapper class="mad-button-content-wrapper">
            <ng-content></ng-content>
        </span>
        <span #rightIconWrapper>
            <ng-content select="[right]"></ng-content>
            <mad-icon *ngIf="type === 'cancel-icon'" icon="fa-times"></mad-icon>
        </span>
        <div *ngIf="_selected && type !== 'tab'" class="selected-overlay"></div>
    </button>
</mad-field>
