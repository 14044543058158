<div style="display:flex;flex:1;flex-direction:column;align-items:center;overflow:hidden;width:100%;">
<mad-header teal madTypography="header-3" class="feedback-header" >
  Feedback
</mad-header>

<div style="display:flex;flex-direction:row;flex:1;width:100%;overflow:hidden">
<mad-infinite-list #infiniteList class="scrollable" [pager]="pager" [template]="feedbackTemplate" [assumedHeight]="100" style="width:500px">
</mad-infinite-list>

<ng-template #feedbackTemplate let-index="index" let-height="height">
  <div *ngIf="!pager.results[index]?.data" style="min-height:100px;max-height:100px;overflow-hidden;border-bottom:solid 1px #eee;width: 100%;display:flex;align-items:center;text-decoration:none;"></div>
  <a *ngIf="pager.results[index]?.data; let data" style="min-height:100px;max-height:100px;overflow-hidden;border-bottom:solid 1px #eee;width: 100%;display:flex;align-items:center;text-decoration:none;" [style.background]="selectedFeedback?.id === data?.id ? '#DDECF3' : null"  tabindex="0" href="javascript:void(0);" (click)="onFeedbackClick(data)">
  <div class="feedback-body" style="flex:1">
    <div style="display:flex;align-items:center;flex-direction:row;text-align:center;flex:1;font-size:13px;">
      <div class="emojis" style="transform:scale(0.75);">
        <div class="emoji" *ngIf="data.rating === EMOJI.ANGRY" style="fill:#48BBE6;opacity:0.7">
          <ng-container *ngTemplateOutlet="angrySVG"></ng-container>
        </div>
        <div class="emoji" *ngIf="data.rating === EMOJI.UNHAPPY" style="fill:#48BBE6;opacity:0.7">
          <ng-container *ngTemplateOutlet="unhappySVG"></ng-container>
        </div>
        <div class="emoji" *ngIf="data.rating === EMOJI.NEUTRAL" style="fill:#48BBE6;opacity:0.7">
          <ng-container *ngTemplateOutlet="neutralSVG"></ng-container>
        </div>
        <div class="emoji" *ngIf="data.rating === EMOJI.SMILE" style="fill:#48BBE6;opacity:0.7">
          <ng-container *ngTemplateOutlet="smileSVG"></ng-container>
        </div>
        <div class="emoji" *ngIf="data.rating === EMOJI.HAPPY" style="fill:#48BBE6;opacity:0.7">
          <ng-container *ngTemplateOutlet="happySVG"></ng-container>
        </div>
        <div class="emoji" *ngIf="!data.rating" style="opacity:0.15;fill:#67a2b1;">
          <ng-container *ngTemplateOutlet="emptySVG"></ng-container>
        </div>
      </div>
      <div style="display:flex;flex-direction:column;text-align:start;margin-left:1em;justify-content:center;">
        <div *ngIf="data.account_name" style="position:relative;display:flex;align-items:center;top:-2em;font-size:10px;">
          <div style="max-width:200px;overflow:hidden;position:relative;text-overflow:ellipsis;white-space:nowrap">
            {{data.account_name}}
          </div>
          <div style="margin-left:0.5em;position:relative;">
            ({{data.sap}})
          </div>
        </div>
        <div>{{data.updated_on | date: 'short'}}</div>
        <div>{{data.updated_by}}</div>
      </div>
      <div style="flex:1;"></div>
      <div style="font-size:20px;width:1.5em;height:1.5em;border-radius:50%;display:flex;align-items:center;justify-content:center;margin-right:1em;" [style.visibility]="!data.feedback ? 'hidden' : null">
      <mad-icon icon="fa-clipboard" style="justify-content:center;color:#ccd"></mad-icon>
      </div>
      <div style="font-size:20px;width:1.5em;height:1.5em;border-radius:50%;display:flex;align-items:center;justify-content:center;margin-right:1em;" [style.visibility]="!data.files?.length ? 'hidden': null">
      <mad-icon icon="fa-paperclip" style="justify-content:center;color:#ccd" ></mad-icon>
      </div>
    </div>
  </div>
  </a>
</ng-template>

<mad-body class="feedback-body scrollable" style="overflow:auto;justify-self:center;padding:2em !important;padding-top:0!important;padding-left:10em !important;border-left:solid 1px #eee;flex-direction:row;display:flex;" [style.width]="getFeedbackWidth()">
  <ng-container *ngIf="!selectedFeedback">
    <div madTypography="header-3" style="text-align:center;flex:1;left:-17em;top:4em;position:relative;">
      Feedback not found
    </div>
  </ng-container>
  <ng-container *ngIf="selectedFeedback">
  <div style="display:flex;flex:1;flex-direction:column">
    <div madTypography="header-3" class="section-header feedback" style="font-size:50px;align-items:center;display:flex;margin-top:1em;">
      Feedback
    </div>
    <div class="section-inner" style="margin-top:2em;">
      {{selectedFeedback?.feedback}}
    </div>

    <div class="section" style="align-items:start;">
      <div madTypography="header-3" class="section-header" style="margin-top:2em;">
        Submitted by
      </div>
      <div class="section-inner">
        {{selectedFeedback?.updated_by}} on {{selectedFeedback?.updated_on | date:'medium'}}
      </div>
    </div>
    <div madTypography="header-3" class="section-header rating">
      Rating
    </div>
    <div class="emojis">
      <div #angry class="emoji" [class.selected]="selectedFeedback?.rating === EMOJI.ANGRY">
        <ng-container *ngTemplateOutlet="angrySVG"></ng-container>
      </div>

      <div #unhappy class="emoji" [class.selected]="selectedFeedback?.rating === EMOJI.UNHAPPY" >
        <ng-container *ngTemplateOutlet="unhappySVG"></ng-container>
      </div>

      <div #neutral class="emoji" [class.selected]="selectedFeedback?.rating === EMOJI.NEUTRAL" >
        <ng-container *ngTemplateOutlet="neutralSVG"></ng-container>
      </div>

      <div #smile class="emoji" [class.selected]="selectedFeedback?.rating === EMOJI.SMILE" >
        <ng-container *ngTemplateOutlet="smileSVG"></ng-container>
      </div>

      <div #happy class="emoji" [class.selected]="selectedFeedback?.rating === EMOJI.HAPPY">
        <ng-container *ngTemplateOutlet="happySVG"></ng-container>
      </div>
    </div>

    <div class="send-wrapper">
      <!--<mad-button class="send-button" (click)="onSendClick()">Send feedback</mad-button>-->
    </div>
  </div>

  <div  class="section" style="margin-left:10em;width:25em;">
    <mad-spring direction="vertical" [visible]="selectedFeedback?.session" class="files-spring">
      <div madTypography="header-3" class="section-header files">Session #{{selectedFeedback.session}}</div>
      <div style="text-align:start;padding-bottom:2em;">
      <mad-link (click)="viewSession(selectedFeedback)" style="margin-left:0.1em;margin-bottom:0.5em;">
        <div style="display:flex;max-width:25em;">
          <div style="flex:1;overflow:hidden;position:relative;text-overflow:ellipsis;white-space:nowrap">
            {{selectedFeedback?.account_name}}
          </div>
          <div style="margin-left:0.5em;position:relative;">
            ({{selectedFeedback?.sap}})
          </div>
        </div>
      </mad-link>
      </div>
    </mad-spring>
    <mad-spring direction="vertical" class="files-spring" #fileSpring [visible]="selectedFeedback?.files?.length" >
      <div madTypography="header-3" class="section-header files">Files</div>
      <div class="files" *ngIf="selectedFeedback?.files?.length" style="width:350px;">
        <div *ngFor="let file of selectedFeedback?.files" class="file" >
          <div class="icon-wrapper">
            <mad-icon *ngIf="file?.type.startsWith('image')" class="icon" icon="file-image"></mad-icon>
            <mad-icon *ngIf="file?.type === 'application/pdf'" class="icon" icon="file-pdf"></mad-icon>
            <mad-icon *ngIf="!file?.type.startsWith('image') && file?.type !== 'application/pdf'" class="icon" icon="file-zip"></mad-icon>
            <div *ngIf="file.infected || file.pending || file.infected === null"  style="display:flex;margin-left:3em;flex-direction:row;align-items:center;">
              <ng-template #downloadConfirmContent>
                <div style="display:flex;flex-direction:column;position:relative;max-width:300px;font-size:12px;padding:1em;align-items:center;color:#1e1e1f">
                  <div *ngIf="file.infected">
                    This file was identified as potentially malicious.  Are you sure you want to download?
                  </div>
                  <div *ngIf="!file.infected">
                    This file is either still scanning or unable to be scanned.  Are you sure you want to download?
                  </div>
                  <div style="display:block;width:100%;margin-top:1em;text-align:end">
                  <mad-button type="secondary" (click)="onDownloadClick(file)">Yes</mad-button>
                  <mad-button type="primary" (click)="popover.hide()">No</mad-button>
                  </div>
                </div>
              </ng-template>
              <mad-icon *ngIf="file.infected" icon="fa-exclamation-triangle" style="color:red;margin-left:2em;;margin-right:0.5em;"></mad-icon>
              <mad-icon *ngIf="!file.infected" icon="fa-exclamation-circle" style="color:red;margin-left:2em;;margin-right:0.5em;"></mad-icon>
              <div style="font-size:11px;display:inline;flex-wrap:wrap;">
                <ng-container *ngIf="file.infected">
                  Potential virus or malware found.
                </ng-container>
                <ng-container *ngIf="!file.infected">
                  File scan in progress or incomplete.
                </ng-container>
                <div>
                <mad-link class="remove-link" style="display:inline-block;vertical-align:middle;height:auto;margin:0;margin-right:0.25em;margin-top:-0.3em;font-size:inherit;width:auto"
                  [madPopover]="{popover: popover, hideOnBlur: true, showOnFocus: true, content: downloadConfirmContent}"
                  >
                  Download
                </mad-link>
                <ng-container *ngIf="file.infected">
                  at your own risk
                </ng-container>
                </div>
              </div>
            </div>
            <div *ngIf="!file.pending && file.infected === false"  style="display:flex;flex-direction:row;width:200px;align-items:center;margin-left:4.5em;">
              <mad-link class="remove-link" (click)="onDownloadClick(file)" style="margin-left:2em;">
                Download
              </mad-link>
            </div>
          </div>
          <div class="name" style="width:auto;max-width:100px">
            {{file.name}}
          </div>
        </div>
      </div>
    </mad-spring>
  </div>
  </ng-container>
</mad-body>
</div>

<mad-popover #popover class="white opaque">
</mad-popover>
</div>
<ng-template #emptySVG>
<svg 
  width="17.331758mm"
  height="17.822168mm"
  viewBox="0 0 17.331758 17.822168"
  version="1.1" 
  id="svg20"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:svg="http://www.w3.org/2000/svg">
  >
  <defs id="defs17"/>
  <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1" transform="translate(-27.699799,-29.508162)" style="display:none" sodipodi:insensitive="true">
    <path id="path13505-3-8" style="fill:#67a2b1;fill-opacity:1;stroke-width:0.241922" d="m 36.365938,29.508162 a 8.6658944,8.9111557 0 0 0 -8.666139,8.911084 8.6658944,8.9111557 0 0 0 8.666139,8.911085 8.6658944,8.9111557 0 0 0 8.66562,-8.911085 8.6658944,8.9111557 0 0 0 -8.66562,-8.911084 z m -3.259233,6.352584 a 1.1907216,1.1907216 0 0 1 1.191141,1.190625 1.1907216,1.1907216 0 0 1 -1.191141,1.190625 1.1907216,1.1907216 0 0 1 -1.190625,-1.190625 1.1907216,1.1907216 0 0 1 1.190625,-1.190625 z m 6.525699,0.02997 a 1.1907216,1.1907216 0 0 1 1.190625,1.190625 1.1907216,1.1907216 0 0 1 -1.190625,1.190625 1.1907216,1.1907216 0 0 1 -1.191141,-1.190625 1.1907216,1.1907216 0 0 1 1.191141,-1.190625 z m -0.247012,4.527889 c 0,0 -0.440897,2.842133 -3.104719,2.891814 -2.600021,0.0485 -3.274219,-2.807063 -3.274219,-2.807063 2.451455,-0.0787 3.926547,-0.04975 6.378938,-0.08475 z"/>
  </g>
  <g inkscape:groupmode="layer" id="layer2" inkscape:label="Layer 2" style="display:inline;opacity:1">
    <ellipse style="fill:inherit;fill-opacity:1;fill-rule:evenodd;stroke-width:0.282434" id="path49" cx="8.6903067" cy="8.9007931" rx="8.6903057" ry="8.9007978"/>
  </g>
</svg>
</ng-template>
<ng-template #angrySVG>
  <svg
    width="17.331758mm"
    height="17.822166mm"
    viewBox="0 0 17.331758 17.822166"
    version="1.1"
    id="svg20"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg">
    <defs
    id="defs17" />
    <g
    inkscape:label="Layer 1"
    inkscape:groupmode="layer"
    id="layer1"
    transform="translate(-23.993915,-31.233246)">
    <path
    id="path13505-3-07"
    style="fill-opacity:1;stroke-width:0.241922"
    d="m 32.660054,31.233246 a 8.6658944,8.9111557 0 0 0 -8.666139,8.911085 8.6658944,8.9111557 0 0 0 8.666139,8.911082 8.6658944,8.9111557 0 0 0 8.66562,-8.911082 8.6658944,8.9111557 0 0 0 -8.66562,-8.911085 z m -3.266466,6.7076 a 1.1907216,1.1907216 0 0 1 1.190625,1.190625 1.1907216,1.1907216 0 0 1 -1.190625,1.190625 1.1907216,1.1907216 0 0 1 -1.190625,-1.190625 1.1907216,1.1907216 0 0 1 1.190625,-1.190625 z m 6.409943,0 a 1.1905,1.1907216 0 0 1 1.190625,1.190625 1.1905,1.1907216 0 0 1 -1.190625,1.190625 1.1905,1.1907216 0 0 1 -1.190625,-1.190625 1.1905,1.1907216 0 0 1 1.190625,-1.190625 z m -3.145543,4.574916 c 2.424768,0.005 3.49436,2.289265 3.49436,2.289265 l -0.783416,0.367419 c 0,0 -0.833652,-1.787615 -2.713011,-1.791105 -1.834322,-0.003 -2.718699,1.718757 -2.718699,1.718757 L 29.16776,44.707359 c 0,0 1.117997,-2.195968 3.490228,-2.191597 z" />
    </g>
  </svg>
</ng-template>
<ng-template #unhappySVG>
  <svg
    #unhappy
    width="17.332273mm"
    height="17.822168mm"
    viewBox="0 0 17.332273 17.822168"
    version="1.1"
    id="svg20"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg">
    <defs
    id="defs17" />
    <g
    inkscape:label="Layer 1"
    inkscape:groupmode="layer"
    id="layer1"
    transform="translate(-53.577152,-32.365625)">
    <path
    id="path13505-3-0"
    style="fill-opacity:1;stroke-width:0.241922"
    d="m 62.24329,32.365625 a 8.6658944,8.9111557 0 0 0 -8.666138,8.911084 8.6658944,8.9111557 0 0 0 8.666138,8.911085 8.6658944,8.9111557 0 0 0 8.666136,-8.911085 8.6658944,8.9111557 0 0 0 -8.666136,-8.911084 z m -3.35535,6.622854 a 1.1907216,1.1907216 0 0 1 1.190625,1.191141 1.1907216,1.1907216 0 0 1 -1.190625,1.190625 1.1907216,1.1907216 0 0 1 -1.190625,-1.190625 1.1907216,1.1907216 0 0 1 1.190625,-1.191141 z m 6.495208,0 a 1.1907216,1.1907216 0 0 1 1.190625,1.191141 1.1907216,1.1907216 0 0 1 -1.190625,1.190625 1.1907216,1.1907216 0 0 1 -1.190625,-1.190625 1.1907216,1.1907216 0 0 1 1.190625,-1.191141 z m -4.831228,4.345469 1.615406,1.160137 1.734259,-1.156518 1.874822,0.818552 -0.345716,0.793234 -1.443323,-0.628901 -1.837613,1.224214 -1.703255,-1.220597 -1.730642,0.751893 -0.345716,-0.792718 z" />
    </g>
  </svg>
</ng-template>
<ng-template #neutralSVG>
  <svg
    #neutral
    width="17.331758mm"
    height="17.822168mm"
    viewBox="0 0 17.331758 17.822168"
    version="1.1"
    id="svg20"
    inkscape:version="1.1 (c68e22c387, 2021-05-23)"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg">
    <defs
    id="defs17" />
    <g
    inkscape:label="Layer 1"
    inkscape:groupmode="layer"
    id="layer1"
    transform="translate(-24.643915,-28.205638)">
    <path
    id="path13505-3"
    style="fill-opacity:1;stroke-width:0.241922"
    d="m 33.310054,28.205638 a 8.6658944,8.9111557 0 0 0 -8.666139,8.911084 8.6658944,8.9111557 0 0 0 8.666139,8.911085 8.6658944,8.9111557 0 0 0 8.66562,-8.911085 8.6658944,8.9111557 0 0 0 -8.66562,-8.911084 z m 3.147094,6.495211 a 1.1907216,1.1907216 0 0 1 1.190625,1.190625 1.1907216,1.1907216 0 0 1 -1.190625,1.190625 1.1907216,1.1907216 0 0 1 -1.190625,-1.190625 1.1907216,1.1907216 0 0 1 1.190625,-1.190625 z m -6.422863,5.29e-4 a 1.1907216,1.1907216 0 0 1 1.190625,1.190625 1.1907216,1.1907216 0 0 1 -1.190625,1.190625 1.1907216,1.1907216 0 0 1 -1.190625,-1.190625 1.1907216,1.1907216 0 0 1 1.190625,-1.190625 z m 6.803718,4.874636 0.0036,0.865581 -7.228502,0.04289 -0.0057,-0.865063 z" />
    </g>
  </svg>
</ng-template>
<ng-template #smileSVG>
  <svg
    #smile
    width="17.331758mm"
    height="17.822168mm"
    viewBox="0 0 17.331758 17.822168"
    version="1.1"
    id="svg20"
    inkscape:version="1.1 (c68e22c387, 2021-05-23)"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg">
    <defs
    id="defs17" />
    <g
    inkscape:label="Layer 1"
    inkscape:groupmode="layer"
    id="layer1"
    transform="translate(-24.938974,-28.315637)">
    <path
    id="path13505-3-5"
    style="fill-opacity:1;stroke-width:0.241922"
    d="m 33.60511,28.315637 a 8.6658944,8.9111557 0 0 0 -8.666136,8.911084 8.6658944,8.9111557 0 0 0 8.666136,8.911085 8.6658944,8.9111557 0 0 0 8.665622,-8.911085 8.6658944,8.9111557 0 0 0 -8.665622,-8.911084 z m -3.471622,6.622854 a 1.1907216,1.1907216 0 0 1 1.190625,1.191141 1.1907216,1.1907216 0 0 1 -1.190625,1.190625 1.1907216,1.1907216 0 0 1 -1.190625,-1.190625 1.1907216,1.1907216 0 0 1 1.190625,-1.191141 z m 6.585127,0 a 1.1907216,1.1907216 0 0 1 1.191141,1.191141 1.1907216,1.1907216 0 0 1 -1.191141,1.190625 1.1907216,1.1907216 0 0 1 -1.190625,-1.190625 1.1907216,1.1907216 0 0 1 1.190625,-1.191141 z m -0.528651,4.954735 0.7829,0.367419 c 0,0 -1.069073,2.283749 -3.493844,2.288749 -2.372231,0.0044 -3.490227,-2.19108 -3.490227,-2.19108 l 0.769464,-0.39274 c 0,0 0.884377,1.721757 2.718696,1.718757 1.87936,-0.0035 2.713011,-1.791105 2.713011,-1.791105 z" />
    </g>
  </svg>
</ng-template>
<ng-template #happySVG>
  <svg
    #happy
    width="17.331758mm"
    height="17.822168mm"
    viewBox="0 0 17.331758 17.822168"
    version="1.1"
    id="svg20"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg">
    <defs
    id="defs17" />
    <g
    inkscape:label="Layer 1"
    inkscape:groupmode="layer"
    id="layer1"
    transform="translate(-27.699799,-29.508162)">
    <path
    id="path13505-3-8"
    style="fill-opacity:1;stroke-width:0.241922"
    d="m 36.365938,29.508162 a 8.6658944,8.9111557 0 0 0 -8.666139,8.911084 8.6658944,8.9111557 0 0 0 8.666139,8.911085 8.6658944,8.9111557 0 0 0 8.66562,-8.911085 8.6658944,8.9111557 0 0 0 -8.66562,-8.911084 z m -3.259233,6.352584 a 1.1907216,1.1907216 0 0 1 1.191141,1.190625 1.1907216,1.1907216 0 0 1 -1.191141,1.190625 1.1907216,1.1907216 0 0 1 -1.190625,-1.190625 1.1907216,1.1907216 0 0 1 1.190625,-1.190625 z m 6.525699,0.02997 a 1.1907216,1.1907216 0 0 1 1.190625,1.190625 1.1907216,1.1907216 0 0 1 -1.190625,1.190625 1.1907216,1.1907216 0 0 1 -1.191141,-1.190625 1.1907216,1.1907216 0 0 1 1.191141,-1.190625 z m -0.247012,4.527889 c 0,0 -0.440897,2.842133 -3.104719,2.891814 -2.600021,0.0485 -3.274219,-2.807063 -3.274219,-2.807063 2.451455,-0.0787 3.926547,-0.04975 6.378938,-0.08475 z" />
    </g>
  </svg>
</ng-template>
