export class EnvironmentEnum {
    private static ORDINAL_SEQ = 0;

    public static readonly DEVELOPMENT = new EnvironmentEnum('Development', 'DEV');
    public static readonly QA = new EnvironmentEnum('QA', 'QA');
    public static readonly STAGE = new EnvironmentEnum('Stage', 'STAGE');
    public static readonly TRAINING = new EnvironmentEnum('Training', 'TRAINING');
    public static readonly PROD = new EnvironmentEnum('Prod', 'PROD');
    public static readonly LOCAL = new EnvironmentEnum('Local', 'LOCAL');
    public static readonly LOCAL_LIVE = new EnvironmentEnum('Local-Live', 'LOCAL_LIVE');

    public readonly ordinal: number = 0;

    // private to disallow creating other instances of this type
    private constructor(public readonly displayName: string, public readonly value: string) {
        this.ordinal = EnvironmentEnum.ORDINAL_SEQ++;
    }

    static forString(value: string): EnvironmentEnum {
        if (value == EnvironmentEnum.DEVELOPMENT.value) {
            return EnvironmentEnum.DEVELOPMENT;
        } else if (value == EnvironmentEnum.QA.value) {
            return EnvironmentEnum.QA;
        } else if (value == EnvironmentEnum.STAGE.value) {
            return EnvironmentEnum.STAGE;
        } else if (value == EnvironmentEnum.TRAINING.value) {
            return EnvironmentEnum.TRAINING;
        } else if (value == EnvironmentEnum.PROD.value) {
            return EnvironmentEnum.PROD;
        } else if (value == EnvironmentEnum.LOCAL.value) {
            return EnvironmentEnum.LOCAL;
        } else if (value == EnvironmentEnum.LOCAL_LIVE.value) {
            return EnvironmentEnum.LOCAL_LIVE;
        }
        console.warn("Invalid value type passed in.  Defaulting to Development...", value);
        return EnvironmentEnum.DEVELOPMENT;
    }

    equals(value: string | EnvironmentEnum): boolean {
        return this == value ||
            this.value == value;
    }

    toString() {
        return this.displayName;
    }
};
