import { Component, Input, } from "@angular/core";

@Component({
    selector: 'mad-watermark',
    templateUrl: './watermark.component.html',
    styleUrls: ['./watermark.component.scss']
})
export class WatermarkComponent {
    @Input()
    envName: string;

    constructor() { }
}
