import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { IDownloadFile } from '@shared/model/file';
import { Service } from '../service/service';
import type { PDFAPI } from '../../../../../services/lib/pdf/pdf.api';
import { FileUtil } from '../../utils/file';

@Injectable()
export class PDFService extends Service<typeof PDFAPI> {
    async download(params: { session: number }): Promise<IDownloadFile> {
        try {
            const response = await firstValueFrom(
                this.get('/pdf/download', {
                    params,
                    responseType: 'blob',
                    observe: 'response'
                })
            );
            return await FileUtil.parseFile(response);
        } catch (err) {
            console.error('Error calling pdf download: ', err);
            throw err;
        }
    }

    async preview(params: { sap: string, session: number }): Promise<IDownloadFile> {
        try {
            const response = await firstValueFrom(this.get('/pdf/preview', {
                params,
                responseType: 'blob',
                observe: 'response'
            }
            ));
            return await FileUtil.parseFile(response);
        } catch (err) {
            console.error('Error generating preview pdf: ', err);
            throw err;
        }
    }

}
