export class Formatter {
    constructor (config: any) {}
    handleKeyDown (e: KeyboardEvent) {
    }
    format (target: HTMLInputElement, value: string) {
        if (value === null || value === undefined) {
            value = '';
        }
        value += '';
        return {
            raw: value,
            value,
            selectionStart: -1,
            selectionEnd: -1
        };
    }
}
