import { Component, Input } from '@angular/core';

@Component({
    selector: 'mad-env-header',
    templateUrl: './env-header.component.html',
    styleUrls: ['./env-header.component.scss']
})
export class EnvHeaderComponent {
    @Input() envName: string;

    constructor() { }
}
