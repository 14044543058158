import {Component, ViewChild, Input, TemplateRef, ElementRef} from '@angular/core';
import {ModalComponent, ModalActions} from './../modal/modal.component';
import {OutletComponent, OutletItem} from './../outlet/outlet.component';


@Component({
  selector: 'mad-alert',
  templateUrl: './alert.component.html',
  providers: ModalActions.Providers()
})
export class AlertComponent extends OutletItem {
  @ViewChild(ModalComponent) template: TemplateRef<any>;
  @ViewChild('modal') modal: ModalComponent;
  @Input() outlet: string;
  @Input() title: string;
  @Input() body: string;
  @Input() visible: boolean = true;
  @Input() buttons: {text: string; click: () => void}[];
  ngOnInit () {}
  mask = true;
  component: any;

  constructor (public element: ElementRef) {
    super();
  }

  close () {
    this.visible = false;
    if (this.component) {
      this.component.destroy();
    }
  }

  static show ({title, body, buttons, outlet: name}: {title: string; body: string; buttons: {text: string; click: () => void;}[]; outlet?: string}) {
    name = name || '';
    var outlet = OutletComponent.outletMap[name] && OutletComponent.outletMap[name].outlet;
    if (!outlet) {
      throw 'outlet must be initialized first';
    }
    var factory = outlet.componentFactoryResolver.resolveComponentFactory(this),
      component = outlet.viewContainer.createComponent(factory);

    component.instance.template = component.instance.modal as any;
    component.instance.visible = true;
    component.instance.component = component;
    component.instance.title = title;
    component.instance.body = body;
    component.instance.buttons = buttons;
    component.changeDetectorRef.detectChanges();
    OutletComponent.add(component.instance.modal, component.instance.outlet);
    if (component.instance.modal) {
      component.instance.modal.changeDetectorRef.detectChanges();
    }
    return component.instance;
  }


}
