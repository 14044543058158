import { SessionService } from '../../services/session/session.service'
import { BehaviorSubject } from 'rxjs';
import { InvoiceService } from '../../services/invoice/invoice.service';
import type { SessionMessage } from '../../../../../services/lib/session/session.service';
import { IInvoice } from '@shared/model/invoice';

export class SessionInvoices {
    changes = new BehaviorSubject(null);

    data: ISessionInvoice[] = [];

    constructor(
        public session: {
            state: { id: number | string };
            resetMissingVersions(): void;
            checkMissingVersions(a: number | number[]): any;
            sessionService: SessionService;
            invoiceService: InvoiceService;
        }) {
    }

    handleMessage(op: SessionMessage<'file'> & { notouch?: string }, do_sort = true) {
        var invoices = this.data,
            data = op.data,
            changes = false;

        this.session.checkMissingVersions(op.data.relationships_version)
        switch (op.type) {
            case 'INSERT':
            case 'UPDATE':
                var current: ISessionInvoice
                for (var i = 0, ln = invoices.length; i < ln; i++) {
                    var invoice = invoices[i];
                    if (invoice.id === -1 && (invoice.name === data.name)) {
                        current = invoice;
                        current.id = data.id
                        break;
                        //} else if (invoice.key === ((data as any).key)) {
                        //current = invoice;
                        //break;
                    } else if (invoice.id === data.id) {
                        if (invoice.id === -1) {
                            continue;
                        }
                        current = invoice;
                        break;
                    }
                }
                if (!current && !data.inactive) {
                    changes = true;
                    this.data.push(data);
                } else if (current) {
                    for (let p in data.versions) {
                        if (current.versions[p] === undefined || data.versions[p] > current.versions[p]) {
                            changes = true;
                            current.versions[p] = data.versions[p];
                            if (op.notouch !== p) {
                                if (p === 'inactive') {
                                    if (data.inactive && data.pending) {
                                        setTimeout(() => {
                                            if (current.versions.inactive === data.versions.inactive) {
                                                current.inactive = data.inactive;
                                                current.removing = true;
                                                setTimeout(() => {
                                                    this.removeInactive({ id: data.id })
                                                }, 500)
                                            }
                                        }, 1000);
                                    } else {
                                        current.removing = data.inactive;
                                        current.inactive = data.inactive;
                                        setTimeout(() => {
                                            this.removeInactive({ id: data.id })
                                        }, 500)
                                    }
                                } else {
                                    current[p] = data[p];
                                }
                            }
                        }
                    }
                }

                if (do_sort) {
                    this.sort();
                }

                break;
            case 'DELETE':
                changes = true;
                for (var i = 0, ln = invoices.length; i < ln; i++) {
                    if (invoices[i].id === data.id) {
                        invoices[i].inactive = true;
                        this.removeInactive({ id: data.id });
                    }
                }
                break;
        }

        if (changes) {
            this.changes.next(true);
        }
    }

    removeInactive({ id }: { id: number }) {
        var invoices = this.data;
        for (var i = 0, ln = invoices.length; i < ln; i++) {
            if (invoices[i].id === id && invoices[i].inactive) {
                invoices.splice(i, 1);
                this.sort();
                this.changes.next(true);
                return true;
            }
        }
        return false;
    }

    async add(invoice: File) {
        var data: ISessionInvoice = {
            id: -1,
            session: this.session.state.id as any,
            //key: internal_key++,
            inactive: false,
            pending: new Date(),
            infected: null,
            error: null,
            progress: 0,
            name: invoice.name,
            type: invoice.type,
            updated_on: null,
            updated_by: null,
            relationships_version: null,
            versions: {} as any
        },
            on_error = (error: { canceled?: boolean }) => {
                if (error.canceled) {
                    data.inactive = true;
                    var index = this.data.indexOf(data);
                    if (index !== -1) {
                        this.data.splice(index, 1);
                        this.changes.next(true);
                    }
                }
            };
        try {
            this.handleMessage({
                type: 'UPDATE',
                target: 'file',
                data
            });
            this.session.invoiceService.add({
                invoice,
                session: this.session.state.id as any
            }).subscribe((v: any) => {
                if (typeof v.loaded === 'number' && typeof v.total === 'number') {
                    data.progress = v.loaded / v.total;
                }
                if (v.type === 4) {
                    v.progress = 1;
                    if (v.status === 200) {
                        var result = v.body;
                        if (result.data && result.data.length) {
                            this.handleMessage({
                                type: 'UPDATE',
                                target: 'file',
                                data: result.data[0]
                            });
                        } else if (result.error) {
                            on_error(result.error);
                        }
                    } else {
                        on_error(null);
                    }
                }
            });
        } catch (e) {
            on_error(e);
        }
    }

    sort() {
        var invoices = this.data.slice();
        invoices.sort((a, b) => {
            var a_id = a.id,
                b_id = b.id;
            if (a_id === -1 && b_id > -1) {
                return 1;
            } else if (b_id === -1 && a_id > -1) {
                return -1;
            } else if (a.id > b.id) {
                return 1;
            } else if (a.id < b.id) {
                return -1;
            } else {
                return 0;
            }
        });
        this.data = invoices;
    }

    async remove(invoice: number) {
        var result = await this.session.invoiceService.remove({
            session: this.session.state.id as any,
            invoice
        }).toPromise();
        if (result && result.data && result.data.length) {
            this.handleMessage({
                type: 'UPDATE',
                target: 'file',
                data: result.data[0]
            });
        }
    }
}

export interface ISessionInvoice extends IInvoice {
    progress?: number;
    removing?: boolean;
}
