import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {ModalComponent} from './modal.component';
import {ActionsModule} from './../actions/actions.module';
import {NavigationModule} from '../navigation/navigation.module';
import {BodyModule} from '../body/body.module';

@NgModule({
  imports: [
     BrowserModule,
     BodyModule,
     CommonModule,
     NavigationModule,
     ActionsModule
  ],
  declarations: [
    ModalComponent
  ],
  exports: [
    ModalComponent
  ],
  bootstrap: [ModalComponent]
})
export class ModalModule { }
