<div class="loading-overlay" *ngIf="busy">
  <div [class]="'loading-indicator ' + calculatedStyle" id="loading-indicator">
	<div class="overlay-icon">
      <svg class="spot-icon spot-loading-spinner spot-loading-spinner--large" aria-labelledby="title" style="margin:0;fill:teal">
		<title>spinner</title>
		<use xlink:href="/assets/spot/dist/icons/spot_icons.svg#spinner"></use>
      </svg>
	</div>
	<div class="overlay-message" *ngIf="busyMessage">
      {{busyMessage}}
	</div>
  </div>
</div>

