import { HttpResponse } from '@angular/common/http';
import { IDownloadFile } from '@shared/model/file';

export class FileUtil {
    public static async download(file: IDownloadFile): Promise<void> {
        const url = this.generateBlobUrl(file);
        const a = document.createElement('a');

        const name = file.fileName;
        a.target = '_blank';
        a.download = name || 'download';
        a.href = url;

        a.click();
        URL.revokeObjectURL(url);
    }

    public static async open(file: IDownloadFile): Promise<void> {
        const url = this.generateBlobUrl(file);
        const a = document.createElement('a');

        const name = file.fileName;
        a.target = '_blank';
        // a.download = name || 'download';
        a.href = url;

        // window.open(url);

        a.click();
        URL.revokeObjectURL(url);
    }

    private static generateBlobUrl(file: IDownloadFile): string {
        const contents = file.contents;
        const url = URL.createObjectURL(contents);

        return url;
    }

    public static async parseFile(response: HttpResponse<Blob>): Promise<IDownloadFile> {
        if (response.body && response.body.type === 'application/json') {
            const json = JSON.parse(await response.body.text());
            if (json && json.error) {
                throw json.error;
            }
        }
        const fileName = response.headers.get('content-disposition').match((/[;]{0,1}[\s\t]*filename[\s\t]*=[\s\t]*([^;]*)/))[1];
        return {
            fileName,
            contents: response.body
        };
    }
}
