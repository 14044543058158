import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {ActionsDirective} from './actions.directive';
import {NavigationModule} from '../navigation/navigation.module';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     NavigationModule
  ],
  declarations: [
    ActionsDirective
  ],
  exports: [
    ActionsDirective
  ],
  bootstrap: []
})
export class ActionsModule { }
