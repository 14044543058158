export interface IWebSocketMessage<PDataType = any> {
    id?: number;
    path?: string;
    re?: number;
    type?: string;
    data?: PDataType;
    success?: boolean;
    error?: string | Error;
}

export interface IWebSocketMessageClient {
    waitFor(m: Message): any;
    respond(d: any, opts?: any): Promise<Message>;
}

export class Message<T = any, CLIENT extends IWebSocketMessageClient = any> implements IWebSocketMessage<T> {
    public readonly startTime = Date.now();
    public msgPromise: Promise<Message<T>>;
    public rejectFunction: (reason?: any) => void;
    public resolveFunction: (value: Message) => void;

    public readonly data: T;
    public readonly id: number;
    public readonly re: number;
    public readonly type: string;
    public readonly path: string;
    public readonly params?: any;
    public readonly success?: boolean;
    public error?: string | Error;

    client: CLIENT;

    public constructor(
        { id, re, path, type, data, success, error }: { id: number; re?: number; type: string; path: string; data?: any; success?: boolean; error?: string | Error },
        client: CLIENT
    ) {
        this.id = id;
        this.re = re;
        this.type = type;
        this.path = path;
        this.data = data;
        this.success = success;
        this.error = error;
        this.client = client;

        if (path) {
            const params = {};
            this.params = params;
            const search = path.split('?')[1];
            if (search) {
                const searchParams = new URLSearchParams(search);
                searchParams.forEach((v, k): void => {
                    params[k] = v;
                });
            }
        }
    }

    public respond({ success, error, data }: { success?: boolean; error?: string | Error; data?: any }, opts?: { raw?: boolean; bypassAuth?: boolean }): Promise<Message> {
        return this.client.respond(
            {
                id: this.id,
                type: this.type,
                path: this.path,
                success,
                error,
                data
            },
            opts
        );
    }

    public async response(): Promise<Message> {
        if (!this.resolveFunction) {
            this.msgPromise = new Promise<Message>((resolve, reject): void => {
                this.rejectFunction = reject;
                this.resolveFunction = resolve;
                this.client.waitFor(this);
            });
        }

        return await this.msgPromise;
    }

    public toJSON(): IWebSocketMessage {
        return Message.safeJSON(this);
    }

    public toString(): string {
        const retVal = this.toJSON();
        return JSON.stringify(retVal);
    }

    public static safeJSON(msg: Message): IWebSocketMessage {
        // Strip out client member to avoid circular json when logging.
        const { client, ...safeMessage } = msg;
        return safeMessage;
    }
}
