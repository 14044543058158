import { Component, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchComponent } from '../search/search.component';
import { SelectedPanelsComponent } from '../selected-panels/selected-panels.component';
import { SummaryComponent } from '../summary/summary.component';
import { AccountState, AccountSession } from '../../states/account/account.state';
import { UserState } from '../../states/user/user.state';
import { INewPanel } from '../../states/session/session-panels';
import { PDFService } from '../../services/pdf/pdf.service';
import { FileUtil } from '../../utils/file';
import { MADAlertService } from '../../services/alert/alert.service';
import { ErrorHandler } from '../../providers/error-handler/error-handler';

interface AccountQueryParams {
    view: string;
}

@Component({
    selector: 'mad-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent {
    @ViewChild('search') public search: SearchComponent;

    @ViewChild('selectedPanels') public selectedPanels: SelectedPanelsComponent;

    @ViewChild('summary') public summary: SummaryComponent;

    @Input() public submitted: boolean;

    @Input() public inactive: boolean;

    public configurePanel: INewPanel;

    private routeSubscription: Subscription;

    public _screen: (AccountComponent['SCREEN'])[keyof AccountComponent['SCREEN']];

    private accountStateSub: Subscription;

    public account: AccountSession;

    private accountQueryParams: AccountQueryParams;

    private paramTimer: any;

    public isShowingHistory = false;

    public isVisibleHistory = false;

    public skipHistoryAnimation = false;

    SCREEN = {
        SEARCH: <'search'>'search',
        SELECTED_PANELS: <'panels'>'panels',
        SUMMARY: <'summary'>'summary'
        // HISTORY: <'history'>'history',
    };

    private sapId: string;

    private sessionId: number;

    public isInitializing = true;

    public isResizing = false;

    onResize: Function;

    requiresInit = false;

    public constructor(
        public accountState: AccountState,
        public userState: UserState,
        public router: Router,
        public route: ActivatedRoute,
        public changeDetector: ChangeDetectorRef,
        public pdfService: PDFService,
        public alertService: MADAlertService,
        public errorHandler: ErrorHandler
    ) {
        this.routeSubscription = route.queryParams.subscribe((params: AccountQueryParams) => {
            if (!this.account) {
                this.accountQueryParams = params;
                if (params && params.view) {
                    this._screen = params.view as any;
                    this.requiresInit = true;
                }
                return;
            }
            this.handleParams(params);
        });
        let timer: any;
        this.accountStateSub = this.accountState.subscribe((account) => {
            if (account !== this.account && account) {
                this.account = account;
                this.sapId = (this.account ? this.account.sap : null);
                this.sessionId = (this.account && this.account.session ? this.account.session.sessionId : null);
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(() => {
                    timer = null;
                    if (this.account === account) {
                        this.handleParams(this.accountQueryParams);
                    }
                });
            }
        });
    }

    ngOnInit() {
        window.addEventListener('resize', this.onResize = () => {
            this.isResizing = true;
            this.refreshLayout();
            this.isResizing = false;
        });
    }

    ngOnDestroy() {
        this.routeSubscription && this.routeSubscription.unsubscribe();
        this.accountStateSub && this.accountStateSub.unsubscribe();
        window.removeEventListener('resize', this.onResize as any);
    }

    handleParams(params: AccountQueryParams) {
        const dispatch = () => {
            this.isInitializing = false;

            this.paramTimer = null;
            const screen = ('view' in params) ? ((params.view as any)) : undefined;

            this.accountQueryParams = params;

            if (this.account && this.account.session) {
                const panels = this.account.session.panels.data;
                for (const panel of panels) {
                    panel.expanded = panel.isDetailsExpanded = false;
                }
            }

            const prevScreen = this._screen;
            this._screen = screen;

            this.refreshLayout(prevScreen);
        };

        if (this.paramTimer) {
            clearTimeout(this.paramTimer);
        }
        if (!this.search) {
            this.changeDetector.detectChanges();
        }
        dispatch();
    }

    _refresh_timer: any;
    refreshLayout(prevScreen?: this['_screen']) {
        if (this._screen !== 'summary' && !this.selectedPanels) {
            this.changeDetector.detectChanges();
        }

        const dispatch = () => {
            this._refresh_timer = null;
            switch (this._screen) {
                case this.SCREEN.SEARCH:
                    this.showSearch(prevScreen);
                    break;
                case this.SCREEN.SUMMARY:
                    this.showSummary(prevScreen);
                    break;
                case this.SCREEN.SELECTED_PANELS:
                    // this.updateParams('summary')
                    // this.showSummary(null);
                    this.showSelectedPanels(prevScreen);
                    break;
                // case this.SCREEN.HISTORY:
                // this.showHistory();
                // break;
                default:
                    this.showSearch(prevScreen);
                    break;
            }
        }
        if (this._refresh_timer) {
            clearTimeout(this._refresh_timer);
        }

        if (this._screen !== 'summary' && !this.selectedPanels.element.nativeElement.style.width) {
            const width = document.body.offsetWidth;
            const hiddenWidth = Math.max(Math.min(width * 0.21, 400), 285);
            this.selectedPanels.element.nativeElement.style.transition = null;
            if (prevScreen === 'summary') {
                this.selectedPanels.element.nativeElement.style.opacity = 0;
            }
            if (this._screen === 'panels') {
                this.selectedPanels.element.nativeElement.style.transform = `translateX(100%)translateX(${hiddenWidth}px)`;
                this.selectedPanels.element.nativeElement.style.width = `${document.body.offsetWidth - hiddenWidth}px`;
                this._refresh_timer = setTimeout(dispatch);
            } else if (this._screen === 'search') {
                this.selectedPanels.element.nativeElement.style.transform = `translateX(${hiddenWidth}px)`;
                this.selectedPanels.element.nativeElement.style.width = `${document.body.offsetWidth - hiddenWidth}px`;
                this._refresh_timer = setTimeout(dispatch);
            }
        } else {
            dispatch();
        }
    }

    async updateParams(view: this['_screen']) {
        view = view || 'search';

        if (view === this.SCREEN.SEARCH) {
        } else {
            this.configurePanel = null;
        }
        this.router.navigate([], { relativeTo: this.route, queryParams: { view } });
    }

    showSearch(prevScreen?: this['_screen']) {
        const search = this.search.element.nativeElement;
        const selectedPanels = this.selectedPanels.element.nativeElement;
        const width = document.body.offsetWidth;
        const hiddenWidth = Math.max(Math.min(width * 0.21, 400), 285);
        // summary = this.summary.element.nativeElement,
        const ox = width - hiddenWidth;
        selectedPanels.style.width = `${hiddenWidth}px`;
        search.style.width = `${ox}px`;
        selectedPanels.style.opacity = '1';
        selectedPanels.style.transform = `translateX(${ox}px)`;
        if (this.isResizing) {
            selectedPanels.style.transition = null;
        } else if (prevScreen === 'summary') {
            // pass
        } else {
            selectedPanels.style.transition = 'width 600ms, background-color 500ms, transform 500ms';
        }
    }

    showSelectedPanels(prevScreen?: AccountComponent['_screen']) {
        const search = this.search.element.nativeElement;
        const selectedPanels = this.selectedPanels.element.nativeElement;
        const width = document.body.offsetWidth;
        const hiddenWidth = Math.max(Math.min(width * 0.21, 400), 285);
        // summary = this.summary.element.nativeElement,
        const ox = width - hiddenWidth;

        search.style.width = `${ox}px`
        selectedPanels.style.opacity = '1';
        selectedPanels.style.transform = `translateX(${hiddenWidth}px)`;
        selectedPanels.style.width = `${ox}px`;
        if (this.isResizing) {
            selectedPanels.style.transition = null;
        } else if (prevScreen === 'summary') {
            // pass
        } else {
            selectedPanels.style.transition = 'width 0ms, background-color 500ms, transform 500ms';
        }
    }

    showSummary(prevScreen?: this['_screen']) {
        setTimeout(() => {
            if (this._screen !== this.SCREEN.SUMMARY) {
                return;
            }

            const summary = this.summary.element.nativeElement;
            summary.style.transition = 'opacity 750ms';
            summary.style.opacity = 1;
        });
    }

    public hasSelectedPanels(): boolean {
        return this.account && this.account.session && this.account.session.panels.data.length > 0;
    }

    public configure(panel: INewPanel) {
        if (this.search) {
            this.updateParams(this.SCREEN.SEARCH);
        }
    }

    public addPanel(panel: INewPanel): void {
        if (this.account) {
            this.account.session.panels.add(panel);
            this.selectedPanels.scrollToEnd();
        }
    }

    public hideHistory() {
        this.isShowingHistory = this.isVisibleHistory = false;

        if (this.account && this.account.session && this.account.session.state && this.account.session.state.approved !== null) {
            // open latest session for this account
            this.accountState.openLatestSession();
        }
    }

    public showHistory(skipAnimation?: boolean): void {
        if (skipAnimation) {
            this.skipHistoryAnimation = true;
            setTimeout(() => this.skipHistoryAnimation = false, 1);
        }
        this.isShowingHistory = true;
        setTimeout(() => {
            this.isVisibleHistory = true;
        })
    }

    async previewQuote() {
        if (!this.hasSelectedPanels()) {
            return;
        }
        try {
            this.alertService.setBusy(true, 'Generating Quote PDF...');
            const resp = await this.pdfService.preview({ sap: this.sapId, session: this.sessionId });
            FileUtil.download(resp);
        } catch (err) {
            console.error("Error generating preview pdf: ", err);
            this.errorHandler.toast('PDF_GEN_ERROR');
        } finally {
            this.alertService.setBusy(false);
        }
    }

    public toggleSelectedPanels(): void {
        if (this._screen === this.SCREEN.SELECTED_PANELS) {
            this.updateParams(this.SCREEN.SEARCH);
        } else {
            this.updateParams(this.SCREEN.SELECTED_PANELS);
        }
    }
}
