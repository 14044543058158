import {Component, Output, EventEmitter, Input, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {ToastComponent} from './../../components/toast/toast.component';
import {SessionState} from './../../states/session/session.state';
import {UserState} from './../../states/user/user.state';
import {Subscription} from 'rxjs';
import {SubmitProcessComponent} from './../submit-process/submit-process.component';

@Component({
  selector: 'mad-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent {
  @Input() approved: boolean;
  @Input() submitted: boolean;
  @Input() inactive: boolean;
  @Input() reviewing: boolean;
  @Input() session: SessionState;
  @Input() sap: string;
  @Input() vdc_reviewing: boolean;
  @Input() ssa_reviewing: boolean;
  @Output() openhistory = new EventEmitter();
  @ViewChild('recentEditTemplate') recentEditTemplate: TemplateRef<any>;
  @ViewChild('submitProcess') submitProcess: SubmitProcessComponent;

  _user_sub: Subscription;

  constructor (public userState: UserState) {
    this._user_sub = userState.changes.subscribe(v=> {
      this.ngOnChanges(null);
    });
  }

  _approved_toast: ToastComponent;
  _rejected_toast: ToastComponent;
  _inactive_toast: ToastComponent;
  _recent_edit_toast: ToastComponent;

  ngOnChanges (changes: SimpleChanges) {
    //TODO also notify for meta?
    if (changes && changes.session && this.session && this.session.panels.data.length) {
      requestAnimationFrame(() => {
        this._recent_edit_toast = ToastComponent.show({
          priority: 'medium',
          type: 'danger',
          icon: 'fa-exclamation-triangle',
          body: this.recentEditTemplate,
          position: 'left',
          animation: 'fadeIn',
          animationDuration: '400ms',
          progressBar: false,
          duration: 10000,
          maxWidth: document.body.offsetWidth < 1024 ? 'calc(100% - 20px)' : '75%'
        })
      });
    }

    var not_submitting = !(this.submitProcess && this.submitProcess.queue.length);

    if (this.vdc_reviewing && this.approved) {
      if (!this._approved_toast || this._approved_toast.hiding) {
        this._approved_toast = ToastComponent.show({
          type: 'success',
          title: "Approved",
          body: `The session for ${this.sap} has been approved. `,
          priority: 'medium',
          duration: 'infinite'
        });
      }
    } else if (this._approved_toast) {
      this._approved_toast.close();
      this._approved_toast = null;
    }
    if (this.vdc_reviewing && this.approved === false) {
      if (!this._rejected_toast || this._rejected_toast.hiding) {
        this._rejected_toast = ToastComponent.show({
          type: 'danger',
          title: "Rejected",
          body: `The session for ${this.sap} has been rejected.`,
          priority: 'high',
          duration: 'infinite'
        });
      }
    } else if (this._rejected_toast) {
      this._rejected_toast.close();
      this._rejected_toast = null;
    }
    if (this.inactive) {
      if (!this._inactive_toast || this._inactive_toast.hiding) {
        this._inactive_toast = ToastComponent.show({
          type: 'danger',
          title: "Deleted",
          body: `The session for ${this.sap} has been deleted.  Further changes will not be allowed`,
          priority: 'high',
          duration: 'infinite'
        });
      }
    } else if (this._inactive_toast) {
      this._inactive_toast.close();
      this._inactive_toast = null;
    }
  }

  ngOnDestroy() {
    this._user_sub && this._user_sub.unsubscribe();
    this._approved_toast && this._approved_toast.close();
    this._rejected_toast && this._rejected_toast.close();
    this._recent_edit_toast && this._recent_edit_toast.close();
    this._inactive_toast && this._inactive_toast.close();
  }
}
