<!--<div class="mad-header-inner">-->
  <div class="mad-header-left">
    <ng-content select="[left]"></ng-content>
  </div>
  <div class="mad-header-content">
    <ng-content></ng-content>
  </div>
  <div class="mad-header-right">
    <ng-content select="[right]"></ng-content>
  </div>
<!--</div>-->
