import { Component, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IFeedbackData, IFileMessage, IFeedbackMessage, IFeedbackFile } from '@shared/model/feedback';
import { FeedbackService } from '../../../services/feedback/feedback.service';
import { EMOJI } from '../../feedback/feedback.component';
import { InfinitePager } from '../../../components/infinite-list/infinite-pager';
import { FileUtil } from '../../../utils/file';
import { IWebSocketSubscriptionHandle } from '../../../providers/websocket';

@Component({
    selector: 'mad-ssa-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: [
        '../../feedback/feedback.component.scss',
        'feedback.component.scss'
    ]
})
export class FeedbackComponent implements OnDestroy {
    @ViewChild('infiniteList') infiniteList: any;

    paramsSub: Subscription;

    EMOJI = EMOJI;

    totalCount: number = 0;

    _results: IFeedbackData[] = [];

    selectedFeedback: IFeedbackData;

    _feedback_sub: IWebSocketSubscriptionHandle;

    pager = new InfinitePager<this['selectedFeedback']>({
        total: 40,
        getTotal: async () => {
            return this.totalCount;
        },
        getData: async (top, limit) => {
            try {
                const result = (await this.feedbackService.page({
                    top,
                    limit
                }).toPromise());

                if (result.error) {
                    throw result.error;
                }

                const data = result.data;
                this.pager.setTotal(data.count);

                return data.results;
            } catch (e) {
                console.error(e);
            }

        }
    });

    constructor(public route: ActivatedRoute, public router: Router, public feedbackService: FeedbackService) {
        this.paramsSub = route.params.subscribe(async ({ feedback }) => {
            if (this.selectedFeedback && this.selectedFeedback.id === feedback) {
                return;
            }
            try {
                this.selectedFeedback = await feedbackService.view({ id: feedback });
            } catch (e) {
                console.error(e);
            }
        });
    }

    async ngOnInit() {
        const sub = await this.feedbackService.subscribe().toPromise();

        //this is a lossy algorithm -- its not 100% important these
        //resolve or have gaps (unlike session details)
        //could be changed at some point but the immediate value is likely not there
        //would be more important if the feedback became editable
        this._feedback_sub = sub.subscribe((message) => {
            switch (message.data.target) {
                case 'feedback':
                    this.handleFeedback(message.data);
                    break;
                case 'file':
                    this.handleFile(message.data as IFileMessage);
                    break;
                default:
                    break;
            }
        });
    }

    findFeedback(id: string | number) {
        const results = this.pager.results;
        for (let i = 0; i < results.length; i++) {
            if (results[i] && results[i].data && (results[i].data.id === id)) {
                return i;
            }
        }
        return -1;
    }

    handleFeedback(data: IFeedbackMessage) {
        const index = this.findFeedback(data.data.id);

        switch (data.type) {
            case 'INSERT':
                if (index === -1) {
                    this.pager.setTotal(this.pager.total.getValue() + 1);
                    this.pager.results.unshift({
                        lock: this.pager.results[0].lock,
                        data: { ...data.data, files: [] }
                    });
                    break;
                }
            case 'UPDATE':
                if (index !== -1) {
                    const feedback = this.pager.results[index].data;
                    Object.assign(feedback, data.data);
                }
                if (this.selectedFeedback && this.selectedFeedback.id === data.data.id) {
                    Object.assign(this.selectedFeedback, data.data);
                }
                break;
            case 'DELETE':
                //NOT_SUPPORTED
                break;
            default:
                break;
        }
    }

    handleFile(data: IFileMessage) {
        function check(feedback: IFeedbackMessage['data']) {
            function find_index(id: string) {
                const files = feedback.files;
                if (files) {
                    for (let i = 0; i < files.length; i++) {
                        if (files[i].id === id) {
                            return i;
                        }
                    }
                }
                return -1;
            }
            if (feedback) {
                switch (data.type) {
                    case 'INSERT':
                    case 'UPDATE':
                        const index = find_index(data.data.id);
                        if (index !== -1) {
                            if (data.data.inactive) {
                                feedback.files.splice(index, 1);
                            } else {
                                Object.assign(feedback.files[index], data.data);
                            }
                        } else {
                            feedback.files.push(data.data);
                        }
                    case 'DELETE':
                        //NOT_SUPPORTED
                        break;
                    default:
                        break;
                }
            }
        }

        const feedback = this.pager.results[this.findFeedback(data.data.parent_id)].data;
        if (feedback) {
            check(feedback);
        }
        if (this.selectedFeedback && this.selectedFeedback.id === data.data.parent_id) {
            check(this.selectedFeedback);
        }
    }

    ngOnDestroy(): void {
        this.paramsSub.unsubscribe();

        if (this._feedback_sub) {
            this._feedback_sub.unsubscribe();
        }
    }

    async onDownloadClick(file: IFeedbackFile): Promise<void> {
        FileUtil.download(
            await this.feedbackService.download({
                id: file.id
            })
        );
    }

    getFeedbackWidth(): string {
        return `${Math.min(document.body.offsetWidth * .75, 1024)}px`;
    }

    onFeedbackClick(config: IFeedbackData): void {
        this.selectedFeedback = config;
        this.router.navigate([{ feedback: config.id }], {
            relativeTo: this.route
        });
    }

    viewSession({ sap, session }: IFeedbackData): void {
        this.router.navigate(['account', { sap, session }]);
    }
}
