import { Directive, ElementRef, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { InputComponent } from '../input/input.component';
import { DateFormatter } from './date.formatter';
import { CurrencyFormatter } from './currency.formatter';
import { PercentFormatter } from './percent.formatter';
import { NumberFormatter } from './number.formatter';
import { Formatter } from './formatter';

/*
 * You would think a decent one would exist for angular, but no not really
 * more intuitive currency formatter + TODO add other formats
 * */
@Directive({
    selector: '[madFormatter]'
})
export class FormatterDirective {
    _key_down = false;

    _value_sub: Subscription;

    _prevent = false;

    _formatter: CurrencyFormatter | DateFormatter | PercentFormatter | NumberFormatter | Formatter;

    _input: HTMLInputElement;

    @Input() madFormatter: { type: 'currency' | 'percent' | 'number' | 'none'; config?: any };

    @Input() madFormatterValue: string;

    @Output() madFormatterValueChange = new EventEmitter();

    constructor(public el: ElementRef<HTMLInputElement>, public control: NgControl) {
        this._onKeyUp = this._onKeyUp.bind(this);
        this._onKeyDown = this._onKeyDown.bind(this);

        el.nativeElement.addEventListener('keydown', this._onKeyDown)
        el.nativeElement.addEventListener('keyup', this._onKeyUp)
    }

    ngOnInit() {
        if (this.control instanceof InputComponent) {
            this._input = this.control.input.nativeElement;
        } else if (this.el instanceof HTMLInputElement) {
            this._input = this.el;
        } else {
            this._input = this.el.nativeElement.querySelector('input');
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.madFormatter) {
            switch (changes.madFormatter.currentValue.type) {
                case 'number':
                    this._formatter = new NumberFormatter(changes.madFormatter.currentValue);
                    break;
                case 'percent':
                    this._formatter = new PercentFormatter(changes.madFormatter.currentValue);
                    break;
                case 'currency':
                    this._formatter = new CurrencyFormatter(changes.madFormatter.currentValue);
                    break;
                case 'date':
                    this._formatter = new DateFormatter(changes.madFormatter.currentValue);
                    break;
                default:
                    this._formatter = new Formatter(changes.madFormatter.currentValue);
                    break;
            }
        }
    }

    ngOnDestroy() {
    }

    _onKeyDown(e: KeyboardEvent) {
        this._formatter.handleKeyDown(e);
    }
    _onKeyUp() {
        this._key_down = false;
    }

    public hasFocus(input: HTMLInputElement): boolean {
        return input === document.activeElement;
    }

    public format(input: HTMLInputElement, value: any): { raw: string; value: string; selectionStart: number; selectionEnd: number } {
        const result = this._formatter.format(input, value);
        if (input.value !== result.value) {
            input.value = result.value;
            if (this.hasFocus(input)) {
                if (result.selectionStart >= 0) {
                    input.selectionStart = result.selectionStart;
                }
                if (result.selectionEnd >= 0) {
                    input.selectionEnd = result.selectionEnd;
                }
            }
        }
        return result;
    }

    changeValue() {
    }
}
