import { Injectable } from '@angular/core';
declare var spot: any;

@Injectable()
export class MADAlertService {

    busy = false;
    busyMsg: string = 'Searching...';

    constructor() {
    }

    setBusy(isBusy: boolean, busyMsg?: string) {
        console.log("setBusy: " + isBusy, busyMsg);
        this.busy = isBusy;
        this.busyMsg = busyMsg;
    }

    isBusy(): boolean {
        return this.busy;
    }

    showError(title: string, msg: string) {
        spot.Message.toast({
            //'display': 'inline',
            'dismissible': true,
            'priority': 'medium',
            'alertLevel': 'danger',
            'title': title,
            'html': msg,
            //'progressBar': true,
            'icon': 'alert-notification',
            'iconPath': '/assets/spot-library/dist/icons/spot_icons.svg',
            'animationIn': 'bounceInRight',
            'animationOut': 'bounceOutRight',
            'position': 'top-right',
            //'timer': 5000,
            // ,
            // 'onDismiss': function() {
            //     alert("hey you closed me!");
            // }
        });
    }

    showToast(title: string, msg: string, icon: string = 'checkmark', onDismissFunc: any = null) {
        spot.Message.toast({
            //'display': 'inline',
            'dismissible': true,
            'priority': 'medium',
            'alertLevel': 'success',
            'title': title,
            'html': msg,
            'progressBar': true,
            'icon': icon,
            'iconPath': '/assets/spot-library/dist/icons/spot_icons.svg',
            'animationIn': 'bounceInRight',
            'animationOut': 'bounceOutRight',
            'position': 'top-right',
            'timer': 5000,
            'onDismiss': onDismissFunc
            // ,
            // 'onDismiss': function() {
            //     alert("hey you closed me!");
            // }
        });

    }
}
