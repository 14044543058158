import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ToastComponent, TOAST_TYPE, TOAST_PRIORITY, TOAST_POSITION } from "../../components/toast/toast.component";
import { EventData, EventService } from "../../services/event/event.service";

const SILENT_ERRORS = {
    "NO_TESTMIX": true
};

export interface IToastConfig {
    type?: TOAST_TYPE;
    title?: string;
    code?: string;
    priority?: TOAST_PRIORITY;
    position?: TOAST_POSITION;
    duration?: number | "infinite";
    progressBar?: boolean;
    context?: any;
    body?: string;
}

@Injectable()
export class ErrorHandler {
    public constructor(public transloco: TranslocoService, public domSanitizer: DomSanitizer, private eventService: EventService) {
        window["error_handler"] = this;
    }

    public handleError(err: Error | string | IToastConfig, context?: any): boolean {
        if (err instanceof Error || typeof err === "string") {
            const msg = this.getErrorMessage(err, context);
            const title = "handleError version: " + err["code"] ? err["code"] : this.transloco.translate("ERROR");
            const toastConfig: IToastConfig = {
                type: "danger",
                priority: "medium",
                duration: 8000,
                progressBar: true,
                body: msg,
                title
            };
            this.toast(toastConfig, context);
        } else {
            this.toast(err, context);
        }
        return true;
    }

    public toast(error: string | IToastConfig, context?: any): void {
        if (!error) {
            return;
        }
        this.eventService.emit(new EventData("ErrorHandler", error));
        let config: IToastConfig;
        let body: string;
        let title = this.transloco.translate("ERROR");
        if (typeof error === "string") {
            if (SILENT_ERRORS[error]) {
                return;
            }
            body = this.getErrorMessage(error, context);
            title = body !== error ? error : title;

            config = {
                type: "danger",
                priority: "medium",
                duration: 8000,
                progressBar: true,
                body,
                title
            };
        } else if (error) {
            if (SILENT_ERRORS[error.code]) {
                return;
            }
            body = error.body || this.getErrorMessage(error.code, context || error.context || {});
            title = error.title || error.code || title;
            config = {
                type: error.type || "danger",
                priority: error.priority || "medium",
                duration: error.duration || 5000,
                progressBar: "progressBar" in error ? error.progressBar : true,
                body,
                title
            };
        }

        ToastComponent.show({
            title: config.title,
            body: config.body,
            priority: config.priority,
            type: config.type,
            duration: config.duration,
            progressBar: config.progressBar
        });
    }

    public getErrorMessage(error: string | Error, context?: any): string {
        if (!error) {
            return "";
        }
        let msg: string;
        if (typeof error === "string") {
            msg = this.transloco.translate(error, context || {});
            return msg;
        }

        const srcContext = context || error["context"] || { sourceError: "" };
        const srcError = error["sourceError"];
        if (srcError) {
            const code = srcError.code;
            const message = srcError.message;
            const baseError = srcError.error;
            let details;
            if (code && message) {
                details = `${code} : ${message}`;
            } else if (code || message) {
                details = code || message;
            } else if (baseError) {
                details = baseError;
            }
            srcContext.sourceError = "<br/>[" + details + "]";
        }
        msg = this.transloco.translate(error["code"] || error.message, srcContext);

        return msg;
    }

    public getSafeErrorMessage(error: string | Error, context?: any): SafeHtml {
        const msg = this.getErrorMessage(error, context);
        if (msg) {
            return this.domSanitizer.bypassSecurityTrustHtml(msg);
        }
        return "";
    }
}
