import {Component, HostListener, Input, ElementRef, ViewChild} from '@angular/core';
import {Field} from '../field/field.component';

let ids = 0;

@Component({
    selector: 'mad-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: Field.Providers(CheckboxComponent)
})
export class CheckboxComponent extends Field {

    @ViewChild('input') input: ElementRef;
    @Input() type: 'alert' | 'toggle' | 'check' = 'check'; // accepts alert
    @Input() text: string; // text is the text displayed adjacent to the checkbox
    @Input() size: 'small' | 'default' = 'default';
    @Input() value: any;
    _indeterminate: boolean = false;
    @Input() set indeterminate(v) {
        if (this._indeterminate !== v) {
            this._indeterminate = v;
            this.input.nativeElement.indeterminate = v;
        }
    }
    get indeterminate() {
        return this._indeterminate;
    }
    _checked: boolean;
    @Input() set checked(checked: boolean) {
        if (checked !== this._checked) {
            this._checked = checked;
            if (!this._writing) {
                if (this._onChange) {
                    this._onChange(checked);
                }
                if (this._onTouched) {
                    this._onTouched();
                }
            }
            //}
        }
    }
    get checked() {
        return this._checked;
    }

    _writing: boolean;
    isFocused: boolean = false; // used to manage focus class in toggle mode
    _id: string = `--cl-checkbox-${ids++}`;


    //aot dependency injection seems to break when constructor inheritance is required
    constructor (public element: ElementRef) {
        super(element);
    }

    getClassName() {
        var type = this.type
        if (type && type !== 'check') {
            return `cl-checkbox-input spot-toggle ${type !== 'toggle' ? `spot-toggle--${type}` : '' }` ;
        } else {
            return 'cl-checkbox-input spot-form__checkbox-input';
        }
    }

    isToggle () {
        return this.type && this.type !== 'check';
    }

    @HostListener('keydown', ['$event'])
    onKeyDown($event: KeyboardEvent) {
        if ($event.key === 'Enter') {
            this.toggle();
        }
    }

    toggle() {
        this.checked = !this.checked;
    }

    writeValue (checked: boolean) {
        this._writing = true;
        this.checked = checked;
        this._writing = false;
    }

    reset() {
        this._checked = false;
    }
    
    focusInput () {
        this.input.nativeElement.focus();
    }

    blurInput () {
        this.input.nativeElement.blur();
    }
}
