
<mad-submit-process #submitProcess [session]="session" [status]="session?.state?.status" (openhistory)="openhistory.emit(true)"></mad-submit-process>

<ng-template #recentEditTemplate>
  <div style="display:flex;flex-direction:row;font-size:14px;flex-wrap:wrap;margin-left:1em;">
    <ng-container *ngIf="!vdc_reviewing && !ssa_reviewing">
      <div style="margin-right:0.5em;">
        The most recent session for this account was not submitted and is available below. 
      </div>
      <div style="white-space:nobreak;margin-right:5em;">
        <b>Last Update:&nbsp;&nbsp;{{session?.state?.updated_on | date}} - {{session?.state?.updated_by}}</b>
      </div>
    </ng-container>
    <ng-container *ngIf="vdc_reviewing">
      <div style="margin-right:0.5em;">
        Your most recent session is pending approval. To edit or cancel the submission, please contact CAG Sales Support
      </div>
    </ng-container>
    <ng-container *ngIf="ssa_reviewing">
      <div style="margin-right:0.5em;">
        This most recent session is under review and is available below.
      </div>
      <div style="white-space:nobreak;margin-right:5em;">
        <b>Last Update:&nbsp;&nbsp;{{session?.state?.updated_on | date}} - {{session?.state?.updated_by}}</b>
      </div>
    </ng-container>
  </div>
</ng-template>
