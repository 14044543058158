import { Component, ViewChild, ElementRef, Input, SimpleChanges, Output, EventEmitter, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AccountState } from '../../states/account/account.state';
import { SessionState } from '../../states/session/session.state';
import { UserState } from '../../states/user/user.state';

const STATUS_MIN_TIME = 4000;

@Component({
    selector: 'mad-submit-process',
    templateUrl: './submit-process.component.html',
    styleUrls: ['./submit-process.component.scss']
})
export class SubmitProcessComponent implements OnChanges {
    @ViewChild('calculator') calculator: ElementRef;

    @ViewChild('screen') screen: ElementRef;

    @ViewChild('thinking') thinking: ElementRef;

    @ViewChild('email') email: ElementRef;

    @Input() session: SessionState;

    @Input() status: SessionState['state']['status'] = null;

    @Output() openhistory = new EventEmitter();

    hasError = false;

    currentStatusValue: SessionState['state']['status'] = null;

    set currentStatus(status: this['currentStatusValue']) {
        this.currentStatusValue = status;
        this.hasError = false;
        switch (status) {
            case 'USER_PRICING':
                this.runCalculator();
                break;
            case 'USER_VERIFYING':
                this.runThinking();
                break;
            case 'USER_EMAILING':
                this.runEmail();
                break;
            case 'USER_ERROR':
                this.runError();
                break;
            case 'SSA_REVIEWING':
                this.runReviewing();
                break;
            case 'SSA_ERROR':
                this.runSsaError();
                break;
            case 'SSA_REVIEWED':
                this.runSsaReviewed();
                break;
            case 'SSA_EMAILING':
                this.runSsaEmailing();
                break;
            case 'AUTO_REVIEWED':
                this.runApproved();
                break;
            default: // end
                console.error('Reached default on switch statement!');
                break;
        }
    }

    get currentStatus() {
        return this.currentStatusValue;
    }

    currentStatusStart = null;

    userPath = ['USER_PRICING', 'USER_VERIFYING', 'USER_EMAILING'] as SessionState['state']['status'][];

    reviewingPath = ['SSA_EMAILING'];

    statusPath: this['userPath'] | this['reviewingPath'] = this.userPath;

    CALCULATOR = 1;

    THINKING = 2;

    EMAIL = 4;

    ERROR = 8;

    REVIEWING = 16;

    SSA_EMAILING = 32;

    SSA_ERROR = 64;

    SSA_REVIEWED = 128;

    APPROVED = 256;

    state = this.CALCULATOR | this.THINKING | this.EMAIL;

    buttons: number[] = Array.apply(null, new Array(16)).map((d, i) => i);

    word = [];

    targetWord = 'Calculating...';

    targetIndex = 0;

    waitingIndex = null;

    queue: { type: string; index: number; info?: string; start?: number; timer?: any }[] = [];

    constructor(public userState: UserState, public router: Router, public accountState: AccountState) { }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.status) {
            if (this.status === null && !this.queue.length) {
                return;
            }
            const path = this.session.state.reviewing ? this.reviewingPath : this.userPath;
            const targetIndex = path.indexOf(this.status);
            const queue = this.queue;

            // don't show these status changes to a vdc user
            if (!this.userState.state.can_approve
                && (this.status === 'SSA_EMAILING' || this.status === 'SSA_ERROR' || this.status === 'SSA_REVIEWED')) {
                return;
            }

            if (targetIndex !== -1) {
                this.statusPath = path;
            }

            if (targetIndex === -1) {
                if ((this.status === 'USER_ERROR'
                    || this.status === 'AUTO_REVIEWED'
                    || this.status === 'SSA_REVIEWING'
                    || this.status === 'SSA_ERROR'
                    || this.status === 'SSA_REVIEWED') && queue.length) {
                    if (this.queue[this.queue.length - 1].type !== this.status) {
                        queue.push({ type: this.status, index: -1, info: this.session.state.submit_error, start: null });
                    }
                }
            } else {
                const qln = queue.length - 1;
                for (let i = targetIndex; i >= 0; i -= 1) {
                    let found = false;
                    for (let j = qln; j >= 0; j -= 1) {
                        if (queue[j].index === i) {
                            found = true;
                            break;
                        }
                    }
                    if (found) {
                        break;
                    }
                    queue.splice(qln + 1, 0, { index: i, type: path[i], info: null, start: null })
                }
            }

            const setTimer = (): void => {
                if (queue[0] && !queue[0].start) {
                    queue[0].start = Date.now();
                }
                if (queue[0] && this.currentStatus !== queue[0].type) {
                    this.currentStatus = queue[0].type as any;
                }
                if (queue.length > 1 || (queue[0] && queue[0].index === -1)) {
                    if (!queue[0].timer) {
                        queue[0].timer = setTimeout(() => {
                            const type = queue[0].type;
                            if ((type !== 'AUTO_REVIEWED' && type !== 'SSA_REVIEWING' && type !== 'SSA_REVIEWED') || queue.length > 1) {
                                queue.shift();
                                setTimer();
                            }
                        }, Math.max(STATUS_MIN_TIME - (Date.now() - queue[0].start), 0));
                    }
                }
            };
            setTimer();
        }
    }

    private runCalculator(): void {
        this.state = this.CALCULATOR;

        const buttons = this.buttons;
        let resetDelay = undefined;
        const fingers = 2;
        const pressed = [];
        const tick = (finger: number): void => {
            if (this.state !== this.CALCULATOR) {
                return;
            }

            const index = Math.round(Math.random() * (buttons.length - 1));
            const bi = buttons[index];

            if (resetDelay === undefined || Date.now() - resetDelay > 1000) {
                const press = true;
                if (press) {
                    buttons.splice(index, 1);
                    pressed.push(bi);

                    setTimeout(() => {
                        const pIndex = pressed.indexOf(bi);
                        if (pIndex !== -1) {
                            buttons.push(bi);
                            pressed.splice(pIndex, 1);
                        }
                    }, 100);
                }
                if (press) {
                    this.targetIndex += 1;
                    if (this.targetIndex > 30) {
                        this.word.shift();
                        this.targetIndex = 30;
                    }
                    for (let i = this.word.length; i < this.targetIndex; i += 1) {
                        const ch = { scaled: false };
                        this.word.push(ch);
                        setTimeout(() => {
                            ch.scaled = true;
                        }, 0);
                    }
                }
            }
            setTimeout(() => tick(finger), 100);
        };

        for (let i = 0, ln = fingers; i < ln; i += 1) {
            setTimeout(() => tick(i), i * 100);
        }
    }

    thinkingState = {
        stage: 0
    };

    getCharacterTransform(ch: any, i: number) {
        const index = this.word.length - i;
        if (this.state === this.CALCULATOR || (this.state === this.THINKING && this.thinkingState.stage === -1)) {
            if (ch.scaled) {
                return `translateX(${-index * 25}px)scale(1)`;
            }
            return `translateX(${-index * 25}px)scale(0)`;
        } else if (this.state === this.THINKING && this.thinkingState.stage !== -1) {
            const parent = this.thinking.nativeElement.parentNode.getBoundingClientRect();
            if (!ch.thinking_stage) {
                return `translateX(${parent.width / 4 + i * 10 - 50}px)scale(3)`;
            } else {
                if (ch.thinking_stage === 1) {
                    return `translateX(${parent.width / 4 + i * 10 - 50}px)translateY(150px)scale(0.2)`;
                } else if (ch.thinking_stage === 2) {
                    return `translateX(${parent.width / 4 + i * 10 - 50}px)translateY(80px)scale(1)`;
                }
            }
        } else if (this.state === this.EMAIL) {
            const email = this.email.nativeElement.getBoundingClientRect();
            const screen = this.screen.nativeElement.getBoundingClientRect();
            if (!ch.email_stage || ch.email_stage === 1) {
                return `rotateZ(20deg)translateX(${email.left - screen.right - email.width / 2 + i * 3 + 20 - 5}px)translateY(-30px)rotateZ(34deg)scaleY(0.01)scale(0.25)`;
            } else if (ch.email_stage === 2) {
                return `rotateZ(20deg)translateX(${email.left - screen.right - email.width / 2 + i * 3 + 20 - 5}px)translateY(-30px)rotateZ(38deg)scaleY(8)scale(0.5)`;
            }
        }
    }

    onTransitionEnd(ch: any, i) {
        if (this.state === this.THINKING) {
            if (this.thinkingState.stage > -1) {
                if (i === this.word.length - 1 && this.thinkingState.stage === 0) {
                    this.thinkingState.stage = 1;
                }
                if (ch.thinking_stage !== 1) {
                    ch.thinking_stage = 1;
                }
                setTimeout(() => {
                    ch.thinking_stage = ch.thinking_stage === 1 ? 2 : 1;
                }, Math.sin((i + 1) / (this.word.length + 1) * Math.PI) * 200 + 200);
            }
        } else if (this.state === this.EMAIL) {
            if (ch.email_stage !== 1) {
                ch.email_stage = 1;
            }
            setTimeout(() => {
                ch.email_stage = ch.email_stage === 1 ? 2 : 1;
            }, Math.sin((i + 1) / (this.word.length + 1) * Math.PI) * 200 + 200);
        }
    }

    getButtonPressedClass(index: number) {
        return this.buttons.indexOf(index) === -1;
    }

    runThinking() {
        this.state = null;
        setTimeout(() => {
            this.state = this.THINKING;
            this.thinkingState.stage = -1;

            if (this.word.length < 16) {
                for (let i = this.word.length - 1; i < 16; i += 1) {
                    this.word.unshift({});
                }
            }
            if (this.word.length > 16) {
                this.word.splice(0, this.word.length - 16)
                // console.error(this.word);
            }
            setTimeout(() => {
                this.thinkingState.stage = 0;
            }, 100);
        });
    }

    runEmail(): void {
        setTimeout(() => {
            this.state = this.EMAIL;
        });
    }

    runError(): void {
        setTimeout(() => {
            this.state = this.ERROR;
            this.hasError = true;
        });
    }

    runReviewing(): void {
        setTimeout(() => {
            this.state = this.REVIEWING;
        });
    }

    runApproved(): void {
        setTimeout(() => {
            this.state = this.APPROVED;
        });
    }

    runSsaEmailing(): void {
        setTimeout(() => {
            this.state = this.SSA_EMAILING;
        });
    }

    runSsaReviewed(): void {
        setTimeout(() => {
            this.state = this.SSA_REVIEWED;
        });
    }

    runSsaError(): void {
        setTimeout(() => {
            this.state = this.SSA_ERROR;
        });
    }

    onExitClick(): void {
        this.router.navigate(['sap']);
    }

    async onViewHistoryClick(): Promise<void> {
        if (this.session.state.approved !== null) {
            await this.accountState.openLatestSession();
        }
        this.openhistory.emit(true);
        this.queue = [];
    }

    onReviewClick(): void {
        this.queue = [];
        this.router.navigate(
            [
                'account',
                {
                    sap: this.session.state.sap
                }
            ], {
            queryParams: {
                view: 'summary'
            }
        })
    }

    async onCreateNewClick(): Promise<void> {
        await this.accountState.openLatestSession();
        this.queue = [];
    }
}
