export interface IMadError<T = any> {
    name?: string;
    code?: string;
    message?: string;
    sourceError?: Error | null;
    isSystemError?: boolean;
    context?: T | null;
    stack?: string;
}

export class MadError<T = Record<string, string>> extends Error implements IMadError<T> {
    public static readonly NAME = 'MadError';

    public readonly sourceError?: Error | null;

    public readonly context?: T | null;

    public readonly code?: string;

    public readonly isSystemError: boolean = false;

    public constructor(code: string, msg?: string, err?: Error | null, context?: T | null, isSystemError?: boolean) {
        super(msg || code);

        // Required because we are extending a built-in class
        Object.setPrototypeOf(this, MadError.prototype);

        this.name = MadError.NAME;
        this.code = code || msg;
        this.sourceError = err;
        this.context = context;
        this.isSystemError = isSystemError === undefined ? false : isSystemError;

        if (err) {
            this.stack = err.stack;
        } else {
            Error.captureStackTrace(this, this.constructor);
        }
    }

    /**
     * Used to generate toString json object that is in turn "stringified" with JSON.stringify
     */
    public toJSON(): IMadError {
        return { ...this };
    }

    public toString(): string {
        const retVal = this.toJSON();
        return JSON.stringify(retVal);
    }

    public getErrorCode(): string {
        if (this.code) {
            return this.code;
        }
        return this.message;
    }
}
