import {Component, Input, OnChanges, HostBinding} from '@angular/core';

@Component({
    selector: 'mad-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnChanges {
  @Input() icon: string = '';
  @Input() loadingIcon: string;
  //= 'fa-sync fa-spin';
  @Input() altIcon;
  @Input() type: 'button' | 'avatar' | 'link' | 'auto' = 'auto';
  @Input() size: number | string;
  @Input() showAlt: boolean;
  @Input() loading: boolean;

  @HostBinding('class') class: string;

  _iconIsFA = false;
  _altIsFA = false;
  _loadingIsFA = false;

  ngOnChanges () {
    var iconIsFA = false,
      altIsFA = false,
      loadingIsFA = false;

    if (this.icon) {
      iconIsFA = this.icon.startsWith('fa-');
    }

    if (this.altIcon) {
      altIsFA = this.altIcon.startsWith('fa-');
    }

    if (this.loadingIcon) {
      loadingIsFA = this.loadingIcon.startsWith('fa-')
    }

    this._iconIsFA = iconIsFA;
    this._loadingIsFA = loadingIsFA;
    this._altIsFA = altIsFA;

    this.class = `mad-icon mad-icon-${this.type} `;
  }

  getStyle () {
    if (this.size) {
      var size = this.size;
      if (!this._iconIsFA) {
        return {width: `${size}px`, height: `${size}px`};
      } else {
        if (typeof size === 'number' || !isNaN(parseFloat(size))) {
          size = `${size}px`;
        }
        return {'fontSize': size};
      }
    }
  }
}
