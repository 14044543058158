import { Injectable } from '@angular/core';
import { CanActivate, Router, Params, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { IAccount } from '@shared/model/account';
import { AuthState } from '../../states/auth/auth.state';
import { UserState } from '../user/user.state';
import { AccountService } from '../../services/account/account.service';
import { ReferenceService } from '../../services/reference/reference.service';
import { SessionService } from '../../services/session/session.service';
import { PanelService } from '../../services/panel/panel.service';
import { InvoiceService } from '../../services/invoice/invoice.service';
import { LIMSService } from '../../services/lims/lims.service';
import { PriceService } from '../../services/price/price.service';
import { SessionState } from '../session/session.state';
import { ErrorHandler } from '../../providers/error-handler/error-handler';
import { MadClientError } from '../../utils/client-error';

export type AccountSession = IAccount & { session?: SessionState };

@Injectable()
export class AccountState extends BehaviorSubject<AccountSession> implements CanActivate {
    public state: AccountSession;

    private lock: any;

    private sessionId: number;

    public constructor(
        public router: Router,
        public userState: UserState,
        public accountService: AccountService,
        public sessionService: SessionService,
        public panelService: PanelService,
        public invoiceService: InvoiceService,
        public limsService: LIMSService,
        public priceService: PriceService,
        public referenceService: ReferenceService,
        public authState: AuthState,
        public errorHandler: ErrorHandler
    ) {
        super({} as AccountSession);
        this.authState.authorized.subscribe((e): void => this.onAuthorized(e));
    }

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        // First ensure auth handshake is complete and user is allowed access to app.
        const result = await firstValueFrom(this.authState.canActivate(route, state));
        if (!result) {
            return false;
        }

        const sap = route.params && route.params.sap;
        const value = this.getValue();
        const sessionId = this.getSessionId(route.params);

        if (route.url[0].path === 'sap') {
            this.lock = {};
            return this.handleSAP();
        } else if (value && value.sap && value.sap === sap) {
            if (!value.session || (sessionId && value.session.state.id !== sessionId)) {
                this.lock = {};
                return await this.handleAccount(sap, sessionId);
            }
            return true;
        } else if (!sap) {
            this.errorHandler.toast('SAP_UNSPEC');
            return false;
        }
        // Else

        this.lock = {};
        return await this.handleAccount(sap, sessionId);
    }

    public getSessionId(params: Params): number | null {
        let sessionId = params && params.session;

        if (typeof sessionId === 'string') {
            try {
                sessionId = parseInt(sessionId);
            } catch (err) {
                sessionId = null;
            }
        } else if (typeof sessionId !== 'number') {
            sessionId = null;
        }
        return sessionId;
    }

    private handleSAP(): boolean {
        const value = this.getValue();
        this.next({} as AccountSession);

        value && value.session && value.session.disconnect();
        return true;
    }

    private async handleAccount(sap: string, sessionId?: number): Promise<boolean> {
        try {
            const success = await this.openAccount(sap, sessionId);

            return success;
        } catch (err) {
            // If error is thrown while checking for routing activate, just redirect
            // the user back to the SAP search screen and handle error from there.
            // NOTE: This will lose the original URL they were navigating to, but if user doesn't
            // have access to the SAP, etc, then there's not much that they will be able to do.

            console.error('Error handling account: ', err);
            if (!err.code || err.code === 'SYS_ERROR') {
                this.errorHandler.handleError(err);
                return false;
            }

            await this.router.navigate(['sap', { sap: sap }]);
            return false;
        }
    }

    /**
     * Opens the specified account.
     * Throws error if Account is not found, access denied, credit block, group not eligible.
     */
    public async openAccount(sap: string, sessionId?: number): Promise<boolean> {
        try {
            const accountSession = (await this.accountService.open(sap)) as AccountSession;
            const lock = this.lock;
            const value = this.getValue();

            if (!accountSession) {
                return false;
            }

            if (accountSession.order_block_code) {
                throw new MadClientError('ORDER_BLOCK', 'Account has ORDER_BLOCK, not opening...', null, { desc: accountSession.order_block_desc });
            }

            if (accountSession.group_code && accountSession.group_eligibility !== true) {
                throw new MadClientError('GROUP_NOT_ELIGIBLE');
            }

            // TODO: Refactor so sessionState is just injectable and can pull sessionService, etc from binding
            accountSession.session = new SessionState({
                sap: accountSession.sap,
                sessionId,
                sessionService: this.sessionService,
                panelService: this.panelService,
                invoiceService: this.invoiceService,
                limsService: this.limsService,
                priceService: this.priceService,
                referenceService: this.referenceService,
                errorHandler: this.errorHandler,
                userState: this.userState
            });

            // subscribe to new sap
            await accountSession.session.connect();

            // unsubscribe from old
            if (value && value.session) {
                await value.session.disconnect();
            }

            this.state = accountSession;
            this.next(accountSession);

            if (lock === this.lock) {
                if (accountSession.session && accountSession.session.ssa_reviewing) {
                    await this.router.navigate(['account', { sap: accountSession.sap }], { queryParams: { view: 'summary' } });
                } else if (accountSession.session && accountSession.session.vdc_reviewing) {
                    await this.router.navigate(['account', { sap: accountSession.sap }], { queryParams: { view: 'panels' } });
                } else if (accountSession.session && accountSession.session.panels.data.length) {
                    await this.router.navigate(['account', { sap: accountSession.sap }], { queryParams: { view: 'panels' } });
                } else {
                    await this.router.navigate(['account', { sap: accountSession.sap }], { queryParams: { view: 'search' } });
                }
            }

            return true;
        } catch (err) {
            console.error('Error calling openAccount: ', err);
            if (err.code) {
                throw err;
            }
            throw new MadClientError('SYS_ERROR', 'Error thrown opening account', err);
        }
    }

    public async openLatestSession(): Promise<void> {
        await this.handleAccount(this.state.sap, this.sessionId);
        this.router.navigate(['account', { sap: this.state.sap }], { queryParams: { view: 'search' } });
    }

    private onAuthorized(authorized: boolean): void {
        const value = this.getValue();
        if (!authorized && value && value.session) {
            value.session.disconnect();
        } else if (value && value.session) {
            value.session.connect();
        }
    }
}
