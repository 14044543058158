import { Component, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { SpringComponent } from '../../components/spring/spring.component';
import { FeedbackService } from '../../services/feedback/feedback.service';
import { AccountState } from '../../states/account/account.state';
import { ErrorHandler } from '../../providers/error-handler/error-handler';

export enum EMOJI {
    UNKNOWN = null,
    ANGRY = 1,
    UNHAPPY = 2,
    NEUTRAL = 3,
    SMILE = 4,
    HAPPY = 5
}

@Component({
    selector: 'mad-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: [
        './feedback.component.scss'
    ]
})
export class FeedbackComponent {
    @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

    @ViewChild('fileSpring') fileSpring: SpringComponent;

    EMOJI = EMOJI;

    feedback: string;

    rating: EMOJI = EMOJI.UNKNOWN;

    files: File[] = [];

    NONE = 0;

    LOADING = 1;

    SUCCESS = 2;

    FAILURE = 4;

    submitting = false;

    state = this.NONE;

    file_limit = 5;

    file_size: string = '0';

    mb_limit = 20;

    @Output() close = new EventEmitter();

    constructor(public feedbackService: FeedbackService, public accountState: AccountState, public error: ErrorHandler) {
    }

    getFeedbackWidth(): string {
        let width = document.body.offsetWidth;
        if (width > 1024) {
            width = 1024;
        }
        return `${width}px`;
    }

    onEmojiClick(emoji: EMOJI): void {
        if (this.rating === emoji) {
            this.rating = EMOJI.UNKNOWN;
        } else {
            this.rating = emoji;
        }
    }

    onFileChange(): void {
        const fileInput = this.fileInput;
        const files = fileInput.nativeElement.files;
        const filesLeft = this.file_limit - this.files.length;
        const fileSize = parseFloat(this.file_size);
        let sizeLeft = this.mb_limit - fileSize;
        let target = Math.min(files.length, filesLeft);
        let hasMbError = false;

        for (let i = 0; i < files.length && target > 0; i += 1) {
            const file = files[i];
            const size = file.size / 1000000;

            if (size < sizeLeft) {
                sizeLeft -= size;
                this.files.push(file);
                target--;
            } else {
                hasMbError = true;
            }
        }

        if (hasMbError) {
            this.error.toast('FILE_SIZE_LIMIT');
        }

        this.file_size = this.getTotalMb();

        this.fileInput.nativeElement.value = null;
        this.fileInput.nativeElement.files = null;

        this.fileSpring.update();
    }

    getTotalMb(): string {
        const files = this.files;
        let total = 0;
        for (let i = 0; i < files.length; i += 1) {
            total += files[i].size;
        }
        return (total / 1000000).toFixed(2).replace('.00', '');
    }

    onRemoveClick(file: File): void {
        const index = this.files.indexOf(file);
        if (index !== -1) {
            this.files.splice(index, 1);
            this.file_size = this.getTotalMb();
        }
    }

    onCloseClick(): void {
        this.close.emit(true);
    }

    async onSendClick(): Promise<void> {
        if (this.submitting) {
            return;
        }
        const minimumSpinTime = new Promise((resolve): void => {
            setTimeout(() => { resolve(true) }, 1000);
        });
        try {
            this.submitting = true;
            this.state = this.LOADING;

            const session = this.accountState.state && this.accountState.state.session;

            const result = await this.feedbackService.give(
                {
                    session: session && session.state.id || null,
                    feedback: this.feedback,
                    rating: this.rating,
                    files: this.files
                }
            ).toPromise();

            if (result.error) {
                throw result.error;
            }

            await minimumSpinTime;
            this.state = this.SUCCESS;

            setTimeout(() => {
                this.onCloseClick();
            }, 3000);
        } catch (e) {
            console.error(e);

            await minimumSpinTime;
            this.state = this.FAILURE;

            setTimeout(() => {
                this.submitting = false;
                setTimeout(() => {
                    this.state = this.NONE;
                }, 500);
            }, 3000);
        }
    }

    isSubmitDisabled(): boolean {
        return (this.files.length || this.feedback || this.rating) ? false : true;
    }
}
