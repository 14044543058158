import {Component,  Input, HostBinding, OnChanges, OnInit, ElementRef} from '@angular/core';

@Component({
    selector: 'mad-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnChanges, OnInit {
    @Input() type: 'navigation' | 'card' | 'modal' | 'toast' | 'auto' = 'auto';
    @HostBinding('class') class: string;

    constructor (public element: ElementRef) {}

    ngOnInit () {
        this.ngOnChanges();
    }

    ngOnChanges () {
        this.class = this.getClass();
    }
    getClass () {
        return `mad-header mad-header-${this.type}`;
    }
}
