import { Injectable } from '@angular/core';
import { HttpRequest, HttpInterceptor, HttpEvent, HttpHandler, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface SSEInterceptor extends HttpInterceptor { }

export class SSEBaseInterceptor implements SSEInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req);
    }
}
@Injectable()
export class SSE_INTERCEPTORS extends Array<SSEInterceptor> { }

export enum SSE_EVENT {
    OPEN = 1,
    MESSAGE = 2,
    ERROR = 3
}

export type SSEMessageEvent<DATA> = MessageEvent & { json: DATA };

export type SSEMessageEventKey = keyof SSE['eventMap'];

@Injectable()
export class SSE<DATA extends any = any> {
    protected eventSource: EventSource;

    eventMap: {
        open?: ((event: Event) => any)[];
        error?: ((event: Event) => any)[];
        message?: ((event: Event) => any)[];
    } = {};

    private sseOnError: any;
    set onerror(callback: SSE['eventMap']['error'][0]) {
        if (this.sseOnError) {
            this.removeEventListener('error', this.sseOnError);
        }
        this.sseOnError = callback;
        this.addEventListener('error', callback);
    }

    private sseOnOpen: any;
    set onopen(callback: SSE['eventMap']['open'][0]) {
        if (this.sseOnOpen) {
            this.removeEventListener('open', this.sseOnOpen);
        }
        this.sseOnOpen = callback;
        this.addEventListener('open', callback);
    }

    private sseOnMessage: any;
    set onmessage(callback: SSE['eventMap']['message'][0]) {
        if (this.sseOnMessage) {
            this.removeEventListener('message', this.sseOnMessage);
        }
        this.sseOnMessage = callback;
        this.addEventListener('message', callback);
    }

    constructor(url: string, options?: { withCredentials: boolean }) {
        this.eventSource = options ? new EventSource(url, options) : new EventSource(url);
        this.eventSource.addEventListener('open', (event: MessageEvent) => {
            this.dispatch('open', event);
        });
        this.eventSource.addEventListener('message', (event) => {
            try {
                event['json'] = JSON.parse(event.data);
            } catch (e) { }
            this.dispatch('message', event);
        });
        this.eventSource.addEventListener('error', (event) => {
            this.dispatch('error', event);
        });
    }

    private dispatch(name: SSEMessageEventKey, event: Event) {
        const map = this.eventMap[name];
        if (map) {
            for (let i = 0; i < map.length; i += 1) {
                map[i](event);
            }
        }
    }

    addEventListener<EVENT extends SSEMessageEventKey = SSEMessageEventKey>(name: EVENT, callback: SSE['eventMap'][EVENT][0]) {
        if (!callback) {
            return;
        }
        if (!this.eventMap[name]) {
            this.eventMap[name] = [];
        }
        this.eventMap[name].push(callback);
    }

    removeEventListener<EVENT extends SSEMessageEventKey = SSEMessageEventKey>(name: EVENT, callback: SSE['eventMap'][EVENT][0]) {
        const map = this.eventMap[name];
        if (!map) {
            return false;
        }

        const index = map.indexOf(callback);
        if (index !== -1) {
            map.splice(index, 1);
            return true;
        }
        return false;
    }

    close() {
        this.eventSource.close();
    }

    isOpen() {
        return this.eventSource.readyState === this.eventSource.OPEN;
    }

    isClosed() {
        return this.eventSource.readyState === this.eventSource.CLOSED;
    }

    isConnecting() {
        return this.eventSource.readyState === this.eventSource.CONNECTING;
    }
}

@Injectable()
export class SSEClient extends HttpHandler {
    constructor(protected interceptors: SSE_INTERCEPTORS) {
        super();
    }

    handle(request: HttpRequest<any>) {
        const result = new Observable<any>((sub) => {
            const auth = request.headers.get('authorization');
            if (auth) {
                const token = auth.split('Bearer ')[1];
                request = request.clone({
                    setParams: {
                        'sseToken': token
                    }
                });
            }
            const source = new SSE(request.urlWithParams, { withCredentials: true });

            source.addEventListener('error', (err) => {
                sub.error(err);
            });

            sub.next(source);
            sub.complete();
        });
        return result;
    }

    create<DATA extends any = any>(url: string, { params }: { params?: { [key: string]: any } } = {}) {
        let parsedParams;
        if (params) {
            const params2 = { ...params };
            for (const param in params2) {
                if (params2[param] === null || params2[param] === undefined) {
                    delete params2[param];
                }
            }
            parsedParams = new HttpParams({ fromObject: params2 });
        }

        const fakeRequest = new HttpRequest('GET', url, { params: parsedParams });
        const interceptors = this.interceptors;

        let last: Observable<HttpEvent<any>>;
        for (let i = 0; i < interceptors.length; i += 1) {
            last = interceptors[i].intercept(fakeRequest, this);
        }

        interface SSEEvent extends EventSource {
            onmessage: (event: SSEMessageEvent<DATA>) => ReturnType<EventSource['onmessage']>;
            addEventListener: (
                event_name: keyof EventSourceEventMap,
                callback: (event: SSEMessageEvent<DATA>) => ReturnType<EventListenerObject['handleEvent']>) => ReturnType<EventSource['addEventListener']>;
        }
        return last as any as Observable<SSEEvent>;
    }
}
