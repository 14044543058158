import {Component, TemplateRef, Input, OnChanges, ViewContainerRef, ComponentFactoryResolver, ChangeDetectorRef, ElementRef} from '@angular/core';

@Component({selector: 'mad-outlet', template: ''})
export abstract class OutletItem {
  abstract outlet: string;
  abstract visible: boolean;
  abstract template: TemplateRef<any>;
  abstract mask: boolean;
  abstract element: ElementRef;

  ngOnInit () {
    OutletComponent.add(this, this.outlet || '');
  }

  ngOnDestroy () {
    OutletComponent.remove(this, this.outlet || '');
  }
}

var outlet_map: {[key: string]: {name: string; outlet: OutletComponent; templates: OutletItem[]}} = {
}


@Component({
  selector: 'mad-outlet',
  templateUrl: './outlet.component.html',
  styleUrls: ['./outlet.component.scss']
}) export class OutletComponent implements OnChanges {
  @Input() name: string = '';

  map: (typeof outlet_map)[''];
  _height: number;

  constructor (public componentFactoryResolver: ComponentFactoryResolver, public viewContainer: ViewContainerRef,public changeDetector: ChangeDetectorRef,public element: ElementRef) {}

  ngOnInit () {
    this.ngOnChanges()
  }

  ngAfterViewChecked () {
    this._height = this.element.nativeElement.offsetHeight;
  }

  ngOnChanges () {
    var name = this.name || '',
    map = outlet_map[name];
    if (!map) {
      map = outlet_map[name] = {name, outlet: this, templates: []};
    }
    if (this.map && this.map.name !== name) {
      this.map.outlet = undefined;
      this.map = undefined;
    }
    if (!this.map) {
      this.map = outlet_map[name];
      this.map.outlet = this;
    }
  }

  static outletMap = outlet_map;

  static add (template: OutletItem, outlet: string) {
    if (!template) {
      return;
    }
    outlet = outlet || '';
    var map = outlet_map[outlet];
    if (!map) {
      map = outlet_map[outlet] = {outlet: undefined, name: outlet, templates: []};
    } 

    if (map.templates.indexOf(template) === -1) {
      map.templates.push(template);
    }

    if (map.outlet) {
      map.outlet.changeDetector.detectChanges();
    }
  }

  static remove (template: OutletItem, outlet: string) {
    outlet = outlet || '';
    var map = outlet_map[outlet];
    if (map) {
      var index = map.templates.indexOf(template);
      if (index !== -1) {
        map.templates.splice(index, 1);
      }
      if (map.outlet) {
        map.outlet.changeDetector.detectChanges();
      }
    }
  }
}

