<mad-field>
    <div (mousedown)="input.focus()">
        <label class="spot-form__checkbox spot-form__checkbox-indeterminate cl-checkbox-label-wrapper spot-form__checkbox--{{size}}">
            <input
                #input [class]="getClassName()"
                type="checkbox"
                [(ngModel)]="checked"
                [id]="_id"
                />
            <label *ngIf="isToggle()" class="spot-toggle-label" [class.cl-checkbox-toggle-focus]="isFocused" [for]="_id">
                <ng-container *ngTemplateOutlet="contentWrapper"></ng-container>
            </label>
            <span *ngIf="!isToggle()" class="spot-form__checkbox-inner">
                <span class="spot-form__checkbox-visual">
                    <svg class="spot-icon spot-form__checkbox-checked-icon" aria-labelledby="title">
                        <title></title>
                        <use xlink:href="/assets/spot/dist/icons/spot_icons.svg#checkmark"></use>
                    </svg>
                    <svg class="spot-icon spot-form__checkbox-indeterminate-icon" aria-labelledby="title">
                        <title></title>
                        <use xlink:href="/assets/spot/dist/icons/spot_icons.svg#checkbox-indeterminate"></use>
                    </svg>
                </span>
                <span class="spot-form__checkbox-label">
                    <ng-container *ngTemplateOutlet="contentWrapper"></ng-container>
                </span>
            </span>
        </label>
    </div>
</mad-field>
<ng-template #contentWrapper>
    <ng-content></ng-content>
</ng-template>
