import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MsalBroadcastService, MsalGuard, MsalService } from '@azure/msal-angular';
import { EventMessage, AuthenticationResult, AccountInfo } from '@azure/msal-browser';
import { BehaviorSubject, Observable, Subscriber, firstValueFrom } from 'rxjs';
import { IUser } from '@shared/model/user';
import { UserService } from '../../services/user/user.service';
import { WebSocket } from '../../providers/websocket/websocket';
import { ENV } from '../../globals/env';

export enum AuthStatusEnum {
    PENDING = 'PENDING',
    AUTHENTICATED = 'AUTHENTICATED',
    AUTHORIZED = 'AUTHORIZED',
    FAILURE = 'FAILURE'
}

// workaround for safari
// override window.open
const ORIGINAL_WINDOW = window['open'];
const MSAL_WINDOWS = [];

window['open'] = function(...args) {
    const result = ORIGINAL_WINDOW.apply(this, arguments) as ReturnType<Window['open']>;
    if (args[1] && args[1].startsWith('msal')) {
        MSAL_WINDOWS.push(result);
    }
    const close = result['close'];
    result['close'] = function() {
        const index = MSAL_WINDOWS.indexOf(result);
        if (index !== -1) {
            MSAL_WINDOWS.splice(index, 1);
        }
        return close.apply(this, arguments);
    };
    return result;
};

/**
 * Authorization in IRL is a two-step process.
 * - First, the user is authenticated via Azure SSO so we know who they are and if they even have access to IRL in general.
 * - Second, we test if the user is authorized to use IRL in the current environment (dev, qa, prod, etc)
 *   and what their role is within the app.
 * Unless both actions are complete, the user is not considered "logged in" to the app
 */

@Injectable()
export class AuthState {
    authorized = new BehaviorSubject<boolean>(false);

    public status = new BehaviorSubject<AuthStatusEnum>(null);

    hasRefreshedUser = false;

    isAcquireSilentPending = false;

    errorCode: string;

    errorMessage: string;

    dispatchTimer: any;

    constructor(
        public broadcastService: MsalBroadcastService,
        public msalGuard: MsalGuard,
        public msalService: MsalService,
        public userService: UserService,
        public websocket: WebSocket
    ) {
        const me = this;

        broadcastService.msalSubject$.subscribe((event: EventMessage): void => {
            me.handleMsalEvent(event);
        });

        // Once user has been authorized by Azure and authenticated by IRL,
        // connect websocket and notify any listeners that the user has been authorized to use IRL.
        this.status.subscribe(async (status: AuthStatusEnum): Promise<void> => {
            try {
                if (status === AuthStatusEnum.AUTHORIZED) {
                    try {
                        await this.websocket.connect();
                        if (this.authorized.getValue() !== true) {
                            me.dispatch('auth', true);
                        }
                    } catch (err) {
                        console.error('Error calling websocket.connect: ', err);
                    }
                } else if (status === AuthStatusEnum.FAILURE) {
                    console.warn('Calling disconnect when status went to state: ', status);
                    this.websocket.disconnect();
                }
            } catch (err) {
                console.error('Error in status.subscribe: ', err);
            }
        });
    }

    handleMsalEvent(event: EventMessage): void {
        switch (event.eventType as typeof event.eventType) {
            case 'msal:acquireTokenStart':
                this.isAcquireSilentPending = true;
                break;
            case 'msal:ssoSilentStart':
            case 'msal:loginStart':
                this.start();
                break;
            case 'msal:handleRedirectEnd':
                setTimeout(() => {
                    if (this.getStatus() === null) {
                        this.failure(event.eventType);
                    }
                }, 1000);
                break;
            case 'msal:acquireTokenFailure':
                this.isAcquireSilentPending = false;
            case 'msal:ssoSilentFailure':
            case 'msal:loginFailure':
                this.failure(event.eventType);
                break;
            case 'msal:acquireTokenSuccess':
                this.isAcquireSilentPending = false;
            case 'msal:ssoSilentSuccess':
            case 'msal:loginSuccess':
                this.success(event.eventType);
                break;
            case 'msal:logoutSuccess':
                this.failure(event.eventType);
                break;
            default: break;
        }
    }

    // This call is what determines if the user has access to IRL.
    // If it succeeds, then user has all necessary groups assigned for this environment (dev, qa, etc)
    // If it fails, it's usually because either the user doesn't have access to this environment, or
    //     the jwt token has expired.
    // If the token has expired, should be able to call with fresh data and retry (need to find where this
    //     takes place)
    // If user doesn't have access to environment, then this is a critical error and the client
    //     should stop calling to backend.
    async updateUser(accessToken: string): Promise<IUser> {
        return await this.userService.update({ token: accessToken });
    }

    async loginPopup(): Promise<AuthenticationResult> {
        let result: AuthenticationResult;
        try {
            result = await firstValueFrom(this.msalService.loginPopup());
        } catch (e) {
            for (let i = 0, ln = MSAL_WINDOWS.length; i < ln; i += 1) {
                if (!MSAL_WINDOWS[i] || MSAL_WINDOWS[i].closed) {
                    MSAL_WINDOWS.splice(i--, 1);
                    ln -= 1;
                }
            }
            if (e.errorCode === 'interaction_in_progress') {
                if (!MSAL_WINDOWS.length) {
                    document.cookie = 'msal.interaction.status=; Max-Age=0';
                    result = await firstValueFrom(this.msalService.loginPopup());
                } else {
                    MSAL_WINDOWS[0].focus();
                }
            }
        }
        return result;
    }

    start(): void {
        this.dispatch('status', AuthStatusEnum.PENDING);
    }

    async success(type: string): Promise<void> {
        // MSAL reports successful connect.
        // Now must check backend authorization to confirm user has access to "this" environment
        // before considering user authorized...

        // refresh user info
        // add first run status
        if (type !== 'msal:acquireTokenSuccess' || !this.hasRefreshedUser) {
            this.hasRefreshedUser = true;
            const result = await this.msalService.instance.acquireTokenSilent({
                scopes: ['User.Read', 'profile', 'openid'],
                account: this.getActiveAccount(this.msalService.instance.getAllAccounts())
            });

            this.dispatch('status', AuthStatusEnum.AUTHENTICATED);

            try {
                await this.updateUser(result.accessToken);

                // If updateUser succeeds, then the user is authorized and all set to use app.
                // Set status and auth flags to notify any listeners of this.

                this.dispatch('status', AuthStatusEnum.AUTHORIZED);
                this.dispatch('auth', true);
            } catch (err) {
                console.error('Error calling userService: ', err);
                // httpAuthError below will be called by http.interceptor.  No need to set status to failed twice.
                // this.dispatch('status', AuthStatusEnum.FAILURE);
            }
        }
    }

    failure(type: string): void {
        console.error('auth.state.failure: ', type);
        this.hasRefreshedUser = false;

        this.dispatch('status', AuthStatusEnum.FAILURE);

        this.dispatch('auth', false);
    }

    dispatch(field: 'auth' | 'status', value: any): void {
        let latestStatus: AuthStatusEnum;
        let latestAuth: boolean;
        if (field === 'auth') {
            latestAuth = value;
            if (this.authorized.getValue() === value) {
                return;
            }
        } else if (field === 'status') {
            latestStatus = value;
            if (this.getStatus() === value) {
                return;
            }
        }

        if (!this.dispatchTimer) {
            this.dispatchTimer = setTimeout(() => {
                this.dispatchTimer = null;
                if (latestStatus) {
                    const nextStatus = latestStatus;
                    latestStatus = null;
                    this.setStatus(nextStatus);
                }
                if (latestAuth !== undefined) {
                    const nextAuth = latestAuth;
                    latestAuth = null;
                    this.setAuthorized(nextAuth);
                }
            });
        }
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return new Observable<boolean>((sub): void => {
            const result = this.msalGuard.canActivate(route, state);

            result.subscribe((activated: boolean): void => {
                if (!activated || !this.authorized.value || this.authorized.getValue() === false) {
                    // User not yet authenticated or authorized.  Kick off the process if needed.
                    this.authorize(state, sub);
                } else {
                    // User is authenticated.
                    sub.next(true);
                    sub.complete();
                }
            });
        });
    }

    async authorize(state: RouterStateSnapshot, sub: Subscriber<boolean>): Promise<void> {
        const authSubscription = this.authorized.subscribe(async (value: boolean): Promise<void> => {
            if (value === true) {
                authSubscription.unsubscribe();
                window.location.hash = state.url;

                sub.next(true);
                sub.complete();
            }

            if (!this.isAcquireSilentPending) {
                this.isAcquireSilentPending = true;
                const activeAccount = this.getActiveAccount(this.msalService.instance.getAllAccounts());
                if (activeAccount) {
                    this.msalService.acquireTokenSilent({
                        scopes: ['User.Read', 'profile'],
                        account: activeAccount
                    });
                } else {
                    this.loginPopup();
                }
            }
        });
    }

    setStatus(status: AuthStatusEnum): void {
        if (this.status.getValue() !== status) {
            this.status.next(status);
        }
    }

    getStatus(): AuthStatusEnum {
        return this.status.getValue();
    }

    setAuthorized(authorized: boolean): void {
        if (this.authorized.getValue() !== authorized) {
            this.authorized.next(authorized);
        }

        if (!authorized) {
            // Allow user to try again when something went wrong
            this.hasRefreshedUser = false;
        }
    }

    getAuthorized(): boolean {
        return this.authorized.getValue();
    }

    // Called from http.interceptor
    public httpAuthError(errorResponse: HttpErrorResponse): void {
        console.error('httpAuthError: ', errorResponse);

        // This method is called from http.interceptor when a 401 or 403 error is thrown
        // Check error.  If is a 401 error, this could happen if jwt token is expired and should be retried.
        // If it's a 403 error, it means user isn't set up in Azure/SSO to access IRL in this environment

        this.errorCode = this.getThrownErrorCode(errorResponse);

        if (errorResponse.status === 401) {
            this.errorMessage = 'User not authorized';
        } else if (errorResponse.status === 403) {
            this.errorMessage = 'Your user account is not configured to use this application';

            // User doesn't have access to app, don't try to reconnect constantly...
            // this.websocket.reconnect = false;
        }

        this.setAuthorized(false);
        this.setStatus(AuthStatusEnum.FAILURE);
    }

    private getActiveAccount(accounts: AccountInfo[]): AccountInfo | null {
        if (!accounts || accounts.length === 0) {
            return null;
        }
        const foundAccount = accounts.find((acct: AccountInfo): boolean => {
            return (acct.idTokenClaims && acct.idTokenClaims['aud'] === ENV.SSO_CLIENT_ID);
        });
        if (!foundAccount) {
            console.error('IRL account not found');
        }
        return foundAccount;
    }

    private getThrownErrorCode(errorResponse: HttpErrorResponse): string {
        const error = errorResponse && errorResponse.error ? errorResponse.error.error : null;
        if (typeof error === 'string') {
            return error;
        }
        if (error && error.code) {
            return error.code;
        }
        return null;
    }

    async getAccessToken(): Promise<string> {
        const result = await this.msalService.instance.acquireTokenSilent({
            scopes: ['User.Read', 'profile', 'openid'],
            account: this.getActiveAccount(this.msalService.instance.getAllAccounts())
        });
        console.log('tokenSilent resp=', result);
        return result.accessToken;
    }
}
