import {Component,  Input, HostBinding, OnChanges, OnInit} from '@angular/core';

@Component({
    selector: 'mad-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnChanges, OnInit {
    @Input() type: 'navigation' | 'card' | 'modal' | 'toast' | 'auto' = 'auto';
    @HostBinding('class') class: string;

    ngOnInit () {
        this.ngOnChanges();
    }

    ngOnChanges () {
        this.class = this.getClass();
    }

    getClass () {
        return `mad-footer mad-footer-${this.type}`;
    }
}
