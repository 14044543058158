<mad-overlay [busy]="alertService.busy" [busyMessage]="alertService.busyMsg" align="center" messageAlign="vertical" overlayStyle="bold"></mad-overlay>
<mad-watermark *ngIf="showWatermark" [envName]="envName"></mad-watermark>

<mad-navigation-layout>
  <div *ngIf="updateRequired" class="spot-message priority-high--success animated" style="z-index: 100">
    <div class="message--content">
      <div class="message--body">
        <a href="javascript:void(0)" (click)="onUpdateClick()" class="update-required-header">The application has been updated. Complete any open enrollments and click here to refresh.</a>
      </div>
    </div>
    <div class="icon-tab">
      <svg class="spot-icon" aria-labelledby="title">
        <title>alert</title>
        <use xlink:href="/assets/spot/dist/icons/spot_icons.svg#alert-notification"></use>
      </svg>
    </div>
  </div>
  <div *ngIf="systemMessage" class="spot-message priority-high--danger animated" style="z-index: 100">
    <div class="message--content">
      <div class="message--body">Panel Builder will have a maintence window on Nov 23 between 8:00 and 10:00 PM EST <a href="#random-45101" class="spot-link">View</a></div>
    </div>
    <div class="icon-tab">
      <svg class="spot-icon" aria-labelledby="title">
        <title>bell</title>
        <use xlink:href="/assets/spot/dist/icons/spot_icons.svg#bell"></use>
      </svg>
    </div>
  </div>
  <!-- <a  href="javascript:void(0)" (click)="onUpdateClick()" class="update-required-header">The application has been updated.  Complete any ongoing operations and click here to refresh.</a> -->

  <mad-env-header *ngIf="showEnvironment" [envName]="envName"></mad-env-header>
  <mad-header #header white class="app-header" [class.init]="!initCalled" [class.sap]="isSap()" [class.authorized]="isAuthSuccess()">
    <mad-body>
      <h1 #title class="title" madTypography="heading-2" [style]="getTitleStyle()" (transitionend)="onTransitionEnd($event)">
        <div class="icon-wrapper" (click)="onIconClick()">
          <svg #iconYellow class="yellow" width="38.684210526" height="45" viewBox="0 0 196.18967 228.03061" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
            <defs id="defs2">
              <linearGradient id="linearGradient39309" inkscape:swatch="solid"><stop style="stop-color: #000000; stop-opacity: 1" offset="0" id="stop39307" /></linearGradient>
              <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5384"><use x="0" y="0" xlink:href="#g5380" id="use5386" width="100%" height="100%" transform="translate(-361.63917,176.90722)" /></clipPath>
            </defs>
            <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1" transform="translate(-67.118317,249.77051)">
              <path style="fill: #000000; fill-opacity: 0; stroke: #83ccda; stroke-width: 12; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 4; stroke-dasharray: none; stroke-opacity: 1" d="m 96.618561,-21.773196 c 0,0 0.9166,-165.122454 0,-172.144334 -0.83432,-6.39155 -6.85088,-15.58837 -12.93358,-25.17089 -7.40653,-11.66804 -15.82252,-23.36263 -6.11797,-24.15901 10.18897,-0.83613 169.110269,-0.71238 177.587629,0.68041 4.49554,0.7386 1.10074,6.20152 -4.01445,15.58471 -3.75877,6.89499 -13.97511,19.06933 -16.39792,30.68333 -1.70945,8.19441 -1.62537,164.690926 -1.36082,174.185578" id="path363" sodipodi:nodetypes="cssssssc" />
              <ellipse style="fill: #83ccda; fill-opacity: 1; fill-rule: evenodd; stroke-width: 0.276062" id="path1736" cx="149.01031" cy="-176.39693" rx="12.247422" ry="12.417525" />
              <ellipse style="fill: #83ccda; fill-opacity: 1; fill-rule: evenodd; stroke-width: 0.397915" id="path1736-9" cx="184.73198" cy="-137.10309" rx="17.690722" ry="17.860825" />
              <g inkscape:label="Clip" id="g5380" style="fill: #83ccda; fill-opacity: 1" transform="matrix(0.88603984,0,0,0.87704918,339.32706,-162.35086)"><path id="path1736-9-7" style="fill: #83ccda; fill-opacity: 1; fill-rule: evenodd; stroke-width: 0.407439" d="m -196.63918,122.81443 a 18.201031,18.201031 0 0 1 -18.20103,18.20103 18.201031,18.201031 0 0 1 -18.20103,-18.20103 18.201031,18.201031 0 0 1 18.20103,-18.20103 18.201031,18.201031 0 0 1 18.20103,18.20103 z m -58.85566,-45.927836 h 119.41237 V 159.8969 h -119.41237 z" /></g>
            </g>
          </svg>
          <svg #iconWhite class="white" width="38.684210526" height="45" viewBox="0 0 196.18967 228.03061" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
            <defs id="defs2">
              <linearGradient id="linearGradient39309" inkscape:swatch="solid"><stop style="stop-color: #000000; stop-opacity: 1" offset="0" id="stop39307" /></linearGradient>
              <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5384"><use x="0" y="0" xlink:href="#g5380" id="use5386" width="100%" height="100%" transform="translate(-361.63917,176.90722)" /></clipPath>
            </defs>
            <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1" transform="translate(-67.118317,249.77051)">
              <path style="fill: #000000; fill-opacity: 0; stroke: #83ccda; stroke-width: 12; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 4; stroke-dasharray: none; stroke-opacity: 1" d="m 96.618561,-21.773196 c 0,0 0.9166,-165.122454 0,-172.144334 -0.83432,-6.39155 -6.85088,-15.58837 -12.93358,-25.17089 -7.40653,-11.66804 -15.82252,-23.36263 -6.11797,-24.15901 10.18897,-0.83613 169.110269,-0.71238 177.587629,0.68041 4.49554,0.7386 1.10074,6.20152 -4.01445,15.58471 -3.75877,6.89499 -13.97511,19.06933 -16.39792,30.68333 -1.70945,8.19441 -1.62537,164.690926 -1.36082,174.185578" id="path363" sodipodi:nodetypes="cssssssc" />
              <ellipse style="fill: #83ccda; fill-opacity: 1; fill-rule: evenodd; stroke-width: 0.276062" id="path1736" cx="149.01031" cy="-176.39693" rx="12.247422" ry="12.417525" />
              <ellipse style="fill: #83ccda; fill-opacity: 1; fill-rule: evenodd; stroke-width: 0.397915" id="path1736-9" cx="184.73198" cy="-137.10309" rx="17.690722" ry="17.860825" />
              <g inkscape:label="Clip" id="g5380" style="fill: #83ccda; fill-opacity: 1" transform="matrix(0.88603984,0,0,0.87704918,339.32706,-162.35086)"><path id="path1736-9-7" style="fill: #83ccda; fill-opacity: 1; fill-rule: evenodd; stroke-width: 0.407439" d="m -196.63918,122.81443 a 18.201031,18.201031 0 0 1 -18.20103,18.20103 18.201031,18.201031 0 0 1 -18.20103,-18.20103 18.201031,18.201031 0 0 1 18.20103,-18.20103 18.201031,18.201031 0 0 1 18.20103,18.20103 z m -58.85566,-45.927836 h 119.41237 V 159.8969 h -119.41237 z" /></g>
            </g>
          </svg>
        </div>
        <span class="title-text">
          <b class="title-idexx">IDEXX Reference Laboratories</b>
          &nbsp;Panel Builder
        </span>
      </h1>

      <div #idexxLogo style="position: absolute; left: 50%; height: 1px; width: 1px; top: 100%; margin-top: -2px"></div>
      <div class="idexx-logo-wrapper">
        <img src="/assets/images/idexx.png" height="25">
      </div>

      <div class="digital-identity">
        <div class="spot-di">
          <div #user class="user spot-di__header">
            <span #signIn>
              <mad-button type="primary" *ngIf="!isAuthSuccess()" (click)="onSignIn()">Sign in</mad-button>
            </span>
            <span *ngIf="isAuthSuccess()" class="user-wrapper">
              <div class="popover-link" [madPopover]="{popover:popover, hideOnBlur: true, showOnFocus: true, content: userOptions}">
                <mad-link class="name">{{msal?.instance?.getAllAccounts()[0]?.name}}</mad-link>
              </div>
            </span>
          </div>
          <div class="spot-popover spot-popover--no-inset spot-popover--align-rightcaret" id=""></div>
        </div>
      </div>

      <mad-popover class="white opaque" #popover position="bottom-center"> </mad-popover>

      <ng-template #userOptions>
        <div class="user-popover">
          <mad-link *ngIf="showTrainingLink" class="link" (click)="goTraining()"> <mad-icon icon="fa-users"></mad-icon>Training site</mad-link>
          <mad-link *ngIf="showProdLink" class="link" (click)="goProd()"> <mad-icon icon="fa-users"></mad-icon>Exit Training</mad-link>
          <mad-link *ngIf="userState?.state?.is_admin" class="link" [madPopover]="{popover: popover, target: user, showOnClick: true, hideOnBlur: true, content: configure}">
            <mad-icon icon="fa-cog"></mad-icon>
            Configure
          </mad-link>
          <mad-link class="link" (click)="feedbackVisible = true" [madPopover]="{popover: popover, target: idexxLogo, showOnClick: true, hideOnBlur: true, type: 'bubble', content: feedback}">
            <mad-icon icon="fa-comment"></mad-icon>
            Feedback?
          </mad-link>
          <mad-link *ngIf="showAdminLink" class="link" (click)="goAdmin()" >
            <mad-icon icon="fa-cogs"></mad-icon>
            Admin
          </mad-link>
          <mad-link class="link" (click)="onSignOut()"> <mad-icon icon="fa-sign-out-alt"></mad-icon>Sign out </mad-link>
        </div>
      </ng-template>
    </mad-body>
  </mad-header>
  <router-outlet></router-outlet>
  <mad-outlet></mad-outlet>
  <ng-template #feedback>
    <mad-feedback (close)="popover.hide()"></mad-feedback>
  </ng-template>
  <ng-template #configure>
    <div madTypography="header-3" style="padding-left: 24px; padding-top: 10px">
      <mad-link [madPopover]="{popover: popover, target: user, showOnClick: true, hideOnBlur: true, content: userOptions}">
        <mad-icon icon="fa-arrow-left"></mad-icon>
      </mad-link>
      Configure
    </div>
    <div style="padding: 24px; color: #1e1e1f; padding-top: 74px; min-width: 20em">
      <mad-input [(ngModel)]="IRL.services.params.imposter">
        <mad-label style="top: -0.5em">Request&nbsp;services&nbsp;as&nbsp;VDC&nbsp;(userId)</mad-label>
      </mad-input>

      <br />
      <!--<mad-checkbox-group style="margin-top:2em;position:relative;display:flex;" [ngModel]="getViewUI()" (ngModelChange)="updateViewUI($event)">-->
		<mad-label position="top" style="margin-top: 2em; margin-bottom: 1em">View UI as:</mad-label>
		<mad-checkbox value="ssa" [ngModel]="userState?.state?.is_ssa" (ngModelChange)="updateViewUI('ssa',$event)">
          <mad-label position="left">SSA</mad-label>
		</mad-checkbox>
		<mad-checkbox value="admin" [ngModel]="userState?.state?.is_admin" (ngModelChange)="updateViewUI('admin',$event)">
          <mad-label position="left">Admin</mad-label>
		</mad-checkbox>
		<!--</mad-checkbox-group>-->
    </div>
  </ng-template>
  <div *ngIf="!isAuthSuccess()" class="authorized-mask mad-sso">
    <div *ngIf="isAuthFailure()" class="authorized-mask-inner" madTypography="header-2">
      Please select Sign In to continue.
      <br />
      <br />
      <mad-icon *ngIf="errorMessage" icon="fa-exclamation-triangle" style="color:red;margin-right:0.5em;"></mad-icon>
      Access to account not allowed, please reach out to <a href="mailto:salestools@idexx.com">salestools@idexx.com</a> for assistance. 
      <span *ngIf="errorCode" style="text-align: right; font-size: 0.35em; font-style: italic"> [ {{errorCode}} ]</span>
    </div>
    <div *ngIf="isAuthPending()" class="authorized-mask-inner" madTypography="header-2">Connecting...&nbsp;&nbsp;&nbsp;</div>
    <div *ngIf="isAuthSsoSuccess()" class="authorized-mask-inner" madTypography="header-2">Authorizing...&nbsp;&nbsp;&nbsp;</div>
  </div>
</mad-navigation-layout>
