import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'mad-filter-selection',
    templateUrl: './filter-selection.component.html',
    styleUrls: ['./filter-selection.component.scss']
})
export class FilterSelectionComponent {
    @Input() type: 'default' | 'highpriority' | 'teal' = 'default';
    @Input() cancel: boolean;
    @Input() size: 'small' | 'medium' = 'small';
    @Output() remove = new EventEmitter<Event>();

    onRemoveClick ($event: Event) {
        this.remove.emit($event);
    }
}
