import {BehaviorSubject} from 'rxjs';
import {delay} from 'rxjs/operators'
//helper to abstract complexities of paging
export class InfinitePager<T = any> {
    targetPageSize = 40;
    lock = {};
    getData: (top: number, limit: number) => Promise<T[]>;
    getTotal: () => Promise<number>;
    scrollBufferInterval = 100;
    totalBufferInterval = 50;

    results: {lock: any, data: T}[] = [];

    _scroll_promise: Promise<T[][]>;
    _scroll_timer: any;
    _gaps: {top: number; limit: number}[]
    _defer_reset = false;
    _total_timer = null;
    _nocache = false;
    _grow = false;

    total = new BehaviorSubject(0)

    
    constructor ({
        scrollBufferInterval,
        totalBufferInterval,
        getData,
        getTotal,
        grow,
        targetPageSize,
        nocache, //results are not cached/instead passed through directly to infinite list
        total
    }:{
        getData: InfinitePager<T>['getData'];
        getTotal?: InfinitePager<T>['getTotal'];
        totalBufferInterval?: InfinitePager<T>['totalBufferInterval'];
        scrollBufferInterval?: InfinitePager<T>['scrollBufferInterval'];
        targetPageSize?: InfinitePager<T>['targetPageSize'];
        nocache?: boolean;
        total?: number;
        grow?: boolean;
    }) {
        if (typeof scrollBufferInterval === 'number') {
            this.scrollBufferInterval = scrollBufferInterval;
        } 

        if (typeof totalBufferInterval === 'number') {
            this.totalBufferInterval = totalBufferInterval;
        } 

        if (getData) {
            this.getData = getData;
        } 

        if (getTotal) {
            this.getTotal = getTotal;
        } 

        if (nocache) {
            this._nocache = nocache;
        }
        if (grow) {
            this._grow = grow;
        }

        if (typeof targetPageSize === 'number') {
            this.targetPageSize = targetPageSize;
        } 

        if (typeof total === 'number') {
            this.total.next(total);
        }

    }

    setTotal (total: number) {
        if (this._total_timer) {
            clearTimeout(this._total_timer);
        } 
        var dispatch = () => {
            this._total_timer = null;
            this.total.next(total);
        };
        if (this.totalBufferInterval) {
            this._total_timer = setTimeout(dispatch, this.totalBufferInterval)
        } else {
            dispatch();
        }
        
    }

    reset (defer=true) {
        this.lock = {};
        if (defer) {
            this._defer_reset = true;
        } else {
            this.results = [];
        }
    }

    invalidate () {
        this.lock = {};
    }

    async get (start: number, end: number) {
        var results = this.results,
            gaps: {top: number; limit: number}[] = [],
            last: typeof gaps[0] = null,
            lock = this.lock,
            total = this.total.value as number,
            target = this.targetPageSize,
            nocache = this._nocache;

        if (!results) {
            return [];
        } 

        if (nocache) {
            if (this._scroll_timer) {
                clearTimeout(this._scroll_timer);
                this._scroll_timer = null;
            }
            return new Promise<T[]>((resolve) => {
                if (this.scrollBufferInterval) {
                    this._scroll_timer = setTimeout(async () => {
                        var result = await this.getData(start, (end - start + 1));
                        resolve(result);
                    }, this.scrollBufferInterval)
                } else {
                    resolve(this.getData(start, (end - start + 1)));
                }
            });
        } else {
            for (var i=Math.max(start, 0), ln=Math.min(end+1, total);i< ln;i++) {
                if (results[i] && results[i].lock === lock) {
                    last = null
                } else if (!last) {
                    gaps.push(last  = {top: i, limit: 1});
                } else {
                    last.limit++;
                }
            }

            var first = gaps[0],
                last = gaps[gaps.length - 1];

            if (first) {
                if (first.limit < target) {
                    var sub_target = target;
                    if (first === last && first.top === start && first.top + first.limit === end + 1) {
                        sub_target = Math.ceil(target / 2);
                    }
                    for (var i=first.top-1;i>=0 && first.limit < sub_target;i--) {
                        if (!results[i] || results[i].lock !== lock) {
                            if (first.top - 1 < 0) {
                                break;
                            }
                            first.top--;
                            first.limit++
                        } else {
                                break;
                            }
                    }
                }
                if (last.limit < target) {
                    var sub_target = target;
                    if (first === last && first.top === start && first.top + first.limit === end + 1) {
                        sub_target = Math.ceil(target / 2)
                    }
                    for (var i=(last.top+last.limit),ln=total;i<ln && last.limit < sub_target;i++) {
                        if (!results[i] || results[i].lock !== lock) {
                            last.limit++;
                        } else {
                            break;
                        }
                    }
                }
            }

            if (!gaps.length) {
                return results.slice(start, end+1).map(v=>v && v.data);
            } else {
                this._gaps = gaps;
                if (!this._scroll_promise) {
                    this._scroll_promise = new Promise<T[][]>((resolve) => {

                        var dispatch = async () => {
                            if (this.lock !== lock) {
                                resolve(null);
                            } 

                            var gaps = this._gaps,
                                rows = this.getData;

                            this._scroll_timer = null;
                            this._scroll_promise = null;
                            this._gaps = null;

                            for (var i=0,ln=gaps.length;i<ln;i++) {
                                var gap = gaps[i],
                                    top = gap.top,
                                    limit = gap.limit;

                                for (var j=top,jln=top+limit;j<jln;j++) {
                                    if (!results[j]) {
                                        results[j] = {lock, data: undefined};
                                    }
                                }
                            }

                            try {
                                var result = await Promise.all(
                                    gaps.map(({top,limit})=>rows(top,limit))
                                );
                            } catch (e) {
                                if (this.lock === lock) {
                                    for (var i=0,ln=gaps.length;i<ln;i++) {
                                        var gap = gaps[i],
                                            top = gap.top,
                                            limit = gap.limit;

                                        for (var j=top,jln=top+limit;j<jln;j++) {
                                            if (!results[j]) {
                                                results[j] = undefined;
                                            }
                                        }
                                    }
                                }
                                resolve(null);
                                return;
                            }

                            if (this.lock !== lock) {
                                resolve(null);
                                return;
                            }

                            if (this._defer_reset) {
                                this._defer_reset = false;
                                results = this.results = [];
                            }

                            for (var i=0,ln=result.length;i<ln;i++) {
                                var gap = gaps[i],
                                    top = gap.top,
                                    set = result[i] as any[];

                                for (var j=0,jln=set.length;j<jln;j++) {
                                    var index = j + top,
                                        item = results[index];
                                    if (!item || item.data === undefined) {
                                        item = results[index] = {lock, data: set[j]} as any;
                                    }
                                }
                            }

                            resolve(result);
                        }
                        if (this.scrollBufferInterval) {
                            this._scroll_timer = setTimeout(dispatch, this.scrollBufferInterval);
                        } else {
                            dispatch()
                        }
                    });
                }
                await this._scroll_promise;
                return results.slice(start, end + 1).map(v=>v && v.data);
            }


        }
    }
}
