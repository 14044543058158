import { Component, Input, OnChanges, OnInit } from "@angular/core";

@Component({
    selector: 'mad-overlay',
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit, OnChanges {

    //TODO: Add fadeIn/fadeOut animations

    @Input()
    busy: boolean;

    @Input()
    busyMessage: string;

    @Input()
    overlayStyle: "bold" | "normal" = "bold";

    @Input()
    align: "center" | "left" | "right" = "center";

    // Determines if message appears below animation icon or next to.
    @Input()
    messageAlign: "vertical" | "horizontal" = "vertical";

    constructor() { }

    ngOnInit(): void {
        this.calcStyle();
    }

    ngOnChanges(): void {
        this.calcStyle();
    }

    calculatedStyle: string;

    calcStyle() {
        let style = '';

        if (this.overlayStyle == 'bold') {
            style += ' overlay-bold';
        } else {
            style += ' overlay-normal';
        }

        if (this.messageAlign == 'vertical') {
            style += ' message-vertical';
        } else {
            style += ' message-horizontal';
        }

        if (this.align == 'center') {
            style += ' align-overlay-center';
        } else if (this.align == 'left') {
            style += ' align-overlay-left';
        } else {
            style += ' align-overlay-right';
        }

        this.calculatedStyle = style;
    }

}
