import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {ResizeModule} from './../resize/resize.module';
import {NavigationComponent} from './navigation.component';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     ResizeModule
  ],
  declarations: [
    NavigationComponent,
  ],
  exports: [
    NavigationComponent,
  ],
  bootstrap: [NavigationComponent]
})
export class NavigationModule { }
