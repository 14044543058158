import { Component, Input, ViewChild, ElementRef, AfterContentChecked, HostBinding } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Field } from '../field/field.component';

@Component({
    selector: 'mad-link',
    templateUrl: './link.component.html',
    styleUrls: ['./link.component.scss'],
    providers: Field.Providers(LinkComponent)
})
export class LinkComponent extends Field implements AfterContentChecked {
    @Input() href = this.sanitizer.bypassSecurityTrustUrl('javascript:void(0)');

    //@Input() size: 'large' | 'small' | 'default' = null;
    //@Input() ellipsis: boolean = false;
    //@Input() showVisited: boolean = false;

    @Input() target: string;

    @Input() linkClass: string;

    @Input() title: string = "";

    @ViewChild('link') link: ElementRef;

    @ViewChild('contentWrapper') contentWrapper: ElementRef;

    @ViewChild('leftIconWrapper') leftIconWrapper: ElementRef;

    @ViewChild('rightIconWrapper') rightIconWrapper: ElementRef;

    @HostBinding('class') class: string;

    _iconOnly = false;

    _leftIcon = false;

    _rightIcon = false;

    //aot dependency injection seems to break when constructor inheritance is required
    constructor(public element: ElementRef, public sanitizer: DomSanitizer) {
        super(element);
    }

    ngAfterContentChecked() {
        if (this.contentWrapper && !this.contentWrapper.nativeElement.childNodes.length) {
            this._iconOnly = true;
        } else {
            this._iconOnly = false;
        }
        if (this.leftIconWrapper && this.leftIconWrapper.nativeElement.childNodes.length) {
            this._leftIcon = true;
        } else {
            this._leftIcon = false;
        }
        if (this.rightIconWrapper && this.rightIconWrapper.nativeElement.childNodes.length) {
            this._rightIcon = true;
        } else {
            this._rightIcon = false;
        }
        this.ngOnChanges();
    }

    ngOnChanges() {
        this.class = this.getClass();
    }

    getClass() {
        return `mad-link
            ${this._iconOnly && 'mad-icon-only' || ''}
            ${this._rightIcon && 'mad-right-icon'}
            ${this._leftIcon && 'mad-left-icon'}
            ${this.disabled && 'disabled' || ''}`
    }

    focusInput() {
        this.link.nativeElement.focus();
    }

    blurInput() {
        this.link.nativeElement.blur();
    }

    getTabIndex() {
        if (this.disabled && (!this.link || document.activeElement !== this.link.nativeElement)) {
            return -1;
        } else {
            return 0;
        }
    }

}
