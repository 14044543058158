<ng-container *ngTemplateOutlet="templateLabel"></ng-container>
<ng-container *ngTemplateOutlet="templateMicro"></ng-container>
    <ng-content></ng-content>
<ng-container *ngTemplateOutlet="templateError"></ng-container>
<ng-template #templateLabel>
    <ng-content select="mad-label"></ng-content>
    <mad-label *ngIf="parentField.label?.length">{{parentField.label}}</mad-label>
    <ng-container *ngIf="parentField?.label?.createEmbeddedView">
        <ng-container *ngTemplateOutlet="parentField.label"></ng-container>
    </ng-container>
</ng-template>

<ng-template #templateMicro>
    <ng-content select="mad-micro"></ng-content>
    <mad-micro *ngIf="parentField.micro?.length">{{parentField.micro}}</mad-micro>
    <ng-container *ngIf="parentField?.micro?.createEmbeddedView">
        <ng-container *ngTemplateOutlet="parentField.micro"></ng-container>
    </ng-container>
</ng-template>
<ng-template #templateError>
    <ng-content select="mad-error"></ng-content>
    <div *ngIf="parentField.error?.length || parentField.contentErrors?.length" class="spot-form__field-error mad-field-error">
        <mad-error *ngFor="let text of parentField.getErrors()">
            {{text}}
        </mad-error>
        <ng-container *ngIf="parentField?.error?.createEmbeddedView">
            <ng-container *ngTemplateOutlet="parentField.error"></ng-container>
        </ng-container>
    </div>
</ng-template>
