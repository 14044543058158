import {NgModule} from '@angular/core';
import {FeedbackModule} from './feedback/feedback.module';

@NgModule({
  imports: [
      FeedbackModule
  ],
  providers: [
  ],
  declarations: [
  ],
  exports: [
      FeedbackModule
  ],
  bootstrap: [
    FeedbackModule
  ]
})
export class SSAModule { }
