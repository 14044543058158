import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {ComponentsModule} from './../../components/components.module';
import {SubmitProcessComponent} from './submit-process.component';
import {TranslocoModule} from '@ngneat/transloco';
import {AccountState} from './../../states/account/account.state';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     ComponentsModule,
     TranslocoModule
  ],
  providers: [
      AccountState
  ],
  declarations: [
      SubmitProcessComponent
  ],
  exports: [
    SubmitProcessComponent
  ],
  bootstrap: [SubmitProcessComponent]
})
export class SubmitProcessModule { }
