import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {TypographyDirective} from './typography.directive';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule
  ],
  declarations: [
    TypographyDirective
  ],
  exports: [
    TypographyDirective
  ],
  bootstrap: []
})
export class TypographyModule { }
