import {Injectable, Component, ElementRef, Input, HostBinding, OnChanges} from '@angular/core';
import {Actions} from './../actions/actions';

@Component({
    selector: 'mad-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnChanges {
    _imageHasContent = false;
    //_startRemove = false;
    //_removing = false;
    //_removed = false;

    @Input() interactive: boolean = true;
    @Input() type: 'inherit';
    @HostBinding('class') class;
    @HostBinding('style') style;

    //subscription: Subscription;

    constructor (public element: ElementRef) {
        //this.onTransitionEnd = this.onTransitionEnd.bind(this);
    }

    ngOnChanges() {
        this.class = this.type || '';
    }

    ngAfterContentChecked () {
        var image = this.element.nativeElement.querySelector('[mad-card-image]');
        if (image) {
            if (image.childNodes.length > 0) {
                this._imageHasContent = true;
            } else {
                this._imageHasContent = false;
            }
        }
        this.class = this.getClass();
        //this.style = this.getStyle();
    }

    getClass () {
        return `mad-card 
        ${this.interactive ? 'interactive' : ''} 
        ${this.type || ''} `;
    }

    //getStyle() {
        //if (this._removing) {
            //return {height: 0};
        //}
    //}

    //onDismiss () {
        //this.element.nativeElement.querySelector('.mad-card').style.height = `${this.element.nativeElement.firstChild.offsetHeight}px`;
        //setTimeout(() => {
            //this._removing = true;
        //})
        //this.element.nativeElement.addEventListener('transitionend', this.onTransitionEnd, true)
    //}

    //onTransitionEnd () {
        //if (this._removing) {
            //this._removed = true;
        //}
    //}
}
