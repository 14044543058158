import { Component } from "@angular/core";
import { InputActions } from '../input/input.actions';
import { InputComponent } from '../input/input.component';
import { Field } from "../field/field.component";

@Component({
    selector: "mad-date",
    templateUrl: "./../input/input.component.html",
    styleUrls: ["./../input/input.component.scss"],
    providers: Field.Providers(DateComponent).concat(...(InputActions.Providers()))
})
export class DateComponent extends InputComponent {
    inputType = 'date';
    formatter = { type: 'date' } as { type: 'date' };
}
