import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {ResizeModule} from './../resize/resize.module';
import {FooterComponent} from './footer.component';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     ResizeModule
  ],
  declarations: [
    FooterComponent
  ],
  exports: [
    FooterComponent
  ],
  bootstrap: [FooterComponent]
})
export class FooterModule { }
