<ng-content select="mad-header"></ng-content>
<figure mad-card-image class="spot-card--img" [style.display]="_imageHasContent ? '' : 'none'">
    <ng-content select="mad-card-overlay"></ng-content>
    <ng-content select="mad-card-image"></ng-content>
</figure>

<div class="mad-card-body-wrapper">
    <mad-body><ng-content></ng-content></mad-body>
    <ng-content select="mad-body"></ng-content>
</div>

<ng-content select="mad-footer"></ng-content>
