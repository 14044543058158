import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {ResizeModule} from './../resize/resize.module';
import {PopoverComponent, PopoverDirective} from './popover.component';
import {PopoverOutletDirective} from './popover-outlet.directive';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     ResizeModule
  ],
  declarations: [
    PopoverComponent,
    PopoverDirective,
    PopoverOutletDirective
  ],
  exports: [
    PopoverComponent,
    PopoverDirective,
    PopoverOutletDirective
  ],
  bootstrap: [PopoverComponent]
})
export class PopoverModule { }
