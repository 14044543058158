import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {IconComponent} from './icon.component';
import {FieldModule} from './../field/field.module';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     FieldModule
  ],
  declarations: [
    IconComponent
  ],
  exports: [
    IconComponent
  ],
  bootstrap: [IconComponent]
})
export class IconModule { }
