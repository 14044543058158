import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs'
import { UserState } from './../user/user.state';

@Injectable()
export class SSAState implements CanActivate {

    constructor(public userState: UserState) { }

    canActivate(route: ActivatedRouteSnapshot) {
        return new Observable<boolean>((sub) => {
            (async () => {
                this.userState.canActivate(route).toPromise();

                try {
                    const result = await this.userState.statePromise;

                    if (!result || (!result.can_approve)) {
                        sub.next(false);
                    } else {
                        sub.next(true);
                    }
                } catch (err) {
                    sub.next(false);
                }
            })();
        });
    }
}
