import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {TextAreaComponent} from './textarea.component';
import {FieldModule} from '../field/field.module';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     FieldModule,
     FormsModule
  ],
  declarations: [
    TextAreaComponent
  ],
  exports: [
    TextAreaComponent
  ],
  bootstrap: [TextAreaComponent]
})
export class TextAreaModule { }
