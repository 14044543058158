import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';
import { IReferenceData, ILIMSReference, INotificationGroup } from '@shared/model/reference';
import { WebSocket } from '../../providers/websocket/websocket';
import { SSEClient } from '../../providers/sse/sse';
import { AuthState } from '../../states/auth/auth.state';
import { Service } from '../service/service';
import type { ReferenceAPI } from '../../../../../services/lib/reference/reference.api';

export interface IReferenceDataMapped extends IReferenceData {
    limsMap?: { [key: string]: ILIMSReference };
    notificationGroupsMap?: { [key: string]: INotificationGroup };
}

@Injectable()
export class ReferenceService extends Service<typeof ReferenceAPI> implements OnDestroy {
    referencePromise: Promise<any>;

    data: BehaviorSubject<IReferenceDataMapped> = new BehaviorSubject(null);

    referenceSub: Subscription;

    constructor(public http: HttpClient, public sseClient: SSEClient, public wsClient: WebSocket, public authState: AuthState) {
        super(http, sseClient, wsClient);
        this.referenceSub = authState.authorized.subscribe((v): void => {
            if (!v) {
                this.referencePromise = null;
            } else {
                this.reference();
            }
        });
        if (authState.authorized) {
            this.reference();
        }
    }

    ngOnDestroy(): void {
        this.referenceSub.unsubscribe();
    }

    async reference(): Promise<IReferenceDataMapped> {
        if (!this.referencePromise) {
            this.referencePromise = new Promise<IReferenceDataMapped>(async (resolve, reject): Promise<void> => {
                try {
                    const resp = await firstValueFrom(this.get('/reference'));

                    if (resp.error || !resp.success || !resp.data) {
                        reject(resp.error || 'NO_DATA');
                    }

                    const result: IReferenceDataMapped = resp.data;

                    let map = {};
                    result.limsMap = map;
                    const lims = result.lims;

                    lims.forEach((limsC: ILIMSReference): void => {
                        map[limsC.lims_code] = limsC;
                    });

                    map = {};
                    result.notificationGroupsMap = map;
                    const group = result.notificationGroups;

                    group.forEach((groupC: INotificationGroup): void => {
                        map[groupC.test_code] = groupC;
                    });

                    this.data.next(result);
                    resolve(result);
                } catch (e) {
                    console.error(e);
                    this.referencePromise = null;
                    reject(e);
                }
            });
        }
        return this.referencePromise;
    }
}
