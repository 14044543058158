import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {RadioGroupComponent} from './radio-group.component';
import {FieldModule} from '../field/field.module';
import {FormsModule} from '@angular/forms';
import {RadioModule} from '../radio/radio.module';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     RadioModule,
     FieldModule,
     FormsModule
  ],
  declarations: [
      RadioGroupComponent
  ],
  exports: [
      RadioGroupComponent
  ],
  bootstrap: [RadioGroupComponent]
})
export class RadioGroupModule { }
