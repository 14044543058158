<ng-template #modal>
    <mad-navigation madActions #navigation>
        <div clActions #overlay class="mad-overlay" >
            <div [ngClass]="getModalClass()" >
                <span #headerWrapper style="display:inline-block;width:auto;position:relative;">
                    <ng-content select="mad-header"></ng-content>
                </span>
                <div #contentWrapper class="mad-modal-body-wrapper">
                    <mad-body>
                        <ng-content select="mad-body"></ng-content>
                        <ng-content></ng-content>
                    </mad-body>
                </div>
                <span #footerWrapper style="display:inline-block;width:auto;position:relative;">
                    <ng-content select="mad-footer"></ng-content>
                </span>
            </div>
        </div>
    </mad-navigation>
</ng-template>
