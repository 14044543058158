import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { SAPComponent } from './business/sap/sap.component';
import { AccountState } from './states/account/account.state';
import { AuthState } from './states/auth/auth.state';
import { SSAState } from './states/ssa/ssa.state';
import { HistoryComponent } from './business/history/history.component';
import { FeedbackComponent as SSAFeedbackComponent } from './business/ssa/feedback/feedback.component';
import { AccountComponent } from './business/account/account.component';

const routes: Routes = [{
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthState, AccountState]
}, {
    path: 'ssa',
    canActivate: [AuthState, SSAState],
    children: [{
        path: 'feedback',
        component: SSAFeedbackComponent,
        canActivate: [AuthState, SSAState]
    }]
}, {
    path: 'sap',
    component: SAPComponent,
    canActivate: [AuthState, AccountState]
}, {
    path: 'history',
    component: HistoryComponent,
    canActivate: [AuthState, AccountState]
}, {
    path: '',
    redirectTo: '/sap',
    pathMatch: 'full'
}
    // , {
    //   path: '**',
    //   redirectTo: '/sap'
    // }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules
            // enableTracing: true  // <-- debugging purposes only
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
