import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {ResizeModule} from './../resize/resize.module';
import {FilterSelectionComponent} from './filter-selection.component';
import {LinkModule} from '../link/link.module';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     ResizeModule,
     LinkModule
  ],
  declarations: [
    FilterSelectionComponent
  ],
  exports: [
    FilterSelectionComponent
  ],
  bootstrap: [FilterSelectionComponent]
})
export class FilterSelectionModule { }
