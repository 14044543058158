import { NgModule } from '@angular/core';
import { EnvHeaderComponent } from './env-header.component';

@NgModule({
    imports: [
    ],
    declarations: [
        EnvHeaderComponent
    ],
    exports: [
        EnvHeaderComponent
    ],
    bootstrap: [EnvHeaderComponent]
})
export class EnvHeaderModule { }
