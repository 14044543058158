import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {InfiniteListComponent} from './infinite-list.component';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule
  ],
  declarations:[
    InfiniteListComponent
  ],
  exports: [
    InfiniteListComponent
  ],
  bootstrap: [InfiniteListComponent]
})
export class InfiniteListModule { }
