import {
    ChangeDetectorRef,
    Injectable,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import {OutletComponent, OutletItem} from './../outlet/outlet.component';
import {Actions} from "../actions/actions";
import {DomUtil} from "../../utils/dom";
import {NavigationComponent} from "../navigation/navigation.component";

let count = 0;

@Injectable()
export class ModalActions extends Actions({
    name: 'mad-modal',
    types: {
        "modal-show": {name: "Show", description: "Shows the modal popup."},
        "modal-hide": {name: "Hide", description: "Hides the modal popup."},
        "modal-user-cancel": {name: "Cancel", description: "Cancel button is pressed"},
        "modal-user-primary": {name: "Primary", description: "Primary actino"},
        "modal-user-mask": {name: "Mask", description: "Mask is clicked"}
    },
    targets: {
        cancel: {name: "Cancel", matcher: 'mad-button[type=cancel-icon],mad-button[type=cancel]'},
        mask: {name: "Mask", matcher: ".spot-modal__mask"},
        buttons: {name: "Buttons", matcher: "button"},
        inputs: {name: "Inputs", matcher: "[contenteditable], input"},
        "*": {name: "All", matcher: "*"}

    },
    interactions: {
        click: {
            "cancel": "modal-user-cancel",
            "mask": "modal-user-mask"
        },
        keydown: {
            "Escape": "modal-user-cancel",
            "Enter": {
                inputs: "modal-user-primary"
            }
        }
    }
}) {}

@Component({
    selector: "mad-modal",
    templateUrl: "./modal.component.html",
    styleUrls: ["./modal.component.scss"],
    providers: ModalActions.Providers()
})
export class ModalComponent extends OutletItem implements OnInit, OnDestroy {
    @ViewChild('headerWrapper') headerWrapper: ElementRef;
    @ViewChild('footerWrapper') footerWrapper: ElementRef;
    @ViewChild('contentWrapper') contentWrapper: ElementRef;

    @Input()
    outlet: string;
    
    @Input()
    width: string;
    @Input()
    height: string = "";

    @Output()
    cancel = new EventEmitter();

    @Output()
    hide = new EventEmitter();

    @Output()
    show = new EventEmitter();

    @Output()
    primary = new EventEmitter();

    @Output()
    secondary = new EventEmitter();

    @Input() set visible(visible) {
        if (this._visible !== visible) {
            if (visible) {
                if (this.preserveFocus && document.activeElement instanceof HTMLElement) {
                    this._last_focus = document.activeElement;
                }
            }
            this._visible = visible;
            if (visible) {
                if (this.preserveFocus) {
                    this._focus_timer = requestAnimationFrame(() => {
                        if (this.overlay && !DomUtil.queryUp(document.activeElement, this.overlay.nativeElement)) {
                            this._focus_timer = 0;
                            const result = this.overlay.nativeElement.querySelector(this.focusSelector);
                            if (result) {
                                result.focus();
                            }
                        }
                    });
                }
            } else {
                if (this._focus_timer) {
                    cancelAnimationFrame(this._focus_timer);
                    this._focus_timer = 0;
                }
                if (this.preserveFocus && this._last_focus) {
                    this._last_focus.focus();
                    this._last_focus = null;
                }
            }
        }
    }

    @Input() auto: boolean = true;
    @Input() focusSelector = `input:not([disabled]):not([readonly]), a:not([disabled]),
        mad-button:not([type="cancel-icon"]) button:not([disabled]):not([tabindex="-1"]),
        [contenteditable], textarea:not([disabled]):not([readonly])`;
    @Input() preserveFocus: boolean = true;
    @Input() mask = false;

    @ViewChild("modal") template: TemplateRef<any>;
    @ViewChild("overlay") overlay: ElementRef;
    @ViewChild("navigation") navigation: NavigationComponent;

    _last_focus: HTMLElement;
    _focus_timer: any;
    _visible_timer: any;
    _visible: boolean = false;
    _id: string = `modal-${count++}`;


    get visible() {
        return this._visible;
    }

    constructor(public viewContainer: ViewContainerRef, public element: ElementRef, public action: ModalActions, public changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.action.subscribe((action) => {
            if (this.auto) {
                switch (action.type) {
                    case "modal-show":
                        if (action.$event) {
                            action.$event.preventDefault();
                        }
                        this.show.emit(action.$event);
                        this.visible = true;
                        break;
                    case "modal-hide":
                        if (action.$event) {
                            action.$event.preventDefault();
                        }
                        this.hide.emit(action.$event);
                        this.visible = false;
                        break;
                    case "modal-user-mask":
                    case "modal-user-cancel":
                        if (action.$event) {
                            action.$event.preventDefault();
                        }
                        this.cancel.emit(action.$event);
                        this.action.next({
                            type: "modal-hide",
                            $event: action.$event,
                            payload: null
                        });
                        break;
                    case "modal-user-primary":
                        if (action.$event) {
                            action.$event.preventDefault();
                        }
                        this.primary.emit(action.$event);
                        break;
                }
            }
        });

        this.onFocus = this.onFocus.bind(this);
        this.onResize = this.onResize.bind(this);
        document.addEventListener("focus", this.onFocus, true);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        if (this._visible_timer) {
            clearTimeout(this._visible_timer);
            this._visible_timer = 0;
        }
        if (this._focus_timer !== 0) {
            cancelAnimationFrame(this._focus_timer);
            this._focus_timer = 0;
        }
        document.removeEventListener("focus", this.onFocus, true);
    }


    getModalClass(): string {
        return `mad-modal spot-modal ${this._id}`;
    }

    onResize($event: Event) {
    }

    onFocus($event: FocusEvent) {
        $event.preventDefault();
        if (this.preserveFocus && this.visible) {
            if (this.overlay && !DomUtil.queryUp($event.target as HTMLElement, `.${this._id}`)) {
                    //should be last node in Outlet
                    var map = OutletComponent.outletMap[this.outlet || ''],
                        templates = map.templates || [];
                    for (var i=templates.length-1;i>=0;i--) {
                        if (DomUtil.queryUp($event.target as any, templates[i].element.nativeElement)) {
                            break;
                        } else if (templates[i] instanceof ModalComponent ) {
                            const result = (templates[i] as ModalComponent).overlay.nativeElement.querySelector(this.focusSelector);
                            if (result) {
                                result.focus();
                            }
                            break;
                        }
                }
            }
        }
    }

    ngAfterContentChecked () {
        if (!this.contentWrapper) {
            return;
        }
    }

}
