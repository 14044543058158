import { Component, OnInit } from '@angular/core';
import { IRole } from '@shared/model/role';
import { IUser } from '@shared/model/user';
import { Message } from '@shared/model/message';
import { UtilService } from '../../../services/util/util.service';
import { AdminService } from '../../../services/admin/admin.service';
import { AuthState } from '../../../states/auth/auth.state';

@Component({
    selector: 'irl-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {
    private users: IUser[] = [];

    public roles: IRole[] = [];

    public sortedData: IUser[] = [];

    private lastSort: any;

    public message: Message = new Message();

    public saving: boolean;

    public selectedUser: IUser;

    public searchEmail: string;

    public foundUser: IUser;

    public searchEnabled = false;

    constructor(
        private adminService: AdminService,
        private authState: AuthState
    ) { }

    public async ngOnInit(): Promise<void> {
        await this.getUsers();
        await this.getRoles();
    }

    public roleSelected(user: IUser): void {
        console.log('roleSelected: ', user);
        user.role_id = user.role.id;
        user.modified = true;
    }

    public compareRole(r1: IRole, r2: IRole): boolean {
        return r1 && r2 ? r1.id === r2.id : r1 === r2;
    }

    public async cancelUser(user: IUser): Promise<void> {
        console.log('cancelUser');
        if (user && user.id) {
            const userResp = await this.adminService.getUser(user.id).toPromise();
            if (userResp.success && userResp.data) {
                const origUser = userResp.data;
                this.replaceUser(origUser, this.users);
            }

            delete user.modified;
        }
        if (user === this.foundUser) {
            delete this.foundUser;
        }
    }

    public async saveUser(user: IUser): Promise<void> {
        console.log('saveUser', user);

        this.message.setInfo('Saving...');

        this.saving = true;

        try {
            const saveResp = await this.adminService.saveUser(user);
            console.log('saveUser result: ', saveResp);

            this.saving = false;
            this.message.clear();

            if (saveResp.success === true && saveResp.data) {
                this.message.setSuccess('Save sucessful');
                this.replaceUser(saveResp.data, this.users);
                delete user.modified;

                this.getUsers();
            } else {
                console.error('Error saving user: ', saveResp.error);
                this.message.setError('Error saving user: ' + UtilService.getErrorMessage(saveResp.error));
            }
        } catch (err) {
            console.error('Error saving user: ', err);
            this.message.setError('Error saving user: ' + UtilService.getErrorMessage(err));

            this.saving = false;
        }
    }

    private replaceUser(user?: IUser, list?: IUser[]): void {
        if (!user || !list) {
            return;
        }
        const ix = list.findIndex(function(u1): boolean {
            return u1.id === user.id;
        });
        if (ix !== -1) {
            list[ix].role = user.role;
            list[ix].role_id = user.role_id;
        }
    }

    private resort(): void {
        console.log('resort');
        if (this.lastSort) {
            this.sortData(this.lastSort);
        } else {
            this.sortData({ active: 'context', direction: 'asc' })
        }
        console.log('this.sortedData=', this.sortedData);
    }

    public sortData(sort: any): void {
        console.log('sortData: ', sort);
        const data = this.users.slice();

        this.lastSort = sort;

        if (!sort.active || sort.direction === '') {
            this.sortedData = data;
            return;
        }

        this.sortedData = data.sort((a: IUser, b: IUser) => {
            const isAsc = sort.direction === 'asc';
            try {
                switch (sort.active) {
                    case 'name': return compare(a.name, b.name, isAsc);
                    case 'role': {
                        return compare(this.getName(a, a.role), this.getName(b, b.role), isAsc);
                    }
                    case 'username': return compare(a.username, b.username, isAsc);
                    case 'email': return compare(a.email, b.email, isAsc);
                    default: return 0;
                }
            } catch (err) {
                console.error('Error sorting: ', err);
            }
        });
    }

    private getName(obj: any, objChild: any): string {
        if (objChild) {
            return objChild.name;
        }
        console.warn('getName: Child is not defined: ', obj);
        return null;
    }

    public selectUser(user: IUser): void {
        const existUser =
            user && user.id
                ? this.users.find((eUser: IUser): boolean => { return eUser.id === user.id })
                : null;
        this.selectedUser = existUser || user;
        this.foundUser = user;
    }

    private async getUsers(): Promise<IUser[] | null> {
        try {
            const resp = await this.adminService.getUsers();
            console.log('Got users: ', resp);
            if (resp.success) {
                this.users = resp.data;

                this.sortedData = this.users.slice();
                this.resort();
                return this.users;
            }

            console.error('Error retrieving users: ', resp);
            this.message.setError('Error retrieving users: ', UtilService.getErrorMessage(resp.error));
            return null;
        } catch (err) {
            console.error('Error retrieving users2: ', err);
            this.message.setError('Error retrieving users: ', UtilService.getErrorMessage(err));
        }
    }

    private async getRoles(): Promise<void> {
        try {
            const roleResp = await this.adminService.getRoles();
            console.log('Got roles: ', roleResp);
            if (roleResp.success) {
                this.roles = roleResp.data;
            } else {
                console.error('Error retrieving roles: ', roleResp);
                this.message.setError('Error retrieving roles: ' + UtilService.getErrorMessage(roleResp.error));
            }
        } catch (err) {
            console.error('Error retrieving roles2: ', err);
            this.message.setError('Error retrieving roles: ' + UtilService.getErrorMessage(err));
        }
    }

    public toggleSearch(): void {
        this.searchEnabled = !this.searchEnabled;
    }

    public async searchUser(): Promise<IUser> {
        console.log('searchUser: ', this.searchEmail);
        this.message.clear();

        const accessToken = await this.authState.getAccessToken();
        const searchResp = await this.adminService.searchUser(this.searchEmail, accessToken);
        console.log('searchResp=', searchResp);

        if (!searchResp.success || !searchResp.data) {
            this.message.setError('User not found');
        }

        this.foundUser = searchResp.data;
        this.selectUser(this.foundUser);

        if (!this.foundUser.role) {
            // Set default role to sales
            this.foundUser.role = this.getSalesRole();
            this.foundUser.role_id = this.foundUser.role ? this.foundUser.role.id : null;
        }

        return this.foundUser;
    }

    private getSalesRole(): IRole {
        return this.roles.find((r: IRole): boolean => {
            return r.developer_name === 'SALES';
        });
    }
}

function compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
