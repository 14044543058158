import {Component, ViewChildren, OnInit} from '@angular/core';
import {TabComponent} from './tab.component';

@Component({
  selector: 'mad-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  @ViewChildren(TabComponent) tabs: TabComponent[];
  ngOnInit () {
  }
}

