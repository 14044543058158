<div class="history-content">
    <div class="history-body">
      <mad-infinite-list class="scrollable history-list" [pager]="pager" [template]="historyTemplate" [assumedHeight]="32">
        <div class="history-header">
          <mad-button class="history-return-button" type="secondary"  [disabled]="pager?.results[0]?.data?.reviewing && (!userState.state.can_approve)" (click)="onCloseClick()">
            <ng-container *ngIf="!session?.ssa_reviewing && !(session?.vdc_reviewing && session?.state.approved === null)">
              Return to active session
            </ng-container>
            <ng-container *ngIf="session?.vdc_reviewing && session?.state.approved === null">
              Session is currently under review
            </ng-container>
            <ng-container  *ngIf="session?.ssa_reviewing">
              Review session
            </ng-container>
          </mad-button>
        </div>
        <div class="history-list-header">
          Below is a list of recent submissions and their status.
        </div>
        <div class="history-row header">
          <div class="history-row-inner">
            <div class="activity-date">
              Activity/Submission Date
            </div>
            <div class="representative">
              IDEXX Representative
            </div>
            <div class="status">
              Status
            </div>
            <div class="download">
              View/Download
            </div>
          </div>
        </div>
      </mad-infinite-list>
    </div>
</div>

<ng-template #historyTemplate let-index="index" let-height="height">
  <div *ngIf="pager.results[index]?.data; let data" class="history-row">
    <div class="history-row-inner">
      <div class="activity-date">
        {{(data.submitted_on || data.reviewed_on || data.proposed_on) | date}}
      </div>
      <div class="representative">
        {{data.submitted_by || data.reviewed_by || data.proposed_by}}
      </div>
      <div class="status">
        <ng-container *ngIf="data.approved">
          Approved/pricing submitted
        </ng-container>
        <ng-container *ngIf="data.approved === false">
          <span class="rejected">
            Rejected
          </span>
        </ng-container>
        <ng-container *ngIf="data.reviewing">
          <span class="under-review">
            Under review
          </span>
        </ng-container>
      </div>
      <div class="download">
        <div class="download-inner">
          <mad-link (click)="onDownloadClick(data, $event)" [href]="data.url" target="_blank"> <!-- (click)="onDownloadClick(data, $event)"   -->
            <mad-icon class="download-icon" icon="file-pdf"></mad-icon>
          </mad-link>
        </div>
      </div>
    </div>
  </div>
</ng-template>
