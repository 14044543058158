<div *ngIf="message && (message.message||message.title)" [class]="msgClass">
    <div class="message--content">
        <div class="message--header">{{message.title}}</div>
        <div class="message--body">{{message.message}}</div>
    </div>
    <div class="icon-tab">
        <svg class="spot-icon">
            <use [attr.xlink:href]="iconHref"></use>
        </svg>
    </div>
    <div class="dismiss">
        <svg class="spot-icon" aria-labelledby="title">
            <title>cancel</title>
            <use xlink:href="/assets/spot/dist/icons/spot_icons.svg#cancel"></use>
        </svg>
    </div>
</div>
