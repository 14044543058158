import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {DigitalIdentityComponent} from './digital-identity.component';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule
  ],
  declarations:[
    DigitalIdentityComponent
  ],
  exports: [
    DigitalIdentityComponent
  ],
  bootstrap: [DigitalIdentityComponent]
})
export class DigitalIdentityModule { }
