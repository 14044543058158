import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {SelectComponent} from './select.component';
import {FieldModule} from '../field/field.module';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     FormsModule,
     FieldModule
  ],
  declarations:[
    SelectComponent
  ],
  exports: [
    SelectComponent
  ],
  bootstrap: [SelectComponent]
})
export class SelectModule { }
