import {Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output} from "@angular/core";

const template = document.createElement("div");
template.innerHTML = `
<div class="cl-resize-expand" style="overflow:auto;display:block;position:absolute;top:0;left:0;height:100%;width:100%">
    <div class="cl-resize-expand-inner"></div>
</div>
<div class="cl-resize-shrink" style="display:block;position:absolute;top:0;left:0;height:100%;width:100%;overflow:auto">
    <div class="cl-resize-shrink-inner" style="display:block;position:absolute;top:0;left:0;overflow:hidden;width:200%;height:200%"></div>
</div>
`;
template.style.cssText = `overflow:hidden;display:block;top:0;left:0;height:100%;width:100%;
                            z-index:0;position:absolute;pointer-events:none;visibility:hidden;opacity:0;`;
template.className = "cl-resize";

class Resize {
    callbacks: Function[];
    monitor: HTMLElement;
    expand: Element;
    expandInner: Element;
    shrink: Element;
    state: Object;
    scroll: Function;
}

@Directive({
  selector: "[madResize]"
})
export class ResizeDirective implements OnInit, OnDestroy {
    @Output("madResize") madResize = new EventEmitter();

    public el: ElementRef;
    private resize: Resize;
    private dispatch: Function;

    constructor (el: ElementRef) {
        this.el = el;
        this.dispatch = (state, last): void => {
            this.madResize.emit(state);
        };
    }

    ngOnInit () {
        const el = this.el,
        nativeElement = el.nativeElement;

        if (nativeElement.$clEvents && nativeElement.$clEvents.resize) {
            this.resize = nativeElement.$clEvents.resize;
            nativeElement.$clEvents.resize.callbacks.push(this.dispatch);
            return;
        }

        let resize;
        const monitor = template.cloneNode(true) as HTMLElement;
        nativeElement.appendChild(monitor);
        resize = this.resize = (nativeElement.$clEvents || (nativeElement.$clEvents = {})).resize = {
            callbacks: [],
            monitor,
            expand: monitor.querySelector(".cl-resize-expand"),
            shrink: monitor.querySelector(".cl-resize-shrink"),
            expandInner: monitor.querySelector(".cl-resize-expand-inner"),
            el: el.nativeElement,
            state: {
                width: undefined,
                height: undefined,
                $event: Event
            },
            scroll: function onScroll ($event): void {
                const height = resize.expand.offsetHeight,
                width = resize.expand.offsetWidth,
                last = resize.state,
                callbacks = resize.callbacks;

                if (height === resize.state.height && width === resize.state.width) {
                  resize.expand.scrollTop = height + 1;
                  resize.expand.scrollLeft = width + 1;
                  return;
                }
                resize.state = {
                    width,
                    height,
                    $event
                };
                resize.shrink.scrollTop = resize.shrink.scrollHeight;
                resize.shrink.scrollLeft = resize.shrink.scrollWidth;
                resize.expandInner.style.setProperty("width", `${width + 1}px`);
                resize.expandInner.style.setProperty("height", `${height + 1}px`);
                resize.expand.scrollLeft = resize.expand.scrollWidth;
                resize.expand.scrollTop = resize.expand.scrollHeight;
                let i, ln;
                for (i = 0, ln = callbacks.length ; i < ln ; i++) {
                    callbacks[i](resize.state, last);
                }
            }
        };
        monitor.addEventListener("scroll", resize.scroll, true);
        resize.callbacks.push(this.dispatch);
        //resize.scroll();
        setTimeout(() => {
            resize.scroll();
        });
    }
    ngOnDestroy () {
        const el = this.el,
            nativeElement = el.nativeElement,
            resize = this.resize,
            callbacks = resize.callbacks,
            monitor = resize.monitor;

        let index;
        if (callbacks && callbacks.length) {
            index = callbacks.indexOf(this.dispatch);
            if (index !== -1) {
                callbacks.splice(index, 1);
                if (callbacks.length === 0) {
                    monitor.removeEventListener("scroll", resize.scroll as any); // invalid warning generated from typescript
                    delete nativeElement.$clEvents.resize;
                    nativeElement.removeChild(monitor);
                }
            }
        }
    }
}
