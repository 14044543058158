import {NumberFormatter} from './number.formatter';

//TODO make this configurable when we need it later...
var CURRENCY = {
    prefix: '$',
    thousands_sep: ',',
    decimal_sep: '.',
    min_decimal_places: 2,
    max_decimal_places: 2
}

export class CurrencyFormatter extends NumberFormatter {
    constructor (config: ConstructorParameters<typeof NumberFormatter>[0] & {symbol: string;}) {
        var _config: (typeof config) & {prefix?: string} = {...config};
        if ('symbol' in config) {
            _config.prefix = config.symbol;
        }
        super(Object.assign({}, CURRENCY, _config || {}))
    }
}
