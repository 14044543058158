import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import type { _API, ResponseOfPromise } from '../../../../../services/lib/api';
import { ENV } from '../../globals/env';
import { IRL } from '../../globals/irl';
import { SSEClient } from '../../providers/sse/sse';
import { WebSocket, WebSocketSubscription } from '../../providers/websocket';

let webSocketId = 0; // ws_id is handled per connection -- this allows for natural path separation
// do not remove this

@Injectable()
export class Service<API extends typeof _API> {
    constructor(public http: HttpClient, public sseClient: SSEClient, public wsClient: WebSocket) { }

    createParams(params: { [key: string]: any }): { [key: string]: any } {
        const result = { ...params };
        const irlParams = IRL.services.params;
        let param: string;
        for (param in irlParams) {
            if (param === 'imposter' && !irlParams[param]) {
                continue;
            }
            if (irlParams[param] !== undefined && irlParams[param] !== null) {
                result[param] = irlParams[param];
            }
        }
        return result as any;
    }

    ws<
        RESULT extends ResponseOfPromise<ITEM>['sendData'],
        PARAMS extends ResponseOfPromise<ITEM>['subParams'],
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['ws'] = API['api'][PATH]['ws']
    >(url: PATH, { params }: { params?: PARAMS } = {}): Observable<WebSocketSubscription<RESULT, PATH, PARAMS>> {
        return new Observable<WebSocketSubscription<RESULT, PATH, PARAMS>>((sub): void => {
            (async () => {
                try {
                    sub.next(await this.wsClient.subscribe(url, this.createParams(Object.assign(params || {}, { __id: webSocketId++ }))));
                    sub.complete();
                } catch (err) {
                    console.error('Error in ws call: ', err, url, params);
                    sub.error(err);
                }
            })();
        });
    }

    sse<
        RESULT extends Parameters<ResponseOfPromise<ITEM>['send']>[0]['data'],
        PARAMS extends Parameters<ITEM>[0]['params'],
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['sse'] = API['api'][PATH]['sse']
    >(url: PATH, { params }: { params?: PARAMS } = {}) {
        return this.sseClient.create<RESULT>(`${ENV.SERVICE_TARGET}${url}`, { params: this.createParams(params) })
    }

    get<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['get'] = API['api'][PATH]['get']
    >(url: PATH, options: { params?: PARAMS; responseType?: any; observe: 'response' | 'events', reportProgress?: boolean }): Observable<HttpResponse<any>>
    get<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['get'] = API['api'][PATH]['get']
    >(url: PATH, options: { params?: PARAMS; responseType: 'blob' }): Observable<Blob>
    get<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['get'] = API['api'][PATH]['get']
    >(url: PATH, options: { params?: PARAMS; responseType: 'arraybuffer' }): Observable<ArrayBuffer>
    get<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['get'] = API['api'][PATH]['get']
    >(url: PATH, options?: { params?: PARAMS; responseType?: 'json' }): Observable<RESULT>
    get<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['get'] = API['api'][PATH]['get']
    >(url: PATH, options?: { params?: PARAMS; responseType?: any; observe?: any, reportProgress?: boolean }): Observable<RESULT> {
        const config = options || {};
        return this.http.get(`${ENV.SERVICE_TARGET}${url}`, {
            params: this.createParams(config.params),
            responseType: config.responseType || 'json',
            observe: config.observe,
            reportProgress: config.reportProgress || false
        }) as Observable<RESULT>;
    }

    put<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        BODY extends ResponseOfPromise<Parameters<ITEM>[0]['json']>,
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['put'] = API['api'][PATH]['put']
    >(url: PATH, options: { params?: PARAMS; body?: BODY, responseType?: any; observe: 'response' | 'events', reportProgress?: boolean }): Observable<HttpResponse<any>>
    put<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        BODY extends ResponseOfPromise<Parameters<ITEM>[0]['json']>,
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['put'] = API['api'][PATH]['put']
    >(url: PATH, options: { params?: PARAMS; body?: BODY, responseType: 'blob' }): Observable<Blob>
    put<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        BODY extends ResponseOfPromise<Parameters<ITEM>[0]['json']>,
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['put'] = API['api'][PATH]['put']
    >(url: PATH, options: { params?: PARAMS; body?: BODY, responseType: 'arraybuffer' }): Observable<ArrayBuffer>
    put<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        BODY extends ResponseOfPromise<Parameters<ITEM>[0]['json']>,
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['put'] = API['api'][PATH]['put']
    >(url: PATH, options?: { params?: PARAMS; body?: BODY, responseType?: 'json' }): Observable<RESULT>
    put<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        BODY extends ResponseOfPromise<Parameters<ITEM>[0]['json']>,
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['put'] = API['api'][PATH]['put']
    >(url: PATH, options?: { params?: PARAMS; body?: BODY, responseType?: any; observe?: any, reportProgress?: boolean }): Observable<any> {
        let config = options || {};
        return this.http.put(`${ENV.SERVICE_TARGET}${url}`, config.body, {
            params: this.createParams(config.params),
            responseType: config.responseType || 'json',
            observe: config.observe,
            reportProgress: config.reportProgress || false
        }) as Observable<RESULT>;
    }

    post<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        BODY extends ResponseOfPromise<Parameters<ITEM>[0]['json']>,
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['post'] = API['api'][PATH]['post']
    >(url: PATH, options: { params?: PARAMS; body?: BODY, responseType?: any; observe: 'response' | 'events', reportProgress?: boolean }): Observable<HttpResponse<any>>
    post<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        BODY extends ResponseOfPromise<Parameters<ITEM>[0]['json']>,
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['post'] = API['api'][PATH]['post']
    >(url: PATH, options: { params?: PARAMS; body?: BODY, responseType: 'blob' }): Observable<Blob>
    post<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        BODY extends ResponseOfPromise<Parameters<ITEM>[0]['json']>,
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['post'] = API['api'][PATH]['post']
    >(url: PATH, options: { params?: PARAMS; body?: BODY, responseType: 'arraybuffer' }): Observable<ArrayBuffer>
    post<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        BODY extends ResponseOfPromise<Parameters<ITEM>[0]['json']>,
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['post'] = API['api'][PATH]['post']
    >(url: PATH, options?: { params?: PARAMS; body?: BODY, responseType?: 'json' }): Observable<RESULT>
    post<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        BODY extends ResponseOfPromise<Parameters<ITEM>[0]['json']>,
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['post'] = API['api'][PATH]['post']
    >(url: PATH, options?: { params?: PARAMS; body?: BODY, responseType?: any, observe?: any, reportProgress?: boolean }) {
        let config = options || {};
        return this.http.post(`${ENV.SERVICE_TARGET}${url}`, config.body, {
            params: this.createParams(config.params),
            responseType: config.responseType || 'json',
            observe: config.observe,
            reportProgress: config.reportProgress
        }) as Observable<RESULT>;
    }

    delete<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['delete'] = API['api'][PATH]['delete']
    >(url: PATH, options: { params?: PARAMS; responseType?: any; observe: 'response' | 'events', reportProgress?: boolean }): Observable<HttpResponse<any>>
    delete<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['delete'] = API['api'][PATH]['delete']
    >(url: PATH, options: { params?: PARAMS; responseType: 'blob' }): Observable<Blob>
    delete<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['delete'] = API['api'][PATH]['delete']
    >(url: PATH, options: { params?: PARAMS; responseType: 'arraybuffer' }): Observable<ArrayBuffer>
    delete<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['delete'] = API['api'][PATH]['delete']
    >(url: PATH, options?: { params?: PARAMS; responseType?: 'json' }): Observable<RESULT>
    delete<
        RESULT extends ResponseOfPromise<ITEM>,
        PARAMS extends Parameters<ITEM>[0]['params'],
        PATH extends keyof API['api'],
        ITEM extends API['api'][PATH]['delete'] = API['api'][PATH]['delete']
    >(url: PATH, options?: { params?: PARAMS, responseType?: any, observe?: any, reportProgress?: boolean }) {
        let config = options || {};
        return this.http.delete(`${ENV.SERVICE_TARGET}${url}`, {
            params: this.createParams(config.params),
            responseType: config.responseType || 'json',
            observe: config.observe,
            reportProgress: config.reportProgress
        }) as Observable<RESULT>;
    }
}
