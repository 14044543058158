import {NgModule} from '@angular/core';
import {AccountModule} from './account/account.module';
import {SummaryModule} from './summary/summary.module';
import {SessionModule} from './session/session.module';
import {SubmitProcessModule} from './submit-process/submit-process.module';
import {SelectedPanelsModule} from './selected-panels/selected-panels.module';
import {SelectedPanelsDetailsModule} from './selected-panels-details/selected-panels-details.module';
import {SearchModule} from './search/search.module';
import {FeedbackModule} from './feedback/feedback.module';
import {SAPModule} from './sap/sap.module';
import {SSAModule} from './ssa/ssa.module';
import {HistoryModule} from './history/history.module';

@NgModule({
  imports: [
      AccountModule,
      SessionModule,
      SummaryModule,
      SelectedPanelsModule,
      SelectedPanelsDetailsModule,
      SearchModule,
      FeedbackModule,
      SubmitProcessModule,
      SAPModule,
      SSAModule,
      HistoryModule
  ],
  providers: [
  ],
  declarations: [
  ],
  exports: [
      AccountModule,
      SessionModule,
      SummaryModule,
      SelectedPanelsModule,
      SelectedPanelsDetailsModule,
      SearchModule,
      FeedbackModule,
      SubmitProcessModule,
      SSAModule,
      SAPModule,
      HistoryModule
  ],
  bootstrap: [
    AccountModule,
    SessionModule,
    SelectedPanelsModule,
    SelectedPanelsDetailsModule,
    SearchModule,
    FeedbackModule,
    SubmitProcessModule,
    SSAModule,
    HistoryModule
  ]
})
export class BusinessModule { }
