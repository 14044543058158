import {Component, Input} from '@angular/core';
import {CheckboxComponent} from '../checkbox/checkbox.component';
import {Field} from '../field/field.component';

@Component({
    selector: 'mad-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
    providers: Field.Providers(RadioComponent)
})
export class RadioComponent extends CheckboxComponent {
    @Input() size: 'small' | 'default';
    @Input() group: string;
    onClick () {
        if (!this.input.nativeElement.checked) {
            this.input.nativeElement.checked = true;
        }
    }
}
