<ng-container *ngIf="queue?.length"> <!-- TODO: leave visible until user clicks close || hasError-->
  <div style="position:fixed;top:0;left:0;right:0;bottom:0;z-index:2;background:rgba(225,225,225,.95);display:flex;align-items:center;justify-content:center">
    <div style="display:inline-flex;background:white;border-radius:0.5em;position:relative;flex-direction:row;width:1000px;height:400px;align-items:center;overflow:hidden;">
      <div style="display:inline-flex;flex-direction:row;width:1000px;height:400px;align-items:center;overflow:hidden;" [class.parent-error]="state === ERROR">
        <ng-container *ngIf="statusPath === userPath">
          <ng-container *ngTemplateOutlet="calculatorTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="thinkingTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="emailTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="errorTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="successTemplate"></ng-container>
        </ng-container>
        <ng-container *ngIf="statusPath === reviewingPath">
          <ng-container *ngTemplateOutlet="ssaEmailingTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="ssaErrorTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="ssaSuccessTemplate"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>


<ng-template #calculatorTemplate>
  <div class="calculator" #calculator [class.running]="state === CALCULATOR">
    <div class="screen" style="visibility:hidden">
    </div>
    <div class="screen-wrapper">
      <div #screen class="screen" style="top:7px;margin-left:0;">
        <div style="font-size:56px;flex:1;justify-content:end;padding-right:1em;align-items:center;display:flex;overflow:hidden">
          <div *ngFor="let letter of word;index as i" class="character" [class.calc]="state === CALCULATOR" [class.think]="state === THINKING" [class.emailch]="state === EMAIL"  [style.transition-delay]="(state === CALCULATOR) ? 0 : ((( (word.length-1) - i) * 0) + 'ms')" [style.transform]="getCharacterTransform(letter, i)" (transitionend)="onTransitionEnd(letter, i)" >
          </div>
        </div>
      </div>
    </div>

    <div class="button-row">
      <div #button class="button 7" [class.pressed]="getButtonPressedClass(0)"></div>
      <div #button class="button 8" [class.pressed]="getButtonPressedClass(1)"></div>
      <div #button class="button 9" [class.pressed]="getButtonPressedClass(2)"></div>
      <div #button class="button %" [class.pressed]="getButtonPressedClass(3)"></div>
    </div>

    <div class="button-row">
      <div #button class="button 4" [class.pressed]="getButtonPressedClass(4)"></div>
      <div #button class="button 5" [class.pressed]="getButtonPressedClass(5)"></div>
      <div #button class="button 6" [class.pressed]="getButtonPressedClass(6)"></div>
      <div #button class="button *" [class.pressed]="getButtonPressedClass(7)"></div>
    </div>

    <div class="button-row">
      <div #button class="button 1" [class.pressed]="getButtonPressedClass(8)"></div>
      <div #button class="button 2" [class.pressed]="getButtonPressedClass(9)"></div>
      <div #button class="button 3" [class.pressed]="getButtonPressedClass(10)"></div>
      <div #button class="button -" [class.pressed]="getButtonPressedClass(11)"></div>
    </div>

    <div class="button-row">
      <div #button class="button 0" [class.pressed]="getButtonPressedClass(12)"></div>
      <div #button class="button ." [class.pressed]="getButtonPressedClass(13)"></div>
      <div #button class="button =" [class.pressed]="getButtonPressedClass(14)"></div>
      <div #button class="button +" [class.pressed]="getButtonPressedClass(15)"></div>
    </div>
  </div>
</ng-template>

<ng-template #thinkingTemplate>
  <div class="thinking" #thinking [class.running]="state === THINKING">
    <mad-icon icon="fa-cloud" style="font-size:150px;color:white;transition:text-shadow 1000ms, transform 500ms;" [style.text-shadow]="(state === THINKING || state === EMAIL) ? '0px 3px 7px rgb(0, 167, 181)' : null"></mad-icon>
  </div>
</ng-template>

<ng-template #emailTemplate>
  <div class="email" #email [class.running]="state === EMAIL">
    <mad-icon icon="fa-rocket" style="font-size:150px;transition:text-shadow 1000ms, transform 500ms;text-shadow:0px 5px 15px rgb(0, 167, 181), 0px 0px 16px #e6bb48"></mad-icon>
  </div>
</ng-template>

<ng-template #errorTemplate>
  <div class="error" #error [class.running]="state === ERROR">
    <mad-icon class="error-icon" 
      icon="fa-exclamation-circle" >
    </mad-icon>
      <div style="position:absolute;left:0;right:0;text-align:center;top:1.5em;font-size:20px;transition: opacity 500ms;opacity: 1;" >
        An error occurred. Please try again.
        <br>
        {{session?.state?.submit_error}}
      </div>
  </div>
</ng-template>

<ng-template #successTemplate>
  <div class="success" #success [class.running]="state === REVIEWING || state === APPROVED">
    <div style="width:100%;text-align:center;">
    </div>
      <div style="width:100%;font-weight:300;text-align:center;top:1.5em;font-size:20px;transition: opacity 500ms;opacity: 1;" >
        <div madTypography="header-3" style="font-size:50px;display:flex;align-items:center;justify-content:center;position:absolute;top:0;height:100px;left:0px;right:0px;margin-bottom:1em;">
          <ng-container *ngIf="state === APPROVED">
            Approved!
          </ng-container>
          <ng-container *ngIf="state === REVIEWING">
            Review pending
          </ng-container>
        </div>
        <a (click)="onCreateNewClick()" class="close-btn">&#10005;</a>
        <div style="padding:1em;font-size:23px">
          Your session has been successfully submitted
          <ng-container *ngIf="state === APPROVED">
            and approved. This new pricing has been submitted and should be live within two business days.
          </ng-container>
          <ng-container *ngIf="state === REVIEWING">
            . A decision will be communicated by email. If you need to revise or cancel your request, please contact your Sales Support Analyst.
          </ng-container>
        </div>
      </div>
      <div style="display:flex;position:absolute;bottom:17px;">
        <mad-button *ngIf="session?.ssa_reviewing" (click)="onReviewClick()" style="min-width:100px;margin-right:15px">Review now</mad-button>
        <mad-button (click)="onCreateNewClick()" style="min-width:100px;margin-right:15px">Create new session</mad-button>
        <mad-button type="link" (click)="onViewHistoryClick()" style="min-width:100px;margin-right:15px;">View history</mad-button>
      </div>
  </div>
</ng-template>

<ng-template #ssaEmailingTemplate>
  <div class="thinking" [class.running]="state === SSA_EMAILING">
    <mad-icon icon="fa-envelope" style="font-size:150px;color:white;transition:text-shadow 1000ms, transform 500ms;text-shadow:0px 5px 15px rgb(0, 167, 181), 0px 0px 16px #e6bb48"></mad-icon>
  </div>
</ng-template>

<ng-template #ssaErrorTemplate>
  <div class="error" #error [class.running]="state === SSA_ERROR">
    <mad-icon class="error-icon" 
      icon="fa-exclamation-circle" >
    </mad-icon>
      <div style="position:absolute;left:0;right:0;text-align:center;top:1.5em;font-size:20px;transition: opacity 500ms;opacity: 1;" >
        An error occurred. Please try again.
        <br>
        {{session?.state?.submit_error}}
      </div>
  </div>
</ng-template>

<ng-template #ssaSuccessTemplate>
  <div class="success" #success [class.running]="state === SSA_REVIEWED">
    <div style="width:100%;text-align:center;">
    </div>
      <div style="width:100%;font-weight:300;text-align:center;top:1.5em;font-size:20px;transition: opacity 500ms;opacity: 1;" >
        <div madTypography="header-3" style="font-size:50px;display:flex;align-items:center;justify-content:center;position:absolute;top:0;height:100px;left:0px;right:0px;margin-bottom:1em;">
          Review Complete
        </div>
        <a (click)="onCreateNewClick()" class="close-btn">&#10005;</a>
        <div style="padding:1em;font-size:23px">
          You have completed the review for this session and notifications have been sent.
        </div>
      </div>
      <div style="display:flex;position:absolute;bottom:17px;">
        <mad-button (click)="onCreateNewClick()" style="min-width:100px;margin-right:15px">Create new session</mad-button>
        <mad-button type="link" (click)="onViewHistoryClick()" style="min-width:100px;margin-right:15px;">View history</mad-button>
      </div>
  </div>
</ng-template>
