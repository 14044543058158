import { Component, OnInit } from '@angular/core';

import { IFinancialCategory } from '@shared/model/financial-category';

import { AdminService } from '../../../services/admin/admin.service';
import { ICatAssign, IAssignedCategoriesData } from '@shared/model/service/cat-assign';

const PAGE_SIZE = 200;

@Component({
    selector: 'irl-assigned-categories',
    templateUrl: './assigned-categories.component.html',
    styleUrls: ['./assigned-categories.component.scss']
})
export class AssignedCategoriesComponent implements OnInit {

    categories: IFinancialCategory[] = [];

    assignedCategories: ICatAssign[] = [];

    // Codes that exist in madsalesforce.lims_test__c with lims='LYNXX US' and marked as active
    missingCodes: ICatAssign[] = [];

    // Codes that exist in admin_test_code, but not in madsalesforce.lims_test__c
    invalidCodes: ICatAssign[] = [];

    pages: ICatAssign[][] = [];

    pageNum = 0;

    selectedTest: ICatAssign;

    selectedMissing: ICatAssign;

    selectedInvalid: ICatAssign;

    saving = false;

    constructor(
        private adminService: AdminService
    ) { }

    async ngOnInit() {
        await this.getFinancialCategories();
        await this.getAssignedCategories();
    }

    selectCategory(ctg: IFinancialCategory): void {
        console.log("selectCategory: ", ctg);
    }

    selectTest(tCode: ICatAssign): void {
        this.selectedTest = tCode;
    }

    selectMissing(tCode: ICatAssign): void {
        console.log("selectMissing: ", tCode);
        this.selectedMissing = tCode;
    }

    selectInvalid(tCode: ICatAssign): void {
        this.selectedInvalid = tCode;
    }

    addTest(tCode: any): void {
        console.log("TODO: addTest", tCode);
    }

    categorySelected(tCode: ICatAssign, ctg: any): void {
        console.log("categorySelected: ", tCode, ctg);
    }

    compareCategory(ctg1: IFinancialCategory, ctg2: IFinancialCategory): boolean {
        return ctg1 === ctg2;
    }

    isMismatch(ctg: string): boolean {
        if (!ctg) {
            return true;
        }
        if (this.categories.findIndex(function(c: IFinancialCategory) { return c.name === ctg; }) === -1) {
            return true;
        }
        return false;
    }

    splitPages(): ICatAssign[][] {
        if (!this.assignedCategories) {
            return;
        }
        this.pages = []
        for (let i = 0; i < this.assignedCategories.length; i += PAGE_SIZE) {
            const chunk = this.assignedCategories.slice(i, i + PAGE_SIZE)
            this.pages.push(chunk)
        }

        console.log("this.pages=", this.pages);
        return this.pages;
    }

    nextPage(): void {
        this.pageNum++;
        this.pageNum = Math.min(this.pageNum, this.pages.length) + 1;
    }

    prevPage(): void {
        this.pageNum--;
        this.pageNum = Math.max(this.pageNum, 0);
    }

    goPage(ix: number): void {
        this.pageNum = ix;
    }

    async getFinancialCategories() {
        try {
            this.categories = await this.adminService.getFinancialCategories();
        } catch (err) {
            console.error("Error getting financial categories: ", err);
        }
    }

    async getAssignedCategories(): Promise<any> {
        try {
            const catAssignResp: IAssignedCategoriesData = await this.adminService.getAssignedCategories();

            this.assignedCategories = catAssignResp.assignedCategories;
            this.missingCodes = catAssignResp.missingCodes;
            this.invalidCodes = catAssignResp.invalidCodes;

            this.splitPages();

        } catch (err) {
            console.error("Error getting assigned categories: ", err);
        }
    }
}
