import { Component, ElementRef, forwardRef, Input, Renderer2, ChangeDetectorRef } from "@angular/core";
import { InputActions } from '../input/input.actions';
import { InputComponent } from "../input/input.component";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Field } from '../field/field.component';

export const SELECT_VALUE_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NumberComponent),
    multi: true
};

const MAX_ERROR = "ERRORS_AND_FEEDBACK.OVERLARGE_NUMBER";
const MIN_ERROR = "ERRORS_AND_FEEDBACK.OVERSMALL_NUMBER";

@Component({
    selector: "mad-number",
    templateUrl: "../input/input.component.html",
    providers: [SELECT_VALUE_CONTROL_VALUE_ACCESSOR].concat(InputActions.Providers()).concat(Field.Providers(NumberComponent)),
    styleUrls: ["./number.component.scss"]
})

export class NumberComponent extends InputComponent {
    @Input() min: number;
    @Input() max: number;
    @Input() step: number;
    @Input() placeholder: string = '';

    inputType = 'number';

    constructor(public renderer: Renderer2, element: ElementRef, actions: InputActions, public changeDetector: ChangeDetectorRef) {
        super(renderer, element, actions, changeDetector);
    }

    getAttributes() {
        return {
            min: this.min,
            max: this.max,
            step: this.step,
            placeholder: this.placeholder
        };
    }

    overMax(num: number): boolean {
        return num > this.max;
    }

    underMin(num: number): boolean {
        return num < this.min;
    }

    validateNumber(num: number): void {
        const setError = (error, remove = false) => {
            const validations = (<any[]>this.error || []).slice();
            const index = validations.indexOf(error);
            if (index !== -1 && remove) {
                validations.splice(index, 1);
            } else if (index === -1 && !remove) {
                validations.push(error);
            }
            this.error = validations;
        };

        if (typeof this.max === 'number' && this.overMax(num)) {
            setError(MAX_ERROR);
        } else {
            setError(MAX_ERROR, true);
        }
        if (typeof this.min === 'number' && this.underMin(num)) {
            setError(MIN_ERROR);
        } else {
            setError(MIN_ERROR, true);
        }
    }

    onNgModelChange(value): void {
        this.validateNumber(value);
    }
}
