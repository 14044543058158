import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { IAccountRecent } from '@shared/model/account';
import { SafeHtml } from '@angular/platform-browser';
import { DomUtil } from '../../utils/dom';
import { AccountService } from '../../services/account/account.service';
import { AccountState } from '../../states/account/account.state';
import { MADAlertService } from '../../services/alert/alert.service';
import { InputComponent } from '../../components/input/input.component';
import { SAPActions } from './sap.actions';
import { ErrorHandler } from '../../providers/error-handler/error-handler';

@Component({
    selector: 'mad-sap',
    templateUrl: './sap.component.html',
    styleUrls: ['./sap.component.scss'],
    providers: [
        AccountService,
        ...(SAPActions.Providers())
    ]
})
export class SAPComponent implements OnInit, OnDestroy {
    public searchValue: string = '';

    public isLoading: boolean;

    public recentAccounts: IAccountRecent[];

    public errorMsg: string | SafeHtml = '';

    @ViewChild('searchInput') searchInput: InputComponent;

    private routeSubscription: Subscription;

    public constructor(
        public element: ElementRef,
        public action: SAPActions,
        public accountService: AccountService,
        public accountState: AccountState,
        public route: ActivatedRoute,
        public router: Router,
        private alertService: MADAlertService,
        public errorHandler: ErrorHandler
    ) {
        action.subscribe(({ type, $event }) => {
            switch (type) {
                case 'prev':
                    this.prevResult();
                    break;
                case 'next':
                    this.nextResult();
                    break;
                case 'prevent':
                    this.prevent($event);
                    break;
                case 'input-default':
                    this.openPractice({ sap: this.searchValue });
                    break;
            }
        });

        this.routeSubscription = this.route.params.subscribe((params): void => {
            if (params && params.sap) {
                setTimeout(() => {
                    console.log('SAP search params=', params);
                    this.searchValue = params.sap;
                    this.openPractice({ sap: params.sap });
                });
            }
        });
    }

    async ngOnInit(): Promise<void> {
        this.recentAccounts = await this.accountService.recent();
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    prevent($event: Event): void {
        $event.preventDefault();
    }

    prevResult(): void {
        this.nextResult(-1);
    }

    nextResult(direction: 1 | -1 = 1): void {
        let target: HTMLElement | InputComponent;
        const links = this.element.nativeElement.querySelectorAll('mad-link a');
        const result = DomUtil.queryUp(document.activeElement, links);
        if (!result) {
            if (direction === 1) {
                target = links[0];
            }
            if (direction === -1) {
                target = links[links.length - 1];
            }
        } else {
            for (let i = 0; i < links.length; i += 1) {
                if (links[i] === result) {
                    if (direction === -1) {
                        target = links[i - 1] || this.searchInput;
                    } else {
                        target = links[i + 1] || this.searchInput;
                    }
                    break;
                }
            }
        }

        if (target) {
            if (typeof target['focusInput'] === 'function') {
                (target as InputComponent).focusInput();
            } else if (typeof target.focus === 'function') {
                (target as HTMLElement).focus();
            }
        }
    }

    public async openPractice({ sap }: { sap: string }): Promise<boolean> {
        delete this.errorMsg;

        this.alertService.setBusy(true, `Loading account ${sap}`);
        this.isLoading = true;

        let success;
        try {
            success = await this.accountState.openAccount(sap, null);
            console.log('success=', success);
        } catch (err) {
            /**
             * openAccount can throw various errors:
             * ORDER_BLOCK
             * GROUP_NOT_ELIGIBLE
             * SAP_NO_ACCESS
             * SAP_NOT_FOUND
             * SAP_UNSPEC
             * SYS_ERROR
             */
            console.error("handleAccount threw error: " + err, err);
            if (err.code) {
                const safeMsg = this.errorHandler.getSafeErrorMessage(err);
                this.errorMsg = safeMsg;
            } else {
                this.errorMsg = "Account not found or not eligible. Contact <a href='mailto:salestools@idexx.com'>salestools@idexx.com</a> for support.";
            }
            success = false;
        }

        this.isLoading = false;
        this.alertService.setBusy(false);

        return success;
    }

    public searchValueChange(newValue: string): void {
        this.errorMsg = "";
    }
}
