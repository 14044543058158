import { Message } from '@services/lib/api/websocket/message';

export interface IWebSocketSubscriptionHandle {
    unsubscribe(): Promise<Message>;
}

export class WebSocketSubscription<DATA = any, PATH = any, PARAMS extends { [key: string]: any } = any> {
    onMessage: Function[] = [];

    constructor(public path: PATH, public fullPath: string, public params: PARAMS, public ws: any) { }

    updateParam(key: keyof PARAMS, value: PARAMS[typeof key]): void {
        this.params[key] = value;
    }

    next(msg: any): void {
        const onMessage = this.onMessage;
        for (let i = 0; i < onMessage.length; i += 1) {
            onMessage[i](msg);
        }
    }

    subscribe(onMessage: (message: Message<DATA>) => any): IWebSocketSubscriptionHandle {
        this.onMessage.push(onMessage);
        return {
            unsubscribe: (): Promise<Message> => {
                return new Promise<Message>((resolve, reject): void => {
                    try {
                        console.log('websocket client.unsubscribe');
                        const index = this.onMessage.indexOf(onMessage);
                        if (index !== -1) {
                            this.onMessage.splice(index, 1);
                        }
                        if (this.onMessage.length === 0) {
                            resolve(this.ws.unsubscribe(this));
                        }
                        resolve(null);
                    } catch (err) {
                        reject(err);
                    }
                });
            }
        } as IWebSocketSubscriptionHandle;
    }
}

