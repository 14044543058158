import { ISessionPanel } from './session-panel';
import { IAccount } from './account';

interface EditableSession {
    id: number;
    sap: string;
    inactive: boolean;
    submitted: boolean;
    approved: boolean;
    status: ISessionStatus;
    status_on: Date;
    proposed_on: Date;
    proposed_by: string;
    reviewing: boolean;
    reviewed_on: Date;
    reviewed_by: string;
    reason: Reason;
    cc_lkam: boolean;
    cc_rsm: boolean;
    cc_emails: string;
    vdc_comments: string;
    reviewed_comments?: string;
    need_by: Date;
    updated_by: string;
    updated_on: Date;
    default_lkam_email: string;
    default_rsm_email: string;
    default_need_by: Date;
    no_invoice: boolean;
    invoice_submitted: boolean;

    selected_panels: ISessionPanel[];
}

export enum Reason {
    CompetitivePressure = 'competitive_pressure',
    ProtocolCategoryAdoption = 'protocol_category_adoption',
    OptimizingContractTerms = 'optimizing_contract_terms',
    ReactionToChangesInListPricing = 'reaction_to_changes_in_list_pricing',
    WinVolumeFromCompetition = 'win_volume_from_competition',
}

export interface ISession extends EditableSession {
    versions: { [key in keyof EditableSession | 'relationships']: number };
}

export interface ISessionSummary extends ISession {
    account: IAccount;
}

export type ISessionStatus = 'USER_PRICING' | 'USER_VERIFYING' | 'USER_EMAILING' | 'USER_ERROR' | 'AUTO_REVIEWED' | 'SSA_REVIEWING' | 'SSA_EMAILING' | 'SSA_REVIEWED' | 'SSA_ERROR';
