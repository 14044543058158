import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {LinkComponent} from './link.component';
import {FieldModule} from './../field/field.module';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     FieldModule
  ],
  declarations: [
    LinkComponent
  ],
  exports: [
    LinkComponent
  ],
  bootstrap: [LinkComponent]
})
export class LinkModule { }
