export class Queue<T extends { field: string }> {
    private items: T[];

    public constructor() {
        this.items = [];
    }

    public size(): number {
        return this.items.length;
    }

    public enqueue(item: T): void {
        this.items.push(item);
    }

    public dequeue(): T {
        const item = this.items.shift();
        return item;
    }

    public contains(fieldName: string): boolean {
        return this.find(fieldName) !== null;
    }

    public find(fieldName: string): T {
        return this.items.find((item: T): boolean => item.field === fieldName);
    }

    // Removes any item with matching field name
    public remove(fieldName: string): boolean {
        const hasItems = this.contains(fieldName);
        this.items = this.items.filter((item: T): boolean => item.field !== fieldName);
        return hasItems;
    }

    public peek(): T {
        return this.items[0];
    }

    public printQueue(): T[] {
        return this.items;
    }
}
