<span class="mad-icon-inner">
  <i *ngIf="_iconIsFA" 
    [class]="'fas ' + icon + ' icon'"
    [class.show]="!showAlt && !loading"
    [style]="getStyle()"
  ></i>
  <svg *ngIf="!_iconIsFA" 
    class="spot-icon spot-link__icon icon"
    [class.show]="!showAlt && !loading"
    [style]="getStyle()"
    aria-labelledby="title">
      <title></title>
      <use [attr.xlink:href]="'/assets/spot/dist/icons/spot_icons.svg#' + icon"></use>
  </svg>
  <div *ngIf="altIcon && _altIsFA" class="alt-icon" [class.show]="showAlt">
    <i 
       [class]="'fas ' + altIcon"
    ></i>
  </div>
  <svg *ngIf="altIcon && !_altIsFA" 
    class="spot-icon spot-link__icon alt-icon"
    [class.show]="showAlt"
    [style]="getStyle()"
    aria-labelledby="title">
      <title></title>
      <use [attr.xlink:href]="'/assets/spot/dist/icons/spot_icons.svg#' + altIcon"></use>
  </svg>
  <div *ngIf="loadingIcon && _loadingIsFA" class="alt-icon" [class.show]="loading">
    <i 
       [class]="'fas ' + loadingIcon"
    ></i>
  </div>
  <svg *ngIf="loadingIcon && !_loadingIsFA" 
    class="spot-icon spot-link__icon alt-icon spot-loading-spinner"
    [class.show]="loading"
    [style]="getStyle()"
    aria-labelledby="title">
      <title></title>
      <use [attr.xlink:href]="'/assets/spot/dist/icons/spot_icons.svg#' + loadingIcon"></use>
  </svg>
</span>
