import { Injectable } from '@angular/core';

@Injectable()
export class WebSocketConfig {
    url = '/ws-connect';

    reconnectDelay = 5000;

    connectTimeout = 15000;

    constructor(c: WebSocketConfig) {
        if ('url' in c) {
            this.url = c.url;
        }
        if ('reconnectDelay' in c) {
            this.reconnectDelay = c.reconnectDelay;
        }
        if ('connectTimeout' in c) {
            this.connectTimeout = c.connectTimeout;
        }
    }
}
