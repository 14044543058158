import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentsModule} from './../../components/components.module';
import {BrowserModule} from '@angular/platform-browser';
import {SummaryComponent} from './summary.component';
import {SelectedPanelsDetailsModule} from './../selected-panels-details/selected-panels-details.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthState} from './../../states/auth/auth.state';
import {AccountState} from './../../states/account/account.state';
import {UserService} from './../../services/user/user.service';
import {AccountService} from './../../services/account/account.service';
import {SessionService} from './../../services/session/session.service';
import {TranslocoModule} from '@ngneat/transloco';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     FormsModule,
     ReactiveFormsModule,
     ComponentsModule,
     TranslocoModule,
     SelectedPanelsDetailsModule
  ],
  providers: [
      AuthState,
      UserService,
      AccountState,
      AccountService,
      SessionService
  ],
  declarations: [
      SummaryComponent
  ],
  exports: [
    SummaryComponent
  ],
  bootstrap: [SummaryComponent]
})
export class SummaryModule { }
