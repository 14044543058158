import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentsModule} from './../../components/components.module';
import {BrowserModule} from '@angular/platform-browser';
import {HistoryComponent} from './history.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     FormsModule,
     ComponentsModule
  ],
  providers: [
  ],
  declarations: [
      HistoryComponent
  ],
  exports: [
    HistoryComponent
  ],
  bootstrap: [HistoryComponent]
})
export class HistoryModule { }
