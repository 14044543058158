import {Component, ElementRef, HostListener, Input, OnChanges, OnInit, ViewChild} from "@angular/core";
import {ControlValueAccessor} from "@angular/forms";
import {BrowserUtil} from "../../utils/browser";
import {DomUtil} from "../../utils/dom";
import {Field} from "../field/field.component";

export class Option<T> {
    label: string;
    value: T;
    disabled: boolean = false;
    selected: boolean = false;
}
export class Placeholder {
    text: string;
    disabled: boolean = true;
}

@Component({
    selector: "mad-select",
    providers: Field.Providers(SelectComponent),
    templateUrl: "./select.component.html",
    styleUrls: ["./select.component.scss"]
})
export class SelectComponent<T= string> extends Field implements OnInit, OnChanges, ControlValueAccessor {
    @ViewChild("select") selectElement: ElementRef;
    @Input() options: Option<T>[];
    @Input() tabindex: number;
    @Input() placeholder: Placeholder | string;
    @Input() className: string;
    @Input() emptyOption: Option<string> | any = {
        label: "",
        value: ""
    };

    _is_win = BrowserUtil.isWindows();
    _isEmpty: boolean = true;
    _dropDownVisible: boolean = false;

    _timer: any;
    _lock: number = 0;
    classNameMods: string = "";
     _value: T | string;
     _writing: boolean;

    set value (value) {
        if (value !== this._value) {
            if (value === null) {
                // null is reset
                value = undefined;
            }
            this._value = value;

            if (!this._writing) {
                if (this._onChange) {
                    this._onChange(value);
                }
                if (this._onTouched) {
                    this._onTouched();
                }
            }
        }
    }
    get value (): T | string {
        return this._value;
    }

    constructor (public element: ElementRef) {
        super(element);
    }

    ngOnInit () {
    }

    ngOnChanges (): void {
        if (typeof this.placeholder === "string") {
            this.placeholder =  {
                text: this.placeholder,
                disabled: true
            };
        }
        if (this.emptyOption) {
            if (typeof this.emptyOption !== "object") {
                this.emptyOption = {
                    label: typeof this.emptyOption === "string" ? this.emptyOption : "" ,
                    value: ""
                };
            }
            if (this.emptyOption.label === undefined) {
                this.emptyOption.label = "";
            }
            if (this.emptyOption.value === undefined) {
                this.emptyOption.value = "";
            }
        }

        this.classNameMods =  (this.hover && " spot-form__select--hover" || "") +
            (this.focus && " spot-form__select--focus" || "") +
            (this.disabled && " spot-form__select-input--disabled" || "") +
            (this.error && " spot-form--error" || "") +
            (this.className && (" " + this.className) || "");


        this._isEmpty = this.emptyOption && this.value === this.emptyOption.value;
        if (this._isEmpty && this.placeholder) {
            this.value = undefined;
        }
        if (this._isEmpty || this.value === "") {
            // this hack to appease safari (safari selects the first option by default)
            if (this._timer) {
                clearTimeout(this._timer);
            }
            this.selectElement.nativeElement.value = "";
            const lock = ++this._lock;
            this._timer = setTimeout((): void => {
                if (this._lock !== lock) {
                    return;
                }
                this._timer = 0;
                this.selectElement.nativeElement.value = "";
            });
        } else if (this._timer) {
            clearTimeout(this._timer);
            this._timer = 0;
        }
    }

    trackByOption (i, d) {
        return d.value;
    }

    writeValue (value: any) {
        this._writing = true;
        if (this.emptyOption && (this.emptyOption.value === value || value === null) && this.placeholder) {
            this.value = null;
        } else {
            this.value = value;
        }
        this._writing = false;
    }
    onValueChange (value): void {
        this.value = value;
        this.ngOnChanges();
    }


    onKeyDown ($event): void {
        //if ($event.keyCode === 13) {
            //this._dropDownVisible = true;
        //}
        //if (!this._is_win) {
            //return;
        //}
        //if ($event.altKey && ($event.keyCode === 40 || $event.keyCode === 38)) {
            //this._dropDownVisible = true;
        //}
    }

    onKeyUp ($event): void {
        //if (!this._is_win) {
            //return;
        //}
        //if ($event.keyCode === 27) {
            //this._dropDownVisible = false;
        //}
    }

    onClick (): void {
        //if (!this._is_win) {
            //return;
        //}
        //this._dropDownVisible = true;
    }

    @HostListener("document:click", ["$event"]) onDocumentClick ($event): void {
        //if (!this._is_win) {
            //return;
        //}
        //if ($event.target !== this.selectElement.nativeElement && this._dropDownVisible) {
            //this._dropDownVisible = false;
        //}
    }

    onBlur (): void {
        //if (this._is_win) {
            //this._dropDownVisible = false;
        //}
        if (this._onTouched) {
            this._onTouched();
        }
    }

    focusInput (): void {
        this.selectElement.nativeElement.focus();
    }
    blurInput (): void {
        this.selectElement.nativeElement.blur();
    }
}

