import { Observable, BehaviorSubject, Subscription } from "rxjs";
import { FormGroup, FormControl, AbstractControl } from "@angular/forms";
import { PRICE_TYPES } from "@shared/model/session-panel";
import { ISession, Reason } from "@shared/model/session";
import { Message } from "@services/lib/api/websocket/message";
import { MadErrorUtils } from "@shared/model/error/error-utils";
import { Queue } from "@shared/model/utils/queue";
import { IUserClaims } from "@shared/model/user";
import { MadClientError } from "../../utils/client-error";
import { ErrorHandler } from "../../providers/error-handler/error-handler";
import { IWebSocketSubscriptionHandle } from "../../providers/websocket";
import { SessionService } from "../../services/session/session.service";
import { UserState } from "../user/user.state";
import { PriceService } from "../../services/price/price.service";
import { ReferenceService } from "../../services/reference/reference.service";
import { PanelService } from "../../services/panel/panel.service";
import { LIMSService } from "../../services/lims/lims.service";
import { InvoiceService } from "../../services/invoice/invoice.service";
import { SessionPanels } from "./session-panels";
import { SessionInvoices } from "./session-invoices";

import type { SessionMessage } from "../../../../../services/lib/session/session.service";

const STATUS_TIMEOUT = 45 * 1000;

export interface ISessionInit {
    sap: string;
    sessionId?: number;
    sessionService: SessionService;
    priceService: PriceService;
    referenceService: ReferenceService;
    panelService: PanelService;
    invoiceService: InvoiceService;
    limsService: LIMSService;
    errorHandler: ErrorHandler;
    userState: UserState;
}

export interface IFieldUpdate {
    sap: string;
    id: number;
    field: string;
    value: boolean | string | Date;
}

export interface IFieldDef {
    text?: string;
    badge?: string;
    type?: string;
}

export class SessionState {
    sessionSubscriptionHandle: IWebSocketSubscriptionHandle;

    sapId: string;

    sessionId: number;

    errorHandler: ErrorHandler;

    sessionService: SessionService;

    panelService: PanelService;

    limsService: LIMSService;

    invoiceService: InvoiceService;

    priceService: PriceService;

    referenceService: ReferenceService;

    userState: UserState;

    userSubscription: Subscription;

    panels: SessionPanels;

    invoices = new SessionInvoices(this);

    vdc_reviewing = false;

    ssa_reviewing = false;

    statusTimer: NodeJS.Timeout;

    submitErrorTimer: NodeJS.Timeout;

    handleMissingTimer: NodeJS.Timeout;

    changes = new BehaviorSubject(null);

    missingVersions: number[] = [];

    state: ISession & { submit_error?: string };

    preapproved: boolean;

    updateQueue: Queue<IFieldUpdate> = new Queue<IFieldUpdate>();

    updating: boolean;

    form?: FormGroup = new FormGroup({
        reason: new FormControl(),
        cc_lkam: new FormControl(),
        cc_rsm: new FormControl(),
        cc_emails: new FormControl([
            {
                type: "email",
                badge: "LKAM",
                self: this,
                persist: true,
                get text(): string {
                    return (this.self.state && this.self.state.default_lkam_email) || "LKAM not specified";
                },
                hidden: true
            },
            {
                type: "email",
                badge: "RSM",
                self: this,
                persist: true,
                get text(): string {
                    return (this.self.state && this.self.state.default_rsm_email) || "RSM not specified";
                },
                hidden: true
            }
        ]),
        lkam_email: new FormControl(),
        rsm_email: new FormControl(),
        need_by: new FormControl(),
        vdc_comments: new FormControl(),
        reviewed_comments: new FormControl(),
        invoice_submitted: new FormControl(),
        no_invoice: new FormControl()
    });

    timing = {
        // increase timing to react ui user state without
        // needing to recreate rules
        no_invoice: 16,
        invoice_submitted: 16
    };

    timers = {};

    constructor({ sap, sessionId, sessionService, priceService, panelService, invoiceService, limsService, referenceService, errorHandler, userState }: ISessionInit) {
        this.sapId = sap;
        this.sessionId = sessionId;
        this.sessionService = sessionService;
        this.priceService = priceService;
        this.referenceService = referenceService;
        this.panelService = panelService;
        this.invoiceService = invoiceService;
        this.limsService = limsService;
        this.errorHandler = errorHandler;
        this.userState = userState;

        this.panels = new SessionPanels(this);

        this.panels.changes.subscribe((): void => {
            this.checkApproval();
        });
        this.invoices.changes.subscribe((): void => {
            this.checkApproval();
        });

        this.processQueue();

        const controls = this.form.controls;
        const timers = this.timers;
        const timing = this.timing;
        const update = (field: string, send: boolean): void => {
            const current = this.state;
            if (send) {
                const value = controls[field].value;
                if (value === null || !controls[field].dirty) {
                    return;
                }
                current[field] = value;
                if (timers[field]) {
                    clearTimeout(timers[field]);
                }
                timers[field] = setTimeout(async (): Promise<void> => {
                    timers[field] = null;
                    let fieldVal = current[field];
                    if (fieldVal === "" || fieldVal === null || fieldVal === undefined) {
                        fieldVal = null;
                    }
                    if (field === "cc_emails") {
                        const defaultLkamEmail = current.default_lkam_email;
                        const defaultRsmEmail = current.default_rsm_email;

                        // filter out lkam/rsm before updating
                        fieldVal = fieldVal
                            .map((v: { text: string }): string => v.text)
                            .filter((v: string): boolean => v !== defaultLkamEmail && v !== defaultRsmEmail)
                            .join(",");
                    }
                    this.updateField({ sap, id: this.state.id, field, value: fieldVal }, true);
                    // await this.update({ sap, id: this.state.id, field, value: fieldVal });
                }, timing[field] || 500);
            }
        };

        Object.entries(controls).forEach((pEntry: [string, AbstractControl]): void => {
            const [pName, pControl] = pEntry;
            pControl.valueChanges.subscribe((v) => {
                update(pName, true);

                if (pName === "cc_emails") {
                    const emails = controls.cc_emails.value;
                    const lkamFound = emails.filter((lv: IFieldDef): boolean => lv.text === this.state.default_lkam_email)[0];
                    const rsmFound = emails.filter((rv: IFieldDef): boolean => rv.text === this.state.default_rsm_email)[0];
                    if (lkamFound && !lkamFound.hidden !== controls.cc_lkam.value) {
                        controls.cc_lkam.markAsDirty();
                        controls.cc_lkam.patchValue(!lkamFound.hidden);
                    }
                    if (rsmFound && !rsmFound.hidden !== controls.cc_rsm.value) {
                        controls.cc_rsm.markAsDirty();
                        controls.cc_rsm.patchValue(!rsmFound.hidden);
                    }
                } else if (pName === "cc_lkam") {
                    controls.cc_emails.value.filter((lcv: IFieldDef): boolean => lcv.badge === "LKAM")[0].hidden = !v;
                } else if (pName === "cc_rsm") {
                    controls.cc_emails.value.filter((lrv: IFieldDef): boolean => lrv.badge === "RSM")[0].hidden = !v;
                }
                // else {
                // console.log("Not doing anything from value changes for field: ", p);
                // }
            });
        });
    }

    public async disconnect(): Promise<boolean> {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        this.userSubscription = null;

        if (this.sessionSubscriptionHandle) {
            // wait for response
            const message = await this.sessionSubscriptionHandle.unsubscribe();
            if (message) {
                const response = await message.response();
                if (response && response.success) {
                    return true;
                }
                throw "DISCONNECT_ERROR";
            }
        }

        return true;
    }

    public async connect(): Promise<void> {
        let reconnectId: number = this.sessionId || null;
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        this.userSubscription = this.userState.changes.subscribe((v: IUserClaims): void => {
            console.log("userChange: ", v);
            this.updateFlags();
        });
        const connect = async (): Promise<boolean> => {
            return new Promise<boolean>(async (resolve, reject): Promise<void> => {
                try {
                    if (this.sessionSubscriptionHandle) {
                        await this.sessionSubscriptionHandle.unsubscribe();
                        this.sessionSubscriptionHandle = null;
                    }

                    const sub = await this.sessionService.subscribe({ sap: this.sapId, session: reconnectId }).toPromise();
                    let init = false;

                    this.sessionSubscriptionHandle = sub.subscribe(async (message): Promise<void> => {
                        if (message && message.data && message.data.target) {
                            if (message.data.error) {
                                console.log("Message has error set: ", message.data.error);
                                // Parse the error json string into an Error object
                                message.data.error = MadErrorUtils.parseError(message.data.error);
                            }
                            switch (message.data.target) {
                                case "session_selected_panels":
                                    // TODO check missing version
                                    // this.checkMissingVersions(message.data.data.selected_panels_version, this.state.versions.session_selected_panels);
                                    this.panels.handleMessage(message.data);
                                    break;
                                case "file":
                                    this.invoices.handleMessage(message.data);
                                    break;
                                case "session":
                                    if (reconnectId === null) {
                                        reconnectId = message.data.data.id;
                                        sub.updateParam("session", reconnectId);
                                    }
                                    await this.handleSession(message.data);
                                    if (init === false) {
                                        init = true;
                                        resolve(true);
                                    }
                                    break;
                                default:
                                    console.log("Unknown message target: ", message.data.target);
                                    break;
                            }
                        } else {
                            // this.errorHandler.toast("UNEXPECTED_ERROR_SS2");
                            console.error("UNEXPECTED_ERROR_SS2: ", message);
                            // Strip out client member to avoid circular json when logging.
                            const safeMessage = Message.safeJSON(message);
                            reject(new MadClientError("SYS_ERROR", "Error subscribing to session", null, safeMessage));
                        }
                    });
                } catch (err) {
                    reject(err);
                }
            });
        };

        await connect();
    }

    private updateFlags(): void {
        if (!this.userState.state.can_approve && this.state && (this.state.reviewing || this.state.approved !== null)) {
            this.vdc_reviewing = true;
        } else {
            this.vdc_reviewing = false;
        }
        if (this.userState.state.can_approve && this.state && this.state.reviewing) {
            this.ssa_reviewing = true;
        } else {
            this.ssa_reviewing = false;
        }
    }

    // checks to see if there are any gaps between version information -- if there are and not resolved before 3000ms, we need to refresh the session panels
    public checkMissingVersions(next: number | number[], current: number = this.state && this.state.versions && this.state.versions.relationships, tick = 1): void {
        if (Array.isArray(next)) {
            for (let i = 0; i < next.length; i += 1) {
                this.checkMissingVersions(next[i], current, tick);
            }
            return;
        }
        if (typeof current !== "number" || typeof next !== "number") {
            return;
        }
        const missing = this.missingVersions;
        if (this.state.versions.relationships < next) {
            this.state.versions.relationships = next;
        }
        for (let i = 0; i < missing.length; i += 1) {
            if (missing[i] === next) {
                missing.splice(i, 1);
                break;
            }
        }

        // missing versions start at current+1 and go until the next version (we only care about the in-between versions)
        for (let i = current + tick; i < next; i += 1) {
            missing.push(i);
        }

        if (!this.handleMissingTimer && missing.length) {
            this.handleMissingTimer = setTimeout((): void => {
                this.handleMissingTimer = null;
                if (missing.length) {
                    this.refresh(true);
                    // this.panels.refresh(true)
                }
            }, 3000);
        }
    }

    private async refresh(versioning = false): Promise<void> {
        const result = await this.sessionService
            .refresh({
                sap: this.state.sap,
                session: this.state.id,
                session_panel_ids: this.panels.data.map((v) => v.id),
                invoice_ids: this.invoices.data.map((v) => v.id)
            })
            .toPromise();

        if (!result.error) {
            const panels = result.data.panels;
            const invoices = result.data.invoices;

            this.handleSession({
                type: "UPDATE",
                target: "session",
                data: result.data
            });

            if (panels) {
                for (let i = 0; i < panels.length; i += 1) {
                    (panels[i] as any).added = true;
                    this.panels.handleMessage({
                        type: versioning ? "UPDATE" : "INSERT",
                        target: "session_selected_panels",
                        data: panels[i]
                    }, false);
                }
                this.panels.sortById();
            }

            if (invoices) {
                for (let i = 0; i < invoices.length; i += 1) {
                    this.invoices.handleMessage({
                        type: versioning ? "UPDATE" : "INSERT",
                        target: "file",
                        data: invoices[i]
                    }, false);
                }

                this.invoices.sort();
            }
            this.resetMissingVersions();
        }
    }

    public resetMissingVersions(): void {
        this.missingVersions = [];
    }

    private async handleSession(op: SessionMessage<"session">): Promise<void> {
        if (this.updating === true) {
            console.warn("session currently updating.  Ignoring handleSession call...");
            return;
        }

        this.sessionId = (op.data && op.data.id) ? op.data.id : this.sessionId;

        switch (op.type) {
            case "UPDATE":
                await this.sessionUpdated(op.data);
                break;
            case "INSERT":
                break;
            case "DELETE":
                // unexpected behavior -- alert user
                break;
            default:
                // unexpected behavior -- alert user
                this.errorHandler.toast("UNEXPECTED_ERROR_SS1");
                break;
        }
        this.updateFlags();
    }

    private async sessionUpdated(data: ISession): Promise<void> {
        this.checkStatus(data);

        let current = this.state;
        let changes = false;
        const form = this.form;
        let statusChanged = false;

        if (!current) {
            current = data;
            this.state = data;
            changes = true;

            await this.refresh();

            for (const p in data.versions) {
                let value = data[p];
                if (p === "status" && value) {
                    statusChanged = true;
                }
                if (form.controls[p]) {
                    if (p === "cc_emails") {
                        if (!value) {
                            value = "";
                        }
                        value = value.split(/[\s\t]*[;,][\s\t]*/).map((text: string): IFieldDef => ({ text, type: "email" }));
                        value.unshift(...form.controls.cc_emails.value.filter((v: IFieldDef): boolean => v.badge === "RSM" || v.badge === "LKAM"));
                    }
                    form.controls[p].markAsUntouched();
                    form.controls[p].reset(value);
                }
            }
        } else {
            current.default_lkam_email = data.default_lkam_email;
            current.default_rsm_email = data.default_rsm_email;
            current.default_need_by = data.default_need_by;

            for (const p in data.versions) {
                if (data.versions[p] > current.versions[p]) {
                    if (p === "relationships") {
                        // handle session does not fill gaps -- gaps are only ticked at the panel level
                        this.checkMissingVersions(data.versions.relationships, current.versions.relationships, 0);
                    }
                    changes = true;
                    if (+data.versions[p] > +current.versions[p] && !this.timers[p]) {
                        let value = data[p];
                        if (p === "status" && value) {
                            statusChanged = true;
                        }
                        current.versions[p] = data.versions[p];
                        current[p] = value;
                        if (form.controls[p]) {
                            if (p === "cc_emails") {
                                value = value.split(/[\s\t]*[\;\,][\s\t]*/).map((text: string) => ({ text, type: "email" }));
                                value.unshift(...form.controls.cc_emails.value.filter((v: IFieldDef) => v.badge === "RSM" || v.badge === "LKAM"));
                            }
                            form.controls[p].markAsUntouched();
                            form.controls[p].reset(value);
                        }
                    }
                }
            }
        }

        if (statusChanged) {
            this.runStatusTimer();
        }

        if (!current.need_by) {
            form.controls.need_by.markAsUntouched();
            form.controls.need_by.reset(current.default_need_by);
        }

        if (changes) {
            this.checkApproval();
        }
    }

    private runStatusTimer(): void {
        if (this.statusTimer) {
            clearTimeout(this.statusTimer);
        }
        this.statusTimer = setTimeout(() => {
            this.statusTimer = null;
            this.checkStatus(this.state);
        }, Math.max((STATUS_TIMEOUT + 5000) - (Date.now() - new Date(this.state.status_on).getTime()), 0));
    }

    private checkStatus(data: { status: string; reviewing: boolean; status_on: string | Date }): void {
        if (data.status && data.status_on) {
            if ((new Date(data.status_on)).getTime() + STATUS_TIMEOUT <= Date.now()) {
                data.status = null;
            }
        }
    }

    private checkApproval(): void {
        let preapproved = true;
        const panels = this.panels.data;

        for (let i = 0; i < panels.length; i += 1) {
            const panel = panels[i];
            if (panel.type === PRICE_TYPES.COMPETITIVE || !panel.preapproved) {
                preapproved = false;
            }
        }

        this.preapproved = preapproved;

        this.changes.next(true);
    }

    private async update({ sap, id, field, value }: IFieldUpdate): Promise<void> {
        // TODO should merge into result versions
        const result = await this.sessionService.update({ sap, session: id, field, value });
        if (result) {
            this.handleSession({
                type: "UPDATE",
                target: "session",
                data: result
            });
        }
    }

    public isValid(): boolean {
        return this.panels.isValid();
    }

    private competitivePressureIsValid(): boolean {
        if(this.state.reason !== Reason.CompetitivePressure) { return true; }
        return Boolean(this.state.invoice_submitted) || Boolean(this.state.no_invoice || this.invoices.data.length > 0);
    }

    public isValidToSubmit(): boolean {
        if(!this.state.reason || !this.competitivePressureIsValid()) {
            return false;
        }
        const arePanelsValid = this.panels.isValid();
        if (this.preapproved) {
            return arePanelsValid;
        }
        if (this.state.reason) {
            if (this.isReasonCompetitivePressure() || this.panels.containsCompetitivePanel()) {
                return this.invoices.data.length > 0 || this.state.invoice_submitted || this.state.no_invoice;
            }
            return true;
        }
        return false;
    }

    private isReasonCompetitivePressure(): boolean {
        return this.state.reason === Reason.CompetitivePressure;
    }

    private process(type: "SUBMIT" | "APPROVE" | "REJECT"): void {
        let statusError: "USER_ERROR" | "SSA_ERROR";
        let sse: Observable<any>;
        if (type === "APPROVE") {
            sse = this.sessionService.approve({
                session: this.state.id,
                sapId: this.sapId
            });
            statusError = "SSA_ERROR";
        } else if (type === "REJECT") {
            sse = this.sessionService.reject({
                session: this.state.id,
                sapId: this.sapId
            });
            statusError = "SSA_ERROR";
        } else {
            sse = this.sessionService.submit({
                session: this.state.id,
                sapId: this.state.sap
            });
            statusError = "USER_ERROR";
        }

        sse.subscribe((v) => {
            v.addEventListener("message", (data) => {
                if (data.json) {
                    if (data.json.error) {
                        console.error("Error here: ", data.json.error, data);
                        this.state.submit_error = data.json.error;
                        this.state.status = statusError;
                        if (this.submitErrorTimer) {
                            clearTimeout(this.submitErrorTimer);
                        }
                        this.submitErrorTimer = setTimeout(() => {
                            this.submitErrorTimer = null;
                            if (this.state.status === "SSA_ERROR") {
                                this.state.submit_error = null;
                                this.state.status = null;
                            }
                        }, 0);
                        v.close();
                    } else {
                        this.handleSession({
                            type: "UPDATE",
                            target: "session",
                            data: data.json.message
                        });
                    }
                }
            });
            v.addEventListener("error", (): void => v.close());
        });
    }

    public submit(): void {
        this.state.submit_error = null;
        if (this.isValidToSubmit()) {
            this.process("SUBMIT");
        }
    }

    public approve(): void {
        this.process("APPROVE");
    }

    public reject(): void {
        this.process("REJECT");
    }

    /**
     * Check if any requested field updates are present.
     * This is used to make updates run sequentially and limit the number of calls that go to the
     * backend if a user makes a large number of changes at once, say like typing slowly into a text
     * field.  Without this in place, every time the user paused 500ms, an update request was sent to
     * the backend.  These ended up piling up behind locked transactions in the database and failed
     * requests.  With this in place, only one update is called at a time, and any earlier unprocessed
     * requests to the same field will be ignored so only the latest is run.
     */
    private processQueue(): void {
        const queue = this.updateQueue;

        // if the queue is empty, try again in 500ms (based on field update debouncing)
        if (!queue.size()) {
            this.updating = false;
            setTimeout((): void => {
                this.processQueue();
            }, 500);
        } else {
            // pull the oldest update request,
            // run it
            // and then re-run the queue process

            // remove and save the oldest-first
            const request = queue.dequeue();
            this.updating = queue.size() > 0;

            this.update(request)
                .then(
                    (): void => {
                        console.log("update request finished: ", request);
                        // here we can do success handling for the saved field
                    },
                    (err: Error): void => {
                        console.log("update request failed: ", request, err);
                    }
                )
                .finally(() => {
                    this.processQueue();
                });
        }
    }

    private updateField(fieldReq: IFieldUpdate, killEarlier = false): number {
        const queue = this.updateQueue;

        const fieldName = fieldReq.field;
        if (killEarlier && queue.contains(fieldName)) {
            queue.remove(fieldName);
        }

        // push the current field value to the queue
        queue.enqueue(fieldReq);

        this.updating = true;

        return queue.size();
    }
}
