import { Injectable } from "@angular/core";
import { Actions } from "./../actions/actions";

@Injectable()
export class InputActions extends Actions({
    name: 'mad-input',
    types: {
        "input-default": {
            name: "Default Action"
        },
        "undo-changes": {
            name: "Undo cahnges"
        }
    },
    targets: {
        input: { matcher: "input" }
    },
    interactions: {
        keydown: {
            "Enter": { input: "input-default" },
            "Escape": { input: "undo-changes" },
            "ctrl+z": { input: "undo-changes" }
        }
    }
}) { }
