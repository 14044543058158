//import {animate, group, state, style, transition, trigger} from "@angular/animations";
import {ControlValueAccessor} from "@angular/forms";
import {Injectable,ViewChild, Component, ElementRef, Input, OnInit, ViewChildren, QueryList} from "@angular/core";
import {PillComponent} from "../pill/pill.component";
import {InputComponent} from "../input/input.component";
import {Actions} from "../actions/actions";
import {StringUtil} from "../../utils/string";
import {DomUtil} from "../../utils/dom";
import {Field} from "../field/field.component";

//TODO make this better
//may need to slice value to emit -- had to fix a bunch of broken shit Rocky and Ern changed

@Injectable()
export class EmailFaxActions extends Actions({
    name: "mad-email-fax",
    types: {
        "email-fax-blur": {name: "Blur email fax component", private: true},
        "email-fax-focus-last": {
            name: "Focus last pill value",
            description: "Focus the last pill value.  When the input has focus, the cursor needs to be at the start."
        },
    },
    targets: {
        "email-fax-value": {name: "Email/Fax value", matcher: ".email-fax-value"},
        input: {matcher: "input"}
    },
    interactions: {
        keydown: {
            "Backspace": {
                input: "email-fax-focus-last"
            }
        },
        blur: "email-fax-blur"
    }
}) {}

@Component({
    selector: "mad-email-fax",
    templateUrl: "./email-fax.component.html",
    styleUrls: ["./email-fax.component.scss"],
    providers: Field.Providers(EmailFaxComponent).concat(EmailFaxActions.Providers()),
})
export class EmailFaxComponent extends Field implements OnInit, ControlValueAccessor {
    @Input() placeholder = "Email/Fax";
    @Input() maxValues: number | undefined | null | false;
    @Input() allowFax: boolean = true;
    @Input() allowEmail: boolean = true;

    @ViewChild("input") input: InputComponent;
    @ViewChildren("pill") pills: QueryList<PillComponent>;

    _type: string;
    _writing: boolean;
    _inputValue: string = "";
    showInvalidHint: boolean = false;
    inputValIsVisible: boolean = false;
    value: {text: string, type: 'email' | 'fax', persist?: boolean; hidden?: boolean;}[] = [];

    constructor (public element: ElementRef, public action: EmailFaxActions) {
        super(element);
    }

    ngOnInit() {
        this.action.subscribe(action => {
            switch (action.type) {
                case "email-fax-blur": this.onBlur(); break;
                case "email-fax-focus-last": this.focusLastValue(action.$event); break;
                default: break;
            }
        });
    }

    writeValue(value: this['value'] | string | string[]) {
        this._writing = true;
        var values = [],
            current = this.value,
            map = {};

        var parseString = (s: string, datum?: this['value'][0]) => {
            var split = s.split(/[\s\t]*[\;\,]+[\s\t]*/)
            for (var j=0,jln=split.length;j<jln;j++) {
                var text = (split[j] || '').trim(),
                    lower = text.toLowerCase(),
                    type = datum && datum.type || this.validate(text),
                    key = `${type}:${lower}`;

                if (type && !map[key]) {
                    map[key] = true;
                    values.push(datum || {
                        type,
                        text
                    });
                }
            }
        }

        if (typeof value === 'string') {
            parseString(value);
        } else if (value && value.length) {
            for (var i=0,ln=value.length;i<ln;i++) {
                var datum = value[i];
                if (typeof datum === 'string') {
                    parseString(datum);
                } else if (datum.text) {
                    parseString(datum.text, datum);
                } else {
                    console.error('invalid type', datum);
                }
            }
        }

        for (var i=0,ln=current.length;i<ln;i++) {
            var item = current[i],
                key = `${item.type}:${(item.text || '').toLowerCase()}`

            if (!map[key]) {
                this.removeTransition(item);
                values.splice(i,0,item);
            }
        }

        this.value = values;
        this.updateValue();


        this._writing = false;
    }

    trackBy (i, d) {
        return d.type + ':' + d.text;
    }

    focusInput() {
        this.input.focusInput();
    }

    blurInput() {
        this.input.blurInput();
    }

    onBlur() {
        //TODO? consider it later
        //this._inputValue = "";
    }

    onInput() {
        this.showInvalidHint = false;
    }

    focusLastValue($event: Event) {
        if (document.activeElement !== this.input.input.nativeElement || DomUtil.isCursorAtStart(document.activeElement as HTMLElement)) {
            if ($event) {
                $event.preventDefault();
            }
            if (this.pills.last) {
                this.pills.last.focus();
            }
        }
    }


    defaultFocus($event: Event) {
        if (this.input && this.input.element && !DomUtil.queryUp($event.target as HTMLElement, ["a", this.input.element.nativeElement])) {
            this.focusInput();
        }
    }

    showInputVal() {
        this.inputValIsVisible = true;
    }

    shouldHideAddEmailButton() {
        return (this.inputValIsVisible == true) || (this.getLength() >= this.maxValues)
    }

    validate(input: string = this._inputValue || ''): "email" | "fax" {
        const text = input.trim();
        if (this.allowFax && StringUtil.isFax(text)) {
            return "fax";
        } else if (this.allowEmail && StringUtil.isEmail(text)) {
            return "email";
        } else {
            return null;
        }
    }

    add() {
        const text = (this._inputValue || "").trim(),
            lower = text.toLowerCase(),
            dataType = this.validate();
        if (dataType) {
            var value = this.value,
                found = false;
            for (var i=0,ln=value.length;i<ln;i++) {
                if (value[i].type === dataType && value[i].text.toLowerCase() === lower) {
                    found = true;
                    value[i].hidden = false;
                    break;
                }
            }
            this._inputValue = "";
            this.showInvalidHint = false;
            if (!found) {
                this.value.push({type: dataType, text});
            }
            this.updateValue();
            this.inputValIsVisible = false;
        } else if (text) {
            this.showInvalidHint = true;
        }
    }

    remove(target: HTMLElement | PillComponent) {
        if (target instanceof PillComponent) {
            target = target.element.nativeElement;
        }
        target = DomUtil.queryUp(target as HTMLElement, ".email-fax-value") as HTMLElement;
        const index = this.pills.map(v => v.element.nativeElement).indexOf(target);
        if (index !== -1) {
            this.removeTransition(this.value[index]);
            this.updateValue();
        }
    }

    getLength () {
        var value = this.value,
            cnt = 0;
        for (var i=0,ln=value.length;i<ln;i++) {
            if (!value[i].hidden) {
                cnt++;
            }
        }
        return cnt;
    }

    removeTransition (value: this['value'][0]) {
        value.hidden = true;
        setTimeout(() => {
            if (!value.persist && value.hidden) {
                var index = this.value.indexOf(value);
                if (index !== -1) {
                    this.value.splice(index, 1);
                }
            }
        }, 300);
    }

    updateValue() {
        if (this._writing) {
            return;
        }
        var value = this.value.filter(v=>!v.hidden || v.persist);
        if (this._onChange) {
            this._onChange(value);
        }
        if (this._onTouched) {
            this._onTouched(value);
        }
    }
}

