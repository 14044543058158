import { Injectable } from '@angular/core';
import { Actions } from '../../components/actions/actions';

@Injectable()
export class SearchActions extends Actions({
    name: 'mad-search',
    types: {
        'add-text-pill': {
            name: 'Add Text Pill'
        },
        'remove-pill': {
            name: 'Remove pill'
        },
        'find-match': {
            name: 'Finds match'
        }
    },
    targets: {
        search: { matcher: 'mad-input.search' },
        'match-code': { matcher: 'mad-input.match-code' },
        'match-price': { matcher: 'mad-input.match-price' }
    },
    interactions: {
        keydown: {
            'Enter': {
                'search': 'add-text-pill',
                'match-code': 'find-match',
                'match-price': 'find-match'
            },
            'Backspace': {
                'search': 'remove-pill'
            }
        }
    }
}) { }
