import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {ResizeModule} from './../resize/resize.module';
import {BodyComponent} from './body.component';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     ResizeModule
  ],
  declarations: [
    BodyComponent
  ],
  exports: [
    BodyComponent
  ],
  bootstrap: [BodyComponent]
})
export class BodyModule { }
