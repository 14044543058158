import {
    OnChanges,
    TemplateRef,
    HostBinding,
    QueryList,
    SkipSelf,
    Component,
    ContentChildren,
    Directive,
    ElementRef,
    forwardRef,
    Injectable,
    Input,
    OnInit,
    ViewContainerRef,
    ViewEncapsulation
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Injectable()
@Directive()
export abstract class Field {
    parentField: Field;

    @Input() hover: boolean;
    @Input() focus: boolean;
    @Input() disabled: boolean = false;
    @Input() required: boolean;
    @Input() micro: string | TemplateRef<any>;
    @Input() error: string | string[] | TemplateRef<any>;
    @Input() label: string | TemplateRef<any>;
    @Input() fieldClassName: string = "cl-field-wrapper";
    @Input() errorClassName: string = "spot-form--error";
    @Input() hoverClassName: string = "spot-form--hover";
    @Input() focusClassName: string = "spot-form--focus";
    @Input() formControlName: string;
    @ContentChildren('mad-error') errors: QueryList<any>;
    focused: boolean;

    onFocus($event: any) {
        this.focused = true;
    }
    onBlur($event: any) {
        this.focused = false;
    }

    _onChange: Function = Function.prototype;
    _onTouched: Function = Function.prototype;

    static Providers(ref): any[] {
        return [{
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ref),
            multi: true
        }, {
            provide: Field,
            useExisting: forwardRef(() => ref)
        }];
    }

    constructor(public element: ElementRef) {
        this.element.nativeElement.addEventListener('focus', this.onFocus.bind(this), true);
        this.element.nativeElement.addEventListener('blur', this.onBlur.bind(this), true);
        this.element['madField'] = this;
    }

    abstract blurInput(action?);
    abstract focusInput(action?);

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }
    registerOnChange(_onChange: Function) {
        this._onChange = _onChange;
    }
    registerOnTouched(_onTouched: Function) {
        this._onTouched = _onTouched;
    }
    getErrors() {
        var error = this.error;
        if (error && (<any>error).length) {
            if (Array.isArray(error)) {
                return error;
            } else {
                return [error];
            }
        }
        return null;
    }
}

@Component({
    selector: "mad-field",
    templateUrl: "./field.component.html",
    styleUrls: ["./field.component.scss"],
    providers: Field.Providers(FieldComponent)
})
export class FieldComponent extends Field implements OnInit, OnChanges {
    @HostBinding('class') class;

    constructor(public element: ElementRef, container?: ViewContainerRef, @SkipSelf() parent?: Field) {
        super(element);
        if (!(parent instanceof Field)) {
            this.parentField = this;
        } else {
            this.parentField = parent;
        }
    }

    ngOnInit() {
        this.element.nativeElement["madField"] = this.parentField;
    }

    ngOnChanges() {
        this.class = this.getClassName();
    }

    getClassName() {
        const parentField = this.parentField;

        return `${parentField.fieldClassName && (parentField.fieldClassName)}
         ${parentField.error && (parentField.errorClassName) || ""}
         ${parentField.focus && (parentField.focusClassName) || ""}
         ${parentField.hover && (parentField.hoverClassName) || ""}
         ${parentField.required === true ? "cl-required" : ""} ${this.focused ? 'mad-field-focused' : ''}`;
    }


    /*
     *REQUIRED BY IMPLENTATION
     */

    @Input()
    focusInput(action?): void {
        this.parentField.focusInput(action);
    }

    @Input()
    blurInput(action?): void {
        this.parentField.blurInput(action);
    }
    getDisabled() {
        return this.disabled && true || false;
    }
}

