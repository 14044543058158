import { Component, OnInit } from '@angular/core';
import { Message } from '@shared/model/message';
import { ISystemSettings } from '@shared/model/system-settings';
import { AdminService } from '../../../services/admin/admin.service';
import { UtilService } from '../../../services/util/util.service';
import { ENV } from '../../../globals/env';

@Component({
    selector: 'irl-system-settings',
    templateUrl: './system-settings.component.html',
    styleUrls: ['./system-settings.component.scss']
})
export class SystemSettingsComponent implements OnInit {
    public systemSettings: ISystemSettings;

    public allowTraining: boolean = true;

    public trainingSapIds: string[] = [];

    public _search: string;
    _searching = false;

    public message: Message = new Message();

    constructor(private adminService: AdminService) { }

    public async ngOnInit(): Promise<void> {
        if (ENV.isProd()) {
            this.allowTraining = false;
        }
        await this.getSystemSettings();
    }

    public addSAP() {
        console.log("addSAP: ", this._search);
        const value = this._search;
        if (value && value.trim().length > 0 && !this.trainingSapIds.includes(value)) {
            this.trainingSapIds.push(value.trim());
            console.log("pills=", this.trainingSapIds);

            this._search = '';
        }
    }

    public removeSAP(sapId: string) {
        console.log("removeSAP: ", sapId);
        this.trainingSapIds = this.trainingSapIds.filter(function(item) {
            return item != sapId;
        });
    }

    private async getSystemSettings(): Promise<void> {
        try {
            this.systemSettings = await this.adminService.getSystemSettings();
            console.log('Got systemSettings: ', this.systemSettings);

            this.trainingSapIds = this.systemSettings.trainingSapIdList || [];

        } catch (err) {
            console.error('Error retrieving systemSettings: ', err);
            this.message.setError('Error retrieving systemSettings: ', UtilService.getErrorMessage(err));
        }
    }

    async save(): Promise<void> {
        console.log("save");

        // this.alertService.setBusy(true, 'Saving...');

        this.message.clear();

        try {

            this.systemSettings.trainingSapIdList = this.trainingSapIds;

            var saveResp: ISystemSettings = await this.adminService.saveSystemSettings(this.systemSettings);
            console.log("saveSystemSettings saveResp:", saveResp);

            // this.alertService.setBusy(false);

            this.message.setSuccess('Save successful');
            this.systemSettings = saveResp;

        } catch (err) {
            console.error("Error saving system settings: ", err);
            let msg = 'System Error saving system settings: ' + UtilService.getErrorMessage(err);
            this.message.setError(msg);
            // this.alertService.setBusy(false);
        }
    }

    cancel() {
        this.getSystemSettings();
    }
}
