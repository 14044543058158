import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { OverlayComponent } from './overlay.component';

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
    ],
    declarations: [
        OverlayComponent
    ],
    exports: [
        OverlayComponent
    ],
    bootstrap: [OverlayComponent]
})
export class OverlayModule { }
