import {Directive, HostBinding, Input} from '@angular/core';

var map = {
    'heading-1': 'spot-typography__heading--level-1',
    'heading-2': 'spot-typography__heading--level-2',
    'heading-3': 'spot-typography__heading--level-3',
    'heading-4': 'spot-typography__heading--level-4',
    'heading-5': 'spot-typography__heading--level-5',
    'header': 'spot-typography__heading--level-4',
    'heading': 'spot-typography__heading--level-4',
    'header-1': 'spot-typography__heading--level-1',
    'header-2': 'spot-typography__heading--level-2',
    'header-3': 'spot-typography__heading--level-3',
    'header-4': 'spot-typography__heading--level-4',
    'header-5': 'spot-typography__heading--level-5',
    'body': 'spot-typography__text--body',
    'text': 'spot-typography__text--body',
    'secondary': 'spot-typography__text--secondary',
    'tertiary': 'spot-typography__text--tertiary',
    'bold': 'spot-typography__font-weight--bold',
    'longformtext': 'spot-long-form-text'
};

@Directive({
    selector: '[madTypography]'
})
export class TypographyDirective {
    @HostBinding('class') class: string;
    @Input() madTypography: 'heading-1' | 'heading-2' | 'heading-3' | 'heading-4' | 'heading-5' | 'body' | 'secondary' | 'tertiary' | 'bold' | string;

    ngOnChanges () {
        var classes = this.madTypography.toLowerCase().split(/[\s\t\n]+/),
        output = ''
        for (var i=0,ln=classes.length;i<ln;i++) {
            output += (map[classes[i]] || '') + ' ';
        }
        //console.error(output, classes[0], map[classes[0]]);
        this.class = output;
    }

}
