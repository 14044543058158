import { IUserClaims } from '../model/user';

export class PermService {

    static updatePermissions(state: IUserClaims): IUserClaims {
        if (state.is_ssa || state.is_admin) {
            state.can_approve = true;
        }
        return state;
    }

}
