import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './../../components/components.module';
import { BrowserModule } from '@angular/platform-browser';
import { AccountComponent } from './account.component';
import { FormsModule } from '@angular/forms';
import { AuthState } from './../../states/auth/auth.state';
import { AccountState } from './../../states/account/account.state';
import { AccountService } from './../../services/account/account.service';
import { PDFService } from './../../services/pdf/pdf.service';
import { SearchModule } from './../search/search.module'
import { SummaryModule } from './../summary/summary.module';
import { SessionModule } from './../session/session.module';
import { SelectedPanelsModule } from './../selected-panels/selected-panels.module'
import { SubmitProcessModule } from './../submit-process/submit-process.module';
import { HistoryModule } from './../history/history.module';

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        ComponentsModule,
        SessionModule,
        SearchModule,
        SelectedPanelsModule,
        SubmitProcessModule,
        SummaryModule,
        HistoryModule
    ],
    providers: [
        AuthState,
        AccountState,
        AccountService,
        PDFService
    ],
    declarations: [
        AccountComponent
    ],
    exports: [
        AccountComponent
    ],
    bootstrap: [
        AccountComponent
    ]
})
export class AccountModule { }
