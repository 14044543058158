<ng-template #toast>
  <div *ngIf="update()"  [style.animation]="animation + ' ' + animationDuration" (animationend)="onAnimationEnd()">
    <div #wrapper [class]="getClass()" [style]="getStyle()" [style.maxWidth]="maxWidth">
      <div class="icon-wrapper">
        <ng-content select="mad-icon"></ng-content>
        <mad-icon *ngIf="icon" [icon]="icon"></mad-icon>
      </div>
      <div class="content-wrapper">
        <div class="header-wrapper">
          <div class="header-wrapper-content">
            <ng-content select="mad-header"></ng-content>
            <mad-header *ngIf="title">{{title}}</mad-header>
          </div>
          <mad-link *ngIf="dismissible" class="header-wrapper-close"  (click)="close()">
            <mad-icon icon="fa-times" ></mad-icon>
          </mad-link>
        </div>
        <mad-body>
          <ng-content></ng-content>
          <ng-container *ngTemplateOutlet="_body_template"></ng-container>
          <ng-container *ngIf="!_body_template">
            {{body}}
          </ng-container>
        </mad-body>
        <ng-content select="mad-body"></ng-content>
      </div>
      <div #progress *ngIf="duration && progressBar" class="duration-timer">
      </div>
    </div>
  </div>
</ng-template>
