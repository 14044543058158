import { Observable, firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';

import { IUser } from '@shared/model/user';
import { IRole } from '@shared/model/role';
import { IFinancialCategory } from '@shared/model/financial-category';
import { ISystemSettings } from '@shared/model/system-settings';
import { IAssignedCategoriesData } from '@shared/model/service/cat-assign';
import { Service } from '../service/service';
import { UtilService } from '../../services/util/util.service';

import { APIResponseData } from '../../../../../services/lib/api';
import type { AdminAPI } from '../../../../../services/lib/admin/admin.api';

@Injectable()
export class AdminService extends Service<typeof AdminAPI> {
    getUsers(): Promise<APIResponseData<IUser[]>> {
        return firstValueFrom(this.get('/admin/users'));
    }

    getRoles(): Promise<APIResponseData<IRole[]>> {
        return firstValueFrom(this.get('/admin/roles'));
    }

    async getFinancialCategories(): Promise<IFinancialCategory[]> {
        try {
            const resp = await firstValueFrom(this.get('/admin/financial-categories'));
            if (!resp.success) {
                console.error("getFinancialCategories failed: ", resp.error);
                throw new Error("getFinancialCategories failed: " + UtilService.getErrorMessage(resp.error));
            }
            return resp.data;
        } catch (err) {
            console.error("Error calling admin/financial-categories: ", err);
            throw err;
        }
    }

    getUser(userId: number): Observable<APIResponseData<IUser>> {
        return this.get('/admin/user', { params: { id: userId } });
    }

    public saveUserRole(user: IUser): Promise<APIResponseData<IUser>> {
        return firstValueFrom(this.post('/admin/save_user_role', { body: { user } }));
    }

    public saveUser(user: IUser): Promise<APIResponseData<IUser>> {
        return firstValueFrom(this.post('/admin/save_user', { body: { user } }));
    }

    async getAssignedCategories(): Promise<IAssignedCategoriesData> {
        try {
            const resp = await firstValueFrom(this.get('/admin/cat-assign'));
            if (!resp.success) {
                console.error("getAssignedCategories failed: ", resp.error);
                throw new Error("getAssignedCategories failed: " + UtilService.getErrorMessage(resp.error));
            }
            return resp.data;
        } catch (err) {
            console.error("Error calling admin/cat-assign", err);
            throw err;
        }
    }

    async getSystemSettings(): Promise<ISystemSettings> {
        try {
            const resp = await firstValueFrom(this.get('/admin/system-settings'));
            if (!resp.success) {
                console.error("getSystemSettings failed: ", resp.error);
                throw new Error("getSystemSettings failed: " + UtilService.getErrorMessage(resp.error));
            }
            return resp.data;
        } catch (err) {
            console.error("Error calling getSystemSettings", err);
            throw err;
        }

    }

    async saveSystemSettings(settings: ISystemSettings): Promise<ISystemSettings> {
        try {
            const resp = await firstValueFrom(this.post('/admin/save_system_settings', { body: { settings } }));
            if (!resp.success) {
                console.error("saveSystemSettings failed: ", resp.error);
                throw new Error("saveSystemSettings failed: " + UtilService.getErrorMessage(resp.error));
            }
            return resp.data;
        } catch (err) {
            console.error("Error calling saveSystemSettings", err);
            throw err;
        }
    }

    public async searchUser(searchEmail: string, accessToken): Promise<any> {
        console.log("Calling searchUser: ", searchEmail);
        try {
            const resp = await firstValueFrom(this.post('/admin/search_user',
                { body: { email: searchEmail, accessToken } })
            );
            console.log("resp=", resp);
            return resp;
        } catch (err) {
            console.error("Error calling user.search: ", err);
            throw err;
        }
    }
}
