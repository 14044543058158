import {Component, TemplateRef, ViewChild,  Input, HostBinding, OnChanges, OnInit} from '@angular/core';

@Component({
  selector: 'mad-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {
  @ViewChild('tabBody') tabBody: TemplateRef<any>;
  @ViewChild('tabHeader') tabHeader: TemplateRef<any>;
  ngOnInit () {
  }
}

