import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {ResizeModule} from './../resize/resize.module';
import {HeaderComponent} from './header.component';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     ResizeModule
  ],
  declarations: [
    HeaderComponent
  ],
  exports: [
    HeaderComponent
  ],
  bootstrap: [HeaderComponent]
})
export class HeaderModule { }
