<mad-field class="spot-form__field-group" >
    <ng-content select="mad-micro" ngProjectAs="mad-micro"></ng-content>
    <ng-content select="mad-error" ngProjectAs="mad-error"></ng-content>

    <fieldset class="spot-form__checkbox-group">
        <div class="spot-form__checkbox-group-inner">
            <legend class="spot-form__checkbox-group-label">
                <ng-content select="mad-label" ngProjectAs="mad-label"></ng-content>
            <ng-container *ngIf="isTemplate()">
                <ng-container *ngTemplateOutlet="innerLabel"></ng-container>
            </ng-container>
                {{getInnerLabel()}}
            </legend>
            <ng-content></ng-content>
            <mad-checkbox *ngFor="let option of options" [value]="option.value">
                {{option.text}}
            </mad-checkbox>
        </div>
    </fieldset>
</mad-field>
