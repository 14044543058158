import {Injectable, Optional, SkipSelf, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {DomUtil} from './../../utils/dom';
import {StringUtil} from './../../utils/string';
import {BrowserUtil} from './../../utils/browser';

type AtLeastOne<T, U = {[K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U];

export class Subscription {
    constructor (public action: AbstractActions<any, any, any, any>, public fn: Function) {}
    unsubscribe () {
        var subscriptions = this.action._subscriptions;
        for (var i=0,ln=subscriptions.length;i<ln;i++) {
            if (subscriptions[i] === this as any) {
                subscriptions.splice(i, 1);
                break;
            }
        }
    }
}

const keyMap = {},
    macAltMap = {
        "KeyE": "e",
        "KeyO": "o",
        "KeyP": "p",
        "KeyS": "s",
        "KeyJ": "j",
        "KeyK": "k",
        "KeyH": "h",
        "Semicolon": ";",
        "Quote": "'",
        "KeyR": "r",
        "KeyL": "l",
        "KeyC": "c",
        "KeyM": "m"
    },
    macDeadKeys = {
        "KeyE": "´",
        "KeyI": "ˆ",
        "KeyU": "¨",
        "KeyN": "˜"
    },
    isMac = BrowserUtil.isMac(),
    INDETERMINATE = -1,
    tier1_events = [
        'click',
        'dblclick',
        'mouseup',
        'mousedown',
        'mousemove',
        'mouseenter',
        'mouseleave',
        'input',
        'focus',
        'blur',
        'bubble',
        'paste'
    ],
    tier2_events = [
        'keydown',
        'keyup',
        'keypress',
    ],
    events = [
        'click',
        'dblclick',
        'mouseup',
        'mousedown',
        'mousemove',
        'mouseenter',
        'mouseleave',
        'keydown',
        'keyup',
        'keypress',
        'input',
        'focus',
        'blur',
        'bubble',
        'paste'
    ];

export interface ActionConfig {
    name: string;
    types: {[key: string]: any};
    targets?: {[key: string]: any};
    interactions?: {[key: string]: any};
}


export function Actions <
    NAME extends string,
    PAYLOADS extends string | number | Object,
    TYPE_NAMES extends string,
    TYPE_DESCRIPTIONS extends string,
    TARGET_NAMES extends string,
    TARGET_DESCRIPTIONS extends string,
    TARGET_MATCHERS extends string,
    TYPES extends {
        [key: string]: {
            name: TYPE_NAMES, description?: TYPE_DESCRIPTIONS, payload?: PAYLOADS
        }
    },
    TYPE_PROPS extends keyof TYPES,
    TARGETS extends {
        [key: string]: {
            name?: TARGET_NAMES, description?: TARGET_DESCRIPTIONS, matcher: TARGET_MATCHERS 
        } | TARGET_MATCHERS 
    },
    TARGET_INTERACTIONS extends {
        [key in keyof (TARGETS & {'*': any})]?: TYPE_PROPS
    } | TYPE_PROPS,
    KEY_TARGET_INTERACTIONS extends {
        [key: string]: {
            [key in keyof (TARGETS & {'*': any})]?: TYPE_PROPS
        } | TYPE_PROPS
    } | TYPE_PROPS,

    INTERACTIONS extends {
        click?: TARGET_INTERACTIONS;
        dblclick?: TARGET_INTERACTIONS;
        mouseup?: TARGET_INTERACTIONS;
        mousedown?: TARGET_INTERACTIONS;
        mousemove?: TARGET_INTERACTIONS;
        mouseenter?: TARGET_INTERACTIONS;
        mouseleave?: TARGET_INTERACTIONS;
        input?: TARGET_INTERACTIONS;
        focus?: TARGET_INTERACTIONS;
        blur?: TARGET_INTERACTIONS;
        paste?: TARGET_INTERACTIONS;
        keydown?: KEY_TARGET_INTERACTIONS;
        keyup?: KEY_TARGET_INTERACTIONS;
        keypress?: KEY_TARGET_INTERACTIONS;
    }
> (config: {
    name: NAME;
    types: TYPES;
    interactions: INTERACTIONS;
    targets: TARGETS;
}) {
    return class Actions extends AbstractActions<NAME, TYPES, TARGETS, INTERACTIONS> {
        _name = config.name;
        _types = config.types;
        _interactions = config.interactions;
        _targets = config.targets;
    }
}

@Injectable()
export abstract class AbstractActions<
    NAME extends string=string,
    TYPES extends {[key: string]: {name: string, description?: string, payload?: any}} = {[key: string]: {name: string, description?: string, payload?: any}},
    TARGETS extends {[key: string]: {name?: string, description?: string, matcher: string} | string} = {[key: string]: {name?: string, description?: string, matcher: string} | string},
    INTERACTIONS extends {
        click?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        dblclick?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        mouseup?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        mousedown?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        mousemove?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        mouseenter?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        mouseleave?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        input?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        focus?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        blur?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        paste?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        keydown?: {[key: string]: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES} | keyof TYPES} | keyof TYPES;
        keyup?: {[key: string]: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES} | keyof TYPES} | keyof TYPES
        keypress?: {[key: string]: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES} | keyof TYPES} | keyof TYPES;
    } = {
        click?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        dblclick?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        mouseup?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        mousedown?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        mousemove?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        mouseenter?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        mouseleave?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        input?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        focus?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        blur?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        paste?: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES}| keyof TYPES;
        keydown?: {[key: string]: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES} | keyof TYPES} | keyof TYPES;
        keyup?: {[key: string]: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES} | keyof TYPES} | keyof TYPES
        keypress?: {[key: string]: {[key in keyof (TARGETS & {'*': any})]?: keyof TYPES} | keyof TYPES} | keyof TYPES;
    }
> implements OnDestroy {
    abstract _name: NAME;
    abstract _types: TYPES;
    abstract _targets: TARGETS;
    abstract _interactions: INTERACTIONS;

    _subscriptions: Subscription[] = [];
    _value: ({[key in keyof TYPES]: {type: key; $event: Event; payload: TYPES[key]['payload']}})[keyof TYPES]
    _element_tests: {[key in keyof INTERACTIONS]: any[];};
    _event_bindings: {[key in keyof INTERACTIONS]: any} = {} as any;
    _active_events: string[] = [];

    //_eventBindings;

    constructor (@Optional() @SkipSelf() public parent: AbstractActions,public element: ElementRef) {
        this._value = null;
        setTimeout(() => this.init());
        //console.error('start');
        //for (var i=0,ln=events.length;i<ln;i++) {
            //var event = events[i];
            //this._eventBindings[event] = this.dispatch.bind(this, event);
            //this.element.nativeElement.addEventListener(event, this._eventBindings[event], true);
        //}
    }

    init() {
        this._targets = {
            ...this._targets,
            '*': {name: 'All', description: 'Anything and Everything', matcher: '*'}
        }
        this._updateBindings();
    }

    ngOnDestroy () {
        this._removeListeners();
        //for (var i=0,ln=events.length;i<ln;i++) {
            //var event = events[i];
            //this.element.nativeElement.removeEventListener(event, this._eventBindings[event], true);
        //}
    }

    _updateBindings () {
        if (!this.element) {
            return;
        }
        this._removeListeners();
        this._createElementTests();
        this._addListeners();
    }

    _createElementTests () {
        var tier1 = tier1_events,
        tier2 = tier2_events,
        interactions = this._interactions,
        p: string,
        element_tests = this._element_tests = {} as this['_element_tests'];

        //console.error('interactions', this._interactions);
        for (var i=0,ln=tier1.length;i<ln;i++) {
            var interaction = interactions[tier1[i]],
            tests = [];
            if (interaction) {
                if (typeof interaction === 'string') {
                    tests.push({
                        action: interaction,
                        element: '*'
                    });
                } else {
                    for (p in interaction) {
                        tests.push({
                            action: interaction[p],
                            element: p
                        });
                    }
                }
            }
            element_tests[tier1[i]] = tests;
        }

        function escape(d: string) {
            return StringUtil.hasSpecial(d) ? `\\${d}` : d;
        }

        function sort(a: string, b: string) {
            return a > b ? 1 : (a < b ? 1 : 0);
        }

        function filter(v: any) {
            return v;
        }

        var keyTest = /(META\??\+|SHIFT\??\+|CTRL\??\+|ALT\??\+)/i,
            regexTest = /^\/.*\/$/;
        for (var i=0,ln=tier2.length;i<ln;i++) {
            var interaction = interactions[tier2[i]],
            tests = [];
            if (interaction) {
                if (typeof interaction === 'string') {
                    tests.push({
                        action: interaction,
                        element: '*',
                        key: {
                            modifiers: {
                                ctrlKey:0,
                                metaKey:0,
                                shiftKey:0,
                                altKey: 0
                            }, key: '',
                            modifierCount: 0,
                            isRegex:0,
                            regex: new RegExp('.*')
                        }
                    });
                } else {
                    var p: string;
                    for (p in interaction) {
                        var split = p.split(keyTest).filter(filter),
                        slice = split.slice(0, -1),
                        target_key = keyTest && split[split.length - 1] || '/.*/',
                        prop = slice.sort(sort).join(""),
                        isRegex = regexTest.test(target_key),
                        elements = interaction[p];
                        prop = prop ? `${prop}${target_key}` : target_key;
                        var key = {
                            modifiers: {
                                ctrlKey: 0,
                                metaKey: 0,
                                shiftKey: 0,
                                altKey: 0
                            },
                            key: target_key,
                            modifierCount: 0,
                            isRegex,
                            regex: new RegExp(isRegex ?
                                  `^${target_key.slice(1, -1)}$` : (`^${target_key.length > 1 ? target_key.toUpperCase().replace(/./g, escape)
                                      : target_key.replace(/./g, escape)}$`))
                        }

                        for (var j=0,jln=slice.length;j<jln;j++) {
                            var v = slice[j].slice(0, -1),
                            indeterminate = false;

                            if (v[v.length - 1] === "?") {
                                indeterminate = true;
                                v = v.slice(0, -1);
                            }
                            var k = `${v.toLowerCase()}Key`;
                            if (k in key.modifiers) {
                                key.modifiers[k] = indeterminate ? INDETERMINATE : 1;
                                key.modifierCount++;
                            }
                        }

                        if (typeof elements === 'string') {
                            tests.push({
                                action: elements,
                                element: '*',
                                key
                            });
                        } else {
                            var q;
                            for (q in elements) {
                                tests.push({
                                    element: q,
                                    action: elements[q],
                                    key
                                });
                            }
                        }
                    }
                }
            }
            element_tests[tier2[i]] = tests;
        }
    }

    _addListeners () {
        var interactions = this._interactions,
        p: string,
        el = this.element.nativeElement,
        bindings = this._event_bindings,
        active_events = this._active_events;


        for (p in interactions) {
            if (!bindings[p]) {
                bindings[p] = this._dispatch.bind(this, p);
            }
            el.addEventListener(p, bindings[p], true);
            active_events.push(p);
        }
    }

    _removeListeners () {
        if (!this.element) {
            return;
        }
        var active_events = this._active_events,
        bindings = this._event_bindings,
        el = this.element.nativeElement;
        for (var i=0,ln=active_events.length;i<ln;i++) {
            var event = active_events[i];
            el.removeEventListener(event, bindings[event], true);
        }
        this._active_events = [];
    }

    _dispatch (name, $event: KeyboardEvent | MouseEvent | FocusEvent) {
        const targets = this._targets,
            tests = this._element_tests[name] || [],
            testElement = (_test): boolean => {
                var matcher = typeof targets[_test.element] === 'string' ? targets[_test.element] : (targets[_test.element] as any).matcher;
                if (DomUtil.queryUp($event.target as Node, matcher)) {
                    this.next({
                        type: _test.action,
                        $event,
                        payload: _test.payload || null
                    });
                    //if ($event["_immediatePropagationStopped"]) {
                        //return false;
                    //}
                }
                return true;
            };
        //let i, ln, test;

        //this.wrapEvent($event);

        //console.error($event, name);

        switch (name) {
            case "keyup":
            case "keydown":
            case "keypress":
            case "key":
                $event = $event as KeyboardEvent;
                let key = $event.key,
                    target: any,
                    dead: any,
                    index: any;
                if (isMac && $event.altKey && macAltMap[$event.code]) {
                    key = macAltMap[$event.code];
                    target = $event.target;
                    $event.preventDefault();
                    if (dead = macDeadKeys[$event.code]) {
                        if (target instanceof HTMLInputElement
                            || target instanceof HTMLTextAreaElement
                            || (target as HTMLElement).getAttribute("contenteditable")) {
                            // we use domUtil to persist the cursor location while we filter out the bad character from the element
                            DomUtil.persistCursor(target as HTMLElement, async () => {
                                if (target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement) {
                                    index = target.value.indexOf(dead);
                                    if (index !== -1) {
                                        target.value = target.value.split("").filter(v => v !== dead).join("");
                                    }
                                } else {
                                    target.innerHTML = target.innerHTML.split("").filter(v => v !== dead).join("");
                                }

                                // blur is necessary so the accent does not get appended to the next keystroke
                                target.blur();
                                requestAnimationFrame(() => {
                                    // refocus the element
                                    if (document.activeElement === document.body) {
                                        target.focus();
                                    }
                                });
                            });
                        }
                    }
                }
                let modifiers: any,
                    shiftKey: any,
                    ctrlKey: any,
                    altKey: any,
                    metaKey: any;

                for (var i = 0, ln = tests.length; i < ln; i++) {
                    var test = tests[i];
                    modifiers = test.key.modifiers;
                    ({shiftKey, ctrlKey, altKey, metaKey} = modifiers);
                    if (
                        (
                            (ctrlKey === INDETERMINATE || (ctrlKey === 1) === $event.ctrlKey) &&
                            (metaKey === INDETERMINATE || (metaKey === 1) === $event.metaKey) &&
                            (
                                (test.key.key.length === 1 && (!shiftKey || $event.shiftKey)) ||
                                (shiftKey === INDETERMINATE || (shiftKey === 1) === $event.shiftKey)
                            ) &&
                            (altKey === INDETERMINATE || (altKey === 1) === $event.altKey) &&
                            test.key.regex.test((test.key.isRegex || test.key.key.length === 1) ? key : key.toUpperCase())
                        )
                    ) {
                        if (testElement(test) === false) {
                            break;
                        }
                    }
                }
                break;
            case "input":
            case "paste":
            case "focus":
            case "blur":
            case "click":
            case "dblclick":
            case "mouseup":
            case "mousedown":
            case "mouseenter":
            case "mouseleave":
            case "mousemove":
            case "mouse":
                for (i = 0, ln = tests.length; i < ln; i++) {
                    test = tests[i];
                    if (testElement(test) === false) {
                        break;
                    }
                }
                break;
        }
    }


    subscribe (fn: (value: this['_value'])=>any) {
        var result = new Subscription(this as any, fn);
        this._subscriptions.push(result);
        return result
    }

    unsubscribe (fn: (value: this['_value'])=>any) {
        var subscriptions = this._subscriptions;
        for (var i=0,ln=subscriptions.length;i<ln;i++) {
            if (subscriptions[i].fn === fn) {
                subscriptions.splice(i, 1);
                break;
            }
        }
    }

    next (value: this['_value']) {
        this._value = value,
        this._subscriptions = this._subscriptions;
        //console.error(this._subscriptions);

        for (var i=0,ln=this._subscriptions.length;i<ln;i++) {
            this._subscriptions[i].fn(value);
        }

        if (this.parent) {
            this.parent.next(value as any);
        }
    }


    static Providers () {
        var providers = [{provide: this, useClass: this}] as any,
        proto = Object.getPrototypeOf(this)
        while (proto && proto !== Function.prototype && proto !== Object.prototype) {
            providers.push({provide: proto, useExisting: this})
            proto = Object.getPrototypeOf(proto)
        }
        return providers;
    }

}
