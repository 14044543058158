import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {ButtonGroupComponent} from './button-group.component';
import {ButtonModule} from './../button/button.module';
import {FieldModule} from './../field/field.module';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     FieldModule,
     ButtonModule
  ],
  declarations: [
    ButtonGroupComponent
  ],
  exports: [
    ButtonGroupComponent
  ],
  bootstrap: [ButtonGroupComponent]
})
export class ButtonGroupModule { }
