import {DomUtil} from './../../utils/dom'
import {Formatter} from './formatter';

//TODO make this configurable when we need it later...
var NUMBER = {
    PREFIX: '',
    SUFFIX: '',
    THOUSANDS_SEP: ',',
    DECIMAL_SEP: '.',
    MAX_DECIMAL_PLACES: 2,
    MIN_DECIMAL_PLACES: 0,
    ALLOW_NEGATIVE: true
}

export class NumberFormatter extends Formatter {

    PREFIX = NUMBER.PREFIX;
    SUFFIX = NUMBER.SUFFIX;
    THOUSANDS_SEP = NUMBER.THOUSANDS_SEP;
    DECIMAL_SEP = NUMBER.DECIMAL_SEP;
    MIN_DECIMAL_PLACES = NUMBER.MIN_DECIMAL_PLACES;
    MAX_DECIMAL_PLACES = NUMBER.MAX_DECIMAL_PLACES;
    ALLOW_NEGATIVE = NUMBER.ALLOW_NEGATIVE;

    constructor (config: {prefix?: string, suffix?: string, thousands_sep?: string, decimal_sep?: string; max_decimal_places?: number; min_decimal_places?: number; allow_negative?: boolean}) {
        super(config);
        if (config) {
            if ('prefix' in config) {
                this.PREFIX = config.prefix;
            }
            if ('suffix' in config) {
                this.SUFFIX = config.suffix;
            }
            if ('thousands_sep' in config) {
                this.THOUSANDS_SEP = config.thousands_sep;
            }
            if ('decimal_sep' in config) {
                this.DECIMAL_SEP = config.decimal_sep;
            }
            if ('min_decimal_places' in config) {
                this.MIN_DECIMAL_PLACES = config.min_decimal_places;
            }
            if ('max_decimal_places' in config) {
                this.MAX_DECIMAL_PLACES = config.max_decimal_places;
            }
            if ('allow_negative' in config) {
                this.ALLOW_NEGATIVE = config.allow_negative;
            }
        }
    }

    handleKeyDown (e: KeyboardEvent) {
        var target = e.target as HTMLInputElement,
            cursor = DomUtil.getCursor(target),
            value = target.value,
            start = cursor.startOffset,
            end = cursor.endOffset,
            key = e.key.toLowerCase();

        if (
            (key === 'backspace' && (start === end && value[start - 1] === this.DECIMAL_SEP && this.MIN_DECIMAL_PLACES > 0))
        ) {
            e.preventDefault();
            target.selectionStart--;
            target.selectionEnd = target.selectionStart;
        } else if (
            ((key === 'backspace' || key === 'delete') && (start === end - 1) && (value[start] === this.DECIMAL_SEP))
        ) {
            e.preventDefault();
            target.selectionEnd = target.selectionStart;
        }
    }

    format (target: HTMLInputElement, value: string) {
        if (value === null || value === undefined) {
            value = '';
        }
        value += '';

        var original = value;
        value = value || '0';
        var numTest = /[0-9]/;
        value = value + '';


        var cursor  = DomUtil.getCursor(target as HTMLElement) || {startOffset: value.length, endOffset: value.length},
            start = cursor.startOffset,
            end = cursor.endOffset,
            count = 0,
            push = 0,
            decimal_count = 0,
            decimal_start = -1,
            still_zero = true,
            is_negative = false;
        
        //remove leading 0s
        for (var i=0,ln=value.length;i<ln;i++) {
            if (numTest.test(value[i])) {
                if (value[i] !== '0') {
                    still_zero = false;
                    break;
                } else if (still_zero) {
                    value = value.slice(0,i) + value.slice(i+1);
                    if (i < start) {
                        start--;
                        end--;
                        decimal_start--;
                    }
                }
            }
        }


        for (var i=0,ln=value.length;i<ln;i++) {
            if (value[i] === this.DECIMAL_SEP) {
                if (decimal_count++) {
                    value = value.slice(0,i) + value.slice(i+1);
                    if (i < start) {
                        start--;
                        end--;
                        decimal_start--;
                    }
                } else {
                    decimal_start = i;
                }
            }
        }


        if (decimal_start === -1) {
            decimal_start = value.length;
        }

        var can_be_negative = true;
        for (var i=0,ln=value.length;i<ln;i++) {
            if (!numTest.test(value[i]) && value[i] !== this.DECIMAL_SEP) {
                if (value[i] === '-' && can_be_negative) {
                    is_negative = true;
                }
                value = value.slice(0,i) + value.slice(i+1);
                if (i < decimal_start) {
                    decimal_start--;
                }
                if (i < start) {
                    start--;
                    end--;
                }
                i--;
                ln--;
            } else {
                can_be_negative = false;
            }
        }


        for (var i=decimal_start-1;i>=1;i--) {
            count++;
            if (count === 3) {
                value = value.slice(0, i) + this.THOUSANDS_SEP + value.slice(i,value.length)
                count = 0;
                if (i < start) {
                    push++;
                }
            }
        }

        if (value[0] === this.DECIMAL_SEP) {
            value = '0' + value;
            push++;
        }

        if (is_negative && this.ALLOW_NEGATIVE) {
            value = '-' + value;
            push++;
        }

        if (original && !value) {
            raw = value = '0';
        } 

        if (value) {

            if (this.PREFIX) {
                value = this.PREFIX + value;
                push++;
            }


            var min_decimal_places = this.MIN_DECIMAL_PLACES,
                max_decimal_places = this.MAX_DECIMAL_PLACES;

            if (min_decimal_places !== 0) {
                if (!decimal_count) {
                    value += this.DECIMAL_SEP;
                }

                var decimal_start = value.indexOf(this.DECIMAL_SEP);

                if (value.length - 1 - decimal_start < min_decimal_places) {
                    value = value.padEnd(value.length + (min_decimal_places - (value.length - 1 - decimal_start)), '0')
                }

            }
            
            if (max_decimal_places !== -1) {
                var decimal_start = value.indexOf(this.DECIMAL_SEP);
                if (decimal_start !== -1) {
                    if (max_decimal_places === 0) {
                        value = value.slice(0, decimal_start);
                    } else {
                        value = value.slice(0, decimal_start + max_decimal_places + 1);
                    }
                }
            }

            if (this.SUFFIX) {
                value = value + this.SUFFIX;
            }

        }

        var raw = '';
        for (var i=0,ln=value.length;i<ln;i++) {
            if (numTest.test(value[i]) || value[i] === this.DECIMAL_SEP || (value[i] === '-' && this.ALLOW_NEGATIVE)) {
                raw += value[i];
            }
        }


        if (raw.length === 1 && raw[0] === '-' ) {
            raw = '0';
        }

        return {
            raw,
            value,
            selectionStart: start + push,
            selectionEnd: end + push
        };

    }
}
