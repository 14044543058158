import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalModule, MsalInterceptor, MsalGuard } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { HttpInterceptor } from './providers/http/http.interceptor';
import { WebSocket, WebSocketConfig } from './providers/websocket';
import { AccountState } from './states/account/account.state';
import { SSAState } from './states/ssa/ssa.state';
import { ReferenceService } from './services/reference/reference.service';
import { VersionService } from './services/version/version.service';
import { AccountService } from './services/account/account.service';
import { MADAlertService } from './services/alert/alert.service';
import { LIMSService } from './services/lims/lims.service';
import { FeedbackService } from './services/feedback/feedback.service';
import { SessionService } from './services/session/session.service';
import { HistoryService } from './services/history/history.service';
import { PanelService } from './services/panel/panel.service';
import { InvoiceService } from './services/invoice/invoice.service';
import { PriceService } from './services/price/price.service';
import { PDFService } from './services/pdf/pdf.service';
import { UserService } from './services/user/user.service';
import { UserState } from './states/user/user.state';
import { AdminState } from './states/admin/admin.state';
import { AuthState } from './states/auth/auth.state';
import { VersionState } from './states/version/version.state';
import { ENV } from './globals/env';
import { BusinessModule } from './business/business.module';
import { AdminModule } from './admin/admin.module';
import { SSE_INTERCEPTORS } from './providers/sse/sse';
import { SSEModule } from './providers/sse/sse.module';
import { ErrorHandler } from './providers/error-handler/error-handler';
import { TranslocoRootModule } from './transloco/transloco-root.module';

const protectedResourceMap = new Map<string, string[]>();

const CLIENT_ID = ENV.SSO_CLIENT_ID;
protectedResourceMap.set(`${ENV.SERVICE_TARGET}/**/*`, [`${CLIENT_ID}/.default`]);

@NgModule({
    imports: [
        RouterModule,
        HttpClientModule,
        TranslocoRootModule,
        MsalModule.forRoot(
            new PublicClientApplication({
                auth: {
                    clientId: ENV.SSO_CLIENT_ID,
                    authority: ENV.SSO_LOGIN_URL,
                    redirectUri: `${window.location.protocol}//${window.location.host}`,
                    postLogoutRedirectUri: `${window.location.protocol}//${window.location.host}`,
                    navigateToLoginRequestUrl: false
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: true
                }
            }),
            {
                interactionType: InteractionType.Popup,
                authRequest: {
                    scopes: [`${CLIENT_ID}/.default`]
                }
            },
            {
                interactionType: InteractionType.Popup,
                protectedResourceMap
            }
        ),
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        CommonModule,
        SSEModule,
        AppRoutingModule,
        ComponentsModule,
        BusinessModule,
        AdminModule
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        WebSocket,
        ReferenceService,
        VersionService,
        AccountService,
        MADAlertService,
        LIMSService,
        FeedbackService,
        SessionService,
        HistoryService,
        PanelService,
        InvoiceService,
        ErrorHandler,
        PriceService,
        PDFService,
        UserService,
        AccountState,
        SSAState,
        UserState,
        AdminState,
        AuthState,
        VersionState,
        MsalGuard,
        {
            provide: WebSocketConfig,
            useValue: {
                url: `${ENV.SERVICE_TARGET.replace(/^http/, 'ws')}/ws-connect`
            }
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptor,
            multi: true
        },
        {
            provide: SSE_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
