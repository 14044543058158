import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { Service } from './../service/service';
import type { HistoryAPI } from '../../../../../services/lib/history/history.api';
import { IHistoryPage } from '@shared/model/history';

@Injectable()
export class HistoryService extends Service<typeof HistoryAPI> {
    subscribe({ sap }: { sap: string }) {
        return super.ws('/history/subscribe', {
            params: {
                sap
            }
        });
    }

    async page({ sap, top, limit }: { sap: string; top: number; limit: number }): Promise<IHistoryPage> {
        try {
            const response = await firstValueFrom(
                super.get('/history/page', {
                    params: { sap, top, limit }
                })
            );
            if (response.error) {
                console.error("Error calling history.page: ", response);
                throw response.error;
            }

            return response.data;
        } catch (err) {
        }
    }
}
