import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {TabComponent} from './tab.component';
import {TabsComponent} from './tabs.component';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule
  ],
  declarations: [
    TabComponent,
    TabsComponent
  ],
  exports: [
    TabComponent,
    TabsComponent
  ],
  bootstrap: [TabComponent, TabsComponent]
})
export class TabModule { }
