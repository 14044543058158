import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {DateComponent} from './date.component';
import {IconModule} from './../icon/icon.module';
import {FieldModule} from './../field/field.module';
import {FormsModule} from '@angular/forms';
import {FormatterModule} from '../formatter/formatter.module';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     FormsModule,
     FieldModule,
     IconModule,
     FormatterModule
  ],
  declarations:[
    DateComponent
  ],
  exports: [
    DateComponent
  ],
  bootstrap: [DateComponent]
})
export class DateModule { }
