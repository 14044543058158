<mad-field (mousedown)="defaultFocus($event)" style="flex-direction: column;">
    <ng-content select="mad-label" ngProjectAs="mad-label"></ng-content>
    <ng-content select="mad-micro" ngProjectAs="mad-micro"></ng-content>
    <ng-content select="mad-error" ngProjectAs="mad-error"></ng-content>

    <div class="spot-form__input email-fax">
        <span class="mad-email-fax-inner-wrapper">
            <mad-spring *ngFor="let item of value;trackBy: trackBy;let index = index" direction="horizontal" [visible]="!item.hidden">
                <mad-pill  selectable="true" class="email-fax-value" #pill  type="mad" [removable]="true" (action)="remove(pill)" icon="delete" style="white-space:nowrap;min-width:75px;font-size:13px;">
                    <div *ngIf="item.badge" style="border-radius:10em;background:#eee;display:inline-block;padding-left:0.25em;padding-right:0.25em;">{{item.badge}}</div>
                    {{item.text}}
                    <mad-icon class="pill-remove-icon"  right icon="fa-times" (click)="remove(pill)"></mad-icon>
                </mad-pill>
            </mad-spring>
        </span>
    </div>

    <span #field class="cl-email-input-wrapper" *ngIf="inputValIsVisible">
        <div class="input-outer-wrapper">
            <div class="input-inner-wrapper">
                <mad-input *ngIf="getLength() < maxValues"  #input class="input" [(ngModel)]="_inputValue" [placeholder]="placeholder" (defaultAction)="add()" (input)="onInput()" style="min-height:30px;height:30px;text-align:right;">
                </mad-input>
                <mad-button *ngIf="!validate() as type" disabled="true" class="link" id="add">ADD</mad-button>
                <mad-button *ngIf="validate() as type" (click)="add()" class="link" id="add">ADD</mad-button>
            </div>
        </div>
    </span>

    <div>
        <mad-button type="secondary" [disabled]="shouldHideAddEmailButton()" id="add-email" (click)="showInputVal()">+ Add email</mad-button>
    </div>
</mad-field>
