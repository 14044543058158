import { Injectable } from '@angular/core';
import { Actions } from '../../components/actions/actions';
import { InputActions } from '../../components/input/input.actions';

@Injectable()
export class SAPActions extends Actions({
    name: 'SAPSearch',
    types: {
        ...InputActions.prototype._types,
        prevent: { name: 'prevent' },
        prev: { name: 'Previous result' },
        next: { name: 'Next result' },
        'open-practice': { name: 'Open Practice' },
        'show-search': { name: 'show-search' },
        'hide-search': { name: 'hide-search' }
    },
    targets: {
        input: 'input',
        link: 'mad-link'
    },
    interactions: {
        click: {
            link: 'open-practice'
        },
        keydown: {
            ['Shift?+/[^a-zA-Z0-9]/']: 'prevent',
            'ArrowUp': 'prev',
            'ArrowDown': 'next'
        },
        focus: {
            input: 'show-search'
        },
        blur: {
            input: 'hide-search'
        }
    }
}) { }
