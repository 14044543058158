import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {NavigationLayoutComponent} from './navigation-layout.component';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule
  ],
  declarations: [
    NavigationLayoutComponent
  ],
  exports: [
    NavigationLayoutComponent
  ],
  bootstrap: []
})
export class NavigationLayoutModule { }
