import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http'
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AuthState } from '../../states/auth/auth.state';
import { ErrorHandler } from '../../providers/error-handler/error-handler';

@Injectable()
export class HttpInterceptor {
    constructor(public authState: AuthState, public error: ErrorHandler) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((val: HttpEvent<any> | any): void => {
                try {
                    if (val && val.body && val.body) {
                        const error = val.body.error;
                        if (error) {
                            if (error.isHandled) {
                                console.log('Error will be handled by client', error);
                                return;
                            }
                            this.error.toast(error);
                        }
                    }
                } catch (e) {
                    console.error('Unhandled error in http.interceptor: ', e);
                    console.error(e);
                }
            }),
            catchError((val) => {
                console.error('catchError: ', val.status, val);
                if (val.status === 401 || val.status === 403) {
                    this.authState.httpAuthError(val);
                }
                if (val && val.error && val.error.error) {
                    this.error.toast(val.error.error);
                }
                throw val.error;
            })
        );
    }
}
