import { NgModule } from '@angular/core';
import { ResizeModule } from './resize/resize.module';
import { ActionsModule } from './actions/actions.module';
import { AvatarModule } from './avatar/avatar.module';
import { BodyModule } from './body/body.module';
import { ButtonModule } from './button/button.module';
import { ButtonGroupModule } from './button-group/button-group.module';
import { CardModule } from './card/card.module';
import { CheckboxModule } from './checkbox/checkbox.module';
import { CheckboxGroupModule } from './checkbox-group/checkbox-group.module';
import { DigitalIdentityModule } from './digital-identity/digital-identity.module';
import { EmailFaxModule } from './email-fax/email-fax.module';
import { FieldModule } from './field/field.module';
import { FieldsetModule } from './fieldset/fieldset.module';
import { FilterSelectionModule } from './filter-selection/filter-selection.module';
import { FooterModule } from './footer/footer.module';
import { HeaderModule } from './header/header.module';
import { EnvHeaderModule } from './env-header/env-header.module';
import { InputModule } from './input/input.module';
import { DateModule } from './date/date.module';
import { LinkModule } from './link/link.module';
import { ModalModule } from './modal/modal.module';
import { AlertModule } from './alert/alert.module';
import { ToastModule } from './toast/toast.module';
import { OutletModule } from './outlet/outlet.module';
import { NavigationModule } from './navigation/navigation.module';
import { NavigationLayoutModule } from './navigation-layout/navigation-layout.module';
import { NumberModule } from './number/number.module';
import { PillModule } from './pill/pill.module';
import { PopoverModule } from './popover/popover.module';
import { SpringModule } from './spring/spring.module';
import { RadioModule } from './radio/radio.module';
import { RadioGroupModule } from './radio-group/radio-group.module';
import { SelectModule } from './select/select.module';
import { TextAreaModule } from './textarea/textarea.module';
import { TypographyModule } from './typography/typography.module';
import { IconModule } from './icon/icon.module';
import { TabModule } from './tab/tab.module';
import { RippleModule } from './ripple/ripple.module';
import { InfiniteListModule } from './infinite-list/infinite-list.module';
import { FormatterModule } from './formatter/formatter.module';
import { SystemMessageModule } from './system-message/system-message.module';
import { OverlayModule } from './overlay/overlay.module';
import { WatermarkModule } from './watermark/watermark.module';

@NgModule({
    imports: [
        ResizeModule,
        ActionsModule,
        AvatarModule,
        BodyModule,
        ButtonModule,
        ButtonGroupModule,
        CardModule,
        CheckboxModule,
        CheckboxGroupModule,
        DigitalIdentityModule,
        EmailFaxModule,
        FieldModule,
        FieldsetModule,
        FilterSelectionModule,
        FooterModule,
        HeaderModule,
        EnvHeaderModule,
        IconModule,
        InputModule,
        OverlayModule,
        WatermarkModule,
        DateModule,
        LinkModule,
        AlertModule,
        ModalModule,
        ToastModule,
        OutletModule,
        NavigationModule,
        NavigationLayoutModule,
        NumberModule,
        PillModule,
        PopoverModule,
        SpringModule,
        RadioModule,
        RadioGroupModule,
        RippleModule,
        SelectModule,
        TabModule,
        TextAreaModule,
        TypographyModule,
        InfiniteListModule,
        FormatterModule,
        SystemMessageModule
    ],
    declarations: [
    ],
    exports: [
        ActionsModule,
        AvatarModule,
        BodyModule,
        ButtonModule,
        ButtonGroupModule,
        CardModule,
        CheckboxModule,
        CheckboxGroupModule,
        DigitalIdentityModule,
        EmailFaxModule,
        FieldModule,
        FieldsetModule,
        FilterSelectionModule,
        FooterModule,
        HeaderModule,
        EnvHeaderModule,
        IconModule,
        InputModule,
        OverlayModule,
        WatermarkModule,
        DateModule,
        LinkModule,
        AlertModule,
        ModalModule,
        ToastModule,
        OutletModule,
        NavigationModule,
        NavigationLayoutModule,
        NumberModule,
        PillModule,
        PopoverModule,
        SpringModule,
        RadioModule,
        RadioGroupModule,
        ResizeModule,
        RippleModule,
        SelectModule,
        TabModule,
        TextAreaModule,
        TypographyModule,
        InfiniteListModule,
        FormatterModule
    ],
    bootstrap: []
})
export class ComponentsModule { }
