<mad-field>
    <label class="spot-form__radio spot-form__radio--{{size}}">
        <input #input [(checked)]="checked" [name]="group" type="radio" class="spot-form__radio-input" [disabled]="disabled" />
        <span class="spot-form__radio-inner"  (click)="onClick()" >
            <span class="spot-form__radio-visual"></span>
            <span class="spot-form__radio-label" >
                <ng-content></ng-content>
            </span>
        </span>
    </label>
</mad-field>
