import { EventEmitter } from 'events';
import { Message, IWebSocketMessage, IWebSocketMessageClient } from './message';

const SOCKET_TIMEOUT = 55000;
const REFRESH_INTERVAL = 10000;
const RESPONSE_TIMEOUT = 10000;

export interface IWebSocketClient {
    send(input: string): any;
    on?(event: string, callback: Function): any;
    addEventListener?(event: string, callback: Function): any;
    close(...args: any[]): any;
    ping?(fn: (err: Error) => any): any;
    pong?(fn: (err: Error) => any): any;
    readyState: number;
    OPEN: number;
    CLOSED: number;
    CLOSING: number;
}

//common implementation for server and client websockets
export abstract class WebSocketBase extends EventEmitter implements IWebSocketMessageClient {
    public static PING_MESSAGE = 'ping';
    public static PONG_MESSAGE = 'pong';
    public static JSON_PING_MESSAGE = JSON.stringify({ type: 'ping' });
    public static JSON_PONG_MESSAGE = JSON.stringify({ type: 'pong' });
    public static activeConnections: WebSocketBase[] = [];

    private static intervalTimerHandle: any;

    private lastFrame = Date.now();

    private idCounter = 0;

    private waitingResponses: Message[] = [];

    protected client: IWebSocketClient;

    public constructor(client: Promise<IWebSocketClient>, autoConnect = false) {
        super();

        if (autoConnect) {
            this.init(client);
        }
    }

    private async init(client: Promise<IWebSocketClient>): Promise<void> {
        this.connect(await client);
    }

    public connect(client: IWebSocketClient): void {
        if (this.client !== client) {
            if (this.client
                && this.client.readyState !== this.client.CLOSED
                && this.client.readyState !== this.client.CLOSING) {
                this.client.close();
            }
            this.client = client;
            if (!client) {
                return;
            }
            if (this.client.readyState >= this.client.OPEN) {
                try {
                    this.onOpen();
                } catch (err) {
                    console.error('Error in websocket.base.onOpen', err);
                    throw err;
                }
            }
            if (this.client.on) {
                this.client.on('open', (): void => {
                    if (this.client === client) {
                        this.onOpen();
                    }
                });
                this.client.on('close', (): void => {
                    if (this.client === client) {
                        this.onClose();
                    }
                });
                this.client.on('ping', (): void => {
                    if (this.client === client) {
                        this.onPing();
                    }
                });
                this.client.on('pong', (): void => {
                    if (this.client === client) {
                        this.onPong();
                    }
                });
                this.client.on('message', (m: any): void => {
                    if (m && m.length && this.client === client) {
                        const msg = JSON.parse(m.toString());
                        this.onMessage(new Message(msg, this));
                    }
                });
                this.client.on('error', (e: any): void => {
                    if (this.client === client) {
                        this.onError(e);
                    }
                });
            } else {
                this.client.addEventListener('open', (): void => {
                    if (this.client === client) {
                        this.onOpen();
                    }
                });
                this.client.addEventListener('close', (): void => {
                    if (this.client === client) {
                        this.onClose();
                    }
                });
                this.client.addEventListener('message', (m: any): void => {
                    if (m && m.data && this.client === client) {
                        const msg = JSON.parse(m.data);
                        this.onMessage(new Message(msg, this));
                    }
                });
                this.client.addEventListener('error', (e: any): void => {
                    if (this.client === client) {
                        this.onError(e);
                    }
                });
            }

            WebSocketBase.activeConnections.push(this);
            console.log('after activeConnections.push: websocket.Active connections: ', WebSocketBase.activeConnections.length);

            this.startTimer();
        }
    }

    protected abstract onOpen(): void;

    protected startTimer(): void {
        if (!WebSocketBase.intervalTimerHandle) {
            WebSocketBase.intervalTimerHandle = setInterval((): void => {
                const current = Date.now();
                const active = WebSocketBase.activeConnections.slice();
                for (let i = 0; i < active.length; i++) {
                    try {
                        active[i].tick(current);
                    } catch (e) {
                        console.error('websocket.error tick', e);
                        throw e;
                    }
                }
            }, REFRESH_INTERVAL);
        }
    }

    protected onPing(): void {
        this.lastFrame = Date.now();
        if (this.client.pong) {
            this.client.pong((err): void => this.onError(err));
        }
    }

    protected onPong(): void {
        this.lastFrame = Date.now();
    }

    protected onClose(): void {
        const index = WebSocketBase.activeConnections.indexOf(this);
        if (index !== -1) {
            WebSocketBase.activeConnections.splice(index, 1);
            if (WebSocketBase.activeConnections.length) {
                console.log('after onClose: websocket.Active connections: ', WebSocketBase.activeConnections.length);
            }
            if (this.waitingResponses.length) {
                console.log('after onClose: waitFor=', this.waitingResponses.length, this.waitingResponses.map((msg: Message): string => { return WebSocketBase.messageSummary(msg) }));
            }
        } else {
            console.error("onClose called, but client not found", WebSocketBase.activeConnections, this);
        }
    }

    protected onError(error?: Error | string): void {
        console.error('websocket.onError: ', error);
        this.disconnect();
    }

    protected onMessage(message: Message): void {
        try {
            this.lastFrame = Date.now();

            if (message.type === WebSocketBase.PING_MESSAGE) {
                this.send(WebSocketBase.JSON_PONG_MESSAGE, { raw: true });
                // internal
                return;
            }
            if (message.type === WebSocketBase.PONG_MESSAGE) {
                return;
            }
            // check waiting responses
            const waiting = this.waitingResponses;
            for (let i = 0, ln = waiting.length; i < ln; i++) {
                const waitingMessage = waiting[i];
                if (message.re === waitingMessage.id) {
                    try {
                        waitingMessage.resolveFunction(message);
                    } catch (e) {
                        console.error('Error resolving message: ', message, e);
                        throw e;
                    }
                    waiting.splice(i--, 1);
                    ln--;
                }
            }
        } catch (err) {
            console.error('Error in onMessage: ', err);
            throw err;
        }
    }

    protected tick(currentTime: number): void {
        try {
            const waiting = this.waitingResponses;
            const diff = currentTime - this.lastFrame;
            for (let i = 0, ln = waiting.length; i < ln; i++) {
                const waitingMessage = waiting[i];
                if (currentTime - waitingMessage.startTime > RESPONSE_TIMEOUT) {
                    try {
                        waiting.splice(i--, 1);
                        ln--;
                        console.error('RESPONSE_TIMEOUT on waiting job: ', WebSocketBase.messageSummary(waitingMessage));
                        waitingMessage.error = 'RESPONSE_TIMEOUT';
                        waitingMessage.rejectFunction(new Error('RESPONSE_TIMEOUT'));
                    } catch (e) {
                        console.error('Error websocket.tick: ', e);
                        waitingMessage.rejectFunction(e);
                    }
                }
            }
            if (diff > SOCKET_TIMEOUT) {
                console.error('socket timeout2');
                this.disconnect();
            } else if (diff >= REFRESH_INTERVAL) {
                this.send(WebSocketBase.JSON_PING_MESSAGE, { raw: true });
            }
        } catch (err) {
            console.error('Unhandled error in websocket.tick: ', err);
            throw err;
        }
    }

    public async send(message: IWebSocketMessage | string, opts?: { raw?: boolean; bypassAuth?: boolean }): Promise<Message | null> {
        if (!this.client) {
            console.error('websocket.send: client does not exist yet', message);
            return null;
        }
        if (opts && opts.raw) {
            return this.client.send(message as string);
        }
        const id = this.idCounter++;
        let next: any;

        if (typeof message === 'object') {
            next = { ...message, id };
        } else {
            next = { id, data: message } as any;
        }
        this.client.send(JSON.stringify(next));
        return new Message(next, this);
    }

    public async respond(message: IWebSocketMessage, opts?: { raw?: boolean; bypassAuth?: boolean }) {
        const responseId = message.id;
        try {
            return this.send({ ...message, re: responseId, id: null }, opts);
        } catch (err) {
            console.error('Error in websocket.base.response: ', err);
            throw err;
        }
    }

    public waitFor(message: Message): void {
        const waiting = this.waitingResponses;
        for (let i = 0, ln = waiting.length; i < ln; i++) {
            if (waiting[i] === message) {
                console.warn('waitFor: already present. Ignoring...', message);
                return;
            }
        }
        waiting.push(message);
    }

    protected disconnect(): void {
        if (this.client) {
            try {
                if (WebSocketBase.intervalTimerHandle) {
                    console.warn('websocket.base.disconnect: Canceling timer!');
                    clearInterval(WebSocketBase.intervalTimerHandle);
                    WebSocketBase.intervalTimerHandle = null;
                }
                this.client.close();
            } catch (err) {
                console.error('Error thrown calling client.close', err);
                throw err;
            }
        }
    }

    protected isPingMessage(message: IWebSocketMessage | string): boolean {
        if (message === null) {
            return false;
        }
        if (message['type']) {
            return message['type'] === WebSocketBase.PING_MESSAGE;
        }
        return message === WebSocketBase.JSON_PING_MESSAGE;
    }

    protected isPongMessage(message: IWebSocketMessage | string): boolean {
        if (message === null) {
            return false;
        }
        if (message['type']) {
            return message['type'] === WebSocketBase.PONG_MESSAGE;
        }
        return message === WebSocketBase.JSON_PONG_MESSAGE;
    }

    private static messageSummary(message: Message): string {
        return `Message{ id: ${message.id}, type: ${message.type}, success: ${message.success}, error: ${message.error}, data: ${message.data}}`;
    }
}
