import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { IUser, IUserClaims } from '@shared/model/user';
import type { UserAPI } from '../../../../../services/lib/user/user.api';
import { Service } from '../service/service';
import { UtilService } from '../../services/util/util.service';

@Injectable()
export class UserService extends Service<typeof UserAPI> {
    async update(body: { token: string }): Promise<IUser> {
        try {
            const resp = await firstValueFrom(
                this.post('/user/update', {
                    body
                })
            );
            if (!resp.success) {
                console.error("user.update failed: ", resp.error);
                throw new Error("user.update failed: " + UtilService.getErrorMessage(resp.error));
            }
            return resp.data;
        } catch (err) {
            console.error("Error calling user.update: ", err);
            throw err;
        }
    }

    async state(): Promise<IUserClaims> {
        try {
            const resp = await firstValueFrom(
                this.get('/user/state')
            );
            if (!resp.success) {
                console.error("user.state failed: ", resp.error);
                throw new Error("user.state failed: " + UtilService.getErrorMessage(resp.error));
            }
            return resp.data;
        } catch (err) {
            console.error("Error calling user.state: ", err);
            throw err;
        }
    }
}
