import {Component, Input, Optional, HostBinding, AfterContentChecked} from '@angular/core';
import {FieldComponent} from './field.component';
import {Field} from './field.component';

@Component({
    selector: "mad-label",
    templateUrl: "./label.component.html",
    styleUrls: ['./label.component.scss'],
    providers: [{provide: FieldComponent, useExisting: FieldComponent}]
})
export class LabelComponent implements AfterContentChecked {
  @HostBinding('class') class;

  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() error: boolean;
  @Input() position: 'left' | 'left-top' | 'left-center' | 'left-bottom' | 'top' | 'top-left' | 'top-right' | 'top-center'
    | 'right' | 'right-top' | 'right-bottom' | 'bottom' | 'bottom-center' | 'bottom-left' | 'bottom-right' = 'top';
  @Input() type: 'input';

  constructor (@Optional() public field: Field) {
  }

  ngAfterContentChecked () {
    this.class = this.getClass();
  }

  getRequired () {
      var field: Field = this.field && this.field.parentField || this.field;
      return (this.required !== undefined ? this.required : field && field.required) && true || false;
  }
  getDisabled () {
      var field: Field = this.field && this.field.parentField || this.field;
      return (this.disabled !== undefined ? this.disabled : field && field.disabled) && true || false;
  }
  getError () {
      var field: Field = this.field && this.field.parentField || this.field;
      return (this.error !== null ? this.error : (field.error || (field.errors && field.errors.length))) && true || false;
  }

  getClass () {
    return `mad-field-label spot-form__label 
      ${this.getDisabled() && 'spot-form__label--disabled' || ''} 
      ${this.getError() && 'has-errors' || ''}
      ${this.position}
      `;
  }
}
