<ng-template #template>
<div [class]="hostClass" [class.initializing]="initializing" [style.left]="hostLeft" [style.right]="hostRight" [style.top]="hostTop" [style.bottom]="hostBottom" #popover>
    <div class="cl-background-wrapper">
        <div #arrow class="cl-arrow"></div>
        <div #background class="cl-background">
        </div>
    </div>
    <div #outerWrapper class="cl-outer-wrapper" [class.transitioning]="isTransitioning" (scroll)="onScroll($event)" >
        <div #innerWrapper (madResize)="onResize($event)"  style="position:absolute;" [style.top]="top ? (top + 'px') : null" [style.left]="left ? (left + 'px') : null" [style.bottom]="bottom ? (bottom + 'px') : null" [style.right]="right ? (right + 'px') : null" [style.height]="height ? (height + 'px') : null" [style.width]="width ? (width + 'px') : null">
            <div #contentWrapper style="position:relative;width:max-content;height:auto;display:inline-block;" [style.max-width]="constrained?.maxWidth ?  (constrained.maxWidth + 'px') : '100%'">
                <div #templateContentWrapper style="position:relative;display:inline-block;width:auto;">
                    <ng-content></ng-content>
                    <ng-container *ngTemplateOutlet="_content;context:context"></ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
</ng-template>
