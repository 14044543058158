import {NgModule} from '@angular/core';
import {SSEClient, SSE_INTERCEPTORS, SSEBaseInterceptor} from './sse';

@NgModule({
  imports: [
  ],
  providers: [
      SSEClient,
      {
          provide: SSE_INTERCEPTORS,
          multi: true,
          useClass: SSEBaseInterceptor
      }
  ],
  declarations: [
  ],
  exports: [
  ],
  bootstrap: [
  ]
})
export class SSEModule { }
