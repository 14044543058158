import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '../components/components.module';

import { AdminService } from '../services/admin/admin.service';
import { AdminRoutingModule } from './admin-routing.module';
import { UserSettingsComponent } from './views/user-settings/user-settings.component';
import { AssignedCategoriesComponent } from './views/assigned-categories/assigned-categories.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { SystemSettingsComponent } from './views/system-settings/system-settings.component';
import { MessageComponent } from './components/message/message.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        AdminRoutingModule,
        ComponentsModule
    ],
    providers: [
        AdminService
    ],
    declarations: [
        AdminLayoutComponent,
        SystemSettingsComponent,
        UserSettingsComponent,
        AssignedCategoriesComponent,
        MessageComponent
    ],
    exports: [],
    bootstrap: []
})
export class AdminModule { }
