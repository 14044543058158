import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {RadioComponent} from './radio.component';
import {FieldModule} from './../field/field.module';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     FieldModule
  ],
  declarations: [
      RadioComponent
  ],
  exports: [
      RadioComponent
  ],
  bootstrap: [RadioComponent]
})
export class RadioModule { }
