<a *ngIf="selectable" #selectable href="javascript:void(0);" class="cl-pill-selectable" [madRipple]></a>
<span  class="cl-pill-text-content">
    <span #leftIconWrapper class="mad-pill-icon-wrapper">
        <ng-content select="[left],mad-icon:not([right]),mad-avatar:not([right])"></ng-content>
    </span>
    <span class="cl-pill-content-wrapper" fxLayoutAlign="center center">
        <ng-content></ng-content>
    </span>
    <span #rightIconWrapper class="mad-pill-icon-wrapper">
        <ng-content select="[right]"></ng-content>
    </span>
</span>
