import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { IDownloadFile } from '@shared/model/file';
import type { InvoiceAPI } from '@services/lib/invoice/invoice.api';
import { Service } from '../service/service';
import { FileUtil } from '../../utils/file';

@Injectable()
export class InvoiceService extends Service<typeof InvoiceAPI> {
    add({ session, invoice }: { session: number; invoice: File }) {
        const body = new FormData();
        body.append('session', session + '');
        body.append('invoice', invoice);
        return super.post('/invoices/add', {
            body,
            reportProgress: true,
            observe: 'events'
        });
    }

    remove({ session, invoice }: { session: number; invoice: number }) {
        return this.post('/invoices/remove', {
            body: {
                session,
                invoice
            }
        });
    }

    async download({ session, invoice }: { session: number; invoice: number }): Promise<IDownloadFile> {
        try {
            const response = await firstValueFrom(
                this.get('/invoices/download', {
                    params: {
                        session,
                        invoice
                    },
                    responseType: 'blob',
                    observe: 'response'
                })
            );
            return FileUtil.parseFile(response);
        } catch (err) {
            console.error('Error calling invoice download: ', err);
            throw err;
        }
    }
}
