import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthState } from '../states/auth/auth.state';
import { AdminState } from '../states/admin/admin.state';

import { AssignedCategoriesComponent } from './views/assigned-categories/assigned-categories.component';
import { SystemSettingsComponent } from './views/system-settings/system-settings.component';

import { UserSettingsComponent } from './views/user-settings/user-settings.component';

// import { SubmissionsComponent } from './views/submissions/submissions.component';
// import { EnrollmentEditComponent } from './views/enrollment-edit/enrollment-edit.component';

import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';

const routes: Routes = [
    {
        path: 'admin',
        component: AdminLayoutComponent,
        canActivate: [AuthState, AdminState],
        children: [
            //     {
            //         path: 'system',
            //         component: SystemSettingsComponent,
            //         canActivate: [AuthState, AdminState]
            //     },
            //     {
            //         path: 'submissions',
            //         component: SubmissionsComponent,
            //         canActivate: [AuthState, AdminState]
            //     },
            //     {
            //         path: 'enrollment/:id',
            //         component: EnrollmentEditComponent,
            //         canActivate: [AuthState, AdminState]
            //     },
            {
                path: 'user',
                component: UserSettingsComponent,
                canActivate: [AuthState, AdminState]
            },
            {
                path: 'cat-assign',
                component: AssignedCategoriesComponent,
                canActivate: [AuthState, AdminState]
            },
            {
                path: 'system-settings',
                component: SystemSettingsComponent,
                canActivate: [AdminState]
            },
            {
                path: '',
                redirectTo: 'home',
                pathMatch: 'full'
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule { }
