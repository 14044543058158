import {
    Component,
    ElementRef,
    Input,
    AfterContentChecked,
    ContentChildren,
    QueryList,
    ViewChildren,
    AfterViewChecked,
    TemplateRef
} from '@angular/core';
import { CheckboxGroupComponent } from '../checkbox-group/checkbox-group.component';
import { Field } from '../field/field.component';
import { InputActions } from '../input/input.actions';
import { RadioComponent } from '../radio/radio.component';

var ids = 0;

@Component({
    selector: 'mad-radio-group',
    templateUrl: './radio-group.component.html',
    styleUrls: ['./radio-group.component.scss'],
    providers: Field.Providers(RadioGroupComponent).concat(InputActions.Providers())
})
export class RadioGroupComponent extends CheckboxGroupComponent implements AfterViewChecked, AfterContentChecked {
    @ContentChildren(RadioComponent) contentChildren: QueryList<RadioComponent>;

    @ViewChildren(RadioComponent) viewChildren: QueryList<RadioComponent>;

    @Input() direction: 'horizontal' | 'vertical' = 'vertical';

    @Input() options: { text: string; value: any }[];

    @Input() innerLabel: string | TemplateRef<any>;

    id = `radio-group-${ids++}`;

    _value = null;

    constructor(public element: ElementRef) {
        super(element);
    }

    onChange($event: Event, noSet?: boolean) {
        const result = super.onChange($event, true);
        if (!noSet) {
            this.value = result[0] || null;
        }

        return this.value;
    }

    ngAfterViewChecked(): void {
        this.updateGroups();
    }

    ngAfterContentChecked(): void {
        this.updateGroups();
    }

    updateGroups(): void {
        const components = (this.contentChildren && this.contentChildren.toArray() || []).concat(this.viewChildren && this.viewChildren.toArray() || []);
        for (let cmp of components) {
            if (!cmp.group) {
                cmp.group = this.id;
            }
        }
    }
}
