import {Component, SimpleChanges, Input, HostListener, TemplateRef, ElementRef, AfterViewInit, ViewChildren, QueryList, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core'
import {InfinitePager} from './infinite-pager';
import {Subscription} from 'rxjs';

@Component({
    selector: "mad-infinite-list",
    templateUrl: "./infinite-list.component.html",
    styleUrls: ["./infinite-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfiniteListComponent implements AfterViewInit{
    @Input() assumedHeight = 50;
    @Input() buffer = 1; //height based buffer -- useful for forcing down arrow hold
    @Input() pager: InfinitePager;
    @Input() template: TemplateRef<any>;
    @Input() adjustToFirstChild = false;
    @Input() trackBy: (i: number, d: any) => any = this.trackRow;
    @ViewChild('buffer') bufferElement: ElementRef;


    _force_redraw = false;
    _pre_invalidate = false;
    _scroll_y = 0;
    _prevent_scroll: boolean;
    _assumed_height: number;
    _reset_timer: any;
    _update_timer: any;
    _scroll_timer: any;
    _scroll_same_count = 0;
    _last_scroll_top: number;
    _height: number;
    _heights: {[key: number]: number} = [];
    _total_sub: Subscription;
    _total: number;
    rows: {
        height: number;
        is_known: boolean;
        mod?: number;
        index: number;
        top?: number;
        lock?: any;
        data?: any;
    }[] = [];
    _visible: this['rows'];
    _visible_state_map: {[key: string]: InfiniteListComponent['rows'][0]} = {};
    _recheck_timer: any;
    _prevent_immediate = false;
    _prevent_immediate_timer = null;
    _row_lock: any;
    _scrolling = false;
    _buffer_start = 0;
    detectNeeded: boolean = true;




    constructor (public element: ElementRef, public changeDetector: ChangeDetectorRef) {
    }

    ngOnInit () {
        this.ngOnChanges(null);
    }

    ngAfterViewInit () {
        this.element.nativeElement.addEventListener('transitionstart', (e) => this.onTransitionStart(e));
        this.element.nativeElement.addEventListener('animationstart', (e) => this.onTransitionStart(e));
        this.scheduleUpdate();
    }

    ngOnChanges (changes: SimpleChanges) {
        if (
            !changes ||
            (changes.assumedHeight && changes.assumedHeight.currentValue !== changes.assumedHeight.previousValue)
        ) {
            this.reset();
        } else {
            this.reset(true);
        }
        if (changes && changes.pager) { 
            if (this._total_sub) {
                this._total_sub.unsubscribe();
                this._total_sub = null;
            }
            if (this.pager) {
                this._total_sub = this.pager.total.subscribe((total) => {
                    if (total !== this._total) {
                        this._total = total;
                        this.reset(true);
                    }
                });
            }
        }
    }

    ngOnDestroy () {
        if (this._total_sub) {
            this._total_sub.unsubscribe();
        }
    }

    //_buffer_offset = 0;

    update (force?: boolean, pre_invalidate?: boolean, pass?: boolean) {
        if (this._prevent_immediate && !force && !pre_invalidate && !pass) {
            return;
        }

        this._prevent_immediate = true;
        if (!this._prevent_immediate_timer) {
            this._prevent_immediate_timer = requestAnimationFrame(() => {
                this._prevent_immediate_timer = null;
                this._prevent_immediate = false
            });
        }


        var el = this.element.nativeElement,
            st = this.bufferElement.nativeElement.offsetTop,
            adjustToFirstChild = this.adjustToFirstChild,
            buffer = this.buffer,
            scrollTop = el.scrollTop,
            natural_start = Math.max(scrollTop, 0) - st,
            start = Math.max(scrollTop - buffer, 0) - st,
            end = natural_start + el.getBoundingClientRect().height + buffer,
            rows = this.rows,
            visible_state = this._visible || [],
            next_visible_state = [],
            last_visible_state_map = this._visible_state_map,
            visible_state_map = {},
            visible_state_changed = false,
            changed = [],
            unchanged = [],
            lock = {},
            heights = this._heights,
            assumed = this._assumed_height;

        if (this.bufferElement) {
            if (this._buffer_start !== st) {
                this._buffer_start = st;
                visible_state_changed = true;
            }
        }

        //first find the currently visible rows
        for (var i=0,ln=rows.length;i<ln;i++) {
            var row = rows[i],
                row_start = row.top,
                row_height = row.height,
                row_end = row_start + row_height;

            if (
                (row_start <= start && row_end >= start)||
                (row_end >= end && row_start <= end) ||
                (row_start >= start && row_end <= end)
            ) {
                next_visible_state.push(row);
                visible_state_map[row.index] = row;
                row.lock = lock;
            }
        }


        if (next_visible_state.length !== visible_state.length) {
            visible_state_changed = true;
        } else {
            for (var i=0,ln=next_visible_state.length;i<ln;i++) {
                if (!last_visible_state_map[next_visible_state[i].index]) {
                    visible_state_changed = true;
                    break;
                }
            }
        }

        visible_state.splice(0, visible_state.length);
        visible_state.push(...next_visible_state);

        if (visible_state_changed || pre_invalidate) {
            this.changeDetector.detectChanges();
        }

        var row_elements = this.element.nativeElement.querySelectorAll('.row');

        for (var i=0,ln=row_elements.length as number;i<ln;i++) {
            var el = row_elements[i],
                data_index = el.getAttribute('data-index'),
                row = visible_state_map[data_index];

            if (row) {
                var el_height =  (adjustToFirstChild ? el.firstElementChild : el).getBoundingClientRect().height;

                if (!heights[el_height]) {
                    heights[el_height] = 0;
                }

                if (el_height !== row.height) {
                    changed.push({index: row.index, changed_height: el_height - row.height});
                    if (!row.is_known) {
                        row.is_known = true;
                        el_height && heights[el_height]++;
                    } else {
                        heights[row.height]--;
                        el_height && heights[el_height]++;
                    }
                    row.height = el_height;
                } else {
                    unchanged.push(row.index);
                    if (!row.is_known) {
                        row.is_known = true;
                        el_height && heights[el_height]++;
                    }
                }

                if (heights[el_height] > heights[assumed]) {
                    assumed = el_height;
                }
            }
        }

        if (assumed !== this._assumed_height) {
            this._assumed_height = assumed;
        }

        for (var i=0,ln=next_visible_state.length;i<ln;i++) {
            var item = next_visible_state[i];
            if (!item.is_known && item.height !== assumed) {
                //if (assumed > item.height) {
                    changed.push({index: item.index, changed_height: assumed - item.height});
                //}
                item.height = assumed;
            }
        }

        var first_unchanged: number;
        if (unchanged.length) {
            first_unchanged = unchanged[0];
        }

        if (changed.length) {
            visible_state_changed = true;
        }


        for (var i=0,ln=changed.length;i<ln;i++) {
            if (this._scrolling && (!this._prevent_scroll && (changed[i].index || scrollTop !== 0) &&  changed[i].index < first_unchanged)) {
                for (var j=changed[i].index;j>=0;j--) {
                    rows[j].top -= changed[i].changed_height;
                }
            } else {
                for (var j=changed[i].index+1,jln=rows.length;j<jln;j++) {
                    rows[j].top += changed[i].changed_height
                }
            }
        }


        

        this._visible_state_map = visible_state_map;

        if (this._visible !== visible_state) {
            this._visible = visible_state;
        }

        var last = rows[rows.length - 1],
            next_height = last ? (last.top + last.height) : 0;

        if (next_height !== this._height) {
            this._height = last ? (last.top + last.height) : 0;
        }

        if (visible_state_changed || force) {


            this._row_lock = lock;
            !(async () => {
                if (next_visible_state.length) {
                    var visible_results = await this.pager.get(next_visible_state[0].index, next_visible_state[next_visible_state.length-1].index),
                        immediate = false;


                    if (this._row_lock === lock) {
                        for (var i=0,ln=visible_results.length as number;i<ln;i++) {
                            if (next_visible_state[i] && next_visible_state[i].data !== visible_results[i]) {
                                immediate = true;
                                next_visible_state[i].data = visible_results[i]
                            }
                        }
                        if (immediate) {
                            //revalidate until visible state normalizes
                            this.update(false, true, true)
                        }
                    }
                }
            })();
            //revalidate until visible state normalizes
            this.changeDetector.detectChanges();
            this.update(false, false, true);
        }
    }

    @HostListener('scroll', ['$event'])
    onScroll ($event) {
        if ($event) {
            //check for re-alignment on actual scroll
            var st = this.bufferElement.nativeElement.offsetTop,
                scrollTop = this.element.nativeElement.scrollTop,
                height = this.element.nativeElement.offsetHeight;

            if (scrollTop <= st + height && this.rows[0] && this.rows[0].top < 0) {
                this._scrolling = false;
                this.onScrollEnd();
            }
        }
        if (this._scrolling) {
            this._scroll_same_count = 0;
            return;
        }
        this._scrolling = true;
        if (!this._scroll_timer) {
            this._scroll_same_count = 0;
            this._last_scroll_top = scrollTop;
            var scrollTimer = ()=>{
                var scrollTop = this.element.nativeElement.scrollTop

                if (!this._scrolling) {
                    return;
                }

                if (this._last_scroll_top !== scrollTop) {
                    this._last_scroll_top = scrollTop;
                    this._scroll_same_count = 0;
                    this._scroll_timer = requestAnimationFrame(scrollTimer);
                    this.update(false, false, true);
                } else if (this._scroll_same_count++ === 10) {
                    this._scroll_timer = null;
                    this._scrolling = false;
                    this.onScrollEnd()
                } else {
                    this._scroll_timer = requestAnimationFrame(scrollTimer);
                    this.update(false, false, true);
                }
            };
            this._scroll_timer = requestAnimationFrame(scrollTimer);
        }
    }

    onScrollEnd (scrollTop?: number) {
        //we use querySelectorAll here over viewChildren because of order dependency and we need to 100%
        //ensure the view children are in sync with the data which is not always the case

        var rowElements = this.element.nativeElement.querySelectorAll('.row');
        if (!this.rows.length) {
            this.scheduleUpdate();
            return;
        }

        var rows = this.rows,
            //st = this.bufferElement.nativeElement.offsetTop,
            height = 0,
            first_visible = (rowElements && rowElements[0]) ? this.rows[rowElements[0].getAttribute('data-index')] : null,
            first_visible = this._visible[0],
            first_visible_top = first_visible ? first_visible.top : 0;

        for (var i=0,ln=rows.length;i<ln;i++) {
            rows[i].top = height;
            height += rows[i].height;
        }

        this.update(false, true, true);


        if (scrollTop === undefined) {
            if (first_visible && first_visible_top !== rows[first_visible.index].top) {
                this.element.nativeElement.scrollTop += rows[first_visible.index].top - first_visible_top; 
            }
        } else {
            this.element.nativeElement.scrollTop = scrollTop;
        }

        this.scheduleUpdate();
        this.update(false, true, true);
    }

    reset (grow = false) {
        var rows = this.rows,
            total = parseInt(this._total + ''),
            //total = this.total,
            assumedHeight = this.assumedHeight;

        if (isNaN(total)) {
            total = 0;
        }

        this._assumed_height = assumedHeight;

        if (!grow) {
            rows = this.rows = [];
        }

        this._heights = [];
        this._heights[assumedHeight] = 0;

        //may have more work to do here as we enable remove functionality
        //alternatively we could consider push /splice / remove functions 
        if (total > rows.length) {
            var last = rows[rows.length - 1],
                height = last ? (last.top + last.height) : 0;

            for (var i=rows.length,ln=total;i<ln;i++) {
                var row = {
                    height: assumedHeight,
                    top: height,
                    is_known: false,
                    mod: 0,
                    index: i
                } as this['rows'][0];
                height += assumedHeight;
                rows.push(row);
            }
        } else if (total < rows.length) {
            while (total !== rows.length) {
                rows.pop();
            }
        }

        last = rows[rows.length - 1]

        this._height = last ? (last.top + last.height) : 0;

        this.changeDetector.detectChanges();
        this.scheduleUpdate(!grow);
    }

    trackRow (i: number, d: any) {
        return d.index;
    }

    scheduleUpdate (force?: boolean, pre_invalidate?: boolean) {
        if (force) {
            this._force_redraw = true;
        }
        if (pre_invalidate) {
            this._pre_invalidate = true;
        }
        if (!this._update_timer) {
            this._update_timer =  requestAnimationFrame(() => {

                var force = this._force_redraw,
                    pre = this._pre_invalidate;
                this._update_timer = null;
                this._force_redraw = false;
                this._pre_invalidate = false;
                this._prevent_immediate = false;
                this.update(force, pre);
            });
        }
    }

    scrollTo (...args: any[]) {
        this.element.nativeElement.scrollTo(...args)
    }

    scrollToEnd () {
        var end = this.rows && (this.rows.length - 1) || -1,
        top = this.rows && this.rows[end] && this.rows[end].top || 0

        this.scrollTo(0, top);
        requestAnimationFrame(() => this.scrollTo(0, top));

    }

    scrollToTop () {
        requestAnimationFrame(() => {
            this._prevent_scroll = true;
            this.element.nativeElement.scrollTop = 0;
            this.scheduleUpdate();
            requestAnimationFrame(() => {
                this.element.nativeElement.scrollTop = 0;
                this._prevent_scroll = false;
            });
        })
    }

    _transition_timer: any;
    _transition_target = 600;
    _transition_so_far = 0;

    onTransitionStart (e: TransitionEvent) {
        if ((e.propertyName||'').endsWith('color') || (e['animationName'] === 'fa-spin')) {
            return;
        }
        //asume we need to check for animationstart
        var property = e.type === 'animationstart' ? 'top' : e.propertyName;
        if (
            (
            property.indexOf('top') === -1 &&
            property.indexOf('bottom') === -1 &&
            property.indexOf('left') === -1 &&
            property.indexOf('right') === -1 &&
            property.indexOf('padding') === -1 &&
            property.indexOf('margin') === -1 &&
            property.indexOf('border') === -1 &&
            property.indexOf('height') === -1 &&
            property.indexOf('width') === -1 &&
            property.indexOf('transform') === -1
            ) || property.indexOf('color') !== -1
        ) {
            return;
        }
        this._transition_so_far = 0;
        if (this._transition_timer) {
            return;
        }

        var last_run = Date.now();
        var fn = () => {
            var run = Date.now(),
                elapsed = run - last_run;

            last_run = run;
            this._transition_so_far += elapsed;

            this.update(false, false, true);

            if (this._transition_so_far < this._transition_target) {
                this._transition_timer = requestAnimationFrame(fn);
            } else {
                this._transition_timer = null;
            }
        }
        this._transition_timer = requestAnimationFrame(fn);
        this.update();
    }

}
