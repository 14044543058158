import {Component, ElementRef, Input, ViewChild, HostBinding, OnChanges, AfterContentChecked} from '@angular/core';
import {Field} from '../field/field.component';

@Component({
    selector: 'mad-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    providers: Field.Providers(ButtonComponent)
})
export class ButtonComponent extends Field implements  AfterContentChecked {
    @Input() type: 'primary' | 'secondary' | 'secondary-teal' | 'sales' | 'sales-primary' | 'sales-secondary' | 'sales-secondary-dark' | 'cancel' | 'link' | 'cancel-icon' | 'tab' = 'primary' ;
    @Input() disabled = false;
    @Input() value: any;
    @Input() round: boolean;
    @Input() buttonClass: string;

    @ViewChild('button') button: ElementRef;
    @ViewChild('contentWrapper') contentWrapper: ElementRef;
    @ViewChild('leftIconWrapper') leftIconWrapper: ElementRef;
    @ViewChild('rightIconWrapper') rightIconWrapper: ElementRef;

    @HostBinding('class') class = '';

    _iconOnly = false;
    _leftIcon = false;
    _rightIcon = false;

    _selected = false;
    
    //aot dependency injection seems to break when constructor inheritance is required
    constructor (public element: ElementRef) {
        super(element);
    }

    ngAfterContentChecked () {
        if (this.contentWrapper && !this.contentWrapper.nativeElement.childNodes.length) {
            this._iconOnly = true;
        } else {
            this._iconOnly = false;
        }

        if (this.leftIconWrapper && this.leftIconWrapper.nativeElement.children.length) {
            this._leftIcon = true;
        } else {
            this._leftIcon = false;
        }
        if (this.rightIconWrapper && this.rightIconWrapper.nativeElement.children.length) {
            this._rightIcon = true;
        } else {
            this._rightIcon = false;
        }
        if (this.element) {
            if (this.round) {
                this.element.nativeElement.style.minWidth = 'auto';
                this.element.nativeElement.style.minHeight = 'auto';
                this.element.nativeElement.style.height = 'auto';
                var width = this.element.nativeElement.offsetWidth,
                    height = this.element.nativeElement.offsetHeight;

                width = height = Math.max(height, width);

                this.element.nativeElement.style.minWidth = `${width}px`;
                this.element.nativeElement.style.minHeight = `${height}px`;
                this.element.nativeElement.style.height = `${height}px`;
            }
        }
        this.class = this.getClass();
    }

    getClass () {
        var types = this.type.toLowerCase().split(/[\s\t\n]+/),
        type = 'mad-button ';

        for (var i=0,ln=types.length;i<ln;i++) {
            type += `${types[i]} `;
        }

        return `${type}
             ${this.round && 'round' || ''}
             ${this._iconOnly && 'mad-icon-only' || ''}
             ${this._rightIcon && 'mad-right-icon'}
             ${this._leftIcon && 'mad-left-icon'}
             ${this.disabled && 'disabled'}
         `;
    }

    focusInput () {
        this.button.nativeElement.focus();
    }

    blurInput () {
        this.button.nativeElement.blur();
    }

}
