<!--<div class="mad-footer-inner">-->
  <div class="mad-footer-left">
    <ng-content select="[left],mad-button[type=cancel]"></ng-content>
  </div>
  <div class="mad-footer-content">
    <ng-content></ng-content>
  </div>
  <div class="mad-footer-right">
    <ng-content select="[right]"></ng-content>
  </div>
<!--</div>-->
