import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { of, BehaviorSubject } from 'rxjs';
import { IUserClaims } from '@shared/model/user';
import { PermService } from '@shared/service/perm.service';
import { UserService } from '../../services/user/user.service';
import { AuthState } from '../auth/auth.state';

@Injectable()
export class UserState implements CanActivate {
    statePromise: Promise<IUserClaims>;

    state: IUserClaims = { is_admin: false, is_ssa: false, can_approve: false };

    changes = new BehaviorSubject<IUserClaims>(this.state);

    constructor(public authState: AuthState, public userService: UserService) {
        authState.authorized.subscribe(async (v): Promise<void> => {
            if (!v) {
                this.statePromise = null;
            } else {
                this.statePromise = this.userService.state();
                this.state = await this.statePromise;
                this.changes.next(this.state);
            }
        });
    }

    public set(field: 'is_ssa' | 'is_admin', value: boolean): void {
        this.state[field] = value;

        this.state = PermService.updatePermissions(this.state);

        this.changes.next(this.state);
    }

    public canActivate(route: ActivatedRouteSnapshot) {
        if (!this.statePromise) {
            this.statePromise = this.userService.state();
            this.statePromise.then(v => this.state = v);
        }
        return of(true);
    }
}
