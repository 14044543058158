import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentsModule} from './../../components/components.module';
import {BrowserModule} from '@angular/platform-browser';
import {SelectedPanelsDetailsComponent} from './selected-panels-details.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslocoModule} from '@ngneat/transloco';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     FormsModule,
     ReactiveFormsModule,
     ComponentsModule,
     TranslocoModule
  ],
  providers: [
  ],
  declarations: [
      SelectedPanelsDetailsComponent
  ],
  exports: [
    SelectedPanelsDetailsComponent
  ],
  bootstrap: [SelectedPanelsDetailsComponent]
})
export class SelectedPanelsDetailsModule { }
