import { Component, Output, EventEmitter, Input, SimpleChanges, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { IHistoryItem } from '@shared/model/history';
import { IWebSocketSubscriptionHandle } from '../../providers/websocket';
import { HistoryService } from '../../services/history/history.service';
import { PDFService } from '../../services/pdf/pdf.service';
import { FileUtil } from '../../utils/file';
import { SessionState } from '../../states/session/session.state';
import { InfinitePager } from '../../components/infinite-list/infinite-pager';
import { UserState } from '../../states/user/user.state';
import { ErrorHandler } from '../../providers/error-handler/error-handler';
import type { HistoryMessage } from '../../../../../services/lib/history/history.service';
import { ENV } from '../../globals/env';
import { MADAlertService } from '../../services/alert/alert.service';

@Component({
    selector: 'mad-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnChanges, OnDestroy {
    private historySubscription: IWebSocketSubscriptionHandle;

    // private total: number = 0;

    @Input() public session: SessionState;

    @Output() public close = new EventEmitter();

    public pager = new InfinitePager<IHistoryItem>({
        total: 40,
        getData: async (top, limit): Promise<IHistoryItem[]> => {
            try {
                this.alertService.setBusy(true, `Loading account history...`);
                const result = await this.historyService.page({
                    sap: this.session.state.sap,
                    top,
                    limit
                });

                this.pager.setTotal(result.count);
                this.addLinks(result.results);

                return result.results;
            } catch (e) {
                console.error(e);
                throw e;
            } finally {
                this.alertService.setBusy(false);
            }
        }
    });

    constructor(
        public historyService: HistoryService,
        public pdfService: PDFService,
        public userState: UserState,
        public router: Router,
        private alertService: MADAlertService,
        public errorHandler: ErrorHandler
    ) {
    }

    public async ngOnInit(): Promise<void> {
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.session) {
            if (this.historySubscription) {
                this.historySubscription.unsubscribe();
                this.historySubscription = null;
            }
            if (this.session) {
                const result = await this.historyService.subscribe({ sap: this.session.state.sap }).toPromise();
                this.historySubscription = result.subscribe((message) => {
                    if (message.data) {
                        this.handleMessage(message.data);
                    }
                });
                this.pager.reset(false);
                this.pager.setTotal(40);
            }
        }
    }

    private findSessionIndex(id: string | number): number {
        const results = this.pager.results;
        for (let i = 0, ln = results.length; i < ln; i += 1) {
            if (results[i] && results[i].data && (results[i].data.session === id)) {
                return i;
            }
        }
        return -1;
    }

    private handleMessage(data: HistoryMessage): void {
        const index = this.findSessionIndex(data.data.session);
        switch (data.type) {
            case 'INSERT':
            case 'UPDATE':
                if (index === -1) {
                    this.pager.setTotal(this.pager.total.getValue() + 1);
                    this.pager.results.unshift({
                        lock: this.pager.results[0].lock,
                        data: { ...data.data }
                    });
                } else {
                    const history = this.pager.results[index].data;
                    Object.assign(history, data.data);
                    if (data.data.inactive) {
                        this.pager.results.splice(index, 1);
                        this.pager.setTotal(this.pager.total.getValue() - 1);
                    }
                }

                break;
            case 'DELETE':
                if (index !== -1) {
                    this.pager.results.splice(index, 1);
                    this.pager.setTotal(this.pager.total.getValue() - 1);
                }
                break;
            default:
                break;
        }
    }

    public async ngOnDestroy(): Promise<void> {
        if (this.historySubscription) {
            this.historySubscription.unsubscribe();
        }
    }

    public async onDownloadClick({ session }: { session: number }, $event): Promise<void> {
        // Prevent default behavior when clicking a link
        $event.preventDefault();

        try {
            const resp = await this.pdfService.download({ session });
            FileUtil.download(resp);
        } catch (err) {
            console.error('Error downloading pdf: ', err);
            this.errorHandler.toast('ERROR_DOWNLOADING_PDF');
        }
    }

    // TODO: How is this being called?
    public reviewSession({ session }: { session: number }): void {
        this.router.navigate(['account', {
            sap: this.session.state.sap,
            session
        }], {
            queryParams: {
                view: 'summary'
            }
        });
    }

    public onCloseClick(): void {
        this.close.emit(true);
    }

    //		/pdf/download/?session={{data.session}}
    private addLinks(data: IHistoryItem[]): void {
        console.log("addLinks: ", data);
        if (data) {
            data.forEach((item: IHistoryItem): void => {
                item.url = `${ENV.SERVICE_TARGET}/pdf/download/?session=${item.session}`;
                console.log('item.url=', item.url);
            });
        }
    }
}
