<mad-field
    [class.mad-left-icon]="_leftIcon"
    [class.mad-right-icon]="_rightIcon"
    >
    <ng-content select="mad-label" ngProjectAs="mad-label"></ng-content>
    <ng-content select="mad-micro" ngProjectAs="mad-micro"></ng-content>
    <ng-content select="mad-error" ngProjectAs="mad-error"></ng-content>

    <div class="input-wrapper" [class.grow]="grow">
        <span class="icon-wrapper left" #leftIconWrapper>
            <ng-content select="[left],mad-icon:not([right]),mad-avatar:not([right])"></ng-content>
            <ng-container *ngIf="type === 'search'">
                <mad-icon icon="fa-search fa-lg"
                    class="search-icon"
                    [class.show]="!loading"></mad-icon>

                <mad-icon icon="fa-cog fa-lg fa-spin"
                        class = "search-loading"
                        [class.show] = "loading"
                    >
                </mad-icon>
            </ng-container>
        </span>

        <span class="input-wrapper-inner">
            <div *ngIf="grow" class="input-sizer">{{(!input?.value || placeholder?.length > input?.value.length) ? placeholder : input?.value}}</div>

                    <!--[(madFormatterValue)]="value"-->
            <input
                    #input
                    [madFormatter]="formatter"
                    class="mad-input"
                    [class.grow] = "grow"
                    spellcheck="false"
                    (keydown)="onKeyDown($event)"
                    (input)="onInput($event)"
                    (focus)="onFocus($event)"
                    (blur)="onBlur($event)"
                    [(ngModel)]="_display"
                    (ngModelChange)="onNgModelChange($event)"
                    [placeholder]="placeholder"
                    [type]="inputType"
			  />
              <!-- [disabled]="disabled" -->
        </span>

        <span class="icon-wrapper right" #rightIconWrapper>
            <ng-content select="[right]"></ng-content>
            <ng-content></ng-content>
            <ng-container *ngIf="loading && type !== 'search'">
                <svg class="spot-icon spot-loading-spinner"
                     style="margin:0"
                     aria-labelledby="title">
                    <title></title>
                    <use xlink:href="/assets/spot/dist/icons/spot_icons.svg#spinner"></use>
                </svg>
            </ng-container>
        </span>
    </div>

</mad-field>
