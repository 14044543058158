<mad-body type="header" class="account-header">
  <div class="inner">
    <div madTypography="heading-3" class="text">
      <span class="name">{{account?.name}}</span>
      <span class="sap">({{account?.sap}})</span>
      <div class="link-wrapper">
        <mad-link href="#sap" class="change-link" >
          change account
        </mad-link>
        <div class="line"></div>
        <mad-link  class="change-link session" (click)="showHistory()">
          session history
        </mad-link>
        <div class="line"></div>
        <mad-link  [disabled]="!hasSelectedPanels()" class="change-link session" (click)="previewQuote()">
          preview quote
        </mad-link>
      </div>
    </div>
  </div>
</mad-body>
<div [style.visibility]="isInitializing ? 'hidden' : null" class="account-content" [class.summary]="_screen === SCREEN.SUMMARY" >
  <mad-search #search *ngIf="_screen !== SCREEN.SUMMARY" [session]="account?.session" class="search-screen"  [visible]="_screen === SCREEN.SEARCH" (addPanel)="addPanel($event)"  (open)="updateParams(SCREEN.SEARCH)" [(configure)]="configurePanel" ></mad-search>
  <mad-selected-panels #selectedPanels *ngIf="_screen !== SCREEN.SUMMARY" [session]="account?.session" class="selected-panels-screen"  [visible]="_screen === SCREEN.SELECTED_PANELS" (open)="toggleSelectedPanels()" [(configure)]="configurePanel" (configureChange)="configure($event)" (continue)="updateParams(SCREEN.SUMMARY)"></mad-selected-panels>
  <mad-summary #summary *ngIf="_screen === SCREEN.SUMMARY" [session]="account?.session" (back)="updateParams(SCREEN.SELECTED_PANELS)"></mad-summary>
  <mad-history *ngIf="isShowingHistory || account?.session?.vdc_reviewing" class="history" [session]="account?.session" [class.visible]="isVisibleHistory || account?.session?.vdc_reviewing" [class.noanimation]="skipHistoryAnimation" (close)="hideHistory()" ></mad-history>
</div>
<mad-session [session]="account?.session" [sap]="account?.session?.state?.sap" [approved]="account?.session?.state?.approved" [submitted]="account?.session?.state?.submitted" [reviewing]="account?.session?.state?.reviewing" [vdc_reviewing]="account?.session?.vdc_reviewing" [ssa_reviewing]="account?.session?.ssa_reviewing" [inactive]="account?.session?.state?.inactive" (openhistory)="showHistory(true)"></mad-session>
