import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import type { VersionMessage } from '@services/lib/version/version.service';
import { VersionService } from '../../services/version/version.service';
import { AuthState } from '../auth/auth.state';
import { IWebSocketSubscriptionHandle } from '../../providers/websocket';

@Injectable()
export class VersionState {
    versionSubscriptionHandle: IWebSocketSubscriptionHandle;

    status = new BehaviorSubject<string>(null);

    _version: string;

    constructor(
        public versionService: VersionService,
        public authState: AuthState
    ) {
        this.authState.authorized.subscribe(async (e: boolean): Promise<void> => this.onAuthorized(e));
    }

    /**
     * Wait until user is authorized before checking version.
     */
    private async onAuthorized(authorized: boolean): Promise<void> {
        try {
            if (!authorized) {
                await this.disconnect();
            } else {
                await this.connect();
            }
        } catch (err) {
            console.error('Error in version.state.onAuthorized', err);
        }
    }

    private async disconnect(): Promise<boolean> {
        if (this.versionSubscriptionHandle) {
            //wait for response
            const message = await this.versionSubscriptionHandle.unsubscribe();
            if (message) {
                const response = await message.response();
                if (response && response.success) {
                    return true;
                }
                throw 'DISCONNECT_ERROR';
            }
        }
    }

    private async connect(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.versionSubscriptionHandle) {
                    await this.versionSubscriptionHandle.unsubscribe();
                    this.versionSubscriptionHandle = null;
                }

                const sub = await this.versionService.subscribe().toPromise();

                this.versionSubscriptionHandle = sub.subscribe((message): void => {
                    this.handleVersion(message.data);
                });

                resolve();
            } catch (err) {
                console.error("Error in version connect: ", err);
                reject(err);
            }
        });
    }

    private handleVersion(op: VersionMessage): void {
        if (op) {
            console.log("handleVersion: ", this._version);
            this._version = op.data;
            this.status.next(op.data);
        }
    }
}
