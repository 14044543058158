import {Component, ElementRef, Input, ViewChildren, QueryList, ContentChildren, ViewChild} from '@angular/core';
import {DomUtil} from 'src/app/utils/dom';
import {ButtonComponent} from '../button/button.component';
import {Field} from '../field/field.component';

@Component({
    selector: 'mad-button-group',
    templateUrl: './button-group.component.html',
    styleUrls: ['./button-group.component.scss'],
    providers: Field.Providers(ButtonGroupComponent)
})
export class ButtonGroupComponent extends Field {
    @Input() disabled = false;
    @Input() lines = false;
    @Input() type: ButtonComponent['type'] = 'secondary';
    @Input() direction: 'horizontal' | 'vertical' = 'horizontal';
    @Input() showTabMarker: boolean = this.type === 'tab';
    @Input() allowNoValue: boolean = false; 

    @Input() options: {text: string; value: any}[];
    @Input() multi: boolean = false;

    _value: this['options'][0]['value'] = [];
    get value () {
        return this._value;
    }
    @Input() set value (value: this['_value']) {
        value = value || [];
        this._value = value;
        var components = (this.contentChildren && this.contentChildren.toArray() || []).concat(this.viewChildren && this.viewChildren.toArray() || []);
        for (var i=0,ln=components.length;i<ln;i++) {
            let cmp = components[i],
                v = cmp.value;
            if (v === null || v === undefined) {
                if (cmp.type === 'tab') {
                    v = i;
                }
            }
            if (value.indexOf(v) !== -1) {
                cmp._selected = true;
                if (cmp.type === 'tab' && this.type === 'tab') {
                    if (this._nextTransition) {
                        clearTimeout(this._nextTransition);
                    }
                    this._nextTransition = setTimeout(() => {
                        this._nextTransition = null;
                        this.transitionTabMarkerTo(cmp.element.nativeElement)
                    });
                }
            } else {
                cmp._selected = false;
            }
        }
        this._onChange(this._value);
    }

    @ViewChild('tabMarker') tabMarker: ElementRef;
    @ViewChildren(ButtonComponent) viewChildren: QueryList<ButtonComponent>;
    @ContentChildren(ButtonComponent) contentChildren: QueryList<ButtonComponent>;

    constructor (public element: ElementRef) {
        super(element);
    }

    getClass () {
        return `
        ${this.lines && 'with-lines' || ''}
        ${this.options && this.options.length ? 'spot-button-group-toggle' : 'spot-button-group'}
        `;
    }

    ngOnInit () {
        this.onClick = this.onClick.bind(this);
        this.element.nativeElement.addEventListener('click', this.onClick, true)
        setTimeout(() => this.value = this.value.slice())
    }

    onClick ($event: MouseEvent) {
        var target = DomUtil.queryUp($event.target as HTMLElement, 'mad-button');
        if (target) {
            var components = (this.contentChildren && this.contentChildren.toArray() || []).concat(this.viewChildren && this.viewChildren.toArray() || []);
            for (var i=0,ln=components.length;i<ln;i++) {
                var cmp = components[i];
                if (cmp.element.nativeElement === target) {
                    if (cmp.value === undefined || cmp.value === null) {
                        if (cmp.type !== 'tab') {
                            throw 'Component does not have a value assigned';
                        } else {
                            this.onOptionClicked(i);
                        }
                    } else {
                        this.onOptionClicked(cmp.value);
                    }
                    break;
                }
            }
        }
    }

    focusInput () {
        var button = this.element.nativeElement.querySelector('button');
        if (button) {
            button.focus();
        }
    }

    blurInput () {
        if (DomUtil.queryUp(document.activeElement, this.element.nativeElement)) {
            (<HTMLElement>document.activeElement).blur();
        }
    }

    onOptionClicked (v) {
        var index = this.value.indexOf(v),
            value: this['value'];

        if (index !== -1) {
            //remove if toggle off
            if (!this.allowNoValue && this.value.length === 1) {
                value = this.value;
                //pass
            } else {
                value = this.value.slice();
                value.splice(index, 1);
            }
        } else {
            if (!this.multi) {
                value =  [];
            } else {
                value = this.value.slice()
            }
            value.push(v);
        }
        this.value = value;
    }

    getOptionChecked (option: this['options'][0]) {
        return this.value.indexOf(option.value) !== -1 ? true : false;
    }

    writeValue (value: this['value']) {
        this.value = value;
    }

    reset () {
        this.value = [];
    }

    //getTabMarkerStyle () {
        //var components = (this.contentChildren && this.contentChildren.toArray() || []).concat(this.viewChildren && this.viewChildren.toArray() || []),
            //targetValue = this.value && this.value[0];
        //console.error(targetValue);
        
        //for (var i=0,ln=components.length;i<ln;i++) {
            //var component = components[i],
            //value = component.value;
            //if (value === undefined || value === null) {
                //if (component.type === 'tab') {
                    //value = i;
                //}
            //}
            //if (value === targetValue) {
                //return {
                    //position: 'absolute',
                    //top: `${component.element.nativeElement.offsetTop}px`,
                    //height: `${component.element.nativeElement.offsetHeight}px`,
                    //background: 'blue',
                    //left: 0,
                    //width: '10px'
                //}
            //}
        //}
        //return {};
    //}

    _nextTransition: any;
    transitionTabMarkerTo(targetElement: HTMLElement) {
        var 
            top = targetElement.offsetTop,
            height = targetElement.offsetHeight,
            element = this.tabMarker.nativeElement,
            offsetTop = element.offsetTop,
            offsetHeight = element.offsetHeight;
        if (this._nextTransition) {
            clearTimeout(this._nextTransition);
        }
        if (top < offsetTop) {
            element.style.top = `${top}px`
            element.style.height = `${offsetHeight + (offsetTop - top)}px`;
        } else {
            element.style.top = `${element.offsetTop}px`
            element.style.height = `${(top+height) - offsetTop}px`
        }
        this._nextTransition = setTimeout(() => {
            this._nextTransition = null;
            element.style.top = `${top}px`;
            element.style.height = `${height}px`;
        }, 200)
    }

}
