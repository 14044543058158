import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FieldsetComponent} from './fieldset.component';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule
  ],
  declarations: [
      FieldsetComponent
  ],
  exports: [
      FieldsetComponent
  ],
  bootstrap: [FieldsetComponent]
})
export class FieldsetModule { }
