import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Service } from './../service/service';
import { VersionAPI } from '../../../../../services/lib/version/version.api'
import { SSEClient } from './../../providers/sse/sse';
import { WebSocket } from './../../providers/websocket/websocket';
import { UtilService } from '../../services/util/util.service';

@Injectable()
export class VersionService extends Service<typeof VersionAPI> {
    constructor(
        public http: HttpClient,
        public sseClient: SSEClient,
        public wsClient: WebSocket
    ) {
        super(http, sseClient, wsClient);
    }

    async getVersion(): Promise<string> {
        try {
            const resp = await firstValueFrom(
                super.get('/version')
            );
            if (resp.success && resp.data) {
                return resp.data;
            }
            console.error("version.get failed: ", resp.error);
            throw new Error("version.get failed: " + UtilService.getErrorMessage(resp.error));
        } catch (err) {
            console.error("Error calling version.get: ", err);
            throw err;
        }
    }

    subscribe() {
        return super.ws('/version/subscribe', { params: {} });
    }
}
