import {
    Directive,
    Component,
    OnInit,
    OnDestroy,
    Input,
    ElementRef,
    TemplateRef,
    OnChanges,
    ViewChild,
    ChangeDetectorRef
} from "@angular/core";
import { DomUtil } from "../../utils/dom";
import { OutletItem } from '../outlet/outlet.component'

@Directive({
    selector: '[madPopover]'
})
export class PopoverDirective {
    //_config: {target: PopoverComponent, openOnClick: boolean} = {target: undefined, openOnClick: false};


    //@Input()
    //madPopoverTarget: PopoverComponent;

    _showOnFocus: Function;
    _showOnClick: Function;
    _showOnMouseEnter: Function;
    _hideOnBlur: Function;
    _hideOnMouseOut: Function;
    _showing = false;
    _config: {
        showOnMouseEnter?: boolean;
        showOnFocus?: boolean;
        showOnClick?: boolean;
        hideOnBlur?: boolean;
        hideOnMouseOut?: boolean;
        target: any;
        type: 'bubble' | 'pointer';
        transition: string;
        content: TemplateRef<any>;
        context: any;
        popover: PopoverComponent;
    }

    @Input() set madPopover(config: this['_config']) {
        this._config = config;

        var last_element = null;

        if (config.showOnMouseEnter && !this._showOnMouseEnter) {
            this.element.nativeElement.addEventListener('mouseenter', this._showOnMouseEnter = (e) => {
                var { popover, target, type, content, context } = this._config;
                last_element = popover.target = target || this.element;
                popover.type = type || 'pointer';
                this._showing = true;

                //popover.scheduleVisible(true);
                popover.scheduleVisible(true);
                popover.content = content;
                popover.context = context;
                //popover.visible = true;
                //popover.update();
            }, true);
        } else if (!config.showOnMouseEnter && this._showOnMouseEnter) {
            this.element.nativeElement.removeEventListener('mouseenter', this._showOnMouseEnter)
            this._showOnMouseEnter = null;
        }

        if (config.showOnFocus && !this._showOnFocus) {
            this.element.nativeElement.addEventListener('focus', this._showOnFocus = () => {
                var { popover, target, type, content, context } = this._config;
                last_element = popover.target = target || this.element;

                popover.type = type || 'pointer';

                this._showing = true;
                popover.scheduleVisible(true);
                popover.content = content;
                popover.context = context;
            }, true);
        } else if (!config.showOnFocus && this._showOnFocus) {
            this.element.nativeElement.removeEventListener('focus', this._showOnFocus)
            this._showOnFocus = null;
        }

        if (config.showOnClick && !this._showOnClick) {
            this.element.nativeElement.addEventListener('click', this._showOnClick = () => {
                var { popover, target, type, content, context } = this._config;
                last_element = popover.target = target || this.element;

                popover.type = type || 'pointer';

                this._showing = true;
                popover.scheduleVisible(true);
                popover.content = content;
                popover.context = context;
            }, true);
        } else if (!config.showOnClick && this._showOnClick) {
            this.element.nativeElement.removeEventListener('click', this._showOnClick)
            this._showOnClick = null;
        }

        if (config.hideOnBlur && !this._hideOnBlur) {
            this.element.nativeElement.addEventListener('blur', this._hideOnBlur = () => {
                var { popover, target } = this._config;
                if (popover.target === target || this.element) {
                    this._showing = false;
                    popover.scheduleVisible(false);
                }
            }, true);
        } else if (!config.hideOnBlur && this._hideOnBlur) {
            this.element.nativeElement.removeEventListener('blur', this._hideOnBlur)
            this._hideOnBlur = null;
        }

        if (config.hideOnMouseOut && !this._hideOnMouseOut) {
            this.element.nativeElement.addEventListener('mouseout', this._hideOnMouseOut = (e) => {
                var { popover, target } = this._config;

                if (popover.target === target || this.element) {
                    this._showing = false;
                    popover.scheduleVisible(false);
                }
            }, true);
        } else if (!config.hideOnMouseOut && this._hideOnMouseOut) {
            this.element.nativeElement.removeEventListener('mouseout', this._hideOnMouseOut)
            this._hideOnMouseOut = null;
        }


        if (this._showing && (config.target || this.element) && (config.target || this.element) !== config.popover.target) {
            this._config.popover.target = config.target || this.element;
            last_element = config.target || this.element;
            this._config.popover.ngOnChanges();
        }

    }



    //@Input('madPopoverTarget.showOnFocus') set showOnFocus (showOnFocus: boolean) {
    //if (showOnFocus && !this._showOnFocus) {
    //this.element.nativeElement.addEventListener('focus', this._showOnFocus = () => {
    //this.madPopoverTarget.target = this.target || this.element;
    //if (this.type) {
    //this.madPopoverTarget.type = this.type;
    //}
    //this.madPopoverTarget.scheduleVisible(true);
    //this.madPopoverTarget.content = this.content;
    //}, true);
    //} else if (!showOnFocus && this._showOnFocus) {
    //this.element.nativeElement.removeEventListener('focus', this._showOnFocus)
    //}
    //}
    //get showOnFocus () {
    //return this._showOnFocus && true || false;
    //}
    //@Input('madPopoverTarget.hideOnBlur') set hideOnBlur (hideOnBlur: boolean) {
    //if (hideOnBlur && !this._hideOnBlur) {
    //this.element.nativeElement.addEventListener('blur', this._hideOnBlur = () => {
    //this.madPopoverTarget.target = this.target || this.element;
    //this.madPopoverTarget.scheduleVisible(false);
    //this.madPopoverTarget.content = this.content;
    //}, true);
    //} else if (!hideOnBlur && this._hideOnBlur) {
    //this.element.nativeElement.removeEventListener('blur', this._hideOnBlur)
    //}
    //}
    //get hideOnBlur () {
    //return this._hideOnBlur && true || false;
    //}


    /*
     *  @Input('madPopoverTarget.target') target: any;
     *
     *  @Input('madPopoverTarget.type') type: 'bubble' | 'pointer' = 'pointer';
     *
     *  @Input('madPopoverTarget.transition') transition: string;
     *
     *  @Input('madPopoverTarget.content') content: TemplateRef<any>;
     *
     */
    constructor(public element: ElementRef) {
    }
}

@Component({
    selector: "mad-popover",
    templateUrl: "./popover.component.html",
    styleUrls: ["./popover.component.scss"]
})
export class PopoverComponent extends OutletItem implements OnInit, OnDestroy, OnChanges {
    @ViewChild('template') template: TemplateRef<any>;
    @Input() outlet: string;
    @Input() mask: boolean;

    static _initalized = false;
    static _active = [];
    static _dispatch = 0;

    @Input() target: ElementRef;
    @Input() left: number;
    @Input() right: number;
    @Input() top: number;
    @Input() bottom: number;
    @Input() height: number;
    @Input() width: number;
    @Input() visible: boolean = false;
    @Input() auto: boolean = true;
    @Input() minWidth: number = 0;
    @Input() minHeight: number = 0;
    @Input() constrainTo: "window" | string | HTMLElement = "mad-outlet";
    @Input() position: "top-right" | "top-left" | "top-center" | "bottom-right" | "bottom-left" | "bottom-center" |
        "left-top" | "left-bottom" | "left-center" | "right-top" | "right-bottom" | "auto" | "right-center" = "auto";
    @Input() type: 'pointer' | 'bubble' = 'pointer';
    @Input() class: string;

    //TODO cleanup
    @Input() showEvent = 'focus';
    @Input() hideEvent = 'focus';

    _lastContent: TemplateRef<any>;
    _content: TemplateRef<any>;

    _set_content_timer: any;
    @Input() set content(content: TemplateRef<any>) {
        if (!this.templateContentWrapper) {
            if (this._set_content_timer) {
                clearTimeout(this._set_content_timer);
            }
            this._set_content_timer = setTimeout(() => {
                this._set_content_timer = undefined;
                this.content = content
            });
            return;
        }
        var templateContentWrapper = this.templateContentWrapper.nativeElement;
        if (this._content && this._content !== content) {
            templateContentWrapper.style.transition = 'opacity 0ms';
            templateContentWrapper.style.opacity = 0;
            setTimeout(() => {
                templateContentWrapper.style.transition = 'opacity 400ms';
                templateContentWrapper.style.opacity = 1;
            });

            this.update();
        }
        this._content = content;
    }

    get content() {
        return this._content;
    }

    @Input() context: any;


    //@HostBinding("class") hostClass: any;
    //@HostBinding("class.initializing") initializing = true;
    //@HostBinding("style.left") hostLeft: any;
    //@HostBinding("style.right") hostRight: any;
    //@HostBinding("style.top") hostTop: any;
    //@HostBinding("style.bottom") hostBottom: any;

    hostClass: string;
    initializing = true;
    hostLeft: string;
    hostRight: string;
    hostTop: string;
    hostBottom: string;

    @ViewChild("contentWrapper") contentWrapper: ElementRef<any>;
    @ViewChild("popover") popover: ElementRef<any>;
    @ViewChild("lastTemplateContentWrapper") lastTemplateContentWrapper: ElementRef<any>;
    @ViewChild("templateContentWrapper") templateContentWrapper: ElementRef<any>;
    @ViewChild("innerWrapper") innerWrapper: ElementRef<any>;
    @ViewChild("outerWrapper") outerWrapper: ElementRef<any>;
    @ViewChild("background") background: ElementRef<any>;
    @ViewChild("arrow") arrow: ElementRef<any>;

    isTransitioning = false;
    _lastWidth: number = 0;
    _lastHeight: number = 0;
    _offScreen = false;
    _scheduleVisible: any = null;
    _scheduleValue: boolean = false;

    hostWidth: string | number;
    hostHeight: string | number;
    primaryPosition: string;
    secondaryPosition: string;
    constrained: any;


    constructor(
        public changeDetector: ChangeDetectorRef,
        public element: ElementRef<any>
    ) {
        super()

        setTimeout(() => {
            this.ngOnChanges();
        });
    }


    static init() {
        if (this._initalized) {
            return;
        }

        this._initalized = true;
        const dispatcher = () => {
            if (!this._dispatch) {
                this._dispatch = requestAnimationFrame(() => {
                    this._dispatch = 0;
                    const components = this._active;
                    let i: number, ln: number;
                    for (i = 0, ln = components.length; i < ln; i++) {
                        components[i].resize();
                    }
                });
            }
        };


        window.addEventListener("scroll", dispatcher, true);
        window.addEventListener("resize", dispatcher);
    }

    ngOnInit() {
        super.ngOnInit();
        PopoverComponent.init();
        PopoverComponent._active.push(this);
        setTimeout(() => {
            requestAnimationFrame(() => {
                this.initializing = false;
            });
        });
        this.hostClass = this.getClass();
    }

    ngOnDestroy() {
        PopoverComponent._active.splice(PopoverComponent._active.indexOf(this), 1);
    }

    onResize(resizeState: { width: number, height: number }) {
        this.resize(resizeState);
    }

    resize({ width, height, $event }: { width: number, height: number, $event?: Event } = { width: this._lastWidth, height: this._lastHeight }) {
        //console.error(this);
        width = Math.max(width, this.minWidth);
        height = Math.max(height, this.minHeight);
        this._lastWidth = width;
        this._lastHeight = height;

        //console.error('resize',width,height);
        if (!this.visible || !this.target) {
            return { width: 0, height: 0 };
        }
        const primary = this.primaryPosition,
            secondary = this.secondaryPosition;
        let constrained: { width?: number, height?: number, maxWidth?: number, maxHeight?: number, top?: number, left?: number, primary?: string, secondary?: string, arrow?: { top?: number, left?: number } },
            constrainToContainer = this.getConstrainToRect(),
            _parentRect = (((this.target as any).element && (this.target as any).element.nativeElement) || (this.target.nativeElement) || this.target).getBoundingClientRect(),
            parentRect = {
                top: _parentRect.top,
                left: _parentRect.left,
                bottom: _parentRect.bottom,
                right: _parentRect.right,
                width: _parentRect.width,
                height: _parentRect.height
            };


        if ($event) {
            $event.preventDefault();
            $event.stopImmediatePropagation();
        }

        if (this.position !== "auto") {
            constrained = this.getConstrainedPosition({
                width, height, primary, secondary
            });
            //console.error(constrained);
        } else {
            constrained = {
                width: 0,
                height: 0,
                maxWidth: 0,
                maxHeight: 0,
                top: 0,
                left: 0,
                primary: "",
                secondary: ""
            };
            ["top", "left", "right", "bottom"].forEach((p) => {
                (p === "left" || p === "right" ? ["top", "bottom", "vcenter"] : ["left", "right", "hcenter"]).forEach((s) => {
                    const current = this.getConstrainedPosition({
                        width, height, primary: p, secondary: s
                    });
                    //console.error(p, s, current);
                    if (!current || !constrained) {
                        return;
                    }
                    if (current.width * current.height >= constrained.width * constrained.height) {
                        if (current.maxWidth * current.maxHeight - width * height
                            >= constrained.maxHeight * constrained.maxWidth - width * height) {
                            constrained = current;
                            constrained.primary = p;
                            constrained.secondary = s;
                        }
                    }
                });
            });
            //console.error('chosen', constrained);
            this.primaryPosition = constrained.primary;
            this.secondaryPosition = constrained.secondary;

        }

        const wasOffscreen = this._offScreen || !this.visible;


        if (!constrained || (constrained.width === 0 || constrained.height === 0)) {
            this._offScreen = true;
        } else {
            this._offScreen = false;
        }

        if (
            parentRect.top > constrainToContainer.top + constrainToContainer.height ||
            parentRect.top + parentRect.height < constrainToContainer.top ||
            parentRect.left > constrainToContainer.left + constrainToContainer.width ||
            parentRect.left + parentRect.width < constrainToContainer.left
        ) {
            this._offScreen = true;
        }

        this.constrained = constrained;
        this.hostClass = this.getClass();

        if (wasOffscreen || this._offScreen) {
            if (this.background) {
                this.background.nativeElement.style.transition = "none";
            }
            this.arrow.nativeElement.style.transition = "none";
            this.outerWrapper.nativeElement.style.transition = "none";

            if (!this._offScreen) {
                render.call(this);
                this.popover.nativeElement.style.transition = "opacity 400ms";
                requestAnimationFrame(() => this.resize({ width, height }));
            }

            switch (this.primaryPosition) {
                case "top": this.popover.nativeElement.style.transform = "translateY(-50%)scale(0.5)"; break;
                case "bottom": this.popover.nativeElement.style.transform = "translateY(50%)scale(0.5)"; break;
                case "left": this.popover.nativeElement.style.transform = "translateX(-50%)scale(0.5)"; break;
                case "right": this.popover.nativeElement.style.transform = "translateX(50%)scale(0.5)"; break;
            }
        } else {
            this.popover.nativeElement.style.transform = null;
            if (this.background) {
                this.background.nativeElement.style.transition = null;
            }
            this.arrow.nativeElement.style.transition = null;
            this.popover.nativeElement.style.transition = null;
            this.outerWrapper.nativeElement.style.transition = null;

            render.call(this);
        }


        function render() {

            this.popover.nativeElement.style.transformOrigin =
                `${constrained.left + constrained.width / 2}px ${constrained.top + constrained.height / 2}px`;

            this.hostHeight = Math.min(constrained.height, Math.max(height, this.minHeight));
            this.hostWidth = Math.min(constrained.width, Math.max(width, this.minWidth));
            this.outerWrapper.nativeElement.style.width = `${this.hostWidth}px`;
            this.background.nativeElement.style.width = `${this.hostWidth}px`;

            this.outerWrapper.nativeElement.style.height =
                this.background.nativeElement.style.height = `${this.hostHeight}px`;

            this.outerWrapper.nativeElement.style.top =
                this.background.nativeElement.style.top = `${constrained.top}px`;

            this.outerWrapper.nativeElement.style.left =
                this.background.nativeElement.style.left = `${constrained.left}px`;

            this.arrow.nativeElement.style.top = `${constrained.arrow.top}px`;
            this.arrow.nativeElement.style.left = `${constrained.arrow.left}px`;
        }
    }

    getConstrainToRect() {
        const constraint = this.constrainTo;
        let result: ClientRect | { top: number, bottom: number, left: number, right: number, height: number, width: number }, el: HTMLElement | Node;
        if (typeof constraint === "string") {
            el = DomUtil.queryUp(this.element.nativeElement, constraint);
            result = el && (el as HTMLElement).getBoundingClientRect();
        } else if (constraint instanceof HTMLElement) {
            result = constraint.getBoundingClientRect();
        } else if (constraint && (constraint as any).element) {
            result = (constraint as any).element.nativeElement.getBoundingClientRect();
        }
        if (result) {
            result = {
                top: result.top,
                bottom: result.bottom,
                left: result.left,
                right: result.right,
                width: result.width,
                height: result.height
            };
        }
        return result || {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight,
            right: window.innerWidth,
            bottom: window.innerHeight
        };
    }

    onScroll($event: Event) {
        $event.stopPropagation();
    }

    show() {
        if (this._scheduleVisible) {
            clearTimeout(this._scheduleVisible);
            this._scheduleVisible = null;
            this._scheduleValue = null;
        }
        this.visible = true;
        this.update();
    }

    hide() {
        if (this._scheduleVisible) {
            clearTimeout(this._scheduleVisible);
            this._scheduleVisible = null;
            this._scheduleValue = null;
        }
        this.visible = false;
        this.update();
    }

    scheduleVisible(value: boolean, timer = 0) {
        if (this._scheduleValue && !value) {
            //do nothing
        } else {
            this._scheduleValue = value;
            if (!this._scheduleVisible) {
                this._scheduleVisible = setTimeout(() => {
                    this.visible = this._scheduleValue;
                    this._scheduleVisible = null;
                    this._scheduleValue = null;
                    this.update();
                }, timer);
            }
        }
    }

    getConstrainedPosition({ width, height, primary, secondary }) {
        if (!this.target) {
            return;
        }

        var _parentRect = (((this.target as any).element && (this.target as any).element.nativeElement) || (this.target.nativeElement) || this.target).getBoundingClientRect(),
            parentRect = {
                top: _parentRect.top,
                left: _parentRect.left,
                bottom: _parentRect.bottom,
                right: _parentRect.right,
                width: _parentRect.width,
                height: _parentRect.height
            },
            constrainRect = this.getConstrainToRect(),
            originalLeft = constrainRect.left || 0,
            originalTop = constrainRect.top || 0,
            winTop = constrainRect.top,
            winLeft = constrainRect.left,
            winWidth = constrainRect.width,
            winHeight = constrainRect.height,
            arrowSize = 20,
            constrained = {
                width: 0,
                height: 0,
                left: 0,
                top: 0,
                maxWidth: winWidth,
                maxHeight: winHeight,
                arrow: {
                    top: 0,
                    left: 0,
                    size: arrowSize
                }
            };

        parentRect.top -= winTop;
        parentRect.left -= winLeft;
        parentRect.bottom -= winTop;
        parentRect.right -= winLeft;
        parentRect.height += 2;
        parentRect.width += 2;

        if (parentRect.top < constrained.top) {
            parentRect.top = constrained.top;
        }

        if (parentRect.bottom > winHeight) {
            parentRect.height -= parentRect.bottom - winHeight;
            parentRect.bottom = winHeight;
        }
        if (parentRect.right > winWidth) {
            parentRect.width -= parentRect.right - winWidth;
            parentRect.right = winWidth;
        }


        //console.error(primary,secondary);
        // try to put the arrow anchor in the correct coordinates (if possible, if not  -- find a place visible))
        if (primary === "right" || secondary === "right") {
            constrained.arrow.left = parentRect.width + arrowSize / 2 + 2;
            if (primary === "right") {
                constrained.left = parentRect.width + arrowSize / 2 + 2;
                constrained.maxWidth = winWidth - parentRect.right - 2;
            } else {
                constrained.maxWidth = Math.max(parentRect.right, 0);
                constrained.width = Math.min(constrained.maxWidth, width);
                constrained.left = parentRect.width - constrained.width;
            }
        }
        if (primary === "top" || secondary === "top") {
            constrained.arrow.top = -arrowSize / 2 - 5;
            if (primary === "top") {
                constrained.maxHeight = parentRect.top - arrowSize / 2 - 5;
                constrained.height = Math.min(height, constrained.maxHeight);
                constrained.top = -constrained.height - arrowSize / 2 - 5;
            } else {
                constrained.top = Math.max(-parentRect.top, 0);
                constrained.maxHeight = winHeight - Math.max(parentRect.top, 0);
            }
        }
        if (primary === "left" || secondary === "left") {
            constrained.arrow.left = -arrowSize / 2 - 5;
            if (primary === "left") {
                constrained.maxWidth = parentRect.left - arrowSize / 2 - 4;
                constrained.width = Math.min(constrained.maxWidth, width);
                constrained.left = -constrained.width - arrowSize / 2 - 4;
            } else {
                constrained.maxWidth = winWidth - parentRect.left;
                constrained.left = Math.max(-parentRect.left, 0);
            }
        }
        if (primary === "bottom" || secondary === "bottom") {
            constrained.arrow.top = parentRect.height + arrowSize / 2;
            if (primary === "bottom") {
                constrained.maxHeight = winHeight - parentRect.bottom - arrowSize / 2;
                constrained.top = parentRect.height + arrowSize / 2;
            } else {
                constrained.maxHeight = Math.max(parentRect.bottom, 0);
                constrained.height = Math.min(constrained.maxHeight, height);
                constrained.top = parentRect.height - constrained.height;
            }
        }

        constrained.width = Math.min(constrained.maxWidth, Math.max(this.minWidth, width));
        constrained.height = Math.min(constrained.maxHeight, Math.max(this.minHeight, height));

        if (secondary === "hcenter") {
            constrained.maxWidth = winWidth;
            constrained.arrow.left = parentRect.width / 2;
            if (parentRect.left + parentRect.width / 2 + constrained.width / 2 > winWidth) {
                constrained.left = -parentRect.left + winWidth - constrained.width;
            } else if (parentRect.left + parentRect.width / 2 - constrained.width / 2 < winLeft) {
                constrained.left = -parentRect.left;
            } else {
                constrained.left = parentRect.width / 2 - constrained.width / 2;
            }
        } else if (secondary === "vcenter") {
            constrained.maxHeight = winHeight;
            constrained.height = Math.min(winHeight, height);
            constrained.arrow.top = parentRect.height / 2;
            if (parentRect.top + parentRect.height / 2 + constrained.height / 2 > winHeight) {
                constrained.top = -parentRect.top + winHeight - constrained.height;
            } else if (parentRect.top + parentRect.height / 2 - constrained.height / 2 < 0) {
                constrained.top = -parentRect.top;
            } else {
                constrained.top = parentRect.height / 2 - constrained.height / 2;
            }
        }

        if (primary === "top" || primary === "bottom") {
            if (constrained.arrow.left + arrowSize > constrained.left + constrained.width) {
                constrained.arrow.left = constrained.left + constrained.width - arrowSize;
            } else if (constrained.arrow.left - arrowSize / 2 < constrained.left) {
                constrained.arrow.left = constrained.left + arrowSize * 3 / 4;
            }
        } else {
            if (constrained.arrow.top + arrowSize > constrained.top + constrained.height) {
                constrained.arrow.top = constrained.top + constrained.height - arrowSize;
            }
            if (constrained.arrow.top - arrowSize / 2 < constrained.top) {
                constrained.arrow.top = constrained.top + arrowSize * 3 / 4;
            }
        }

        constrained.top += parentRect.top;
        constrained.left += parentRect.left;
        constrained.arrow.top += parentRect.top;
        constrained.arrow.left += parentRect.left;

        constrained.top += originalTop;
        constrained.left += originalLeft;
        constrained.arrow.top += originalTop;
        constrained.arrow.left += originalLeft;

        if (constrained.height < 0 || constrained.width < 0) {
            constrained.height = 0;
            constrained.width = 0;
            constrained.arrow.size = 0;
            constrained.arrow.left = constrained.arrow.top = 0;
        }

        return constrained;
    }


    getClass() {
        return `cl-popover cl-primary-${this.primaryPosition}
            ${this.type}
            ${this.class || ''}
            cl-secondary-${this.secondaryPosition} ${(!this.visible || this._offScreen) && "cl-popover-hidden" || ""}`;
    }

    isHorizontal(pos: string) {
        return pos === "left" || pos === "right";
    }
    isVertical(pos: string) {
        return pos === "top" || pos === "bottom";
    }

    _updateTimer: any;
    update() {
        if (!this._updateTimer) {
            this._updateTimer = requestAnimationFrame(() => {
                this.ngOnChanges();
                this._updateTimer = undefined;
            });
        }
    }
    _hideCallback: Function;
    _showCallback: Function;
    _showEvent: string;
    _lastTarget: any;
    ngOnChanges() {

        //TODO this logic sucks
        //if (this.target !== this._lastTarget) {
        //if (this._lastTarget) {
        //document.body.removeEventListener(this._showEvent, this._showCallback as any, true);
        //document.body.removeEventListener(this.showEvent, this._showCallback as any, true);
        //document.body.removeEventListener('click', this._showCallback as any, true);
        //document.body.removeEventListener('mousedown', this._hideCallback as any, true);
        //this._showCallback = null;
        //this._hideCallback = null;
        //}
        //}


        if (this.auto === true) {
            if (this._showEvent && this._showEvent !== this.showEvent) {
                document.body.removeEventListener(this._showEvent, this._showCallback as any, true);
                document.body.addEventListener(this.showEvent, this._showCallback as any, true);
            }
            this._showEvent = this.showEvent;
            if (!this._showCallback) {
                document.body.addEventListener(this.showEvent, this._showCallback = (e: Event) => {
                    if (!this.target) {
                        return;
                    }

                    if (this.popover && DomUtil.queryUp(e.target as HTMLElement, [this.popover.nativeElement, (this.target && (this.target as any).element && (this.target as any).element.nativeElement) || this.target.nativeElement].filter(v => v))) {
                        this.scheduleVisible(true)
                    } else {
                        //keep an eye on this but dont think it should have existed -- I really need to rework this component at some point
                        //this.scheduleVisible(false);
                        //console.error(e.target, this.target);
                        //this.scheduleVisible(false, 300);
                    }
                }, true);
                //console.error(this.target);
                document.body.addEventListener('click', this._showCallback as any, true);
                document.body.addEventListener('mousedown', this._hideCallback = (e: Event) => {
                    //console.error(this.target);
                    if (!this.target) {
                        return;
                    }
                    if (!this.popover || !DomUtil.queryUp(e.target as HTMLElement, [this.popover.nativeElement, (this.target && (this.target as any).element && (this.target as any).element.nativeElement) || this.target.nativeElement].filter(v => v))) {
                        this.scheduleVisible(false);
                    }
                }, true);
            }
        } else {
            if (this._showCallback) {
                document.body.removeEventListener(this.showEvent, this._showCallback as any, true);
                document.body.removeEventListener('click', this._showCallback as any, true);
            }
            if (this._hideCallback) {
                document.body.removeEventListener('mousedown', this._hideCallback as any, true);
            }
        }
        if (this.position !== "auto") {
            const positions = (this.position || "").split("-");
            this.primaryPosition = positions[0];
            this.secondaryPosition = positions[1];

            if (this.secondaryPosition === "center" && this.isHorizontal(this.primaryPosition)) {
                this.secondaryPosition = "vcenter";
            } else if (this.secondaryPosition === "center" && this.isVertical(this.primaryPosition)) {
                this.secondaryPosition = "hcenter";
            }
        }

        if (!this.visible) {
            this._offScreen = true;
        }


        this._lastTarget = this.target;
        //console.error(this.target);
        this.hostClass = this.getClass();
        this.hostLeft = (this.left !== undefined && this.left !== null) && (this.left + "px");
        this.hostRight = (this.right !== undefined && this.right !== null) && (this.right + "px");
        this.hostTop = (this.top !== undefined && this.top !== null) && (this.top + "px");
        this.hostBottom = (this.bottom !== undefined && this.bottom !== null) && (this.bottom + "px");

        var width = this.contentWrapper ? this.contentWrapper.nativeElement.offsetWidth : this._lastWidth,
            height = this.contentWrapper ? this.contentWrapper.nativeElement.offsetHeight : this._lastHeight;

        this.update();
        requestAnimationFrame(() => this.resize({ width, height }));
    }

}
