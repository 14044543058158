import { Injectable } from '@angular/core';

@Injectable()
export class UtilService {

    static getErrorMessage(err: any): string {
        const msgParts: string[] = [];
        if (err) {
            if (err.name) {
                msgParts.push(err.name);
            }
            if (err.message) {
                msgParts.push(err.message);
            } else if (err.message2) {
                msgParts.push(err.message2);
            }
            if (err.sourceError) {
                console.error("err.sourceError=", err.sourceError);
            }

        }
        const msg = msgParts.join(' : ');
        return msg;
    }

    static getErrorCode(err: string | Error): string {

        if (typeof err === 'string') {
            return err;
        }
        // For MadError - still work in progress.
        if (err['code']) {
            console.log("error code present: ", err['code'], err);
            return err['code'];
        }
        return err.message;
    }

    static trimToNull(str: string): string {
        var retVal = str;
        if (str && str.trim) {
            retVal = str.trim();
        }
        if (retVal === '' || retVal === 'null') {
            retVal = null;
        }
        return retVal;
    }

}
