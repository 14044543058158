import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {ToastComponent} from './toast.component';
import {HeaderModule} from '../header/header.module';
import {BodyModule} from '../body/body.module';
import {IconModule} from '../icon/icon.module';
import {LinkModule} from '../link/link.module';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     HeaderModule,
     LinkModule,
     BodyModule,
     IconModule
  ],
  declarations: [
    ToastComponent
  ],
  exports: [
    ToastComponent
  ],
  bootstrap: [ToastComponent]
})
export class ToastModule { }
