<mad-field>
    <ng-content select="mad-label" ngProjectAs="mad-label"></ng-content>
    <ng-content select="mad-micro" ngProjectAs="mad-micro"></ng-content>
    <ng-content select="mad-error" ngProjectAs="mad-error"></ng-content>

    <div [ngClass]="'cl-field-select-wrapper spot-form__select' +
            classNameMods
            ">
        <select [tabindex]="tabindex" #select (blur)="onBlur()" (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)" (click)="onClick()" [disabled]="disabled" [ngModel]="_isEmpty ? undefined : value" (ngModelChange)="onValueChange($event)" class="spot-form__select-input" name="">
            <option *ngIf="emptyOption" class="spot-form__select-option" [selected]="_isEmpty" [disabled]="emptyOption.disabled" [value]="emptyOption.value">{{emptyOption.label}}</option>
            <option *ngFor="let option of options;trackBy:trackByOption" class="spot-form__select-option" [value]="option.value">{{option.label}}</option>
        </select>
        <div *ngIf="(!emptyOption && value !== 0 && !value) || (emptyOption && (_isEmpty || value === undefined))" class="cl-select-placeholder spot-form__select-input">
            <div *ngIf="placeholder && !_dropDownVisible" class="spot-form__select-input mad-placeholder-wrapper"  >
                <div class="placeholder">{{placeholder.text}}</div>
            </div>
        </div>
        <div class="spot-form__select-inner"></div>
        <span class="spot-form__select-open-indicator">
            <svg class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
                <title></title>
                <use xlink:href="/assets/spot/dist/icons/spot_icons.svg#caret-down"></use>
            </svg>
        </span>
    </div>
</mad-field>
