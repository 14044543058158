<div class="page-body">

  <h1> Users </h1>
  <mad-button style="float:right" (click)="toggleSearch()">+ New User</mad-button>

  <table matSort (matSortChange)="sortData($event)" style="width:100%;max-height:50%;">
	<tr>
	  <th mat-sort-header="name">Name</th>
	  <th mat-sort-header="role">Role</th>
	  <th mat-sort-header="email">Email</th>
	  <th mat-sort-header="active">Active</th>
	  <th style="width: 150px"></th>
	</tr>
	<tr *ngFor="let user of sortedData" (click)="selectUser(user)" [ngClass]="'irl-user ' + (selectedUser == user ? ' selected' : '') + (user.modified ? ' modified' : '')">
	  <td>{{user.name}} <ng-container *ngIf="user.inactive"> <i>(inactive)</i></ng-container></td>
	  <td>
		<select name="role" class="form-control" [(ngModel)]='user.role' (change)="roleSelected(user)" required [compareWith]="compareRole">
		  <option name='' value=''></option>
		  <option *ngFor="let role of roles" [ngValue]="role">{{role.name}}</option>
		</select>
	  </td>
	  <td>{{user.email}}</td>
	  <td align="center">
		<ng-container *ngIf="!user.inactive">
          <svg class="spot-icon spot-list-group__item-icon" aria-labelledby="title">
            <title>checkmark</title>
            <use xlink:href="/assets/spot/dist/icons/spot_icons.svg#checkmark"></use>
          </svg>
		</ng-container>
	  </td> <!-- TODO: editable checkbox -->
	  <td style="width: 150px">
		<button *ngIf="user.modified" (click)="saveUser(user)">Save</button>
		<button *ngIf="user.modified" (click)="cancelUser(user)">Cancel</button>
	  </td>
	</tr>
  </table>
  <mad-message [message]="message"></mad-message>

  <div name="newUser" *ngIf="searchEnabled" class="search-bar">
	<div class="spot-card--body demo-card-height">
	  <form class="spot-form spot-search-bar" style="width:50%;">
		<input class="spot-form__input spot-search-bar__input" name="searchEmail" [(ngModel)]="searchEmail" placeholder="Search by email address">
		<button class="spot-search-bar__search-button" type="submit" (click)="searchUser()">
          <svg class="spot-icon" aria-labelledby="title">
			<title>search</title>
			<use xlink:href="/assets/spot/dist/icons/spot_icons.svg#search"></use>
          </svg>
		</button>
	  </form>
	</div>
  </div>
  <div *ngIf="foundUser" class="spot-card--primary">
	<header *ngIf="!foundUser.id" class="spot-card--header">
      <div class="spot-typography__heading--level-3">New User</div>
	</header>
	<div class="spot-card--body demo-card-height">
	  <form class="spot-form" style="width:85%;" >
		<div class="form-columns">
		  <div class="form-column">
			<div class="spot-form__field-group">
			  <label class="spot-form__label" for="foundGivenName">First Name</label>
			  <input class="spot-form__input" name="foundGivenName" #foundGivenName [(ngModel)]="foundUser.given_name">
			</div>
			<div class="spot-form__field-group">
			  <label class="spot-form__label" for="foundFamilyName">Last Name</label>
			  <input class="spot-form__input" name="foundFamilyName" #foundFamilyName [(ngModel)]="foundUser.family_name">
			</div>
			<div class="spot-form__field-group">
			  <label class="spot-form__label" for="foundEmail">Email</label>
			  <input class="spot-form__input" name="foundEmail" #foundEmail [(ngModel)]="foundUser.email">
			</div>

		  </div>
		  <div class="form-column">
			<div class="spot-form__field-group">
			  <label class="spot-form__label" for="foundAzureId">Azure Id</label>
			  <input class="spot-form__input" name="foundAzureId" #foundAzureId [(ngModel)]="foundUser.azure_id">
			  <span *ngIf="!foundUser.azure_id" class="spot-form__microcopy" id="password-help-text"> Azure Id will be populated the first time this user visits IRL </span>
			</div>
			<div class="spot-form__field-group">
			  <label class="spot-form__label" for="foundRole">Role</label>
			  <div class="spot-form__select">
				<select class="spot-form__select-input" name="foundRole" [(ngModel)]="foundUser.role" (change)="roleSelected(foundUser)" required [compareWith]="compareRole">
				  <option class="spot-form__select-option" name='' value=''></option>
				  <option class="spot-form__select-option" *ngFor="let role of roles" [ngValue]="role">{{role.name}}</option>
				</select>
				<div class="spot-form__select-inner">
				</div>
				<span class="spot-form__select-open-indicator">
				  <svg class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
					<title>caret-down</title>
					<use xlink:href="/assets/spot/dist/icons/spot_icons.svg#caret-down"></use>
				  </svg>
				</span>
			  </div>
			</div>
			<div class="spot-form__field-group">
			  <fieldset class="spot-form__checkbox-group" role="group">
				<legend class="spot-form__checkbox-group-label"> Inactive?</legend>
				<div class="spot-form__checkbox-group-inner">
				  <label class="spot-form__checkbox" for="foundInactive">
					<input type="checkbox" name="foundInactive" #foundInactive [(ngModel)]="foundUser.inactive" class="spot-form__checkbox-input">
					<span class="spot-form__checkbox-inner">
					  <span class="spot-form__checkbox-visual">
						<svg class="spot-icon spot-form__checkbox-checked-icon" aria-labelledby="title">
						  <title>checkmark</title>
						  <use xlink:href="/assets/spot/dist/icons/spot_icons.svg#checkmark"></use>
						</svg>
						<svg class="spot-icon spot-form__checkbox-indeterminate-icon" aria-labelledby="title">
						  <title>checkbox-indeterminate</title>
						  <use xlink:href="/assets/spot/dist/icons/spot_icons.svg#checkbox-indeterminate"></use>
						</svg>
					  </span>
					</span>
				  </label>
				</div>
			  </fieldset>
			</div>
		  </div>
		</div>
		<div class="spot-long-form-text">
		  <h2>Salesforce User Info</h2>
		</div>
		<div class="form-columns">
		  <div class="form-column">
			<div class="spot-form__field-group">
			  <label class="spot-form__label" for="foundUsername">Username</label>
			  <div name="foundUserName">{{foundUser.username}}</div>
			</div>
		  </div>
		  <div class="form-column">
			<div class="spot-form__field-group">
			  <label class="spot-form__label" for="foundSFUserId">SF User Id</label>
			  <div name="foundSFUserId">{{foundUser.user__c}}</div>
			</div>
		  </div>
		</div>
	  </form>
	</div>
	<footer class="spot-card--footer">
	  <button class="spot-button spot-button--primary" (click)="saveUser(foundUser)"> Save </button>
	  <button class="spot-button spot-button--link" (click)="cancelUser(foundUser)"> Cancel </button>
	</footer>
  </div>
</div>
