import {Component} from '@angular/core';
import {FieldComponent} from './field.component';
import {Input} from '@angular/core';
import {Field} from './field.component';

@Component({
    selector: "mad-micro",
    templateUrl: "./micro.component.html",
    styleUrls: ['./micro.component.scss'],
    providers: [{provide: FieldComponent, useExisting: FieldComponent}]
})
export class MicroComponent {
  @Input() disabled: boolean;

  constructor (public field: Field) {
  }

  getDisabled () {
      var field: Field = this.field.parentField || this.field;
      return (this.disabled !== undefined ? this.disabled : field && field.disabled) && true || false;
  }
}
