import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {PillComponent} from './pill.component';
import {RippleModule} from './../ripple/ripple.module';

@NgModule({
  imports: [
     BrowserModule,
     CommonModule,
     RippleModule
  ],
  declarations:[
    PillComponent
  ],
  exports: [
    PillComponent
  ],
  bootstrap: [PillComponent]
})
export class PillModule { }
